import React from 'react'

import PropTypes from 'prop-types'

// Components
import { Reports } from '../../components/Reports'
import { StateContainer } from '../../components/StateContainer'

// Utils & Service
import {
  createAdminSummaryReport,
  createAdminTransactionReport,
  getAdminReport,
} from '../../services/reports.service'

const CustomTick = ({ value, x, y }) => {
  if (value === 'Hot')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥳
        </text>
      </g>
    )
  if (value === 'Warm')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🤔
        </text>
      </g>
    )
  if (value === 'Cold')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥶
        </text>
      </g>
    )
  return null
}

CustomTick.propTypes = {
  value: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

const AdminReports = () => (
  <div className="h-full">
    <StateContainer stripedBackground>
      <div className="relative mt-1.5 flex h-full w-full flex-col space-y-2 overflow-y-auto rounded-lg bg-background p-3 shadow-md md:h-[85%] md:w-[90%] md:p-4">
        <Reports
          getReport={getAdminReport}
          createExcelReport={createAdminSummaryReport}
          createAdminTransactionReport={createAdminTransactionReport}
        />
      </div>
    </StateContainer>
  </div>
)

export default AdminReports
