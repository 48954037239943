// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Adds new File.
 * @param {object} payload
 * @param {object} cancelToken
 */
export const addFile = async (payload, cancelToken) => {
  try {
    const { data } = await axios.post(`/files/`, payload, { cancelToken })

    return data
  } catch (err) {
    return getErrorMessage(err)
  }
}

/**
 * Updates the File.
 * @param {string} fileId
 * @param {object} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 * @param {func} setUpdatedFile
 */
export const updateFile = async (
  fileId,
  payload,
  setLoading,
  setError,
  setSuccess,
  setUpdatedFile,
) => {
  setLoading(true)

  try {
    const { data } = await axios.patch(`/files/${fileId}`, payload)

    setSuccess(true)
    setLoading(false)
    setUpdatedFile(data)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Delete the File.
 * @param {string} fileId
 * @param {number} fileIndex
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 * @param {func} setUpdatedFiles
 */
export const deleteFile = async (
  fileId,
  fileIndex,
  setLoading,
  setError,
  setSuccess,
  setUpdatedFiles,
) => {
  setLoading(true)

  try {
    await axios.delete(`/files/${fileId}`)

    setSuccess(true)
    setLoading(false)

    /** @todo: replace with whatever handler for `files` - this example would be updating `files` in state with a reducer function `updateArrayState` */
    setUpdatedFiles({ type: 'remove', value: fileIndex })
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Gets the File.
 * @param {string} fileId
 * @param {object} cancelToken
 * @param {func} onProgress
 */
export const getFile = async (fileId, cancelToken, onProgress) => {
  try {
    const { data } = await axios.get(`/files/${fileId}`, {
      cancelToken,
      onUploadProgress: onProgress,
    })

    return data
  } catch (err) {
    return getErrorMessage(err)
  }
}
