import React, { Fragment } from 'react'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'

// Utils
import { joinClassNames } from '../../utils/helpers'

const MultiSelectDropdown = ({
  customButton,
  id,
  label,
  onChange,
  options,
  placeholder,
  style,
  value,
}) => {
  let inputTextColor = 'text-gray-500'
  if (value) inputTextColor = 'text-gray-900'

  return (
    <div style={style}>
      <Listbox multiple onChange={onChange} value={value}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                {label}
              </Listbox.Label>
            )}
            <div className="relative">
              <ListboxButton
                className={
                  customButton
                    ? 'relative cursor-pointer'
                    : 'relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple sm:text-sm sm:leading-6'
                }
                disabled={!!customButton}
                id={id}
                onClick={(e) => e.stopPropagation()}
              >
                {customButton || (
                  <>
                    <span className={`block h-6 truncate ${inputTextColor}`}>
                      {value ? value.name : placeholder}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </>
                )}
              </ListboxButton>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions
                  className={joinClassNames(
                    customButton ? 'w-40' : 'w-full',
                    'absolute z-20 mt-1 max-h-60 min-w-[250px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-300 focus:outline-none sm:text-sm',
                  )}
                  onClick={(e) => e.stopPropagation()}
                >
                  {options.map((o) => (
                    <ListboxOption
                      id={o.id}
                      key={o.id}
                      className={({ active }) =>
                        joinClassNames(
                          active ? 'bg-purple text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                        )
                      }
                      value={o}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={joinClassNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {o.name}
                          </span>

                          {selected ? (
                            <span
                              className={joinClassNames(
                                active ? 'text-white' : 'text-purple',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

MultiSelectDropdown.defaultProps = {
  customButton: null,
  label: null,
  placeholder: 'Select an Option',
  style: {},
  value: [],
}

MultiSelectDropdown.propTypes = {
  customButton: PropTypes.element,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.array,
}

export default MultiSelectDropdown
