import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * LockIcon
 *
 */
const LockIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

LockIcon.defaultProps = {
  className: null,
}

LockIcon.propTypes = {
  className: PropTypes.string,
}

export default LockIcon
