import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircleDashed } from 'tabler-icons-react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
// eslint-disable-next-line import/no-cycle
import { Dialog } from '../Dialog'

const Button = ({
  background,
  borderless,
  className,
  confirm,
  disabled,
  fullWidth,
  icon,
  id,
  label,
  loading,
  onClick,
  outlined,
  size,
  type,
  ...rest
}) => {
  // State
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const configureBackground = () => {
    if (background === 'bg-white') {
      return `${background} border-gray-400 text-gray hover:bg-gray-100 focus:ring-purple`
    }

    if (background === 'bg-red') {
      return `${background} border-red text-white hover:bg-red-400 focus:ring-red`
    }

    if (background === 'bg-purple-100') {
      return `${background} border-purple-600 text-gray hover:border-purple focus:ring-purple`
    }

    if (background === 'bg-purple-400') {
      return `${background} border-purple-800 text-gray hover:border-purple focus:ring-purple`
    }

    if (background === 'bg-green-50') {
      return `${background} border-green-50 text-green-800 hover:border-green-100 hover:bg-green-100`
    }

    if (background === 'bg-orange') {
      return `${background} border-orange text-white hover:bg-orange-300`
    }

    if (background === 'bg-teal') {
      return `${background} border-teal text-dark hover:bg-teal-light hover:border-teal-light`
    }

    return `${background} text-white hover:bg-purple-600 focus:ring-purple border-none`
  }

  const configureClass = () => {
    let base =
      'group relative flex justify-center border font-medium focus:outline-none disabled:cursor-not-allowed disabled:opacity-50'
    let updates = ''

    // Configure border radius, padding and text size
    switch (size) {
      case 'xs':
        base = `${base} rounded py-1.5 px-2.5 text-xs`

        if (icon || loading) base = `${base} pl-8 pr-2`
        break
      case 'sm':
        base = `${base} rounded-md py-2 px-3 text-sm leading-4`

        if (icon || loading) base = `${base} pl-8 pr-2.5`
        break
      case 'md':
        base = `${base} rounded-md sm:py-2 sm:px-4 sm:text-sm sm:leading-5 px-2 text-xs py-1.5`

        if (icon || loading) base = `${base} sm:pl-9 sm:pr-3.5 pl-7 pr-2`
        break
      case 'lg':
        base = `${base} rounded-md py-2 px-4 text-base`

        if (icon || loading) base = `${base} pl-9 pr-3.5`
        break
      case 'xl':
        base = `${base} rounded-md py-3 px-5 text-base`

        if (icon || loading) base = `${base} pl-9 pr-3.5`
        break
      default:
        base = `${base} rounded-md py-2 px-4 text-xs`

        if (icon || loading) base = `${base} pl-8 pr-2.5`
    }

    if (fullWidth) {
      updates = `${updates} w-full`
    }

    if (borderless) {
      updates = `${updates} border-transparent`
    }

    if (outlined) {
      updates = `${updates} border-black hover:border-black-hover`
    }

    return mergeClassNames(base, updates)
  }

  return (
    <>
      <button
        id={id}
        onClick={() => {
          if (confirm) {
            setShowConfirmDialog(true)
          } else if (onClick) {
            onClick()
          }
        }}
        className={mergeClassNames(configureClass(), configureBackground(), className)}
        disabled={disabled || loading}
        // eslint-disable-next-line react/button-has-type
        type={type || 'submit'}
        style={{
          boxShadow: outlined ? '0px 1px 2px 0px #0000000D' : '',
        }}
        {...rest}
      >
        {icon && !loading && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-[5px] sm:pl-[10px]">
            {icon}
          </span>
        )}
        {label}
        {loading && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <div className="h-6 w-6">
              <svg className="mr-3 h-6 w-6 motion-safe:animate-spin-slow" viewBox="0 0 24 24">
                <CircleDashed size={24} strokeWidth={2} color="white" />
              </svg>
            </div>
          </span>
        )}
      </button>

      {showConfirmDialog && (
        <Dialog
          {...confirm}
          onConfirm={() => {
            setShowConfirmDialog(false)
            onClick()
          }}
          onCancel={() => setShowConfirmDialog(false)}
        />
      )}
    </>
  )
}

Button.propTypes = {
  background: PropTypes.string,
  borderless: PropTypes.bool,
  className: PropTypes.string,
  confirm: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  type: PropTypes.string,
}

Button.defaultProps = {
  background: 'bg-purple',
  borderless: false,
  className: '',
  confirm: null,
  disabled: false,
  fullWidth: false,
  icon: null,
  id: null,
  loading: false,
  outlined: false,
  size: 'md',
  type: 'submit',
}

export default Button
