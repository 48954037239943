import React, { useContext, useEffect } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

/**
 * PrivateRoute
 *
 * If a user is unauthenticated and attempts to access this route, they will be
 * redirected to the login page, otherwise we return the requested route.
 */
const PrivateRoute = observer(({ component: Component, roles, ...rest }) => {
  // Context
  const { eventId, organizationId, setEventId, setOrganizationId } =
    useContext(NavigationStoreContext)
  const { isAuthenticated } = useContext(UserStoreContext)
  const location = useLocation()
  const { eventId: updatedEventId, orgId: updatedOrganizationId } = useParams()

  /**
   * Update the stored `event` and `organization` values in case the user
   * navigates to another record that hasn't been marked as active.
   */
  useEffect(() => {
    if (isAuthenticated) {
      if (updatedOrganizationId !== organizationId && updatedOrganizationId !== undefined) {
        setOrganizationId(updatedOrganizationId)
      }

      if (updatedEventId !== eventId && updatedEventId !== undefined) {
        setEventId(updatedEventId)
      }
    }
  }, [location.pathname])

  // User is not authenticated - redirect to Login with current location passed along
  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    )
  }

  // Base case - render the requested component
  return <Component {...rest} />
})

export default PrivateRoute
