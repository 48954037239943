import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * LinkIcon
 *
 */
const LinkIcon = ({ className }) => (
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5 6C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7L4 13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14C5.26522 14 5.51957 13.8946 5.70711 13.7071C5.89464 13.5196 6 13.2652 6 13L6 7C6 6.73478 5.89464 6.48043 5.70711 6.29289C5.51957 6.10536 5.26522 6 5 6ZM2 8L2 5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2C5.79565 2 6.55871 2.31607 7.12132 2.87868C7.68393 3.44129 8 4.20435 8 5L8 8C8 8.26522 8.10536 8.51957 8.29289 8.70711C8.48043 8.89464 8.73478 9 9 9C9.26522 9 9.51957 8.89464 9.70711 8.70711C9.89464 8.51957 10 8.26522 10 8L10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 -1.60592e-07 5 -2.18557e-07C3.67392 -2.76522e-07 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 -1.60592e-07 3.67392 -2.18557e-07 5L-3.49691e-07 8C-3.61284e-07 8.26522 0.105356 8.51957 0.292892 8.70711C0.480428 8.89464 0.734783 9 1 9C1.26522 9 1.51957 8.89464 1.70711 8.70711C1.89464 8.51957 2 8.26522 2 8ZM10 15L10 12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12L8 15C8 15.7956 7.68393 16.5587 7.12132 17.1213C6.55871 17.6839 5.79565 18 5 18C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15L2 12C2 11.7348 1.89464 11.4804 1.70711 11.2929C1.51957 11.1054 1.26522 11 1 11C0.734783 11 0.480428 11.1054 0.292892 11.2929C0.105356 11.4804 -5.12944e-07 11.7348 -5.24537e-07 12L-6.55671e-07 15C-7.13636e-07 16.3261 0.526783 17.5979 1.46447 18.5355C2.40215 19.4732 3.67392 20 5 20C6.32608 20 7.59785 19.4732 8.53553 18.5355C9.47322 17.5979 10 16.3261 10 15Z"
      fill="inherit"
    />
  </svg>
)

LinkIcon.defaultProps = {
  className: null,
}

LinkIcon.propTypes = {
  className: PropTypes.string,
}

export default LinkIcon
