import { useState, useEffect } from 'react'
import _ from 'lodash'

const useFonts = (fonts) => {
  // State
  const [fontFaces, setFontFaces] = useState()

  const loadFontFace = async (fontFace) => {
    const loadedFont = await fontFace.load()
    document.fonts.add(loadedFont)
  }

  useEffect(() => {
    if (fontFaces) {
      fontFaces.forEach((fontFace) => {
        loadFontFace(fontFace)
      })
    }
  }, [fontFaces])

  const convertFontFiletoBase64 = async (file) => {
    const base64 = await new Promise((resolve) => {
      const fileReader = new FileReader()
      fileReader.onload = () => resolve(fileReader.result)
      fileReader.readAsDataURL(file)
    })
    return base64
  }

  useEffect(() => {
    if (fonts && fonts.length > 0) {
      // Filter out any null fonts
      const updatedFonts = _.filter(fonts, (f) => f !== null)

      // Load the signed URLs for the fonts
      Promise.all(
        updatedFonts.map(async (font) => {
          const file = await fetch(font.signedUrl)
          const blob = await file.blob()

          // Convert the font files to base64
          const base64 = await convertFontFiletoBase64(blob)
          return { ...font, base64 }
        }),
      ).then((processedFonts) => {
        setFontFaces(
          processedFonts.map(
            (font) => new FontFace(font.name, `url(${font.base64})`, ['regular', 'italic']),
          ),
        )
      })
    }
  }, [fonts])
}

export default useFonts
