import React from 'react'
import PropTypes from 'prop-types'

const ThumbsUpIcon = ({ className }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 17C0 15.3431 1.34315 14 3 14C4.65685 14 6 15.3431 6 17V29C6 30.6569 4.65685 32 3 32C1.34315 32 0 30.6569 0 29V17Z"
      fill="inherit"
    />
    <path
      d="M8 16.6667V27.5279C8 29.043 8.85601 30.428 10.2111 31.1056L10.3108 31.1554C11.4217 31.7108 12.6466 32 13.8885 32H24.7208C26.6275 32 28.2692 30.6542 28.6431 28.7845L31.0431 16.7845C31.5381 14.3093 29.645 12 27.1208 12H20V4C20 1.79086 18.2091 0 16 0C14.8954 0 14 0.89543 14 2V3.33333C14 5.0643 13.4386 6.74856 12.4 8.13333L9.6 11.8667C8.56142 13.2514 8 14.9357 8 16.6667Z"
      fill="inherit"
    />
  </svg>
)

ThumbsUpIcon.defaultProps = {
  className: null,
}

ThumbsUpIcon.propTypes = {
  className: PropTypes.string,
}

export default ThumbsUpIcon
