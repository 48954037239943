import React from 'react'
import PropTypes from 'prop-types'
import { CameraIcon } from '@heroicons/react/20/solid'

// Components
import { Modal } from '../Modal'

// Icons
import { UserIcon } from '../UserIcon'

/**
 *
 * PhotoVerificationPopup
 *
 */
const PhotoVerificationPopup = ({ attendee, close, continueCheckIn }) => (
  <Modal
    actions={[
      {
        type: 'cancel',
        label: 'Cancel',
        onClick: close,
      },
      {
        type: 'submit',
        label: 'Verify & Continue',
        onClick: () => continueCheckIn(),
      },
    ]}
    icon={<CameraIcon className="h-5 fill-white sm:h-6" />}
    content={
      <div className="mt-3 sm:mt-5">
        <span className="text-sm text-gray-600">
          Please verify that the photo from registration matches the person checking in.
        </span>
        <div className="flex justify-center">
          {attendee.photoVerificationBase64 ? (
            <img
              className="mb-4 mt-8 h-48 w-48 rounded-lg"
              src={`data:image/png;base64,${attendee.photoVerificationBase64}`}
              alt="Attendee"
            />
          ) : (
            <div className="mb-4 mt-8 flex h-48 w-48 items-center justify-center rounded-lg bg-gray-200">
              <span className="text-sm text-gray-400">
                <UserIcon className="h-24 w-24 stroke-black" />
              </span>
            </div>
          )}
        </div>
      </div>
    }
    open
    title="Photo Verification"
  />
)

PhotoVerificationPopup.propTypes = {
  attendee: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  continueCheckIn: PropTypes.func.isRequired,
}

export { PhotoVerificationPopup }
