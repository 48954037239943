/**
 * Pixel Per Inch
 *
 * Panels are sized in inches and are based on the standard badge sizes. We
 * convert the inches to pixels for the canvas.
 */
export const PIXEL_PER_INCH = 96

/**
 * Countries that require the GDPR popup to be displayed.
 */
export const GDPR_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'The Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'United Kingdom',
]

/**
 * Base attendee fields that will always be avialable.
 */
export const NON_SYNCED_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'title',
  'companyName',
  'phoneNumber',
]

export const BASE_RECIPIENT_FIELDS = [
  'recipientFirstName',
  'recipientLastName',
  'recipientEmail',
  'defaultLoginUrl',
  'exhibitorLoginUrl',
  'fetchLoginUrl',
]

/**
 * Available merge fields for each email template context.
 */
export const CONTEXT_MERGE_FIELDS = {
  Event: [
    'eventName',
    'eventLocation',
    'eventStartDate',
    'eventEndDate',
    'eventDateRange',
    'eventTechNotes',
  ],
  Organizer: ['organizerName'],
  'Event Exhibitor': [
    'exhibitorName',
    'exhibitorContactFirstName',
    'exhibitorContactLastName',
    'exhibitorContactEmail',
    'exhibitorTasksCompleted',
    'exhibitorTasksIncomplete',
    'exhibitorTasksTotal',
    'exhibitorTotalLicenses',
    'exhibitorAssignedLicenses',
    'exhibitorUnassignedLicenses',
  ],
  Attendee: ['attendeeFirstName', 'attendeeLastName', 'attendeeCompanyName', 'attendeeEmail'],
  'Assigned By': ['assignedByFirstName', 'assignedByLastName', 'assignedByEmail'],
  'Scanned By': ['scannedByFirstName', 'scannedByLastName', 'scannedByEmail'],
  Transaction: ['transactionLicenseCount', 'transactionRentalDeviceCount'],
  'Get Started': ['getStartedURL'],
}
