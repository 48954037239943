import React, { useEffect, useRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Components
import { Button } from '../../components/Button'
import { Modal } from '../../components/Modal'
import { Popup } from '../../components/Popup'
import { TextInput } from '../../components/TextInput'

// Service
import { getGlobalPopups } from '../../services/global.service'

// Utils
import { toast } from '../../utils/helpers'
import { NON_SYNCED_FIELDS } from '../../utils/constants'

dayjs.extend(relativeTime)

const TABS = [
  {
    id: 0,
    name: 'Attendee',
  },
  {
    id: 1,
    name: 'Staff',
  },
]

/**
 *
 * Popups
 *
 */
const Popups = ({ event, kioskConfiguration, setKioskConfiguration }) => {
  const mergeFields =
    event.enableRegistrationSync && event.enabledCustomFields
      ? [...NON_SYNCED_FIELDS, ...event.enabledCustomFields]
      : NON_SYNCED_FIELDS

  // State
  const [globalPopups, setGlobalPopups] = useState([])
  const [active, setActive] = useState(TABS[0])
  const [showMergeFieldModal, setShowMergeFieldModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredMergeFields, setFilteredMergeFields] = useState(mergeFields)

  // Ref
  const searchInputRef = useRef()

  const handleSuccess = (message) => toast(message, 'success')

  useEffect(() => {
    const getDefaultPopups = async () => {
      const results = await getGlobalPopups(
        () => {},
        () => {},
      )

      setGlobalPopups(results)
    }

    getDefaultPopups()
  }, [])

  const renderPopups = () => {
    const filteredPopups = _.filter(kioskConfiguration.kioskPopups, (p) =>
      _.includes(p.identifier, _.lowerCase(active.name)),
    )

    return _.map(filteredPopups, (popup, i) => (
      <div className="py-2" key={`${popup.id}`}>
        <Popup
          defaultPopup={_.find(globalPopups, { identifier: popup.identifier })}
          eventId={event.id}
          kioskId={kioskConfiguration.id}
          popup={popup}
          updateData={(updatedPopup) => {
            const updatedPopups = _.map(kioskConfiguration.kioskPopups, (p) =>
              p.id === updatedPopup.id ? updatedPopup : p,
            )
            setKioskConfiguration({ ...kioskConfiguration, kioskPopups: updatedPopups })
          }}
        />

        {i !== filteredPopups.length - 1 && <hr className="mt-6 border-gray-300" />}
      </div>
    ))
  }

  return (
    <div className="flex h-full flex-col py-6">
      <div className="flex w-full flex-row items-start justify-between px-8">
        <div className="flex flex-row space-x-6">
          <div className="text-2xl font-bold">Pop-Ups</div>

          <nav className="flex space-x-2 sm:space-x-6" aria-label="Tabs">
            {TABS.map((tab) => {
              const activeTab = active.id === tab.id

              return (
                <button
                  type="button"
                  key={tab.name}
                  onClick={() => setActive(tab)}
                  className={mergeClassNames(
                    activeTab
                      ? 'border-purple-700 text-purple-700'
                      : 'border-white text-gray-500 hover:border-purple-700 hover:text-purple-700',
                    'border-b-2 text-sm font-medium sm:px-2',
                  )}
                  aria-current={activeTab ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              )
            })}
          </nav>
        </div>

        <Button
          background="bg-purple-100"
          className="border-purple-100 text-purple-700"
          label="Merge Fields"
          onClick={() => setShowMergeFieldModal(true)}
        />
      </div>

      <div className="flex h-full flex-col overflow-y-auto px-8">{renderPopups()}</div>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              setShowMergeFieldModal(false)
            },
          },
        ]}
        icon={<ClipboardDocumentListIcon className="h-5 stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex max-h-56 flex-col sm:mt-5">
            <TextInput
              autoComplete="off"
              className="w-[90%] self-center rounded-lg py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-purple" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      setFilteredMergeFields(mergeFields)
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                setFilteredMergeFields(
                  _.filter(mergeFields, (o) => o.toLowerCase().includes(e.target.value)),
                )

                setSearchTerm(e.target.value)
              }}
              placeholder="Search"
              ref={searchInputRef}
              type="text"
              value={searchTerm}
            />

            <div className="mx-8 mt-2 flex flex-col overflow-y-scroll">
              {_.map(filteredMergeFields, (f) => (
                <button
                  className="group flex cursor-pointer flex-row"
                  key={`${f}`}
                  onClick={() => {
                    navigator.clipboard.writeText(`{{ ${f} }}`)
                    handleSuccess('Merge field copied to clipboard!')
                  }}
                  type="button"
                >
                  <span className="group-hover:text-purple">{f}</span>

                  <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-purple" />
                </button>
              ))}
            </div>
          </div>
        }
        open={showMergeFieldModal}
        title="Copy Merge Field"
      />
    </div>
  )
}

Popups.propTypes = {
  event: PropTypes.object.isRequired,
  kioskConfiguration: PropTypes.object.isRequired,
  setKioskConfiguration: PropTypes.func.isRequired,
}

export { Popups }
