import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Routes, Route } from 'react-router-dom'
import { observer } from 'mobx-react'
import _ from 'lodash'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

// Pages
import { NotFound } from '../NotFound'

// Components
import { NavBar } from '../../components/NavBar'
import { PrivateRoute } from '../../components/PrivateRoute'
import { PublicRoute } from '../../components/PublicRoute'
import { StateContainer } from '../../components/StateContainer'
import { TaskProgress } from '../../components/TaskProgress'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Routes
import routes from './RouteConfig'

/**
 *
 * Routes
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
const PageRoutes = observer(() => {
  // Context
  const { accessToken, error, getUpdatedUser, isAuthenticated, isEEUser, user } =
    useContext(UserStoreContext)

  useEffect(() => {
    if (!user && isAuthenticated) {
      const decoded = jwt_decode(accessToken)
      getUpdatedUser(decoded.user_id, true)
    }
  }, [])

  return (
    <div className="relative h-screen w-screen">
      <StateContainer error={error} loading={isAuthenticated && isEEUser === null}>
        <Helmet titleTemplate="%s - Executivevents Portal" defaultTitle="Executivevents Portal">
          <meta name="description" content="Executivevents Portal" />
        </Helmet>

        <NavBar>
          <Routes>
            {/* Main Routes */}
            <Route key="not-found" path="*" element={<NotFound />} />

            {_.map(routes, (route) => {
              const element =
                route.type === 'public' ? (
                  <PublicRoute component={route.page} />
                ) : (
                  <PrivateRoute component={route.page} />
                )

              return <Route key={route.path} element={element} caseSensitive path={route.path} />
            })}
          </Routes>
        </NavBar>
      </StateContainer>

      <TaskProgress />
    </div>
  )
})

export default PageRoutes
