/* eslint-disable import/no-cycle */
import { createContext } from 'react'
import { action, observable } from 'mobx'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  eventId: null,
  globalBrandSettings: null,
  KPIs: null,
  KPIsLastUpdated: null,
  location: null,
  mode: null,
  name: null,
}

const TYPES = {
  eventId: observable,
  globalBrandSettings: observable,
  location: observable,
  KPIs: observable,
  KPIsLastUpdated: observable,
  mode: observable,
  name: observable,
}

export class KioskStore extends BaseStore {
  startNewKiosk = action((eventId, location, mode, name) => {
    this.eventId = eventId
    this.location = location
    this.mode = mode
    this.name = name
  })

  updateKiosk = action((location, mode, name) => {
    this.location = location
    this.mode = mode
    this.name = name
  })

  resetKiosk = action(() => {
    this.eventId = null
    this.location = null
    this.mode = null
    this.name = null
  })

  setGlobalBrandSettings = action((globalBrandSettings) => {
    this.globalBrandSettings = globalBrandSettings
  })

  setKPIs = action((KPIs, lastUpdated) => {
    this.KPIs = KPIs
    this.KPIsLastUpdated = lastUpdated
  })
}

export const kiosk = new KioskStore(INITIAL_STATE, TYPES)
export const KioskStoreContext = createContext(kiosk)
