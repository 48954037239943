import React from 'react'
import PropTypes from 'prop-types'

// Images
import fetchDog from '../../assets/images/fetch_dog.png'
import rocketPack from '../../assets/images/rocket_pack.png'
import executiveEvents from '../../assets/images/executive_events_text_logo.svg'

/**
 *
 * Alert
 *
 */
const AuthSplash = ({ exhibitor }) => (
  <div
    className="flex h-full items-end px-24 pb-28 text-white"
    style={{
      backgroundImage: `url(${exhibitor ? fetchDog : rocketPack})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }}
  >
    <div>
      <h2 className="text-center text-6xl font-bold">
        {exhibitor ? 'Your new best friend in lead retrieval!' : 'Get the most out of your event.'}
      </h2>

      {!exhibitor && (
        <div className="mt-6 flex gap-x-3">
          Powered by <img src={executiveEvents} alt="" />
        </div>
      )}
    </div>
  </div>
)

AuthSplash.defaultProps = {
  exhibitor: false,
}

AuthSplash.propTypes = {
  exhibitor: PropTypes.bool,
}

export default AuthSplash
