import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { XCircleIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'

// Components
import { Modal } from '../../components/Modal'
import { UnlockIcon } from '../../components/UnlockIcon'
import { VerificationCodeInput } from '../../components/VerificationCodeInput'

// Utils & Style
import { decrypt } from '../../utils/helpers'

/**
 *
 * AdminOverride
 *
 */
const AdminOverride = ({ configuration, setShow, submit }) => {
  const {
    control: passwordControl,
    formState: { errors: passwordErrors },
    handleSubmit: handleSubmitPassword,
    setError: setPasswordError,
  } = useForm({
    defaultValues: {
      adminPassword: null,
    },
  })

  /**
   * Handles submitting the admin password form.
   * @param {object} data
   */
  const onSubmitAdminPassword = async (data) => {
    // Decrypt the admin password and check if it matches the entered password
    const authorized = (await decrypt(configuration.adminPassword)) === data.adminPassword

    if (authorized) {
      submit()
    } else {
      setPasswordError('adminPassword', {
        type: 'manual',
        message: 'Incorrect password.',
      })
    }
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
        },
        {
          type: 'submit',
          label: 'Enter',
          onClick: handleSubmitPassword(onSubmitAdminPassword),
        },
      ]}
      content={
        <div className="mt-2 flex flex-col items-center justify-center space-y-2">
          <Controller
            name="adminPassword"
            control={passwordControl}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <VerificationCodeInput
                maskInput
                backgroundColor="white"
                borderColor="gray"
                fontColor="black"
                error={
                  passwordErrors.adminPassword && (
                    <div className="flex items-center gap-2 rounded-lg bg-error-light px-4 py-2 font-medium text-error-dark">
                      <span className="h-5 w-5">
                        <XCircleIcon className="text-error-icon" />
                      </span>
                      {passwordErrors.adminPassword.message}
                    </div>
                  )
                }
                handleSubmitCode={(p) => onChange(p)}
                inputStyle="separated"
                containerStyles={{ alignItems: 'center' }}
                codeLength={4}
              />
            )}
          />
        </div>
      }
      icon={<UnlockIcon className="h-12 fill-white" />}
      onClose={() => setShow(false)}
      open
      setOpen={() => setShow(false)}
      title="Admin Password Required"
    />
  )
}

AdminOverride.propTypes = {
  configuration: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}

export { AdminOverride }
