import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import PropTypes from 'prop-types'

// Components
import { Button } from '../../components/Button'
import { Modal } from '../../components/Modal'
import { PasswordInput } from '../../components/PasswordInput'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'
import { EditIcon } from '../../components/EditIcon'
import { TrashIcon } from '../../components/TrashIcon'
import { AddLocationIcon } from '../../components/AddLocationIcon'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Location from '../../assets/images/location.svg'

// Service
import {
  addDeviceLocation,
  deleteDeviceLocation,
  updateDeviceLocation,
  updateKioskConfiguration,
} from '../../services/kiosks.service'

// Utils
import { toast } from '../../utils/helpers'

const DEFAULT = {
  locationName: '',
}

const VERIFICATION_FIELD_OPTIONS = [
  { label: 'Email', id: 'email' },
  { label: 'Phone Number', id: 'phoneNumber' },
]

const KioskSettings = ({ event, kioskConfiguration, setKioskConfiguration }) => {
  // State
  const [showModal, setShowModal] = useState(false)
  const [editLocation, setEditLocation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [adminPassword, setAdminPassword] = useState(kioskConfiguration.adminPassword)
  const [passwordError, setPasswordError] = useState(false)

  const handleSuccess = (m) => toast(m, 'success')
  const handleError = (m) => toast(m, 'error')

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  const resetForm = () => {
    setEditLocation(false)
    setShowModal(false)
    reset(DEFAULT)
  }

  /**
   * Handles submitting the kiosk location form (either creating or updating).
   * @param {object} data
   */
  const onSubmit = (data) => {
    if (editLocation) {
      modifyLocation({ name: data.locationName, id: data.locationId })
    } else {
      createDeviceLocation(data.locationName)
    }
    resetForm()
  }

  /**
   * Saves kiosk configuration to BE
   */
  const saveConfig = async (newConfig) => {
    const response = await updateKioskConfiguration(
      event.id,
      newConfig,
      () => {},
      () => {},
      handleSuccess,
    )

    setKioskConfiguration(response)
  }

  /**
   * Debounce saving the kiosk configuration to BE
   */
  const saveUpdatedConfig = useCallback(_.debounce(saveConfig, 500), [kioskConfiguration])

  /**
   * Updates kiosk configuration and saves to BE
   */
  const handleChanges = async (newConfig) => {
    setKioskConfiguration(newConfig)
    await saveUpdatedConfig(newConfig)
  }

  /**
   * Creates a device location
   * @param {object} location
   */
  const createDeviceLocation = async (location) => {
    const response = await addDeviceLocation(
      event.id,
      kioskConfiguration.id,
      { name: location },
      handleError,
      setLoading,
      handleSuccess,
    )

    if (response) {
      setKioskConfiguration({
        ...kioskConfiguration,
        kioskDeviceLocations: [...(kioskConfiguration.kioskDeviceLocations || []), response],
      })
    }
  }

  /**
   * Modifies a device location
   * @param {object} location
   */
  const modifyLocation = async (location) => {
    const response = await updateDeviceLocation(
      event.id,
      kioskConfiguration.id,
      { name: location.name, id: location.id },
      handleError,
      setLoading,
      handleSuccess,
    )

    if (response) {
      setKioskConfiguration({
        ...kioskConfiguration,
        kioskDeviceLocations: [
          ...kioskConfiguration.kioskDeviceLocations.filter((l) => l.id !== location.id),
          response,
        ],
      })
    }
  }

  const hasLocations =
    kioskConfiguration.kioskDeviceLocations && kioskConfiguration.kioskDeviceLocations.length > 0

  const verificationOptions = useMemo(() => {
    const options = [...VERIFICATION_FIELD_OPTIONS]
    if (event.enabledCustomFields) {
      options.push({ label: 'Confirmation Code', id: 'attendeeid' })
    }
    return options
  }, [event.enableRegistrationSync])

  return (
    <div className="overflow-y-auto px-8 py-6">
      <div className="mb-6 text-2xl font-bold">Kiosk Settings</div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col gap-7 sm:basis-1/2">
          <div className="w-[312px]">
            <div className="font-bold">Admin Password</div>
            <PasswordInput
              disableAutoComplete
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              hideTooltip
              id="admin-password"
              label="Admin Password"
              name="adminPassword"
              error={passwordError && 'Password must be exactly 4 digits (no characters).'}
              type="number"
              pattern="\d{4}"
              minLength={4}
              maxLength={4}
              onChange={(e) => setAdminPassword(e.target.value)}
              onBlur={(e) => {
                if (e.target.value === kioskConfiguration.adminPassword) return
                // if the value is four digits it's valid
                if (/^\d{4}$/.test(e.target.value)) {
                  handleChanges({
                    ...kioskConfiguration,
                    adminPassword: e.target.value,
                  })
                  setPasswordError(false)
                } else {
                  setPasswordError(true)
                }
              }}
              value={adminPassword}
            />
          </div>

          <div className="w-[312px]">
            <div className="mb-1 font-bold">Attendee Verification</div>
            <Toggle
              className="mb-3"
              checked={kioskConfiguration.useVerification}
              id="useVerification"
              label="Require Verification at Self Check-In"
              labelClassName="sm:flex-none"
              onChange={() =>
                handleChanges({
                  ...kioskConfiguration,
                  useVerification: !kioskConfiguration.useVerification,
                })
              }
            />
            {kioskConfiguration.useVerification && (
              <Select
                openAbove
                className="rounded-2xl border-gray-550 py-2.5 pl-4 pr-9 text-gray-550 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                id="verification-field"
                label="Verification Field"
                name="verificationField"
                options={verificationOptions}
                value={_.find(verificationOptions, {
                  id: kioskConfiguration.verificationField,
                })}
                onChange={(o) => handleChanges({ ...kioskConfiguration, verificationField: o.id })}
              />
            )}
            <Toggle
              className="mb-3"
              checked={kioskConfiguration.requireKioskPhotoVerification}
              id="requireKioskPhotoVerification"
              label="Require Photo Verification at Staff Check-In"
              labelClassName="sm:flex-none"
              onChange={() =>
                handleChanges({
                  ...kioskConfiguration,
                  requireKioskPhotoVerification: !kioskConfiguration.requireKioskPhotoVerification,
                })
              }
            />
          </div>

          {event.enabledCustomFields && (
            <div className="w-[312px] pb-4 sm:pb-24">
              <div className="mb-1 font-bold">Attendee Notes Field from Registration System</div>
              <Select
                openAbove
                search
                className="rounded-2xl border-gray-550 py-2.5 pl-4 pr-9 text-gray-550 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                id="attendee-notes"
                name="attendeeNotes"
                options={_.map(event.enabledCustomFields, (field) => ({
                  id: field,
                  label: field,
                }))}
                value={{
                  id: kioskConfiguration.externalPopupField,
                  label: kioskConfiguration.externalPopupField,
                }}
                onChange={(e) => {
                  if (e.id === kioskConfiguration.externalPopupField) {
                    handleChanges({
                      ...kioskConfiguration,
                      externalPopupField: null,
                      useExternalPopupField: false,
                    })
                  } else {
                    handleChanges({
                      ...kioskConfiguration,
                      externalPopupField: e.id,
                      useExternalPopupField: true,
                    })
                  }
                }}
              />
            </div>
          )}
        </div>

        <div className="sm:basis-1/2">
          <div className="flex justify-between">
            <div className="font-bold">Device Locations</div>
            <Button
              icon={<AddLocationIcon className="h-5 fill-white" />}
              label="Add Location"
              onClick={() => setShowModal(true)}
            />
          </div>

          <div className="mt-2">
            {hasLocations ? (
              kioskConfiguration.kioskDeviceLocations
                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                .map((location) => (
                  <div
                    key={location.id}
                    className="flex items-center justify-between border-b border-gray-500 px-4 py-2"
                  >
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => {
                          reset({ locationName: location.name, locationId: location.id })
                          setEditLocation(location)
                          setShowModal(true)
                        }}
                        type="button"
                      >
                        <EditIcon className="h-5 cursor-pointer fill-black" />
                      </button>
                      {location.name}
                    </div>

                    <button
                      onClick={() => {
                        deleteDeviceLocation(
                          event.id,
                          kioskConfiguration.id,
                          location.id,
                          handleError,
                          setLoading,
                          handleSuccess,
                        )

                        setKioskConfiguration({
                          ...kioskConfiguration,
                          kioskDeviceLocations: kioskConfiguration.kioskDeviceLocations.filter(
                            (l) => l.id !== location.id,
                          ),
                        })
                      }}
                      type="button"
                    >
                      <TrashIcon className="h-5 cursor-pointer fill-black" />
                    </button>
                  </div>
                ))
            ) : (
              <span className="text-center text-sm">No Locations Added.</span>
            )}
          </div>
        </div>
      </div>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setTimeout(() => {
                resetForm()
              }, 500)
            },
          },
          {
            type: 'submit',
            label: editLocation ? 'Save Location' : 'Add Location',
            onClick: handleSubmit(onSubmit),
          },
        ]}
        icon={<img src={editLocation ? Edit : Add} alt={editLocation ? 'Edit' : 'Add'} />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              disabled={loading}
              icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
              data-testid="location"
              error={errors.location && 'This field is required'}
              fullWidth
              id="location"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="location"
              nunito
              label="Location Name"
              placeholder="Name of location"
              {...register('locationName', { required: true })}
            />
          </div>
        }
        loading={loading}
        onClose={() => {
          setShowModal(false)

          setTimeout(() => {
            reset(DEFAULT)
          }, 500)
        }}
        open={showModal}
        setOpen={setShowModal}
        title={editLocation ? 'Edit Location' : 'Add Location'}
      />
    </div>
  )
}

KioskSettings.propTypes = {
  event: PropTypes.object.isRequired,
  kioskConfiguration: PropTypes.object.isRequired,
  setKioskConfiguration: PropTypes.func.isRequired,
}

export { KioskSettings }
