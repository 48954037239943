import React from 'react'
import PropTypes from 'prop-types'
import { ArrowPathIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'

// Styles
import baseColors from '../../utils/colors'

/**
 * StateContainer
 *
 * Handles displaying loading state either synchronously or asynchronously
 *
 * @param {boolean} async - Whether or not the loading state is asynchronous
 * @param {React.ReactNode} children - The children to render
 * @param {string} error - The error message to display
 * @param {string} lastUpdated - The last time the data was updated
 * @param {boolean} loading - Whether or not the data is loading
 * @param {boolean} stripedBackground - Whether or not to display a striped background
 */
const StateContainer = ({ async, children, error, lastUpdated, loading, stripedBackground }) => {
  // If `async`, we want to pass the loading or error state down to the children
  // to display
  if (async) {
    const date = dayjs(lastUpdated).format('MM/DD/YYYY HH:mm')

    // Defaults to returning the last sync time
    let state = (
      <div className="h-4 self-end">
        <span className="text-xs font-bold">{date ? `Last Sync: ${date}` : ''}</span>
      </div>
    )

    // If error, display updated error message with last sync time
    if (error) {
      state = (
        <div className="h-4 self-end">
          <span className="text-xs font-bold">Error getting updated data. Last Sync: {date}</span>
        </div>
      )
    }

    // If `loading`, render a loading indicator
    if (loading) {
      state = (
        <div className="flex h-4 flex-row justify-center gap-1 self-end">
          <span className="text-xs font-bold">Syncing</span>

          <span className="flex items-center pr-3">
            <div className="h-4 w-4">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-4 w-4 motion-safe:animate-spin-slow" viewBox="0 0 16 16">
                <ArrowPathIcon className="h-4 w-4" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    return children(state)
  }

  // If `error`, render the error message
  if (error) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
        <span className="text-center text-2xl font-bold">{error}</span>

        {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
        <svg className="h-10 w-10" viewBox="0 0 40 40">
          <ExclamationCircleIcon
            className="h-10 w-10"
            aria-hidden="true"
            fill={baseColors.error}
          />
        </svg>
      </div>
    )
  }

  // If `loading`, render a loading indicator
  if (loading) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
        <span className="text-2xl font-bold">Loading...</span>

        <span className="flex items-center pr-3">
          <div className="h-10 w-10">
            {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
            <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
              <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
            </svg>
          </div>
        </span>
      </div>
    )
  }

  // Otherwise, render the children
  return (
    <div className="relative flex h-full w-full place-content-center">
      {stripedBackground ? (
        <>
          <div className="absolute top-0 h-[30%] w-full bg-gray-900" />
          <div className="absolute top-[30%] h-[70%] w-full bg-gray-200" />

          <div className="relative flex h-full w-full place-items-center justify-center">
            {children}
          </div>
        </>
      ) : (
        children
      )}
    </div>
  )
}

StateContainer.defaultProps = {
  async: false,
  error: null,
  lastUpdated: null,
  loading: false,
  stripedBackground: false,
}

StateContainer.propTypes = {
  async: PropTypes.bool,
  children: PropTypes.any.isRequired,
  error: PropTypes.string,
  lastUpdated: PropTypes.string,
  loading: PropTypes.bool,
  stripedBackground: PropTypes.bool,
}

export default StateContainer
