import _ from 'lodash'

const MERGE_FIELD = /{{\s+(.*?)\s+}}/g

/**
 * Handles filling in attendee data for merge fields.
 * @param {object} attendeeDetails
 * @param {string} content
 */
export const replaceMergeFields = (attendee, content) => {
  let updatedContent = content

  // Check if the `content` has a merge field in it
  const matches = [...content.matchAll(MERGE_FIELD)]
  if (matches) {
    // Replace the merge field with the attendee data
    _.forEach(matches, (m) => {
      const accessor = m[1]
      const accessorCamel = _.camelCase(accessor)

      let value = m[0]

      // Try the camelCase accessor on the base `attendee` first
      if (attendee[accessorCamel] !== undefined && attendee[accessorCamel] !== null) {
        value = attendee[accessorCamel] || ''
      }
      // Try the snake_case accessor on the base `attendee`
      else if (attendee[accessor] !== undefined && attendee[accessor] !== null) {
        value = attendee[accessor] || ''
      }
      // Otherwise, try the `accessor` on the `attendee.customData`
      else if (attendee.customData && attendee.customData[accessor] !== undefined) {
        value = attendee.customData[accessor] || ''
      }

      // Replace the merge field with the attendee data
      updatedContent = updatedContent.replace(m[0], value)
    })
  }

  return updatedContent
}
