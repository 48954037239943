import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  Listbox,
  ListboxButton,
  Label,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import {
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import _ from 'lodash'
import { useVirtualizer } from '@tanstack/react-virtual'

// Components
import { TextInput } from '../TextInput'

// Utils
import { joinClassNames } from '../../utils/helpers'

/**
 *
 * VirtualizedResultsList
 *
 * - Lazy loads the list of options to help with performance.
 */
const VirtualizedResultsList = ({ hoverElement, options, value }) => {
  // Ref
  const parentRef = useRef()

  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36,
    overscan: 5,
  })

  const items = virtualizer.getVirtualItems()
  const totalSize = virtualizer.getTotalSize()

  const isSelected = (o) => {
    if (!value) return null

    if (_.isArray(value)) {
      return _.find(value, (v) => v.id === o.id)
    }

    return o.id === value.id
  }

  return (
    <div ref={parentRef} className="max-h-60 w-full overflow-auto text-base">
      <div
        className="relative w-full"
        style={{
          height: `${totalSize}px`,
        }}
      >
        {items.map((v) => {
          const o = options[v.index]
          return (
            <ListboxOption
              key={v.index}
              className="group absolute left-0 top-0 w-full cursor-default select-none px-3 py-2 text-gray-900 hover:bg-purple hover:text-white"
              style={{
                height: `${v.size}px`,
                transform: `translateY(${v.start}px)`,
              }}
              value={o}
            >
              {() => (
                <div className="justify-items-between relative flex w-full">
                  <span
                    className={joinClassNames(
                      isSelected(o) ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    )}
                  >
                    {o.label}
                  </span>

                  {isSelected(o) ? (
                    <span className="absolute inset-y-0 right-0 flex items-center text-purple">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}

                  {hoverElement && isSelected(o) && (
                    <div className="absolute right-0 hidden cursor-pointer text-white group-hover:block">
                      {hoverElement}
                    </div>
                  )}
                </div>
              )}
            </ListboxOption>
          )
        })}
      </div>
    </div>
  )
}

VirtualizedResultsList.propTypes = {
  hoverElement: PropTypes.element,
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
}

/**
 *
 * Select
 *
 */
const Select = ({
  className,
  customButton,
  dataTestId,
  disabled,
  icon,
  error,
  hoverElement,
  label,
  labelClassName,
  multiSelect,
  onChange,
  openAbove,
  options,
  placeholder,
  search,
  style,
  value,
}) => {
  // State
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  // Ref
  const searchInputRef = useRef()

  let inputTextColor = 'text-gray-500'
  if (!_.isEmpty(value)) inputTextColor = 'text-gray-900'

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const configureValueLabel = () => {
    if (_.isEmpty(value)) return placeholder

    if (_.isArray(value)) {
      return value.map((v) => v.label).join(', ')
    }

    return value.label
  }

  return (
    <div style={style}>
      <Listbox
        className="disabled:opacity-50"
        disabled={disabled}
        onChange={(option) => {
          if (multiSelect) {
            const selected = _.find(value, (v) => v.id === option.id)

            if (selected) {
              onChange(_.filter(value, (v) => v.id !== option.id))
            } else {
              onChange([...value, option])
            }
          } else {
            onChange(option)
          }
        }}
        value={value}
      >
        {({ open }) => (
          <div className="flex w-full flex-col place-items-start">
            {label && (
              <Label
                className={mergeClassNames(
                  'block font-nunito text-sm font-medium text-gray-700',
                  labelClassName,
                )}
              >
                {label}
              </Label>
            )}

            <div className={customButton ? 'relative' : 'relative w-full'}>
              <ListboxButton
                className={mergeClassNames(
                  customButton
                    ? 'relative cursor-pointer'
                    : 'relative w-full cursor-default rounded-2xl border border-gray-550 bg-white px-4 py-2.5 text-left font-nunito text-gray-900 shadow-sm focus:border-purple',
                  className,
                )}
                data-testid={dataTestId}
                disabled={!!customButton || disabled}
                onClick={(e) => e.stopPropagation()}
              >
                {customButton || (
                  <div
                    className={mergeClassNames(
                      'flex h-[26px] items-center',
                      disabled && 'cursor-not-allowed opacity-50',
                    )}
                  >
                    {icon && (
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
                        {icon}
                      </div>
                    )}

                    <span
                      className={mergeClassNames(
                        `block truncate ${inputTextColor} text-md sm:text-base`,
                        icon && 'pl-5',
                      )}
                    >
                      {configureValueLabel()}
                    </span>

                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </div>
                )}
              </ListboxButton>

              <Transition
                className={openAbove && '!top-auto bottom-full'}
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions
                  className={joinClassNames(
                    'absolute z-50 mt-1 flex max-h-64 flex-col overflow-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-300 focus:outline-none sm:text-sm',
                    customButton ? 'w-40' : 'w-full',
                  )}
                  onClick={(e) => e.stopPropagation()}
                >
                  {search && (
                    <TextInput
                      autoComplete="off"
                      className="ml-2 w-[97.5%] rounded-lg py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
                      icon={
                        <MagnifyingGlassIcon className="ml-2 h-5 text-purple" aria-hidden="true" />
                      }
                      id="search"
                      endIcon={
                        searchTerm ? (
                          <button
                            type="button"
                            onClick={() => {
                              setFilteredOptions(options)
                              setSearchTerm('')
                              searchInputRef.current.value = ''
                            }}
                          >
                            <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                          </button>
                        ) : null
                      }
                      name="search"
                      onChange={(e) => {
                        setFilteredOptions(
                          _.filter(
                            options,
                            (o) =>
                              o.label.toLowerCase().includes(e.target.value.toLowerCase()) ||
                              o.alwaysDisplay,
                          ),
                        )

                        setSearchTerm(e.target.value)
                      }}
                      placeholder="Search"
                      ref={searchInputRef}
                      type="text"
                      value={searchTerm}
                    />
                  )}

                  {filteredOptions.length === 0 ? (
                    <div className="flex justify-center py-2">
                      <span>{search ? 'No results found.' : 'No available options.'}</span>
                    </div>
                  ) : (
                    <VirtualizedResultsList
                      customButton={customButton}
                      hoverElement={hoverElement}
                      options={filteredOptions}
                      value={value}
                    />
                  )}
                </ListboxOptions>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>

      {error && (
        <div className="mt-1 w-full bg-error-light px-2 py-1">
          <p className="text-sm font-medium text-error-dark" id="organization:error">
            {error}
          </p>
        </div>
      )}
    </div>
  )
}

Select.defaultProps = {
  className: '',
  customButton: null,
  dataTestId: null,
  disabled: false,
  error: null,
  icon: null,
  label: null,
  labelClassName: '',
  multiSelect: false,
  openAbove: false,
  placeholder: 'Select an Option',
  search: false,
  style: {},
  value: null,
}

Select.propTypes = {
  className: PropTypes.string,
  customButton: PropTypes.element,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hoverElement: PropTypes.element,
  icon: PropTypes.element,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  openAbove: PropTypes.bool,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
}

export default Select
