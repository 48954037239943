/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/no-unstable-nested-components */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Components
import { PlotMetric } from '../PlotMetric'

// Styles
import baseColors from '../../utils/colors'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
})

dayjs.extend(customParseFormat)

/**
 * EventKPITile
 *
 * - Displays KPI data in a tile
 * - Displays odometer graph when specified
 */
const EventKPITile = ({ backgroundColor, className, data, label, labelIcon, subLabel, type }) => {
  // State
  const [open, setOpen] = useState(false)

  if (type === 'pie') {
    let dataToDisplay = data.data

    if (data.display === 'percent') {
      dataToDisplay = [
        ...data.data,
        {
          id: 'rest',
          color: baseColors.gray[300],
          value: data.total - data.data[0].value,
        },
      ]
    }

    return (
      <div
        className={mergeClassNames(
          'flex h-60 w-full min-w-[250px] flex-col rounded-lg bg-white p-2 shadow-lg',
          className,
        )}
        key={`kpi:${label}`}
      >
        <span className="text-md font-bold">{label}</span>
        <span className="text-sm font-normal">{subLabel}</span>

        <div className="mt-1 flex h-full w-full flex-row">
          <div className="space-y-2">
            {_.map(data.data, (d) => (
              <div className="flex flex-col" key={`data:${d.id}`}>
                <span className={`text-2xl font-semibold ${d.textColor}`}>{d.value || 0}</span>
                {data.display === 'total' && <span className="text-xs">{d.id}</span>}
              </div>
            ))}
          </div>

          <div className="flex flex-1 justify-center">
            <div className="h-[200px] w-[200px]">
              <ResponsivePie
                colors={{ datum: 'data.color' }}
                data={dataToDisplay}
                innerRadius={0.8}
                isInteractive={false}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                layers={['arcs', PlotMetric]}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (type === 'line') {
    return (
      <div
        className={mergeClassNames(
          'flex h-60 w-full min-w-[250px] flex-col rounded-lg bg-white p-2 shadow-lg',
          className,
        )}
        key={`kpi:${label}`}
      >
        <span className="text-md font-bold">{label}</span>
        <span className="text-sm font-normal">{subLabel}</span>
        <span className="text-2xl font-semibold text-purple">{data.main || '$0'}</span>

        <div className="h-full w-full">
          <ResponsiveLine
            axisBottom={{
              tickSize: 0,
            }}
            axisLeft={null}
            axisRight={null}
            axisTop={null}
            colors={{ datum: 'color' }}
            curve="monotoneX"
            data={data.data}
            enableArea
            enableCrosshair={false}
            enableGridX={false}
            enableGridY={false}
            enableSlices="x"
            margin={{ top: 10, right: 15, bottom: 20, left: 15 }}
            pointSize={10}
            pointColor="transparent"
            sliceTooltip={({ slice }) => (
              <div
                style={{
                  background: 'white',
                  padding: '1px 5px',
                  border: '1px solid #fff',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                }}
              >
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    style={{
                      color: point.serieColor,
                      padding: '3px 0',
                    }}
                  >
                    {formatter.format(point.data.yFormatted)}
                  </div>
                ))}
              </div>
            )}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12,
                    fill: '#96959E',
                    fontWeight: 500,
                  },
                },
              },
            }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
            }}
            yFormat=" >-.2f"
          />
        </div>
      </div>
    )
  }

  if (type === 'event-leads') {
    return (
      <div
        className={mergeClassNames(
          `flex w-full min-w-[250px] shrink-0 flex-col rounded-lg ${backgroundColor} justify-between pb-2 pl-5 pr-6 pt-5 shadow-lg`,
          className,
        )}
        key={`kpi:${label}`}
      >
        <span className="flex items-center gap-2 text-2xl font-bold">
          <span>{labelIcon}</span>
          <span>{label}</span>
        </span>
        <span className="text-sm font-normal">{subLabel}</span>
        <div className="flex h-full w-full place-content-end place-items-end">
          <span className="mt-5 text-7xl font-semibold">{data.main || 0}</span>
        </div>
      </div>
    )
  }

  if (type === 'per-device') {
    return (
      <div
        className={mergeClassNames(
          'flex w-full min-w-[250px] shrink-0 flex-col justify-between rounded-lg bg-purple pb-6 pl-6 pr-6 pt-4 text-white shadow-lg',
          className,
        )}
        key={`kpi:${label}`}
      >
        <Disclosure defaultOpen>
          <div className="flex items-start justify-between">
            <span className="mb-10 text-2xl font-bold">{label}</span>
            {data?.length > 3 && (
              <Disclosure.Button className="mt-1">
                <div className="w-6 cursor-pointer" onClick={() => setOpen(!open)}>
                  {!open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </div>
              </Disclosure.Button>
            )}
          </div>

          <div className="flex max-h-[525px] flex-col gap-3 overflow-y-auto pr-5">
            {data && data.length > 0 ? (
              data.slice(0, 3).map((d) => {
                let displayName = `${d.userDeviceName?.slice(0, 42) || 'Device name unavailable'}`

                if (d.userFirstName && d.userLastName) {
                  displayName = `${d.userFirstName} ${d.userLastName}`
                } else if (!d.userFirstName || !d.userLastName) {
                  displayName = `${d.userFirstName || d.userLastName}`
                }

                return (
                  <div className="flex items-center justify-between gap-2 font-medium">
                    <span className="text-lg">{displayName}</span>
                    <span className="hidden grow border-b border-dashed border-white sm:block" />
                    <span className="w-7 text-right text-2xl">{d.totalLeadCount}</span>
                  </div>
                )
              })
            ) : (
              <span className="pb-2 text-center">No Devices Activated</span>
            )}
            <Transition
              show={open}
              enter="transition-all ease-in duration-500"
              enterFrom="transform opacity-0 -top-auto"
              enterTo="transform opacity-100 top-0"
              leave="transition-all ease-out duration-150"
              leaveFrom="transform opacity-100 top-0"
              leaveTo="transform opacity-0 -top-auto"
            >
              <Disclosure.Panel className="flex flex-col gap-3">
                {data?.length > 3 &&
                  data.slice(3).map((d) => {
                    let displayName = `${
                      d.userDeviceName?.slice(0, 42) || 'Device name unavailable'
                    }`

                    if (d.userFirstName && d.userLastName) {
                      displayName = `${d.userFirstName} ${d.userLastName}`
                    } else if (!d.userFirstName || !d.userLastName) {
                      displayName = `${d.userFirstName || d.userLastName}`
                    }

                    return (
                      <div className="flex items-center justify-between gap-2 font-medium">
                        <span className="text-lg">{displayName}</span>
                        <span className="hidden grow border-b border-dashed border-white sm:block" />
                        <span className="w-7 text-right text-2xl">{d.totalLeadCount || 0}</span>
                      </div>
                    )
                  })}
              </Disclosure.Panel>
            </Transition>
          </div>
        </Disclosure>
      </div>
    )
  }

  if (type === 'bar') {
    return (
      <div className="flex flex-col gap-3 rounded-lg bg-white px-6 py-4 shadow-lg md:flex-row">
        <div className="w-full md:w-1/3">
          <div className="mb-4 text-2xl font-bold">{label}</div>
          <div className="md:md-0 mb-6 text-sm">{subLabel}</div>
        </div>
        <div className="flex h-48 md:w-2/3">
          <ResponsiveBar
            borderRadius="8px"
            data={data}
            keys={['leads']}
            indexBy="date"
            margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
            padding={0.4}
            valueScale={{ type: 'linear' }}
            colors="#3182CE"
            enableLabel={false}
            axisBottom={{
              format: (value) => dayjs(value, 'YYYYMMDD').format('M/D'),
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={null}
            theme={{
              grid: {
                line: {
                  stroke: '#6EE5CD',
                  strokeDasharray: '4',
                },
              },
              axis: {
                ticks: {
                  text: {
                    fill: '#96959E',
                    fontSize: '12px',
                    fontWeight: '500',
                  },
                },
              },
            }}
            tooltip={(point) => (
              <div
                style={{
                  padding: '4px 5px',
                  background: '#fff',
                  borderRadius: '8px',
                  border: '1px solid rgb(255, 255, 255)',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 10px',
                  fontSize: '16px',
                  color: 'rgb(140, 86, 246)',
                }}
              >
                <span>Leads: {point.value}</span>
              </div>
            )}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={mergeClassNames(
        'flex h-60 w-full min-w-[250px] shrink-0 flex-col rounded-lg bg-white p-2 shadow-lg',
        className,
      )}
      key={`kpi:${label}`}
    >
      <span className="text-md font-bold">{label}</span>
      <span className="text-sm font-normal">{subLabel}</span>
      <div className="flex h-full w-full place-content-center place-items-center">
        <span className="text-7xl font-semibold text-purple">{data.main || 0}</span>
      </div>
    </div>
  )
}

EventKPITile.defaultProps = {
  backgroundColor: 'bg-white',
  className: null,
  labelIcon: null,
  subLabel: null,
  type: 'default',
}

EventKPITile.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  labelIcon: PropTypes.object,
  subLabel: PropTypes.string,
  type: PropTypes.oneOf(['default', 'bar', 'pie', 'line']),
}

export default EventKPITile
