import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * PlotMetric
 *
 * - Displayed in the center of a pie chart
 */
const PlotMetric = ({ dataWithArc, centerX, centerY }) => {
  const total = dataWithArc[0].value + dataWithArc[1].value
  const percent = (dataWithArc[0].value / total || 0) * 100
  const displayLabel = dataWithArc[0].data.type === 'total'

  let fontSize = 'text-[41px]'
  let labelSize = 'text-xs'
  if (window.innerWidth < 768) {
    fontSize = 'text-2xl'
    labelSize = 'text-[10px]'
  } else if (window.innerWidth < 990) {
    fontSize = 'text-3xl'
    labelSize = 'text-xs'
  }

  return (
    <g>
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        className={`fill-gray-dark font-semibold ${fontSize}`}
      >
        {displayLabel ? total : `${percent.toPrecision(2)}%`}
      </text>

      {displayLabel && (
        <text
          x={centerX}
          y={centerY + 25}
          textAnchor="middle"
          dominantBaseline="central"
          className={`${labelSize}`}
        >
          Total
        </text>
      )}
    </g>
  )
}

PlotMetric.propTypes = {
  dataWithArc: PropTypes.array.isRequired,
  centerX: PropTypes.number.isRequired,
  centerY: PropTypes.number.isRequired,
}

export default PlotMetric
