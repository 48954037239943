// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of attendees.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getAttendees = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(data)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds the specified attendee from the `payload` to the event with the specified `id`.
 * @param {string} id
 * @param {object} payload attendee details
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` created attendee
 */
export const addAttendee = async (
  id,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${id}/attendees/`, payload)

    setLoading(false)
    setSuccess(`${data.firstName} ${data.lastName} created.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the attendee with the specified `id`.
 * @param {string} eventId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateAttendee = async (
  eventId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/events/${eventId}/attendees/${payload.id}/`, payload)

    setLoading(false)
    setSuccess(`${data.firstName} ${data.lastName} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Delete the attendee with the specified `id`.
 * @param {string} eventId
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteAttendee = async (
  eventId,
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/events/${eventId}/attendees/${id}/`)

    setLoading(false)
    setSuccess('Attendee deleted.')
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Get the status for the attendee import specified by `taskId`
 * @param {string} eventId
 * @param {string} taskId
 * @param {func} setError
 * @param {func} setLoading
 * @returns response
 */
export const uploadAttendeeList = async (eventId, file, setError, setLoading) => {
  setLoading(true)

  try {
    const response = await axios.post(`/events/${eventId}/attendee_imports/`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the status for the attendee import specified by `taskId`
 * @param {string} eventId
 * @param {string} taskId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns the task status
 */
export const getAttendeeImportStatus = async (eventId, taskId) => {
  try {
    return await axios.get(`/events/${eventId}/attendee_imports/${taskId}/`)
  } catch (err) {
    return null
  }
}
