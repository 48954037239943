import React from 'react'
import PropTypes from 'prop-types'
import {
  MdAlignHorizontalCenter,
  MdAlignHorizontalLeft,
  MdAlignHorizontalRight,
  MdAlignVerticalBottom,
  MdAlignVerticalCenter,
  MdAlignVerticalTop,
} from 'react-icons/md'
import { TbStackBack, TbStackBackward, TbStackForward, TbStackFront } from 'react-icons/tb'

/**
 *
 * AlignmentControls
 *
 */
const AlignmentControls = ({
  containerHeight,
  containerWidth,
  element,
  isInGroup,
  moveElement,
  updateElement,
}) => {
  const right = containerWidth - element.attrs.width
  const bottom = containerHeight - element.attrs.height
  const centerHorizontal = containerWidth / 2 - element.attrs.width / 2
  const centerVertical = containerHeight / 2 - element.attrs.height / 2

  return (
    <div className="space-y-1">
      <span className="text-sm font-bold">Alignment</span>

      {!isInGroup && (
        <div className="flex gap-1">
          <button
            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400 ${
              element.attrs.x === 0 && 'bg-purple-400 hover:bg-purple-200'
            }`}
            onClick={() => updateElement({ x: 0 })}
            title="Align left"
            type="button"
          >
            <MdAlignHorizontalLeft size={20} />
          </button>
          <button
            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400 ${
              element.attrs.x === centerHorizontal && 'bg-purple-400 hover:bg-purple-200'
            }`}
            onClick={() => updateElement({ x: centerHorizontal })}
            title="Align center (horizontal)"
            type="button"
          >
            <MdAlignHorizontalCenter size={20} />
          </button>
          <button
            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400 ${
              element.attrs.x === right && 'bg-purple-400 hover:bg-purple-200'
            }`}
            onClick={() => updateElement({ x: right })}
            title="Align right"
            type="button"
          >
            <MdAlignHorizontalRight size={20} />
          </button>
          <button
            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400 ${
              element.attrs.y === 0 && 'bg-purple-400 hover:bg-purple-200'
            }`}
            onClick={() => updateElement({ y: 0 })}
            title="Align top"
            type="button"
          >
            <MdAlignVerticalTop size={20} />
          </button>
          <button
            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400 ${
              element.attrs.y === centerVertical && 'bg-purple-400 hover:bg-purple-200'
            }`}
            onClick={() => updateElement({ y: centerVertical })}
            title="Align center (vertical)"
            type="button"
          >
            <MdAlignVerticalCenter size={20} />
          </button>
          <button
            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400 ${
              element.attrs.y === bottom && 'bg-purple-400 hover:bg-purple-200'
            }`}
            onClick={() => updateElement({ y: bottom })}
            title="Align bottom"
            type="button"
          >
            <MdAlignVerticalBottom size={20} />
          </button>
        </div>
      )}

      <div className="flex gap-1">
        <button
          className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400"
          onClick={() => moveElement('back', element.attrs.id)}
          title="Bring to back"
          type="button"
        >
          <TbStackBack size={22} />
        </button>
        <button
          className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400"
          onClick={() => moveElement('backward', element.attrs.id)}
          title="Bring backward"
          type="button"
        >
          <TbStackBackward size={22} />
        </button>
        <button
          className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400"
          onClick={() => moveElement('forward', element.attrs.id)}
          title="Bring forward"
          type="button"
        >
          <TbStackForward size={22} />
        </button>
        <button
          className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400"
          onClick={() => moveElement('front', element.attrs.id)}
          title="Bring to front"
          type="button"
        >
          <TbStackFront size={22} />
        </button>
      </div>
    </div>
  )
}

AlignmentControls.defaultProps = {
  containerHeight: 0,
  containerWidth: 0,
  isInGroup: false,
}

AlignmentControls.propTypes = {
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
  element: PropTypes.object.isRequired,
  isInGroup: PropTypes.bool,
  moveElement: PropTypes.func.isRequired,
  updateElement: PropTypes.func.isRequired,
}

export default AlignmentControls
