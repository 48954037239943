import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { PasswordVerifier } from '../PasswordVerifier'
import Tooltip from '../Tooltip/Tooltip'

/**
 *
 * PasswordInput
 *
 */
const PasswordInput = forwardRef(
  (
    {
      className,
      disableAutoComplete,
      error,
      fullWidth,
      hideTooltip,
      icon,
      id,
      inputStyles,
      label,
      name,
      nunito,
      placeholder,
      renderIconStyles,
      type,
      value: presetValue,
      ...rest
    },
    ref,
  ) => {
    // State
    const [reveal, setReveal] = useState(false)
    const [value, setValue] = useState(presetValue)

    useEffect(() => {
      if (presetValue === value) return
      setValue(presetValue)
    }, [presetValue])

    const renderToggle = () =>
      reveal ? (
        <EyeIcon className={mergeClassNames('h-4 w-4', renderIconStyles)} />
      ) : (
        <EyeSlashIcon className={mergeClassNames('h-4 w-4', renderIconStyles)} />
      )

    return (
      <div className={`flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`}>
        <label
          htmlFor={id}
          className={`pb-1 ${nunito ? 'font-nunito' : ''} text-sm font-medium text-gray-700`}
        >
          {label}
        </label>
        <Tooltip
          content={!hideTooltip && <PasswordVerifier password={value} />}
          fullWidth
          placement="bottom"
        >
          <div
            className={mergeClassNames(
              'relative block w-full appearance-none rounded-none border border-gray-300 bg-white px-3 text-sm text-gray-900 placeholder:text-gray-500',
              className,
            )}
          >
            {icon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
                {icon}
              </div>
            )}

            <input
              autoComplete={disableAutoComplete ? 'off' : 'password'}
              className={mergeClassNames(
                'placeholder:text-md text-md relative w-full border-none p-0 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white disabled:text-gray-400 sm:text-base sm:placeholder:text-base',
                inputStyles,
                nunito ? 'font-nunito' : '',
                'sentry-mask',
              )}
              data-testid={name}
              id={id}
              name={name}
              placeholder={placeholder}
              ref={ref}
              required
              {...rest}
              value={value}
              type={reveal ? type : 'password'}
            />

            <button
              data-testid="password-visibility-toggle"
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 "
              onClick={(e) => {
                e.stopPropagation()

                setReveal(!reveal)
              }}
              type="button"
            >
              {renderToggle()}
            </button>
          </div>

          {error && (
            <div className="mt-1 w-full bg-error-light px-2 py-1">
              <p className="text-sm font-medium text-error-dark" id={`error:${name}`}>
                {error}
              </p>
            </div>
          )}
        </Tooltip>
      </div>
    )
  },
)

PasswordInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  inputStyles: PropTypes.string,
  disableAutoComplete: PropTypes.bool,
  endIcon: PropTypes.object,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  nunito: PropTypes.bool,
  placeholder: PropTypes.string,
  renderIconStyles: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
}

PasswordInput.defaultProps = {
  autoComplete: 'off',
  className: '',
  inputStyles: '',
  disableAutoComplete: false,
  endIcon: null,
  error: '',
  fullWidth: false,
  hideTooltip: false,
  icon: null,
  id: '',
  label: '',
  name: '',
  nunito: false,
  placeholder: '',
  renderIconStyles: null,
  type: 'text',
  value: '',
}

export default PasswordInput
