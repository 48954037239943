import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  $getSelection,
  $isRangeSelection,
} from 'lexical'
import { mergeRegister } from '@lexical/utils'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import PropTypes from 'prop-types'

const BUTTON_STYLES =
  'flex h-7 w-7 cursor-pointer rounded-md border border-transparent align-middle hover:bg-purple-400'

/**
 * Toolbar
 * - Displays a toolbar for the RichTextInput
 * - Handles all text transformations
 */
const Toolbar = ({ className, historyEnabled }) => {
  // Context
  const [editor] = useLexicalComposerContext()

  // State
  const [bold, setBold] = useState(false)
  const [italic, setItalic] = useState(false)
  const [underline, setUnderline] = useState(false)
  const [bullet, setBullet] = useState(false)
  const [numbered, setNumbered] = useState(false)
  const [alignment, setAlignment] = useState('left')

  // Ref
  const toolbarRef = useRef(null)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)

      // Format bullet and numbered list
      setBullet(elementDOM?.tagName === 'UL')
      setNumbered(elementDOM?.tagName === 'OL')

      // Update text format
      setBold(selection.hasFormat('bold'))
      setItalic(selection.hasFormat('italic'))
      setUnderline(selection.hasFormat('underline'))
    }
  }, [editor])

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar()
          })
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar()
            return false
          },
          1,
        ),
      ),
    [editor, updateToolbar],
  )

  /**
   * Format the alignment of the current selection.
   * @param {string} align
   */
  const formatAlignment = (align) => {
    setAlignment(align)
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, align)
  }

  return (
    <div
      className={mergeClassNames(
        'flex flex-row space-x-2 border-b-[1px] border-gray-550 p-1',
        className,
      )}
      ref={toolbarRef}
    >
      {/* General */}

      {historyEnabled && (
        <>
          <button
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND)
            }}
            className={BUTTON_STYLES}
            aria-label="Undo"
            title="Undo"
            type="button"
          >
            <box-icon type="regular" color="black" name="undo" size="sm" />
          </button>

          <button
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND)
            }}
            className={BUTTON_STYLES}
            aria-label="Redo"
            title="Redo"
            type="button"
          >
            <box-icon type="regular" color="black" name="redo" size="sm" />
          </button>
        </>
      )}

      {/* Text Style */}

      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        className={mergeClassNames(BUTTON_STYLES, bold && 'bg-purple-400 hover:bg-purple-200')}
        aria-label="Format Bold"
        type="button"
      >
        <box-icon type="regular" color="black" name="bold" size="sm" />
      </button>

      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        className={mergeClassNames(BUTTON_STYLES, italic && 'bg-purple-400 hover:bg-purple-200')}
        aria-label="Format Italics"
        type="button"
      >
        <box-icon type="regular" color="black" name="italic" size="sm" />
      </button>

      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        className={mergeClassNames(
          BUTTON_STYLES,
          underline && 'bg-purple-400 hover:bg-purple-200',
        )}
        aria-label="Format Underline"
        type="button"
      >
        <box-icon type="regular" color="black" name="underline" size="sm" />
      </button>

      {/* List Style */}

      <button
        className={mergeClassNames(BUTTON_STYLES, numbered && 'bg-purple-400 hover:bg-purple-200')}
        onClick={() => {
          if (!numbered) {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND)
          }
        }}
        type="button"
      >
        <box-icon type="regular" color="black" name="list-ol" size="sm" />
      </button>

      <button
        className={mergeClassNames(BUTTON_STYLES, bullet && 'bg-purple-400 hover:bg-purple-200')}
        onClick={() => {
          if (!bullet) {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND)
          }
        }}
        type="button"
      >
        <box-icon type="regular" color="black" name="list-ul" size="sm" />
      </button>

      {/* Alignment */}

      <button
        className={mergeClassNames(
          BUTTON_STYLES,
          alignment === 'left' && 'bg-purple-400 hover:bg-purple-200',
        )}
        onClick={() => formatAlignment('left')}
        type="button"
      >
        <box-icon type="regular" color="black" name="align-left" size="sm" />
      </button>

      <button
        className={mergeClassNames(
          BUTTON_STYLES,
          alignment === 'center' && 'bg-purple-400 hover:bg-purple-200',
        )}
        onClick={() => formatAlignment('center')}
        type="button"
      >
        <box-icon type="regular" color="black" name="align-middle" size="sm" />
      </button>

      <button
        className={mergeClassNames(
          BUTTON_STYLES,
          alignment === 'right' && 'bg-purple-400 hover:bg-purple-200',
        )}
        onClick={() => formatAlignment('right')}
        type="button"
      >
        <box-icon type="regular" color="black" name="align-right" size="sm" />
      </button>
    </div>
  )
}

Toolbar.defaultProps = {
  className: null,
  historyEnabled: false,
}

Toolbar.propTypes = {
  className: PropTypes.string,
  historyEnabled: PropTypes.bool,
}

export default Toolbar
