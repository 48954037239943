import React from 'react'
import PropTypes from 'prop-types'

const WrapTextIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 7H21C21.2652 7 21.5196 6.89464 21.7071 6.70711C21.8946 6.51957 22 6.26522 22 6C22 5.73478 21.8946 5.48043 21.7071 5.29289C21.5196 5.10536 21.2652 5 21 5H3C2.73478 5 2.48043 5.10536 2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6C2 6.26522 2.10536 6.51957 2.29289 6.70711C2.48043 6.89464 2.73478 7 3 7ZM9 15H3C2.73478 15 2.48043 15.1054 2.29289 15.2929C2.10536 15.4804 2 15.7348 2 16C2 16.2652 2.10536 16.5196 2.29289 16.7071C2.48043 16.8946 2.73478 17 3 17H9C9.26522 17 9.51957 16.8946 9.70711 16.7071C9.89464 16.5196 10 16.2652 10 16C10 15.7348 9.89464 15.4804 9.70711 15.2929C9.51957 15.1054 9.26522 15 9 15ZM18.5 10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11C2 11.2652 2.10536 11.5196 2.29289 11.7071C2.48043 11.8946 2.73478 12 3 12H18.5C18.8978 12 19.2794 12.158 19.5607 12.4393C19.842 12.7206 20 13.1022 20 13.5C20 13.8978 19.842 14.2794 19.5607 14.5607C19.2794 14.842 18.8978 15 18.5 15H15.41L15.71 14.71C15.8983 14.5217 16.0041 14.2663 16.0041 14C16.0041 13.7337 15.8983 13.4783 15.71 13.29C15.5217 13.1017 15.2663 12.9959 15 12.9959C14.7337 12.9959 14.4783 13.1017 14.29 13.29L12.29 15.29C12.199 15.3851 12.1276 15.4972 12.08 15.62C11.98 15.8635 11.98 16.1365 12.08 16.38C12.1276 16.5028 12.199 16.6149 12.29 16.71L14.29 18.71C14.383 18.8037 14.4936 18.8781 14.6154 18.9289C14.7373 18.9797 14.868 19.0058 15 19.0058C15.132 19.0058 15.2627 18.9797 15.3846 18.9289C15.5064 18.8781 15.617 18.8037 15.71 18.71C15.8037 18.617 15.8781 18.5064 15.9289 18.3846C15.9797 18.2627 16.0058 18.132 16.0058 18C16.0058 17.868 15.9797 17.7373 15.9289 17.6154C15.8781 17.4936 15.8037 17.383 15.71 17.29L15.41 17H18.5C19.4283 17 20.3185 16.6313 20.9749 15.9749C21.6313 15.3185 22 14.4283 22 13.5C22 12.5717 21.6313 11.6815 20.9749 11.0251C20.3185 10.3687 19.4283 10 18.5 10Z"
      fill="inherit"
    />
  </svg>
)

WrapTextIcon.defaultProps = {
  className: null,
}

WrapTextIcon.propTypes = {
  className: PropTypes.string,
}

export default WrapTextIcon
