// Utils and Service
import baseAxios from 'axios'
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of stock sizes.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getGlobalStockSizes = async (setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get('/global-paper-stock-size/?limit=1000')

    setLoading(false)
    return data.results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the list of custom fonts.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getGlobalCustomFonts = async (setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get('/global-fonts/?limit=1000')

    setLoading(false)
    return data.results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the custom font data for the specified `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @returns custom font
 */
export const getGlobalCustomFont = async (id, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/global-fonts/${id}`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets a list of available Google fonts.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getGoogleFonts = async (setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const response = await baseAxios.get(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${import.meta.env.VITE_GOOGLE_API_KEY}`,
    )

    setLoading(false)
    return response.data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
