// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of exhibitor leads.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getLeads = async (url, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Create exhibitor leads export.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `export` object
 */
export const createLeadsExport = async (
  eventId,
  eventExhibitorId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/lead_exports/`,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get exhibitor leads export.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {string} exportId
 * @returns `data` results
 */
export const getLeadsExportStatus = async (eventId, eventExhibitorId, exportId) => {
  try {
    const data = await axios.get(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/lead_exports/${exportId}`,
    )
    return data
  } catch (err) {
    return null
  }
}
