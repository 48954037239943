/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import PropTypes from 'prop-types'
import GoogleFontLoader from 'react-google-font'
import _ from 'lodash'

// Components
import { ScanIcon } from '../../components/ScanIcon'

// Images
import BadgerTextLogo from '../../assets/images/badgr_text_logo.svg'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Utils & Service
import useFonts from '../../hooks/Fonts'
import { getGlobalCustomFont } from '../../services/settings.service'

const MiniKiosk = ({ configuration }) => {
  // Context
  const { event } = useContext(NavigationStoreContext)

  // State
  const [googleFont, setGoogleFont] = useState()
  const [customFont, setCustomFont] = useState()
  const [customFontUrls, setCustomFontUrls] = useState([])

  useFonts(customFontUrls)

  useEffect(() => {
    if (configuration.googleFont) {
      setGoogleFont({ font: configuration.googleFont })
      setCustomFont()
    }

    if (configuration.customFont) {
      setGoogleFont()
      setCustomFont(configuration.customFont)
    }
  }, [configuration])

  useEffect(() => {
    // Get all updated custom font data
    if (!_.isEmpty(customFont) && customFontUrls.length === 0) {
      const fonts = []

      Promise.all(
        _.map([customFont], (f) =>
          getGlobalCustomFont(
            f,
            () => {},
            () => {},
          ),
        ),
      ).then((data) => {
        _.forEach(data, (d) => {
          fonts.push(d)
        })

        setCustomFontUrls(fonts)
      })
    }
  }, [customFont])

  const configureFont = () => {
    if (configuration.googleFont) {
      return configuration.googleFont
    }

    if (configuration.customFont && customFontUrls.length > 0) {
      const custom = _.find(customFontUrls, (f) => f.id === configuration.customFont)
      return custom ? `'${custom.name}'` : 'Inter'
    }

    return 'Inter'
  }

  return (
    <div className="relative aspect-[4/3]">
      <div
        className="absolute top-0 flex h-full w-full flex-col"
        style={{
          fontFamily: configureFont(),
          color: configuration.fontColor || '#111827',
        }}
      >
        <div
          className={mergeClassNames(
            'flex h-[70px] w-full items-center justify-center bg-cover bg-center bg-no-repeat',
            !configuration.useDefaultBrand && !configuration.signedHeaderImage && 'bg-[#0D0B0D]',
          )}
          style={
            configuration.signedHeaderImage
              ? {
                  backgroundImage: `url(${configuration.signedHeaderImage})`,
                }
              : {
                  backgroundColor: '#0D0B0D',
                }
          }
        >
          {configuration.useDefaultBrand && (
            <span className="text-sm font-bold text-white">{event.name}</span>
          )}
        </div>

        <div
          className="relative flex w-full grow basis-2/3 justify-center"
          style={{
            backgroundColor: configuration.backgroundColor,
          }}
        >
          <div className="absolute -inset-y-5 z-10 w-[90%]  bg-white p-6 shadow-lg">
            <div className="flex h-full items-center">
              <div className="flex basis-1/2 justify-center px-8">
                <div className="flex max-w-[465px] flex-col items-center">
                  <div className="text-center text-sm font-bold">Have your QR code ready?</div>
                  <span className="mb-6 text-center text-[10px] font-bold">
                    Scan your QR code with Scanner.
                  </span>
                  <ScanIcon className="h-14 fill-black" />
                </div>
              </div>

              <span className="h-full max-h-[200px] min-w-[1px] bg-black"></span>

              <div className="flex basis-1/2 justify-center pl-8 pr-2">
                <div className="flex w-full flex-col gap-4">
                  <div className="text-center text-[10px] font-bold">
                    No QR code? Search for your registration below.
                  </div>

                  <div className="relative w-full">
                    <div className="text-[6px]">Search</div>
                    <div className="flex h-6 items-center justify-start gap-1 rounded-lg border border-gray-400 text-inherit placeholder:text-xs placeholder:text-xs placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple">
                      <MagnifyingGlassIcon className="ml-2 h-2 text-purple" aria-hidden="true" />
                      <div className="text-[5px]">Enter your First Name, Last Name, or Email</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={mergeClassNames(
            'flex h-[70px] w-full items-end justify-start bg-cover bg-center bg-no-repeat pl-6',
            !configuration.useDefaultBrand && !configuration.signedFooterImage && 'bg-[#0D0B0D]',
          )}
          style={
            configuration.signedFooterImage
              ? {
                  backgroundImage: `url(${configuration.signedFooterImage})`,
                }
              : {
                  backgroundColor: '#0D0B0D',
                }
          }
        >
          {!configuration?.useDefaultBrand && (
            <img src={BadgerTextLogo} className="mb-5 h-4" alt="" />
          )}
        </div>
      </div>

      {googleFont && <GoogleFontLoader fonts={[googleFont]} />}
    </div>
  )
}

MiniKiosk.propTypes = {
  configuration: PropTypes.object.isRequired,
}

export { MiniKiosk }
