import React from 'react'
import PropTypes from 'prop-types'

const UserIcon = ({ className }) => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.5 5.5C12.5 7.70914 10.7091 9.5 8.5 9.5C6.29086 9.5 4.5 7.70914 4.5 5.5C4.5 3.29086 6.29086 1.5 8.5 1.5C10.7091 1.5 12.5 3.29086 12.5 5.5Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 12.5C4.63401 12.5 1.5 15.634 1.5 19.5H15.5C15.5 15.634 12.366 12.5 8.5 12.5Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

UserIcon.defaultProps = {
  className: null,
}

UserIcon.propTypes = {
  className: PropTypes.string,
}

export default UserIcon
