// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of exhibitor materials.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getEventMaterials = async (url, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds the specified material from the `payload` to the event with the specified `eventId`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const addEventMaterial = async (
  eventId,
  eventExhibitorId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/materials/`,
      payload,
    )

    setLoading(false)
    setSuccess(`${data.name} created.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the exhibitor material with the specified `payload`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateEventMaterial = async (
  eventId,
  eventExhibitorId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/materials/${payload.id}/`,
      payload,
    )

    setLoading(false)
    setSuccess(`${data.name} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the exhibitor material with the specified `id`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteEventMaterial = async (
  eventId,
  eventExhibitorId,
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.delete(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/materials/${id}/`,
    )

    setLoading(false)
    setSuccess('Material deleted.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
