import React from 'react'
import PropTypes from 'prop-types'

const CircleCheckIconSolid = ({ className }) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="inherit" />
    <path
      d="M12.207 4.79279C12.3945 4.98031 12.4998 5.23462 12.4998 5.49979C12.4998 5.76495 12.3945 6.01926 12.207 6.20679L7.20701 11.2068C7.01948 11.3943 6.76517 11.4996 6.50001 11.4996C6.23485 11.4996 5.98054 11.3943 5.79301 11.2068L3.79301 9.20679C3.61085 9.01818 3.51006 8.76558 3.51234 8.50339C3.51461 8.24119 3.61978 7.99038 3.80519 7.80497C3.9906 7.61956 4.24141 7.51439 4.50361 7.51211C4.76581 7.50983 5.01841 7.61063 5.20701 7.79279L6.50001 9.08579L10.793 4.79279C10.9805 4.60532 11.2348 4.5 11.5 4.5C11.7652 4.5 12.0195 4.60532 12.207 4.79279Z"
      fill="white"
    />
  </svg>
)

CircleCheckIconSolid.defaultProps = {
  className: null,
}

CircleCheckIconSolid.propTypes = {
  className: PropTypes.string,
}

export default CircleCheckIconSolid
