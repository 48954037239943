import React from 'react'
import PropTypes from 'prop-types'

const TrashIcon = ({ className }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00012 0C5.62135 0 5.27509 0.214002 5.1057 0.552786L4.38209 2H1.00012C0.447837 2 0.00012207 2.44772 0.00012207 3C0.00012207 3.55228 0.447838 4 1.00012 4L1.00012 14C1.00012 15.1046 1.89555 16 3.00012 16H11.0001C12.1047 16 13.0001 15.1046 13.0001 14V4C13.5524 4 14.0001 3.55228 14.0001 3C14.0001 2.44772 13.5524 2 13.0001 2H9.61816L8.89455 0.552786C8.72516 0.214002 8.37889 0 8.00012 0H6.00012ZM4.00012 6C4.00012 5.44772 4.44784 5 5.00012 5C5.55241 5 6.00012 5.44772 6.00012 6V12C6.00012 12.5523 5.55241 13 5.00012 13C4.44784 13 4.00012 12.5523 4.00012 12V6ZM9.00012 5C8.44784 5 8.00012 5.44772 8.00012 6V12C8.00012 12.5523 8.44784 13 9.00012 13C9.55241 13 10.0001 12.5523 10.0001 12V6C10.0001 5.44772 9.55241 5 9.00012 5Z"
      fill="inherit"
    />
  </svg>
)

TrashIcon.defaultProps = {
  className: null,
}

TrashIcon.propTypes = {
  className: PropTypes.string,
}

export default TrashIcon
