import { forEach } from 'lodash'
import { makeObservable, action, observable } from 'mobx'

export class BaseStore {
  /**
   * mobx-sync does not set the default values without interacting with at
   * least one of the class variables that are set up as an observable
   *
   * This takes the intial state provided to the class instantiation and runs
   * through each value from the `initialState` that must be set to ensure the
   * default values are correct
   *
   * @param {object} initialState
   * @param {object} types
   */
  constructor(initialState = {}, types = {}) {
    // Store all property values for initial state
    const propertyConfig = {}

    forEach(initialState, (value, key) => {
      // Update initial state with the value - if its an observable
      if (types[key] === observable) {
        this[key] = value
      }

      // Configure property config for mobx
      propertyConfig[key] = types[key]
    })

    this._initialState = initialState
    makeObservable(this, propertyConfig)
  }

  reset = action(() => {
    forEach(this._initialState, (value, key) => {
      this[key] = value
    })
  })
}
