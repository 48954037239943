import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import CurrencyInput from 'react-currency-input-field'

/**
 * Text Input
 */
const TextInput = forwardRef(
  (
    {
      autoComplete,
      className,
      containerClassName,
      currency,
      inputStyles,
      endIcon,
      error,
      fullWidth,
      helpText,
      icon,
      id,
      label,
      labelStyles,
      name,
      nunito,
      placeholder,
      type,
      ...rest
    },
    ref,
  ) => (
    <div
      className={mergeClassNames(
        `flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`,
        containerClassName,
      )}
    >
      {label && (
        <label
          htmlFor={id}
          className={mergeClassNames(
            `pb-1 ${nunito ? 'font-nunito' : ''} text-sm font-medium text-gray-700`,
            labelStyles,
          )}
        >
          {label}
        </label>
      )}
      <div
        className={mergeClassNames(
          'placeholder:text-md relative block w-full appearance-none rounded-none border border-gray-300 bg-white px-3 text-gray-900 placeholder:text-gray-500 sm:placeholder:text-base',
          className,
        )}
      >
        {icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
            {icon}
          </div>
        )}

        {currency ? (
          <CurrencyInput
            id={id}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            type={type}
            className={mergeClassNames(
              'text-md w-full border-none p-0 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50 sm:text-base',
              inputStyles,
              nunito ? 'font-nunito' : '',
            )}
            ref={ref}
            {...rest}
          />
        ) : (
          <input
            id={id}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            type={type}
            className={mergeClassNames(
              'text-md w-full border-none p-0 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50 sm:text-base',
              inputStyles,
              nunito ? 'font-nunito' : '',
            )}
            ref={ref}
            {...rest}
          />
        )}

        {endIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">{endIcon}</div>
        )}
      </div>

      {helpText && (
        <div className="mt-1 w-full px-2 py-1">
          <p className="text-left text-xs font-medium italic text-gray-700">{helpText}</p>
        </div>
      )}

      {error && (
        <div className="mt-1 w-full bg-error-light px-2 py-1">
          <p className="text-sm font-medium text-error-dark" id={`error:${name}`}>
            {error}
          </p>
        </div>
      )}
    </div>
  ),
)

TextInput.defaultProps = {
  autoComplete: 'off',
  className: '',
  containerClassName: null,
  currency: false,
  inputStyles: '',
  endIcon: null,
  error: '',
  fullWidth: false,
  helpText: '',
  icon: null,
  id: '',
  label: '',
  labelStyles: '',
  name: '',
  nunito: false,
  placeholder: '',
  type: 'text',
}

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  currency: PropTypes.bool,
  inputStyles: PropTypes.string,
  endIcon: PropTypes.object,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  labelStyles: PropTypes.string,
  name: PropTypes.string,
  nunito: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

export default TextInput
