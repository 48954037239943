// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of exhibitor qualifiers.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getEventQualifiers = async (url, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds the specified qualifier from the `payload` to the event and exhibitor with the specified ids.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const addEventQualifier = async (
  eventId,
  eventExhibitorId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/qualifiers/`,
      payload,
    )

    setLoading(false)
    setSuccess(`${data.type} Question created.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the exhibitor qualifier with the specified `payload`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateEventQualifier = async (
  eventId,
  eventExhibitorId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/qualifiers/${payload.id}/`,
      payload,
    )

    setLoading(false)
    setSuccess(`${data.type} Question updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Reorder two qualifiers with the specified `payload`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {array} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const reorderEventQualifiers = async (
  eventId,
  eventExhibitorId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/qualifiers/manage/`,
      {
        qualifiers: payload,
        type: 'reorder',
      },
    )

    setLoading(false)
    setSuccess('Order updated.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the exhibitor qualifier with the specified `id`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteEventQualifier = async (
  eventId,
  eventExhibitorId,
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.post(`/events/${eventId}/exhibitors/${eventExhibitorId}/qualifiers/manage/`, {
      id,
      type: 'delete',
    })

    setLoading(false)
    setSuccess('Question deleted.')
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
