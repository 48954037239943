import React from 'react'
import PropTypes from 'prop-types'

const StarsIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 1V5M1 3H5M4 15V19M2 17H6M11 1L13.2857 7.85714L19 10L13.2857 12.1429L11 19L8.71429 12.1429L3 10L8.71429 7.85714L11 1Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

StarsIcon.defaultProps = {
  className: null,
}

StarsIcon.propTypes = {
  className: PropTypes.string,
}

export default StarsIcon
