import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Organization from '../../assets/images/organization_filled.svg'
import OrganizationPlaceholder from '../../assets/images/org_placeholder.svg'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { OrganizationIcon } from '../../components/OrganizationIcon'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import {
  addOrganization,
  getOrganizations,
  updateOrganization,
} from '../../services/organizations.service'

// Utils
import { handlePagination, toast } from '../../utils/helpers'

const DEFAULT = {
  name: '',
  logo: null,
}

/**
 *
 * AdminOrganizations
 *
 */
const AdminOrganizations = observer(() => {
  // Context
  const { updateActiveEntity } = useContext(NavigationStoreContext)
  const { isEEAdmin } = useContext(UserStoreContext)
  const navigate = useNavigate()

  // State
  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState(null)
  const [showOrganizationModal, setShowOrganizationModal] = useState(false)
  const [loadingOrganization, setLoadingOrganization] = useState(false)
  const [editOrganization, setEditOrganization] = useState(false)
  const [uploadLogo, setUploadLogo] = useState(null)
  const [newLogo, setNewLogo] = useState(null)
  const [fileLoaded, setFileLoaded] = useState(false)
  const [fileUploadWarning, setFileUploadWarning] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Ref
  const searchInputRef = useRef(null)

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets the updated list of organizations; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedOrganizationList = async (url) => {
    const response = await getOrganizations(url, handleErrors, setLoading, () => {})

    setTotalRows(response.count)
    setPages({ next: response.next, previous: response.previous })

    setOrganizations(response.results)
  }

  /**
   * When the row count changes, get the updated list of organizations.
   */
  useEffect(() => {
    if (filter) {
      getUpdatedOrganizationList(`/organizations/?limit=${perPage}&${filter}&type=Organizer`)
    } else {
      getUpdatedOrganizationList(`/organizations/?limit=${perPage}&type=Organizer`)
    }
  }, [filter, perPage])

  useEffect(() => {
    if (!fileLoaded && fileUploadWarning) {
      setFileUploadWarning(false)
    }
  }, [fileLoaded])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterOrganizations = useCallback(_.debounce(updateSearch, 500), [])

  const resetForm = () => {
    setEditOrganization(false)
    setUploadLogo(null)
    setNewLogo(null)
    setFileLoaded(false)
    setShowOrganizationModal(false)
    reset(DEFAULT)
  }

  /**
   * Handles submitting the add organizaiton and edit organization forms.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    // If the file is loaded and not uploaded, show the warning
    if (fileLoaded) {
      setFileUploadWarning(true)
      return
    }

    // Reset the warning
    setFileUploadWarning(false)

    if (editOrganization) {
      const updatedData = { name: data.name, signedLogoUrl: data.signedLogoUrl, id: data.id }
      await updateOrganization(updatedData, handleErrors, setLoadingOrganization, (m) => {
        getUpdatedOrganizationList(`/organizations/?limit=${perPage}&${filter}&type=Organizer`)
        handleSuccess(m)
        resetForm()
      })
    } else {
      const newData = { name: data.name, logoUrl: newLogo?.url }
      await addOrganization(newData, handleErrors, setLoadingOrganization, (m) => {
        getUpdatedOrganizationList(`/organizations/?limit=${perPage}&${filter}&type=Organizer`)
        handleSuccess(m)
        resetForm()
      })
      setUploadLogo(null)
    }
  }

  const columns = [
    {
      id: 'name',
      grow: 1.5,
      name: 'Name',
      selector: (row) => row.name,
      cell: (row) => (
        <div className="flex flex-row items-center">
          <div className="mr-2 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full drop-shadow">
            {row.signedLogoUrl ? (
              <img
                src={row.signedLogoUrl}
                alt={row.name}
                className="h-8 w-8 rounded-full object-fill"
              />
            ) : (
              <img src={OrganizationPlaceholder} alt={row.name} className="h-8" />
            )}
          </div>
          <span className="text-sm font-bold text-dark">{row.name}</span>
        </div>
      ),
      sortable: true,
      sortBy: 'name',
      minWidth: '250px',
    },
    {
      id: 'users',
      grow: 1,
      name: 'Number of Users',
      selector: (row) => row.numUsers,
      sortable: true,
      sortBy: 'num_users',
      minWidth: '175px',
    },
    {
      id: 'events',
      grow: 1,
      name: 'Number of Events',
      selector: (row) => row.numEvents,
      sortable: true,
      sortBy: 'num_events',
      minWidth: '175px',
    },
    {
      id: 'dashboard',
      grow: 1,
      name: '',
      cell: (row) => (
        <button
          className="font-bold text-purple-600 hover:rounded-full hover:bg-status-purple hover:px-2 hover:py-0.5"
          type="button"
          onClick={() => {
            updateActiveEntity('organization', row.id)
            navigate(`/organization/${row.id}/dashboard`)
          }}
        >
          Dashboard
        </button>
      ),
      center: true,
      minWidth: '125px',
    },
  ]

  if (isEEAdmin) {
    columns.push({
      id: 'edit',
      grow: 0.25,
      name: '',
      cell: (row) => (
        <button
          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
          type="button"
          onClick={() => {
            reset(row)

            setEditOrganization(row)
            setShowOrganizationModal(true)
          }}
        >
          Edit
        </button>
      ),
      center: true,
    })
  }

  const renderOrganizationLogo = () => {
    if (uploadLogo || !editOrganization || editOrganization.signedLogoUrl === null) {
      return (
        <div>
          <FileUploader
            showImagePreviewAfterUpload
            acceptedFileTypes={['image/*']}
            allowResize
            allowRevert
            fileLoaded={
              fileLoaded || (editOrganization && editOrganization?.signedLogoUrl !== null)
            }
            setFileLoaded={(s) => {
              setFileLoaded(s)
              if (editOrganization && editOrganization?.signedLogoUrl !== null) {
                setUploadLogo(false)
              }
            }}
            handleUploadToServer={async (file) => {
              if (editOrganization) {
                const update = await updateOrganization({
                  id: editOrganization.id,
                  logoUrl: file.url,
                })

                setEditOrganization({ ...editOrganization, signedLogoUrl: update.signedLogoUrl })
                setUploadLogo(null)
              } else {
                setNewLogo({ url: file.url, filename: file.name })
              }

              setFileLoaded(false)
            }}
            id="logo"
            imageCropAspectRatio="1:1"
            type="gcp"
          />

          {fileUploadWarning && (
            <div className="mt-1 w-full bg-error-light px-2 py-1">
              <span className="text-sm font-medium text-error-dark">
                Please upload your logo first or cancel the upload.
              </span>
            </div>
          )}
        </div>
      )
    }

    return (
      <div className="flex flex-row items-center space-x-4">
        {editOrganization.signedLogoUrl && (
          <img
            src={editOrganization.signedLogoUrl}
            alt="Logo"
            className="h-12 w-12 rounded-full object-fill"
          />
        )}

        <Button
          background="bg-white"
          label={
            (editOrganization && editOrganization.signedLogoUrl) || newLogo ? 'Change' : 'Upload'
          }
          onClick={() => setUploadLogo(true)}
          outlined
        />
      </div>
    )
  }

  return (
    <div className="h-full">
      <StateContainer stripedBackground>
        <div className="relative flex h-full w-full flex-col space-y-2 rounded-lg bg-background p-3 shadow-md md:h-[85%] md:w-[90%] md:p-4 short:overflow-y-auto">
          {isEEAdmin && (
            <Button
              background="bg-purple border-purple hover:bg-purple-600"
              className="absolute right-4"
              icon={<PlusIcon className="h-5 sm:h-6" />}
              label="Add Organization"
              onClick={() => setShowOrganizationModal(true)}
            />
          )}

          <div className="flex flex-col">
            <div className="flex flex-row items-center space-x-1">
              <img src={Organization} alt="Organizations" className="h-4" />
              <span className="text-md  font-bold">Organizations</span>
            </div>

            <span className="text-xs">Manage organizations and add organizations.</span>
          </div>

          <TextInput
            className="w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
            icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
            id="search"
            endIcon={
              searchTerm ? (
                <button
                  type="button"
                  onClick={() => {
                    filterOrganizations('')
                    setSearchTerm('')
                    searchInputRef.current.value = ''
                  }}
                >
                  <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                </button>
              ) : null
            }
            name="search"
            onChange={(e) => {
              filterOrganizations(e.target.value)
              setSearchTerm(e.target.value)
            }}
            placeholder="Looking for something?"
            ref={searchInputRef}
            type="search"
            value={searchTerm}
          />

          <DataTable
            columns={columns}
            data={organizations}
            defaultSortFieldId="name"
            defaultSortAsc
            onChangePage={(page) =>
              handlePagination(
                page,
                currentPage,
                perPage,
                totalRows,
                pages,
                setCurrentPage,
                getUpdatedOrganizationList,
                '/organizations/?type=Organizer&limit=',
                filter,
              )
            }
            onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
            onSort={(column, direction) => {
              const d = direction === 'asc' ? '' : '-'
              const url = `/organizations/?order_by=${d}${column.sortBy}&limit=${perPage}&type=Organizer`
              getUpdatedOrganizationList(url)
            }}
            pagination
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginationTotalRows={totalRows}
            paginationServer
            progressPending={loading}
            sortServer
          />
        </div>
      </StateContainer>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setTimeout(() => {
                resetForm()
              }, 500)
            },
          },
          {
            type: 'submit',
            label: editOrganization ? 'Save' : 'Add Organization',
            onClick: handleSubmit(onSubmit),
          },
        ]}
        icon={<img src={editOrganization ? Edit : Add} alt={editOrganization ? 'Edit' : 'Add'} />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<OrganizationIcon className="ml-1.5 stroke-purple" />}
              data-testid="name"
              error={errors.name && 'This field is required'}
              fullWidth
              id="name"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="name"
              nunito
              label="Name of Organization"
              placeholder="Name"
              {...register('name', { required: true })}
            />

            <div className="flex w-full flex-col">
              <label
                htmlFor="logo"
                className="self-start pb-1 font-nunito text-sm font-medium text-gray-700"
              >
                Logo
              </label>

              {renderOrganizationLogo()}
            </div>
          </div>
        }
        loading={loadingOrganization}
        onClose={() => {
          setShowOrganizationModal(false)

          setTimeout(() => {
            resetForm()
          }, 500)
        }}
        open={showOrganizationModal}
        setOpen={setShowOrganizationModal}
        title={editOrganization ? 'Edit' : 'Add an Organization'}
      />
    </div>
  )
})

export default AdminOrganizations
