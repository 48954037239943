import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css'

// Images
import PhoneNumber from '../../assets/images/phone_number.svg'

const Input = forwardRef(({ ...rest }, ref) => (
  <input
    data-testid="phoneNumber"
    ref={ref}
    {...rest}
    className="!border-none !ring-0 disabled:cursor-not-allowed disabled:opacity-50"
    style={{
      border: 'none !important',
      fontSize: '14px',
      width: '100%',
      padding: '0',
      fontFamily: 'Nunito Sans',
    }}
  />
))

/**
 * Renders a phone number input.
 * - Passes in `errors` if they are provided.
 */
const PhoneNumberInput = ({
  control,
  disabled,
  errors,
  hideIcon,
  label,
  labelStyles,
  name,
  placeholder,
}) => (
  <div className="flex w-full flex-col place-items-start">
    {label && (
      <label
        htmlFor={name}
        className={mergeClassNames(
          'pb-1 font-nunito text-sm font-medium text-gray-700',
          labelStyles,
        )}
      >
        {label}
      </label>
    )}
    <div
      className={mergeClassNames(
        'placeholder:text-md relative block w-full appearance-none rounded-2xl border border-gray-550 bg-white px-3 py-2.5 pl-8 pr-4 text-gray-900 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:placeholder:text-base',
      )}
    >
      {!hideIcon && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
          <img alt="PhoneNumber" className="ml-1.5 h-4" src={PhoneNumber} />
        </div>
      )}

      <PhoneInput
        control={control}
        defaultCountry="US"
        disabled={disabled}
        errors={errors}
        id={name}
        inputComponent={Input}
        name={name}
        placeholder={placeholder}
        rules={{
          validate: {
            valid: (v) => {
              if (v !== undefined && v !== null && v.length > 0) return isPossiblePhoneNumber(v)
              return true
            },
          },
        }}
        withCountryCallingCode
      />
    </div>
  </div>
)

PhoneNumberInput.defaultProps = {
  errors: {},
  hideIcon: false,
  label: null,
  labelStyles: '',
  placeholder: 'Phone Number',
}

PhoneNumberInput.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  hideIcon: PropTypes.bool,
  label: PropTypes.string,
  labelStyles: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

export default PhoneNumberInput
