import React from 'react'
import PropTypes from 'prop-types'
import { renderToStaticMarkup } from 'react-dom/server'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { DoodleSticks } from '../DoodleSticks'

// Images
import eventstackLogo from '../../assets/images/eventstack_logo_light.svg'
import jetpack from '../../assets/images/jetpack.svg'
import dog from '../../assets/images/dog_w_badge.png'
import fetchLogoDark from '../../assets/images/fetch_text_logo_dark.svg'

/**
 *
 * WelcomeTile
 *
 */
const WelcomeTile = ({ type }) => {
  const doodle = encodeURIComponent(
    renderToStaticMarkup(<DoodleSticks fill="#8C56F6" opacity={0.3} />),
  )

  return (
    <div
      className="relative mt-10 flex h-[198px] rounded-[14px] border-solid bg-purple-light px-1 py-4 sm:px-2"
      style={{
        backgroundImage: `url('data:image/svg+xml;utf8, ${doodle}')`,
        backgroundSize: 'cover',
      }}
    >
      <div className="z-10 ml-1 mt-4 flex flex-col sm:ml-8">
        <span
          className={mergeClassNames(
            'text-2xl font-bold text-white',
            type !== 'exhibitor' && 'ml-2 sm:ml-6',
          )}
        >
          Welcome to
        </span>
        {type === 'exhibitor' ? (
          <img
            className="mt-2 block h-10 w-auto sm:h-24"
            src={fetchLogoDark}
            alt="Event Stack Logo"
          />
        ) : (
          <img className="block h-10 w-auto sm:h-28" src={eventstackLogo} alt="Event Stack Logo" />
        )}
      </div>

      {type === 'exhibitor' ? (
        <div className="absolute -bottom-1 right-2">
          <img className="block h-36 w-auto sm:h-52" src={dog} alt="Dog" />
        </div>
      ) : (
        <div className="absolute -right-2 -top-5 sm:-right-4 sm:-top-16">
          <img className="block h-36 w-auto sm:h-72" src={jetpack} alt="Jetpack" />
        </div>
      )}
    </div>
  )
}

WelcomeTile.propTypes = {
  type: PropTypes.string.isRequired,
}

export default WelcomeTile
