import React from 'react'
import PropTypes from 'prop-types'

const CogIcon = ({ className }) => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9069 2.14876C16.2436 -0.583439 12.3579 -0.58344 11.6946 2.14876C11.2662 3.91372 9.24407 4.7513 7.69307 3.80626C5.2921 2.34333 2.5445 5.09092 4.00744 7.4919C4.95248 9.0429 4.11489 11.065 2.34993 11.4935C-0.382269 12.1568 -0.382267 16.0425 2.34993 16.7057C4.11489 17.1342 4.95248 19.1563 4.00744 20.7073C2.5445 23.1083 5.2921 25.8559 7.69307 24.393C9.24407 23.4479 11.2662 24.2855 11.6946 26.0505C12.3579 28.7827 16.2436 28.7827 16.9069 26.0505C17.3354 24.2855 19.3575 23.4479 20.9085 24.393C23.3095 25.8559 26.0571 23.1083 24.5941 20.7073C23.6491 19.1563 24.4867 17.1342 26.2516 16.7057C28.9838 16.0425 28.9838 12.1568 26.2516 11.4935C24.4867 11.065 23.6491 9.0429 24.5941 7.4919C26.0571 5.09093 23.3095 2.34333 20.9085 3.80626C19.3575 4.7513 17.3354 3.91372 16.9069 2.14876ZM14.3008 19.3496C17.2003 19.3496 19.5508 16.9991 19.5508 14.0996C19.5508 11.2001 17.2003 8.84961 14.3008 8.84961C11.4013 8.84961 9.05078 11.2001 9.05078 14.0996C9.05078 16.9991 11.4013 19.3496 14.3008 19.3496Z"
      fill="inherit"
    />
  </svg>
)

CogIcon.defaultProps = {
  className: null,
}

CogIcon.propTypes = {
  className: PropTypes.string,
}

export default CogIcon
