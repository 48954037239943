import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Image as KonvaImage } from 'react-konva'
import useImage from 'use-image'

// Service
import { getBadgeImage } from '../../services/badges.service'

// Utils
import { rotateAroundCenter } from '../../pages/EventBadgeBuilder/helpers'

/**
 *
 * BadgeImage
 *
 */
const BadgeImage = ({ base64, data, draggable, inGroup, onChange, transformerId, visible }) => {
  // State
  const [badgeImage, setBadgeImage] = useState(null)

  // Ref
  const ref = useRef()

  const source = useMemo(() => {
    // QR images use the provided `src`
    if (data.attrs.qrImage) return data.attrs.src

    // Try to use the `base64` string if it exists
    if (badgeImage && badgeImage.base64) return `data:image/png;base64,${badgeImage.base64}`

    // Otherwise, default to the `signedUrl`
    return badgeImage?.signedUrl
  }, [data, badgeImage])

  const [image] = useImage(source, 'anonymous', 'origin')

  useEffect(() => {
    const getImage = async () => {
      const badgeImageData = await getBadgeImage(
        data.attrs.badgeImagePath,
        () => {},
        () => {},
      )

      setBadgeImage(badgeImageData)
    }

    if (!data.attrs.qrImage && !base64) {
      getImage()
    } else if (base64) {
      setBadgeImage({ base64 })
    }
  }, [data.attrs.id])

  return (
    <KonvaImage
      {...data.attrs}
      className="Image"
      visible={visible}
      image={image}
      draggable={inGroup ? false : draggable}
      height={data.attrs.height}
      id={data.attrs.id}
      inGroup={inGroup}
      onDragEnd={(e) => {
        onChange(data.attrs.id, {
          x: e.target.x(),
          y: e.target.y(),
        })
      }}
      onTransformEnd={() => {
        // Find transformer off of parent
        let parent = ref.current.getParent()
        if (inGroup) parent = parent.getParent()
        const transformer = parent.children.find((c) => c.attrs.id === transformerId)

        // Transformer only changes the scale of the node, so we need to
        // manually update the width and height of the node using the new
        // scale values.
        const scaleX = transformer.scaleX()
        const scaleY = transformer.scaleY()

        // Update the width and height of the node
        const updatedWidth = Math.max(5, transformer.width() * scaleX)
        const updatedHeight = Math.max(transformer.height() * scaleY)

        // Get current transformer nodes
        const currentNodes = transformer.nodes()

        // Reset the scales of the transformer nodes
        currentNodes.forEach((n) => {
          n.scaleX(1)
          n.scaleY(1)
        })

        // Determine updated position and rotation
        const rotation = transformer.rotation()
        const updatedPosition = rotateAroundCenter(transformer, rotation)

        onChange(data.attrs.id, {
          x: updatedPosition.x,
          y: updatedPosition.y,
          rotation: transformer.rotation(),
          width: updatedWidth,
          height: updatedHeight,
        })
      }}
      ref={ref}
      width={data.attrs.width}
    />
  )
}

BadgeImage.defaultProps = {
  base64: null,
  draggable: true,
  inGroup: false,
  visible: true,
}

BadgeImage.propTypes = {
  base64: PropTypes.string,
  data: PropTypes.object.isRequired,
  draggable: PropTypes.bool,
  inGroup: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  transformerId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
}

export default BadgeImage
