import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Rect } from 'react-konva'

// Utils
import { rotateAroundCenter } from '../../pages/EventBadgeBuilder/helpers'

/**
 *
 * BadgeRectangle
 *
 */
const BadgeRectangle = ({ data, draggable, inGroup, onChange, transformerId, visible }) => {
  // Ref
  const ref = useRef()

  return (
    <Rect
      {...data.attrs}
      visible={visible}
      className="Rect"
      draggable={inGroup ? false : draggable}
      fill={data.attrs.fill}
      height={data.attrs.height}
      id={data.attrs.id}
      inGroup={inGroup}
      onDragEnd={(e) => {
        onChange(data.attrs.id, {
          x: e.target.x(),
          y: e.target.y(),
        })
      }}
      onTransformEnd={() => {
        // Find transformer off of parent
        let parent = ref.current.getParent()
        if (inGroup) parent = parent.getParent()
        const transformer = parent.children.find((c) => c.attrs.id === transformerId)

        // Transformer only changes the scale of the node, so we need to
        // manually update the width and height of the node using the new
        // scale values.
        const scaleX = transformer.scaleX()
        const scaleY = transformer.scaleY()

        // Update the width and height of the node
        const updatedWidth = Math.max(5, transformer.width() * scaleX)
        const updatedHeight = Math.max(transformer.height() * scaleY)

        // Get current transformer nodes
        const currentNodes = transformer.nodes()

        // Reset the scales of the transformer nodes
        currentNodes.forEach((n) => {
          n.scaleX(1)
          n.scaleY(1)
        })

        // Determine updated position and rotation
        const rotation = transformer.rotation()
        const updatedPosition = rotateAroundCenter(transformer, rotation)

        onChange(data.attrs.id, {
          x: updatedPosition.x,
          y: updatedPosition.y,
          rotation: transformer.rotation(),
          width: updatedWidth,
          height: updatedHeight,
        })
      }}
      ref={ref}
      width={data.attrs.width}
    />
  )
}

BadgeRectangle.defaultProps = {
  draggable: true,
  inGroup: false,
  transformerId: null,
  visible: true,
}

BadgeRectangle.propTypes = {
  data: PropTypes.object.isRequired,
  draggable: PropTypes.bool,
  inGroup: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  transformerId: PropTypes.string,
  visible: PropTypes.bool,
}

export default BadgeRectangle
