import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import dayjs from 'dayjs'
import { DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
import { CurrencyDollarIcon } from '@heroicons/react/24/outline'

// Components
import { AddExhibitorModal } from '../../components/AddExhibitorModal'
import { Button } from '../../components/Button'
import { CircleCheckIconSolid } from '../../components/CircleCheckIconSolid'
import { DataTable } from '../../components/DataTable'
import { DataTile } from '../../components/DataTile'
import { EditIcon } from '../../components/EditIcon'
import { EventHeader } from '../../components/EventHeader'
import { IdentificationIcon } from '../../components/IdentificationIcon'
import { StateContainer } from '../../components/StateContainer'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { getExhibitor, getExhibitorKPI, updateExhibitor } from '../../services/exhibitors.service'
import { getLicenses } from '../../services/licenses.service'

// Utils & Styles
import { toast } from '../../utils/helpers'

/**
 *
 * EventExhibitorDetail
 *
 */
const EventExhibitorDetail = observer(() => {
  // Context
  const { event, eventId, updateActiveEntity } = useContext(NavigationStoreContext)
  const { eventExhibitorId } = useParams()
  const navigate = useNavigate()

  // State
  const [loadingExhibitor, setLoadingExhibitor] = useState(false)
  const [exhibitor, setExhibitor] = useState([])
  const [kpis, setKpis] = useState(null)
  const [loadingLicenses, setLoadingLicenses] = useState(false)
  const [licenses, setLicenses] = useState([])
  const [assignedLicenses, setAssignedLicenses] = useState([])
  const [showExhibitorModal, setShowExhibitorModal] = useState(false)
  const [editExhibitor, setEditExhibitor] = useState(false)
  const [tasks, setTasks] = useState(null)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets the exhibitor and KPIs.
   */
  const getUpdatedExhibitor = async () => {
    const response = await getExhibitor(
      eventId,
      eventExhibitorId,
      handleErrors,
      () => {},
      () => {},
    )
    const exhibitorKPI = await getExhibitorKPI(
      eventId,
      eventExhibitorId,
      handleErrors,
      setLoadingExhibitor,
    )
    setKpis(exhibitorKPI)
    setExhibitor(response)
  }

  /**
   * Gets the updated list of licenses; updates pagination.
   * @param {string} url
   */
  const getUpdatedLicenses = async (url) => {
    const response = await getLicenses(url, handleErrors, setLoadingLicenses)
    setTotalRows(response.count)
    setPages({ next: response.next, previous: response.previous })
    const licenseList = response.results.filter((license) => license.isRefunded === false)
    setLicenses(licenseList)
    setAssignedLicenses(licenseList.filter((license) => license.assignee !== null))
  }

  /**
   * Handles submitting the edit exhibitor form.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    // eslint-disable-next-line no-param-reassign
    if (data.numFreeLicensesOverride === '') data.numFreeLicensesOverride = null
    const response = await updateExhibitor(
      eventId,
      data,
      handleErrors,
      setLoadingExhibitor,
      (m) => {
        handleSuccess(m)
        setShowExhibitorModal(false)
      },
    )
    setExhibitor(response)
  }

  /**
   * When the row count changes, get the updated licenses list.
   */
  useEffect(() => {
    if (eventExhibitorId) {
      getUpdatedLicenses(
        `/events/${eventId}/exhibitors/${eventExhibitorId}/licenses/?limit=${perPage}&expand=assignee`,
      )
    }
  }, [perPage])

  /**
   * Loads exhibitor details.
   */
  useEffect(() => {
    getUpdatedExhibitor()
  }, [])

  /**
   * Loads exhibitor tasks.
   */
  useEffect(() => {
    if (exhibitor?.tasklistItems) {
      const completedTasks = _.filter(
        _.map(exhibitor.tasklistItems, (i) => i),
        (i) => i.isComplete,
      )
      const lastCompleted = _.reduce(completedTasks, (a, b) => {
        if (a.isComplete && b.isComplete) {
          return dayjs(a.completedAt).isAfter(dayjs(b.completedAt)) ? a : b
        }
        if (a.isComplete) {
          return a
        }
        return b
      })
      const taskCount = exhibitor.tasklistItems.length
      setTasks({
        completed: completedTasks.length,
        lastCompleted,
        total: taskCount,
      })
    }
  }, [exhibitor])

  return (
    <div className="h-full w-full">
      <StateContainer loading={loadingExhibitor}>
        <div className="h-full w-full flex-col space-y-8 overflow-y-auto p-3">
          <EventHeader event={event} />

          <div className="flex items-center justify-between">
            <span className="text-lg font-bold lg:text-2xl">{exhibitor?.exhibitor?.name}</span>
            <div className="flex gap-2">
              <Button
                background="bg-white"
                icon={<EditIcon className="h-3 fill-gray sm:h-4" />}
                label="Edit"
                onClick={() => {
                  setEditExhibitor({
                    ...exhibitor,
                    exhibitor: exhibitor.exhibitor.name,
                    numFreeLicenses: exhibitor.numFreeLicenses || 0,
                  })
                  setShowExhibitorModal(true)
                }}
                outlined
              />
              <Button
                background="bg-white"
                label="View"
                onClick={() => {
                  updateActiveEntity('exhibitor', exhibitor?.exhibitor?.id)
                  navigate(`/exhibitor/${exhibitor?.exhibitor?.id}/event/${eventId}/home`)
                }}
                outlined
              />
            </div>
          </div>

          {/* Tasks */}
          <div>
            <span className="text-md mr-2 font-bold">Tasks Completed:</span>
            {tasks && (
              <span className="font-bold">
                {tasks.completed}/{tasks.total}
              </span>
            )}

            {tasks?.lastCompleted && (
              <>
                <div className="mb-1">Last task completed:</div>
                <span className="mt-2 inline-flex items-center gap-2 rounded-full border-2 border-purple py-2 pl-3 pr-16">
                  <CircleCheckIconSolid className="h-4 w-6 fill-purple" />
                  <span className="text-sm font-bold">{tasks.lastCompleted.title}</span>
                </span>
              </>
            )}
          </div>

          {/* Main Contact */}
          <div>
            <div className="text-md font-bold">Main Contact</div>
            <div className="text-sm">
              <span className="mr-6">
                {exhibitor?.contactFirstName} {exhibitor?.contactLastName}
              </span>
              <span>{exhibitor?.contactEmail}</span>
            </div>
          </div>

          {/* KPI */}
          {kpis && (
            <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
              <DataTile
                color="blue"
                icon={<CurrencyDollarIcon className="h-6 stroke-white" />}
                label="Total Revenue"
                value={kpis.totalRevenue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              />

              <DataTile
                color="green"
                icon={<IdentificationIcon className="h-6 fill-white" />}
                label="Total Licenses Purchased"
                value={kpis.totalLicensesCount}
              />

              <DataTile
                color="purple"
                icon={<DevicePhoneMobileIcon className="h-6 fill-white" />}
                label="Total Devices Rented"
                value={kpis.rentalDeviceCount}
              />
            </div>
          )}

          {/* Licensed Users */}
          <div className="grid">
            <div className="text-md mb-2 font-bold">
              Licensed Users {`${assignedLicenses.length}/${licenses.length} assigned`}
            </div>

            <DataTable
              columns={[
                {
                  id: 'name',
                  grow: 1,
                  name: 'Name',
                  selector: (row) => row.id,
                  cell: (row) => (
                    <span className="font-medium text-black">
                      {row.assignee.user.firstName} {row.assignee.user.lastName}
                    </span>
                  ),
                  minWidth: '300px',
                },
                {
                  id: 'email',
                  grow: 1,
                  name: 'Email',
                  selector: (row) => row.assignee,
                  cell: (row) => (
                    <span className="font-medium text-black">{row.assignee.user.email}</span>
                  ),
                  minWidth: '300px',
                },
                {
                  id: 'licenseId',
                  grow: 1,
                  name: 'License ID',
                  selector: (row) => row.assignee,
                  cell: (row) => <span className="font-medium text-black">{row.licenseId}</span>,
                  minWidth: '300px',
                },
              ]}
              data={assignedLicenses}
              defaultSortFieldId="name"
              defaultSortAsc
              onChangePage={async (page) => {
                // If the user is requesting the first page and we are not on the next page,
                // we need to get the very first page and not utilize `previous`.
                if (page === 1 && currentPage > 1) {
                  await getUpdatedLicenses(
                    `/events/${eventId}/exhibitors/${eventExhibitorId}/licenses/?limit=${perPage}&page=1&expand=assignee`,
                  )
                }
                // If the user is requesting the last page and we are not on the previous page,
                // we need to get the very last page and not utilize `next`.
                else if (page > currentPage && page - currentPage > 1) {
                  await getUpdatedLicenses(
                    `/events/${eventId}/exhibitors/${eventExhibitorId}/licenses/?limit=${perPage}&page=${Math.ceil(
                      totalRows / perPage,
                    )}&expand=assignee`,
                  )
                }
                // If the user is requesting the next page.
                else if (page > currentPage) {
                  await getUpdatedLicenses(pages.next)
                }
                // Otherwise, the user is requesting the previous page.
                else {
                  await getUpdatedLicenses(pages.previous)
                }

                setCurrentPage(page)
              }}
              onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
              pagination
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 30, 50]}
              paginationTotalRows={totalRows}
              paginationServer
              progressPending={loadingLicenses}
              sortServer
            />
          </div>
        </div>
      </StateContainer>

      {showExhibitorModal && (
        <AddExhibitorModal
          editExhibitor={editExhibitor}
          loading={loadingExhibitor}
          onSubmit={onSubmit}
          setShowExhibitorModal={setShowExhibitorModal}
        />
      )}
    </div>
  )
})

export default EventExhibitorDetail
