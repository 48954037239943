import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 * TextArea
 */
const TextArea = forwardRef(
  (
    {
      className,
      inputStyles,
      error,
      id,
      label,
      labelStyles,
      name,
      nunito,
      placeholder,
      resize,
      type,
      ...rest
    },
    ref,
  ) => (
    <div className="flex w-full flex-col place-items-start">
      <label
        htmlFor={id}
        className={mergeClassNames(
          `pb-1 ${nunito ? 'font-nunito' : ''} text-sm font-medium text-gray-700`,
          labelStyles,
        )}
      >
        {label}
      </label>
      <div
        className={mergeClassNames(
          'relative block w-full appearance-none rounded-none border border-gray-300 bg-white px-3 text-sm text-gray-900 placeholder:text-gray-500',
          className,
        )}
      >
        <textarea
          className={mergeClassNames(
            'w-full border-none p-0 text-sm focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50',
            inputStyles,
            nunito ? 'font-nunito' : '',
            resize && `${resize}`,
          )}
          id={id}
          rows={2}
          name={name}
          placeholder={placeholder}
          ref={ref}
          {...rest}
        />
      </div>

      {error && (
        <div className="mt-1 w-full bg-error-light px-2 py-1">
          <p className="text-sm font-medium text-error-dark" id={`error:${name}`}>
            {error}
          </p>
        </div>
      )}
    </div>
  ),
)

TextArea.defaultProps = {
  className: '',
  inputStyles: '',
  error: '',
  id: '',
  label: '',
  labelStyles: '',
  name: '',
  nunito: false,
  placeholder: '',
  resize: null,
  type: 'text',
}

TextArea.propTypes = {
  className: PropTypes.string,
  inputStyles: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelStyles: PropTypes.string,
  name: PropTypes.string,
  nunito: PropTypes.bool,
  placeholder: PropTypes.string,
  resize: PropTypes.string,
  type: PropTypes.string,
}

export default TextArea
