/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import React from 'react'

/**
 *
 * Alert
 *
 */
const AuthGradient = () => (
  <>
    {/* top right circle */}
    <div
      className="absolute -right-[1200px] -top-[1200px] z-0 h-[1800px] w-[1800px] rounded-full opacity-50"
      style={{
        background: 'radial-gradient(circle, #6262FF 0%, rgba(24,24,35,1) 70%)',
        mixBlendMode: 'lighten',
      }}
    />

    {/* bottom left circle */}
    <div
      className="absolute -bottom-[1250px] -left-[1250px] z-0 h-[1800px] w-[1800px] rounded-full opacity-50"
      style={{
        background: 'radial-gradient(circle, #6262FF 0%, rgba(24,24,35,1) 70%)',
        mixBlendMode: 'lighten',
      }}
    />
  </>
)

export default AuthGradient
