import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Parser } from 'html-to-react'

// Components
import { Modal } from '../Modal'
import { ThumbsUpIcon } from '../ThumbsUpIcon'

// Utils
import { replaceMergeFields } from './helpers'

/**
 *
 * WelcomePopup
 *
 */
const WelcomePopup = ({
  attendee,
  config,
  completeCheckin,
  close,
  externalPopupField,
  staffMode,
}) => {
  // Auto close after 10 seconds
  useEffect(() => {
    completeCheckin()

    const timeout = setTimeout(() => {
      close()
    }, [10000]) // 10 seconds

    return () => clearTimeout(timeout)
  }, [])

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Close',
          onClick: close,
        },
      ]}
      content={
        <div className="mt-4 flex flex-col items-center">
          <span className="text-center text-2xl font-bold">
            {replaceMergeFields(attendee, config.header)}
          </span>
          <p className="mt-4 text-center text-gray">
            {Parser().parse(replaceMergeFields(attendee, config.body))}
          </p>

          {externalPopupField && attendee.customData[externalPopupField] && (
            <p className="mt-4 text-center text-gray">{attendee.customData[externalPopupField]}</p>
          )}

          {staffMode && attendee.customNotes && (
            <p className="mt-4 text-center text-gray">{attendee.customNotes}</p>
          )}
        </div>
      }
      icon={<ThumbsUpIcon className="h-6 w-6 fill-white" />}
      open
    />
  )
}

WelcomePopup.defaultProps = {
  externalPopupField: null,
  staffMode: false,
}

WelcomePopup.propTypes = {
  attendee: PropTypes.object.isRequired,
  completeCheckin: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  externalPopupField: PropTypes.string,
  staffMode: PropTypes.bool,
}

export { WelcomePopup }
