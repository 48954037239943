import React from 'react'
import PropTypes from 'prop-types'

const DoodleSticks = ({ fill, opacity, radialGradient }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4096 4096"
    style={{
      background: radialGradient || '',
    }}
  >
    <g
      transform="translate(0.000000,4096) scale(0.100000,-0.100000)"
      fill={fill}
      stroke="none"
      fillOpacity={opacity}
    >
      <path
        d="M2486 40863 c-118 -178 -227 -422 -281 -628 -59 -229 -29 -274 331
-494 517 -317 935 -567 1014 -606 96 -48 220 -95 395 -151 102 -33 134 -38
211 -39 84 0 93 2 127 28 49 37 70 80 70 142 0 69 -48 168 -119 244 -52 57
-72 68 -347 205 -285 141 -448 233 -692 390 -132 85 -160 108 -181 147 -12 25
-6 41 110 290 144 310 256 556 256 563 0 3 -187 6 -414 6 l-415 0 -65 -97z"
      />
      <path
        d="M4125 40942 c-77 -23 -97 -35 -129 -73 -32 -38 -35 -99 -7 -145 10
-17 47 -55 82 -85 190 -163 273 -258 492 -561 161 -223 193 -260 282 -313 88
-52 268 -109 325 -101 92 13 160 84 160 169 0 39 -12 70 -68 180 -121 234
-229 395 -391 581 -98 114 -243 245 -316 287 -118 69 -313 96 -430 61z"
      />
      <path
        d="M5956 40838 c-33 -255 -32 -336 11 -508 23 -93 57 -156 122 -220 71
-72 170 -121 255 -127 125 -9 223 76 245 214 10 64 31 521 31 687 l0 76 -324
0 -323 0 -17 -122z"
      />
      <path
        d="M7279 40939 c96 -80 470 -275 553 -289 110 -17 252 22 308 86 45 51
48 94 13 162 l-29 57 -434 2 -435 2 24 -20z"
      />
      <path
        d="M15160 40951 c0 -21 86 -113 160 -172 157 -124 315 -206 499 -256 88
-25 112 -27 286 -27 178 -1 196 1 289 27 232 65 389 158 606 360 l83 77 -961
0 c-538 0 -962 -4 -962 -9z"
      />
      <path
        d="M20610 40909 c65 -164 184 -365 310 -524 91 -115 302 -327 392 -395
155 -117 304 -179 480 -200 236 -28 373 42 386 195 2 30 1 66 -2 81 -12 47
-125 152 -256 238 -274 181 -431 340 -565 574 l-48 82 -358 0 -359 0 20 -51z"
      />
      <path
        d="M22350 40951 c0 -21 45 -62 94 -86 46 -22 75 -28 193 -36 232 -17
321 -42 589 -167 247 -115 443 -146 621 -96 101 28 152 109 139 222 -7 61 -27
104 -64 145 l-26 27 -773 0 c-428 0 -773 -4 -773 -9z"
      />
      <path
        d="M26335 40908 c-92 -77 -110 -108 -110 -184 0 -74 19 -106 86 -144 40
-23 54 -25 169 -25 106 0 136 4 195 24 93 32 213 92 305 154 74 50 198 166
220 206 l11 21 -408 0 -408 -1 -60 -51z"
      />
      <path
        d="M28740 40952 c0 -10 77 -84 150 -144 185 -154 475 -292 697 -334 103
-19 270 -21 368 -4 232 41 489 187 735 418 l75 71 -1012 1 c-557 0 -1013 -4
-1013 -8z"
      />
      <path
        d="M33100 40913 c-55 -63 -123 -179 -159 -273 -39 -101 -59 -199 -72
-357 l-11 -131 32 -114 c39 -140 83 -232 153 -323 164 -214 459 -355 742 -355
200 0 325 76 325 199 0 108 -127 228 -320 301 -211 80 -239 127 -201 345 30
180 92 391 166 567 36 86 65 163 65 172 0 14 -36 16 -339 16 l-339 0 -42 -47z"
      />
      <path
        d="M37165 40938 c114 -483 146 -570 239 -664 98 -97 217 -140 371 -132
119 6 169 31 203 100 22 44 23 51 12 120 -6 40 -39 190 -74 333 l-63 260 -347
3 -346 2 5 -22z"
      />
      <path
        d="M38695 40936 c120 -72 271 -90 367 -43 30 15 63 36 73 47 18 20 18
20 -231 20 l-249 0 40 -24z"
      />
      <path
        d="M9195 40857 c-107 -28 -178 -85 -191 -152 -12 -64 12 -125 97 -251
60 -89 108 -184 153 -301 97 -250 208 -430 373 -604 60 -62 129 -141 155 -175
90 -117 182 -174 325 -199 103 -19 158 -18 224 1 76 23 125 62 189 150 30 42
126 170 214 283 165 215 207 285 213 361 5 44 4 46 -70 122 -92 94 -129 113
-254 134 -115 19 -196 14 -276 -16 -61 -23 -180 -97 -240 -150 -21 -18 -39
-31 -41 -29 -26 35 -72 129 -110 227 -59 151 -102 230 -182 331 -75 95 -182
181 -291 233 -69 34 -94 40 -160 44 -45 2 -99 -2 -128 -9z"
      />
      <path
        d="M17990 40833 c-165 -12 -255 -48 -333 -133 -90 -97 -156 -264 -142
-359 8 -54 35 -121 65 -159 20 -25 30 -27 238 -54 831 -106 1049 -125 1253
-109 216 17 301 42 390 112 115 91 157 277 105 469 -33 117 -70 140 -254 151
-75 4 -222 13 -327 19 -104 6 -345 24 -535 41 -190 16 -361 28 -380 27 -19 -1
-55 -3 -80 -5z"
      />
      <path
        d="M13750 40813 c-444 -30 -845 -144 -1093 -311 -141 -94 -291 -273
-348 -412 -61 -151 -3 -283 171 -388 l65 -39 95 44 c140 65 281 147 363 211
127 101 149 111 392 178 422 116 646 143 1005 121 121 -8 139 -7 170 10 53 28
75 75 75 158 -1 94 -27 146 -117 230 -172 162 -307 207 -613 203 -66 -1 -140
-3 -165 -5z"
      />
      <path
        d="M35700 40774 c-41 -16 -96 -48 -131 -76 -31 -25 -39 -38 -39 -63 0
-57 -47 -217 -94 -325 -73 -163 -45 -157 -258 -58 -280 131 -439 197 -515 213
-117 25 -115 26 -157 -64 -48 -103 -53 -137 -28 -192 28 -62 132 -176 237
-261 286 -228 525 -318 817 -306 262 11 420 110 502 313 62 156 75 359 32 527
-52 206 -130 287 -281 294 -38 2 -77 1 -85 -2z"
      />
      <path
        d="M31321 40674 c-95 -48 -165 -160 -197 -316 -23 -113 -10 -201 48
-318 38 -79 55 -99 228 -270 250 -249 370 -393 542 -656 126 -191 174 -226
308 -227 108 0 179 29 251 102 39 40 63 75 78 114 29 79 44 189 36 261 -9 87
-58 230 -105 309 -180 308 -761 915 -965 1008 -62 29 -158 25 -224 -7z"
      />
      <path
        d="M39885 40574 c-180 -33 -468 -104 -539 -133 -160 -64 -265 -156 -337
-294 -23 -45 -51 -93 -61 -107 -18 -26 -18 -26 12 -93 36 -81 91 -144 145
-168 45 -21 153 -25 223 -9 23 6 148 28 276 50 253 42 320 61 389 109 53 36
122 112 147 161 61 120 67 290 15 395 -17 33 -42 67 -56 78 -35 25 -118 29
-214 11z"
      />
      <path
        d="M1130 40551 c-93 -20 -150 -71 -176 -157 -21 -72 -18 -106 41 -418
31 -160 76 -399 101 -531 92 -494 100 -521 178 -601 56 -58 97 -74 185 -74
165 0 306 87 344 213 54 178 18 539 -103 1022 -67 270 -97 340 -176 415 -100
95 -281 155 -394 131z"
      />
      <path
        d="M7345 40418 c-150 -9 -433 -71 -518 -114 -162 -82 -163 -272 -2 -320
134 -40 321 -66 537 -74 249 -10 268 -15 359 -98 119 -109 157 -254 109 -414
-51 -171 -155 -325 -389 -575 l-151 -162 47 -40 c25 -23 75 -55 110 -73 87
-43 176 -44 278 -3 364 145 603 352 741 640 106 222 110 364 19 562 -152 330
-422 554 -775 644 -99 26 -230 36 -365 27z"
      />
      <path
        d="M16739 40281 c-78 -25 -157 -80 -236 -166 -83 -89 -128 -161 -158
-257 -13 -40 -54 -166 -90 -280 -38 -119 -72 -208 -79 -208 -19 0 -1168 385
-1203 403 -33 18 -43 47 -58 168 -13 104 -78 179 -155 179 -67 0 -219 -94
-346 -213 -132 -122 -277 -352 -291 -459 -13 -101 48 -203 150 -252 33 -16
122 -38 242 -61 456 -87 687 -156 1150 -348 322 -132 470 -170 670 -171 115
-1 123 0 187 31 73 34 156 106 195 166 46 72 63 140 108 412 49 300 85 459
145 635 66 192 64 302 -5 379 -55 61 -126 74 -226 42z"
      />
      <path
        d="M24118 40286 c-167 -48 -310 -202 -355 -382 -27 -106 -24 -386 5
-521 31 -143 145 -469 205 -588 66 -128 165 -236 264 -286 62 -31 74 -34 157
-33 161 1 263 50 287 136 16 60 -2 145 -67 316 -134 353 -172 619 -154 1067 4
107 3 125 -16 165 -24 51 -64 94 -109 118 -40 20 -158 25 -217 8z"
      />
      <path
        d="M40555 40251 c-151 -26 -153 -126 -7 -373 75 -126 107 -210 122 -315
14 -106 2 -184 -59 -382 -79 -255 -88 -359 -41 -457 28 -58 93 -125 147 -152
51 -27 143 -43 198 -35 l45 6 0 802 0 803 -67 35 c-104 54 -246 83 -338 68z"
      />
      <path
        d="M38182 40140 c-209 -55 -232 -120 -138 -396 76 -224 182 -497 223
-577 62 -119 200 -226 338 -262 79 -20 130 -19 210 6 114 35 178 116 160 202
-17 82 -228 614 -302 761 -48 95 -184 216 -286 254 -81 30 -124 33 -205 12z"
      />
      <path
        d="M28257 40139 c-235 -35 -457 -296 -457 -537 0 -79 54 -420 111 -702
59 -290 69 -330 160 -648 86 -304 116 -451 129 -631 8 -124 12 -148 36 -191
29 -53 84 -103 142 -127 80 -33 231 -11 324 48 98 63 178 235 178 388 0 160
-62 495 -160 866 -102 381 -180 680 -180 686 0 3 147 9 328 12 355 6 400 12
546 67 144 55 235 136 267 238 17 53 23 249 10 298 -17 62 -51 105 -105 132
-57 29 -199 41 -486 42 -225 0 -433 15 -590 40 -171 28 -187 29 -253 19z"
      />
      <path
        d="M0 39345 c0 -540 3 -795 10 -795 27 0 109 53 151 97 99 103 172 247
218 424 34 134 56 307 48 381 -13 124 -99 333 -191 462 -46 64 -177 195 -215
216 l-21 11 0 -796z"
      />
      <path
        d="M30613 40021 c-123 -42 -193 -126 -228 -273 -8 -35 -20 -142 -25
-238 -12 -203 -15 -232 -42 -360 -24 -112 -62 -212 -125 -330 -63 -117 -151
-260 -176 -288 -48 -52 -144 -81 -207 -62 -20 6 -60 35 -97 72 -60 59 -64 62
-120 65 -53 4 -64 1 -123 -34 -95 -56 -233 -195 -266 -268 -40 -87 -44 -187
-12 -281 85 -244 268 -343 580 -314 253 24 465 128 617 302 87 101 268 383
342 533 132 269 188 495 196 785 5 162 3 188 -26 358 -42 247 -72 311 -160
341 -45 15 -66 14 -128 -8z"
      />
      <path
        d="M11409 40017 c-117 -44 -165 -130 -200 -362 -28 -192 -53 -280 -135
-486 -41 -103 -74 -195 -74 -205 1 -60 72 -147 148 -181 54 -24 174 -22 232 4
92 41 240 230 315 402 66 150 88 255 89 426 1 144 0 152 -26 206 -36 71 -115
154 -173 180 -59 26 -131 33 -176 16z"
      />
      <path
        d="M19978 39956 c-138 -60 -232 -139 -333 -281 -240 -335 -297 -713
-170 -1122 72 -229 197 -434 370 -608 125 -124 250 -214 425 -304 190 -98 292
-120 459 -101 164 19 221 61 229 172 9 116 -48 196 -216 303 -274 176 -311
203 -398 289 -100 100 -149 177 -184 291 -79 256 8 614 224 924 101 146 109
227 30 319 -47 55 -106 85 -244 122 -61 16 -111 30 -113 30 -1 0 -37 -15 -79
-34z"
      />
      <path
        d="M25976 39879 c-256 -33 -529 -161 -713 -332 -76 -71 -114 -124 -143
-197 l-20 -52 31 -56 c49 -87 94 -138 145 -162 41 -20 57 -22 152 -18 125 6
202 29 502 151 294 120 411 133 556 62 91 -44 156 -113 249 -264 157 -254 290
-364 455 -378 125 -11 242 28 287 96 46 68 20 192 -82 407 -119 249 -361 512
-564 613 -209 105 -594 163 -855 130z"
      />
      <path
        d="M36420 39755 c-80 -19 -254 -89 -363 -144 -197 -100 -391 -248 -513
-389 -177 -207 -327 -490 -369 -701 -25 -121 -17 -297 19 -407 46 -144 168
-358 279 -492 120 -145 302 -221 501 -210 126 7 176 46 176 137 0 54 -26 110
-105 226 -124 181 -165 295 -165 455 0 139 34 253 119 403 88 153 254 343 411
470 100 81 291 205 404 263 50 26 105 57 123 70 36 27 55 80 46 125 -27 138
-345 247 -563 194z"
      />
      <path
        d="M18004 39542 c-71 -34 -112 -108 -100 -186 14 -87 210 -341 349 -452
153 -121 256 -156 404 -135 112 16 160 35 180 75 21 39 21 129 0 177 -15 37
-236 319 -311 397 -88 93 -190 132 -361 138 -104 4 -126 2 -161 -14z"
      />
      <path
        d="M12885 39395 c-22 -8 -139 -54 -260 -103 -121 -49 -307 -124 -412
-167 -217 -87 -258 -115 -293 -197 -33 -75 -33 -201 0 -276 45 -103 166 -171
335 -187 151 -15 316 37 664 210 211 105 274 150 313 222 49 90 53 133 24 289
-15 76 -32 141 -39 146 -22 14 -141 57 -187 67 -60 14 -97 13 -145 -4z"
      />
      <path
        d="M21785 39295 c-196 -51 -350 -134 -487 -264 -194 -183 -301 -386
-302 -572 -1 -182 193 -361 330 -304 27 11 107 85 259 239 222 225 309 299
400 342 91 43 138 33 282 -64 205 -138 347 -184 465 -152 79 22 137 102 138
190 0 96 -116 265 -267 388 -222 180 -566 263 -818 197z"
      />
      <path
        d="M6335 39276 c-236 -33 -351 -91 -450 -230 -56 -79 -96 -165 -131
-281 -26 -84 -28 -103 -28 -280 -1 -213 8 -262 70 -393 27 -55 59 -97 132
-174 223 -232 425 -357 693 -428 246 -65 490 -100 693 -100 119 0 139 3 250
36 230 68 291 112 291 210 0 40 -6 58 -27 87 -55 70 -201 120 -408 138 -214
18 -340 37 -500 78 -378 96 -490 192 -490 420 0 97 21 186 67 281 28 59 55 94
125 164 119 117 137 159 99 241 -28 62 -157 194 -216 222 -56 26 -48 25 -170
9z"
      />
      <path
        d="M39664 39246 c-114 -28 -191 -97 -271 -246 -137 -254 -178 -440 -139
-642 51 -268 251 -496 575 -658 146 -73 389 -150 691 -220 126 -29 399 -80
427 -80 10 0 13 56 13 259 l0 260 -52 15 c-29 8 -168 44 -308 81 -350 91 -459
128 -530 181 -40 30 -60 51 -64 71 -25 134 -13 268 45 502 33 132 40 176 36
222 -10 108 -95 219 -194 253 -55 19 -157 19 -229 2z"
      />
      <path
        d="M37075 38893 c-109 -65 -198 -150 -235 -227 -39 -79 -59 -214 -67
-451 -11 -325 20 -430 171 -581 102 -102 142 -118 284 -112 78 3 107 9 147 29
69 35 108 92 107 156 -15 870 -19 989 -36 1033 -30 79 -104 140 -221 180 -32
11 -61 20 -64 20 -4 0 -42 -21 -86 -47z"
      />
      <path
        d="M34345 38857 c-83 -21 -161 -63 -216 -114 -45 -42 -66 -76 -160 -267
-118 -239 -187 -398 -214 -490 -25 -84 -17 -184 20 -256 36 -72 127 -160 205
-199 119 -59 258 -36 342 57 50 55 60 77 102 222 45 155 71 226 208 568 72
180 107 280 103 293 -4 11 -33 47 -65 80 -96 98 -210 135 -325 106z"
      />
      <path
        d="M25460 38674 c-69 -19 -139 -63 -191 -122 -42 -48 -74 -99 -206 -322
-151 -255 -363 -594 -426 -678 -38 -51 -123 -122 -146 -122 -6 0 -131 76 -278
169 -527 331 -610 377 -767 421 -136 38 -218 23 -304 -54 -48 -44 -66 -84 -58
-129 9 -48 113 -201 183 -269 100 -97 1149 -810 1246 -846 118 -44 225 -36
341 26 57 30 182 152 323 316 388 452 735 1054 760 1320 14 152 -73 255 -246
292 -83 17 -161 17 -231 -2z"
      />
      <path
        d="M13998 38565 c-79 -20 -125 -45 -176 -96 -62 -63 -86 -119 -90 -211
-4 -65 0 -85 21 -130 57 -124 158 -181 437 -243 305 -68 523 -191 799 -451
147 -139 234 -278 234 -378 0 -122 -119 -252 -353 -383 -155 -87 -224 -120
-310 -148 -183 -59 -282 -167 -327 -354 -22 -93 -8 -139 62 -208 64 -62 148
-93 254 -93 79 0 139 15 280 70 459 180 711 382 863 692 76 154 126 355 135
543 6 110 6 111 -36 235 -69 202 -170 364 -335 537 -214 224 -452 371 -785
485 -47 16 -171 54 -276 85 -212 62 -299 73 -397 48z"
      />
      <path
        d="M2135 38525 c-263 -48 -603 -283 -804 -555 -154 -208 -282 -503 -312
-716 -23 -167 27 -382 111 -478 49 -55 102 -78 178 -78 127 0 275 104 321 226
10 29 31 131 46 227 15 96 39 210 54 256 36 105 119 230 214 319 98 94 150
119 247 119 107 0 158 -28 318 -174 176 -160 241 -188 363 -157 185 48 306
178 317 340 l4 73 -50 75 c-80 119 -186 241 -257 298 -243 193 -501 271 -750
225z"
      />
      <path
        d="M38047 38446 c-75 -28 -112 -83 -104 -153 9 -87 79 -147 292 -253
167 -83 312 -132 476 -159 231 -40 293 -27 343 72 l28 56 -52 60 c-55 64 -131
118 -275 199 -240 133 -592 222 -708 178z"
      />
      <path
        d="M17815 38419 c-71 -10 -267 -37 -435 -59 -168 -23 -327 -48 -355 -57
-141 -44 -240 -130 -314 -274 -52 -103 -55 -155 -11 -247 33 -70 52 -82 185
-118 61 -17 106 -19 360 -19 370 1 525 22 725 97 68 26 96 43 138 85 100 100
145 243 123 396 -23 162 -78 218 -215 216 -39 -1 -129 -10 -201 -20z"
      />
      <path
        d="M32219 38190 c-127 -38 -239 -140 -444 -405 -76 -99 -184 -225 -240
-280 -169 -168 -367 -292 -585 -367 -87 -30 -106 -32 -220 -33 -197 0 -276 39
-452 221 -145 150 -217 181 -315 133 -83 -40 -198 -213 -223 -336 -24 -113 2
-237 80 -398 69 -139 173 -246 307 -314 97 -48 288 -81 477 -81 169 0 439 81
701 210 445 220 800 577 1055 1062 73 139 95 226 86 335 -13 138 -48 211 -120
244 -44 20 -66 22 -107 9z"
      />
      <path
        d="M4407 38179 c-81 -12 -143 -41 -167 -81 -36 -56 -23 -104 84 -321 52
-105 145 -309 206 -452 155 -360 180 -406 264 -490 112 -111 277 -187 411
-188 138 -1 206 99 176 261 -11 60 -176 461 -326 792 -105 233 -237 399 -367
460 -55 26 -74 30 -143 29 -44 -1 -106 -5 -138 -10z"
      />
      <path
        d="M10582 38165 c-40 -8 -141 -34 -225 -59 -259 -78 -405 -110 -1057
-236 -201 -39 -385 -75 -410 -81 -168 -40 -263 -148 -305 -348 -20 -93 -6
-141 67 -239 l32 -42 -32 -133 c-35 -145 -39 -222 -22 -367 38 -317 151 -542
577 -1150 102 -146 220 -317 263 -381 76 -112 133 -172 224 -233 52 -35 104
-34 182 3 97 46 154 128 154 224 0 58 -36 226 -65 302 -43 116 -220 414 -489
825 -198 303 -227 357 -278 534 -26 93 -72 325 -65 333 8 8 238 61 702 163
542 120 835 195 931 239 164 76 229 217 195 417 -38 216 -134 273 -379 229z"
      />
      <path
        d="M26630 38034 c-259 -37 -441 -123 -587 -277 -243 -256 -508 -817
-538 -1142 -15 -151 38 -385 111 -492 122 -178 355 -288 539 -254 72 13 104
34 129 86 19 38 19 40 -4 183 -38 236 -17 391 84 620 89 204 300 539 383 611
61 53 150 96 231 112 84 16 253 6 362 -21 118 -30 202 -24 299 20 78 36 109
69 118 124 16 100 -151 277 -334 354 -183 78 -544 112 -793 76z"
      />
      <path
        d="M0 37660 c0 -175 4 -261 11 -266 6 -3 65 -8 132 -11 122 -5 283 13
329 38 57 31 75 135 37 219 -49 110 -145 167 -427 253 l-82 25 0 -258z"
      />
      <path
        d="M11820 37827 c-203 -85 -330 -263 -330 -462 1 -170 42 -260 189 -405
264 -263 605 -408 999 -427 144 -6 241 8 391 57 277 90 558 317 671 540 70
138 97 333 58 405 -95 176 -305 129 -596 -133 -130 -117 -187 -153 -277 -177
-110 -28 -217 -13 -419 59 -237 85 -378 219 -432 414 -12 42 -30 77 -54 104
-32 37 -41 41 -90 45 -41 3 -69 -2 -110 -20z"
      />
      <path
        d="M19310 37494 c-101 -37 -149 -85 -275 -274 -84 -126 -424 -566 -701
-908 -101 -125 -111 -250 -27 -343 45 -50 91 -74 168 -88 155 -29 254 -5 410
100 119 79 192 154 370 377 249 312 352 480 440 717 81 218 51 316 -124 398
-80 38 -190 47 -261 21z"
      />
      <path
        d="M22117 37366 c-99 -41 -168 -104 -210 -191 -38 -76 -152 -410 -181
-526 -29 -121 -53 -190 -91 -274 -37 -80 -38 -159 -1 -280 38 -128 83 -217
159 -318 223 -295 515 -528 837 -668 152 -66 305 -147 387 -206 41 -29 97 -65
122 -78 101 -51 266 -56 333 -11 41 27 59 78 59 167 0 88 -15 121 -97 203 -85
85 -171 142 -434 286 -257 140 -369 218 -491 339 -153 153 -183 227 -144 357
25 86 122 454 158 599 59 239 70 300 63 345 -16 98 -94 198 -194 247 -81 40
-193 44 -275 9z"
      />
      <path
        d="M39552 37379 c-68 -13 -137 -49 -276 -143 -490 -332 -914 -722 -1095
-1005 -30 -46 -35 -62 -35 -115 1 -75 26 -128 97 -204 62 -64 93 -82 145 -82
101 0 144 29 838 551 612 459 742 563 773 612 81 132 -84 346 -299 387 -60 11
-87 11 -148 -1z"
      />
      <path
        d="M16955 37174 c-75 -24 -180 -133 -233 -244 -71 -147 -82 -212 -92
-560 -12 -405 -18 -506 -36 -627 -16 -113 -12 -149 31 -234 23 -45 26 -46 140
-89 132 -48 124 -49 221 23 176 130 249 390 261 933 8 382 -23 662 -82 740
-48 63 -125 85 -210 58z"
      />
      <path
        d="M33152 37165 c-177 -53 -285 -133 -346 -256 -61 -121 -70 -236 -28
-331 32 -73 124 -163 210 -205 74 -37 79 -38 186 -38 104 0 114 2 165 30 81
45 102 78 113 183 5 48 10 177 11 287 2 198 2 201 -25 257 -14 31 -38 64 -54
74 -43 29 -134 28 -232 -1z"
      />
      <path
        d="M34479 37140 c-87 -22 -149 -52 -183 -90 -71 -79 -53 -160 55 -245
183 -144 326 -317 440 -535 101 -192 279 -288 480 -260 95 14 151 46 173 100
21 52 20 75 -4 172 -30 115 -98 263 -175 379 -118 177 -372 409 -505 461 -61
24 -218 34 -281 18z"
      />
      <path
        d="M37556 37039 c-56 -14 -164 -73 -219 -120 -64 -55 -856 -994 -930
-1103 -54 -80 -77 -144 -77 -214 0 -69 22 -120 78 -182 62 -71 130 -103 228
-108 95 -5 145 12 215 74 54 49 945 1066 1011 1154 102 138 128 245 81 336
-62 118 -251 198 -387 163z"
      />
      <path
        d="M27239 36854 c-105 -32 -203 -171 -216 -307 -9 -101 8 -150 81 -223
75 -75 129 -97 293 -119 204 -29 392 -64 553 -105 289 -73 635 -133 920 -160
106 -10 391 -49 398 -55 6 -6 -21 -449 -54 -875 -58 -742 -59 -770 -46 -864
13 -98 47 -173 94 -210 135 -102 395 -9 487 174 38 75 47 116 70 325 25 224
34 368 66 1015 13 283 26 553 29 600 7 129 -11 307 -36 358 -26 55 -98 98
-178 107 -30 4 -161 22 -290 41 -803 120 -991 152 -1328 228 -243 55 -303 64
-512 76 -214 13 -275 12 -331 -6z"
      />
      <path
        d="M6420 36778 c-87 -12 -246 -67 -315 -109 -91 -55 -155 -119 -245
-245 -194 -272 -322 -501 -355 -638 -21 -86 -21 -240 -1 -318 31 -117 94 -209
185 -272 50 -35 161 -76 205 -76 84 0 139 84 200 305 63 227 167 411 345 612
192 217 328 291 536 294 28 0 102 15 165 32 l115 32 3 50 c7 107 -29 155 -171
227 -208 104 -430 140 -667 106z"
      />
      <path
        d="M20495 36762 c-93 -68 -178 -173 -264 -327 -185 -329 -412 -693 -763
-1223 -124 -186 -212 -330 -219 -355 -33 -122 44 -259 170 -302 55 -19 143
-19 203 -1 87 26 211 111 299 204 210 224 341 413 569 822 224 401 262 493
325 772 l35 158 -20 53 c-27 71 -68 126 -136 177 -45 36 -65 44 -107 48 -45 3
-57 0 -92 -26z"
      />
      <path
        d="M1918 36531 c-93 -30 -135 -109 -183 -346 -50 -251 -117 -429 -226
-600 -81 -129 -119 -237 -119 -341 0 -38 5 -47 50 -90 98 -93 164 -124 264
-124 86 0 164 39 261 131 204 194 324 493 342 855 7 142 -3 198 -54 297 -36
69 -134 171 -193 202 -37 19 -107 27 -142 16z"
      />
      <path
        d="M3029 36506 c-110 -39 -191 -133 -205 -239 -7 -50 47 -267 102 -412
72 -186 176 -320 293 -378 64 -31 70 -32 186 -31 102 1 128 4 173 24 94 41
162 144 162 244 0 49 -26 103 -79 170 -58 72 -116 179 -187 342 -68 158 -96
202 -152 239 -74 49 -216 69 -293 41z"
      />
      <path
        d="M7933 36310 c-59 -30 -88 -80 -87 -150 1 -46 11 -76 66 -190 74 -152
104 -232 179 -470 28 -91 62 -187 75 -213 32 -61 86 -122 139 -153 96 -56 358
-93 423 -60 41 21 72 82 72 141 0 102 -111 441 -210 640 -130 262 -243 372
-459 446 -90 31 -149 34 -198 9z"
      />
      <path
        d="M10354 36299 c-59 -17 -126 -71 -158 -129 -29 -51 -31 -62 -31 -155
0 -129 21 -175 109 -245 73 -59 148 -96 361 -183 344 -139 511 -180 755 -181
253 -2 440 17 548 53 134 45 340 141 374 174 64 64 108 201 89 274 -14 52 -51
85 -136 123 -132 59 -159 62 -590 58 l-390 -3 -100 31 c-160 51 -471 141 -582
168 -103 26 -192 32 -249 15z"
      />
      <path
        d="M23677 36120 c-97 -38 -157 -108 -157 -185 0 -46 11 -66 78 -143 67
-76 130 -109 348 -181 249 -83 471 -110 890 -111 321 0 434 19 514 87 125 105
49 291 -162 397 -86 44 -122 49 -618 91 -228 19 -489 42 -580 50 -211 19 -254
18 -313 -5z"
      />
      <path
        d="M4409 36090 c-59 -31 -62 -38 -42 -116 24 -97 24 -356 0 -479 -32
-160 -98 -389 -130 -448 -37 -70 -110 -139 -165 -155 -22 -7 -78 -17 -123 -23
-123 -15 -313 -49 -320 -57 -3 -4 -14 -36 -23 -72 -10 -36 -20 -73 -22 -82 -6
-22 65 -101 121 -134 99 -57 146 -67 358 -72 314 -7 432 15 560 102 140 96
253 300 302 546 22 110 30 318 16 430 -26 207 -137 436 -248 513 -94 65 -213
85 -284 47z"
      />
      <path
        d="M39358 36070 c-68 -37 -106 -113 -92 -187 10 -54 134 -232 237 -340
220 -230 379 -287 618 -223 113 31 130 175 36 300 -122 162 -290 358 -334 387
-84 57 -152 75 -298 79 -116 4 -134 2 -167 -16z"
      />
      <path
        d="M33605 36054 c-166 -16 -233 -44 -265 -109 -25 -52 -25 -68 1 -115
27 -48 170 -144 354 -237 211 -107 262 -175 311 -409 37 -177 37 -336 -1 -609
-43 -313 -30 -401 75 -506 25 -25 77 -61 115 -80 61 -30 80 -34 167 -37 127
-6 179 9 234 63 40 41 42 46 87 231 78 317 82 347 82 564 0 336 -57 536 -213
754 -137 189 -390 379 -592 444 -112 36 -259 55 -355 46z"
      />
      <path
        d="M13351 35967 c-62 -20 -135 -99 -184 -200 -53 -108 -72 -187 -62
-266 13 -105 271 -719 342 -813 114 -152 393 -319 630 -379 279 -71 537 -43
799 86 138 68 231 136 405 297 147 137 173 167 211 243 46 91 59 152 68 330
l8 170 -27 28 c-42 46 -108 80 -155 80 -80 0 -165 -52 -308 -190 -163 -156
-261 -234 -351 -279 -180 -91 -390 -98 -550 -18 -166 83 -284 236 -417 539
-102 234 -128 281 -171 319 -69 59 -158 79 -238 53z"
      />
      <path
        d="M473 35752 c-28 -21 -78 -53 -110 -73 -44 -27 -65 -48 -88 -90 l-30
-54 -3 -197 c-4 -220 3 -272 50 -369 27 -55 31 -74 29 -137 -2 -133 36 -182
209 -266 82 -40 88 -41 185 -41 90 0 106 3 158 29 69 33 111 86 158 196 36 86
49 194 30 253 -7 20 -41 68 -76 107 -72 80 -97 134 -114 250 -15 100 -66 205
-133 276 -54 58 -182 155 -202 153 -6 0 -34 -17 -63 -37z"
      />
      <path
        d="M27425 35504 c-188 -20 -490 -86 -564 -123 -91 -47 -152 -140 -172
-260 -9 -56 -9 -77 1 -101 27 -66 40 -68 562 -80 359 -8 562 -22 582 -40 12
-11 -52 -121 -168 -289 -183 -266 -244 -385 -287 -569 -42 -175 -19 -440 50
-588 35 -75 122 -171 186 -205 41 -22 46 -22 97 -10 302 78 348 120 348 326 0
125 -17 215 -54 295 -35 75 -36 157 -1 226 14 27 71 110 126 184 255 340 365
506 399 604 75 211 -17 430 -222 530 -140 69 -309 96 -618 101 -124 1 -243 1
-265 -1z"
      />
      <path
        d="M31830 35499 c-101 -12 -764 -39 -970 -39 -226 0 -306 -11 -387 -50
-74 -37 -131 -97 -176 -189 -52 -107 -40 -208 39 -323 51 -73 116 -102 341
-147 87 -18 138 -21 371 -21 149 0 292 4 319 10 26 5 106 17 177 25 137 16
260 52 376 108 93 45 198 152 229 232 49 130 35 280 -33 348 -53 54 -129 66
-286 46z"
      />
      <path
        d="M38805 35473 c-27 -10 -216 -101 -420 -204 -399 -200 -585 -283 -856
-380 -211 -75 -225 -81 -249 -122 -81 -131 93 -357 294 -382 71 -9 124 3 373
84 488 160 781 302 1074 523 121 91 173 151 185 213 13 73 -71 186 -185 249
-67 36 -146 43 -216 19z"
      />
      <path
        d="M21378 35436 c-33 -12 -135 -91 -166 -129 -7 -8 -16 -70 -22 -138
-34 -394 -146 -1001 -237 -1283 -32 -98 -37 -108 -56 -103 -12 3 -107 35 -212
70 -361 124 -424 136 -529 97 -30 -12 -73 -38 -96 -58 l-42 -37 16 -64 c38
-148 103 -218 312 -333 437 -240 604 -298 858 -298 188 0 285 31 321 101 52
100 211 843 269 1255 33 234 54 646 35 711 -18 64 -94 145 -177 185 -57 28
-79 33 -152 36 -53 1 -99 -3 -122 -12z"
      />
      <path
        d="M6367 35216 c-133 -48 -142 -186 -26 -367 27 -41 146 -166 345 -362
194 -191 328 -331 370 -389 l67 -90 -44 -91 c-47 -98 -250 -372 -574 -777 -95
-118 -188 -240 -208 -271 -39 -61 -83 -163 -93 -214 -5 -30 -3 -35 24 -49 103
-53 279 -79 381 -55 133 30 332 185 567 440 192 208 314 345 400 454 82 103
272 410 280 453 10 50 -1 116 -25 163 -52 100 -270 346 -625 706 -389 395
-413 414 -541 448 -74 19 -246 19 -298 1z"
      />
      <path
        d="M11954 34994 c-65 -16 -121 -78 -165 -181 -28 -67 -33 -90 -33 -164
l-1 -85 73 -141 c40 -78 88 -165 107 -194 62 -95 80 -166 80 -314 -1 -221 -51
-370 -160 -481 -94 -95 -239 -159 -456 -203 -166 -33 -262 -101 -322 -229 -30
-63 -32 -74 -32 -182 0 -108 2 -119 30 -175 26 -53 37 -63 87 -89 52 -25 70
-29 185 -33 329 -13 690 124 914 347 170 169 282 395 346 699 24 114 27 146
27 336 0 191 -2 222 -27 340 -66 311 -167 503 -333 635 -128 102 -228 138
-320 114z"
      />
      <path
        d="M35793 34985 c-31 -13 -115 -115 -128 -154 -3 -12 8 -44 29 -83 104
-189 83 -408 -69 -698 -144 -275 -153 -306 -115 -384 46 -95 170 -176 272
-176 112 0 275 79 383 186 238 236 320 536 239 874 -43 175 -147 316 -285 384
-119 58 -259 80 -326 51z"
      />
      <path
        d="M39757 34983 c-26 -9 -68 -34 -92 -55 -117 -103 -253 -340 -301 -523
-35 -135 -45 -347 -19 -424 22 -69 92 -158 156 -201 45 -30 61 -35 124 -38 81
-4 129 13 176 65 60 66 81 127 109 311 28 187 52 273 131 470 89 223 90 252
11 335 -68 70 -190 95 -295 60z"
      />
      <path
        d="M16630 34979 c-133 -16 -219 -55 -300 -138 -65 -67 -130 -186 -130
-240 1 -137 113 -199 515 -281 586 -120 898 -150 1195 -116 268 31 359 60 446
138 83 77 134 197 134 317 0 101 -49 150 -181 180 -104 24 -129 27 -524 51
-357 23 -509 38 -725 75 -155 26 -286 30 -430 14z"
      />
      <path
        d="M25599 34706 c-147 -39 -254 -162 -332 -381 -43 -122 -225 -963 -287
-1325 -49 -294 -78 -426 -128 -585 -36 -115 -37 -126 -37 -265 0 -128 3 -151
22 -192 25 -54 109 -149 126 -143 7 3 52 9 102 15 160 17 263 89 333 234 90
185 170 460 212 726 83 534 167 964 195 998 2 2 17 -3 33 -10 28 -12 492 -479
648 -653 80 -89 148 -129 219 -129 99 1 167 60 199 174 24 88 28 142 17 206
-19 102 -60 167 -247 384 -210 245 -198 232 -588 638 l-309 322 -66 -1 c-36 0
-87 -6 -112 -13z"
      />
      <path
        d="M1955 34652 c-63 -30 -135 -149 -135 -223 0 -41 90 -231 183 -388
102 -172 91 -251 -63 -457 -88 -117 -176 -197 -360 -329 -240 -172 -285 -233
-282 -380 1 -44 8 -107 15 -140 l12 -60 70 -35 c63 -31 80 -35 170 -38 117 -5
179 12 310 83 250 136 441 323 601 588 125 209 180 442 156 665 -15 140 -33
208 -87 317 -72 148 -189 266 -360 364 -91 53 -166 63 -230 33z"
      />
      <path
        d="M33130 34636 c-144 -31 -179 -98 -187 -356 -8 -242 32 -418 152 -665
199 -408 516 -673 937 -784 184 -49 257 -56 578 -56 l295 0 90 33 c109 39 126
55 132 119 4 41 0 56 -29 103 -18 30 -51 67 -73 82 -59 41 -283 95 -490 118
-210 24 -304 48 -420 105 -165 81 -260 179 -340 350 -56 119 -79 210 -110 445
-26 201 -48 305 -71 351 -18 36 -65 74 -133 108 -117 60 -213 73 -331 47z"
      />
      <path
        d="M5939 34408 c-97 -70 -181 -140 -259 -219 -129 -130 -155 -146 -393
-253 -220 -99 -408 -168 -554 -205 -153 -38 -165 -40 -348 -56 -125 -10 -161
-17 -194 -36 -69 -38 -87 -111 -55 -219 28 -95 173 -209 342 -267 104 -36 238
-36 447 1 487 85 937 278 1174 502 135 126 242 299 282 451 34 130 2 230 -97
304 -37 29 -179 99 -199 99 -1 0 -67 -46 -146 -102z"
      />
      <path
        d="M10485 34466 c-53 -24 -112 -83 -141 -138 -9 -18 -36 -85 -59 -148
-123 -334 -247 -631 -276 -660 -94 -94 -347 -49 -516 92 -99 83 -385 420 -376
444 5 14 -140 243 -204 322 -51 62 -103 86 -188 86 -90 0 -161 -24 -218 -75
-65 -56 -81 -107 -81 -244 0 -106 2 -116 51 -260 147 -434 309 -674 574 -851
156 -104 329 -169 534 -200 128 -20 381 -14 470 11 111 31 235 97 341 183 241
194 386 401 446 638 46 178 23 563 -41 709 -46 105 -195 148 -316 91z"
      />
      <path
        d="M13290 34398 c-144 -55 -279 -284 -280 -475 -1 -168 137 -531 355
-934 137 -253 209 -347 308 -399 l43 -23 122 23 c120 22 123 23 157 62 55 65
78 123 83 213 7 129 -31 250 -248 785 -89 219 -168 440 -210 588 -24 82 -72
145 -127 169 -47 19 -139 15 -203 -9z"
      />
      <path
        d="M22670 34266 c-63 -17 -130 -40 -148 -50 -84 -44 -159 -158 -184
-278 -23 -110 27 -225 122 -277 46 -26 424 -111 953 -216 324 -64 393 -68 502
-30 183 65 281 192 278 360 -3 144 -77 259 -195 303 -120 45 -1082 224 -1188
221 -14 0 -77 -15 -140 -33z"
      />
      <path
        d="M30488 33990 c-227 -28 -353 -138 -389 -340 -24 -134 38 -235 186
-305 77 -36 95 -40 265 -55 277 -25 515 -103 1063 -349 220 -98 318 -156 413
-244 l73 -68 108 11 c144 15 196 33 249 85 59 60 66 91 62 285 -3 164 -3 166
-36 232 -42 85 -89 126 -254 224 -440 259 -893 445 -1247 510 -100 18 -394 27
-493 14z"
      />
      <path
        d="M40420 33964 c-84 -22 -127 -58 -224 -189 -49 -66 -147 -195 -218
-288 -134 -173 -174 -243 -184 -318 l-5 -44 79 -79 c93 -93 129 -114 231 -132
203 -36 315 -6 502 137 l75 57 31 -51 c17 -29 45 -90 63 -137 47 -124 113
-258 154 -315 l36 -50 0 610 0 609 -60 59 c-75 72 -156 111 -274 132 -107 18
-134 18 -206 -1z"
      />
      <path
        d="M3055 33945 c-79 -23 -157 -67 -182 -104 -67 -99 -68 -208 -8 -548
91 -511 119 -635 163 -728 88 -182 239 -285 421 -285 133 0 211 44 227 127 4
21 0 96 -9 168 -16 134 -204 1069 -234 1163 -22 73 -79 151 -136 186 -39 24
-60 30 -120 32 -42 2 -93 -2 -122 -11z"
      />
      <path
        d="M37150 33901 c-25 -9 -69 -31 -98 -47 -45 -25 -54 -36 -68 -78 -25
-73 -16 -187 20 -252 40 -74 232 -300 378 -446 l127 -127 -47 -42 c-26 -23
-119 -89 -207 -147 -88 -58 -196 -129 -241 -158 -220 -145 -279 -212 -279
-319 1 -71 18 -108 77 -164 94 -91 232 -137 332 -112 42 10 1094 571 1178 628
51 35 82 100 73 154 -13 75 -199 327 -336 453 -33 31 -106 93 -162 138 -149
121 -240 211 -317 313 -79 105 -165 190 -215 210 -47 20 -159 18 -215 -4z"
      />
      <path
        d="M38666 33799 c-55 -13 -86 -39 -125 -103 l-34 -56 29 -42 c34 -49 61
-76 139 -142 109 -93 264 -333 312 -484 54 -169 39 -342 -44 -512 -42 -87 -56
-105 -168 -215 -164 -160 -185 -197 -150 -265 34 -66 121 -101 252 -101 97 0
198 30 308 91 91 51 126 77 205 155 150 147 207 322 182 559 -28 265 -129 531
-295 775 -103 152 -201 247 -310 301 -81 40 -95 44 -175 47 -48 1 -104 -2
-126 -8z"
      />
      <path
        d="M0 33158 l0 -607 69 -70 c130 -132 283 -210 412 -211 124 0 250 55
283 123 34 74 16 146 -73 276 -72 106 -115 191 -175 346 -89 228 -198 401
-360 573 -56 59 -113 123 -128 142 l-28 35 0 -607z"
      />
      <path
        d="M15015 33749 c-149 -19 -262 -107 -337 -265 -36 -75 -42 -98 -46
-169 -4 -73 -1 -90 20 -136 49 -105 18 -94 418 -144 765 -97 988 -111 1225
-80 126 17 175 32 247 79 136 87 190 269 139 469 -40 155 -61 166 -335 177
-255 11 -306 15 -727 51 -353 30 -479 33 -604 18z"
      />
      <path
        d="M18574 33561 c-42 -11 -61 -25 -128 -101 -70 -79 -87 -111 -120 -226
-56 -196 -72 -457 -41 -652 18 -112 67 -267 107 -345 72 -136 248 -227 413
-214 77 6 118 27 152 78 42 64 43 141 4 254 -48 140 -52 170 -60 490 -8 285
-10 305 -35 390 -32 106 -69 175 -144 268 -58 72 -70 76 -148 58z"
      />
      <path
        d="M30348 32845 c-138 -38 -216 -74 -846 -389 -326 -162 -624 -305 -663
-317 -94 -28 -176 -23 -249 17 -66 36 -101 78 -150 180 -23 47 -54 94 -72 109
-30 25 -38 27 -96 22 -131 -10 -294 -123 -356 -245 -87 -171 -69 -351 51 -534
73 -111 183 -195 325 -246 65 -24 87 -27 208 -27 122 0 150 4 285 37 198 48
295 84 445 167 1005 549 1027 561 1118 651 83 81 180 204 208 265 31 66 33
189 5 252 -36 79 -87 93 -213 58z"
      />
      <path
        d="M23632 32787 c-46 -14 -117 -80 -141 -130 -12 -24 -37 -103 -56 -177
-19 -73 -40 -137 -47 -142 -32 -20 -202 -67 -286 -78 -121 -17 -393 -7 -567
19 -71 11 -222 38 -335 61 -146 30 -221 41 -259 37 -51 -4 -58 -8 -102 -56
-114 -123 -146 -264 -94 -407 47 -129 126 -218 238 -269 72 -33 296 -90 452
-116 421 -68 912 -21 1180 113 206 103 358 283 412 488 24 92 24 294 -1 375
-55 183 -173 296 -305 294 -31 -1 -71 -6 -89 -12z"
      />
      <path
        d="M4560 32768 c-81 -23 -117 -70 -171 -226 -100 -286 -279 -561 -565
-865 -253 -269 -332 -380 -350 -494 -6 -38 -3 -46 26 -80 50 -59 139 -131 205
-166 56 -30 66 -32 165 -32 90 0 113 4 159 25 102 45 216 143 517 442 l301
299 22 -34 c38 -61 71 -146 107 -274 43 -155 64 -200 121 -263 49 -55 135
-101 221 -119 73 -15 196 -14 235 3 44 18 95 78 103 121 27 147 -142 616 -459
1266 -111 227 -164 297 -270 349 -107 52 -275 74 -367 48z"
      />
      <path
        d="M19984 32720 c-134 -35 -259 -114 -373 -235 -161 -173 -261 -370
-302 -595 -20 -110 -17 -472 5 -564 41 -172 186 -375 308 -431 158 -72 342
-50 363 43 3 16 -1 71 -10 123 -39 245 7 580 108 773 65 125 210 266 274 266
37 0 61 -16 154 -105 109 -104 149 -120 300 -119 91 0 112 4 154 25 27 13 58
37 69 52 60 83 -22 310 -174 480 -196 220 -612 356 -876 287z"
      />
      <path
        d="M7685 32599 c-150 -19 -221 -84 -219 -204 2 -144 36 -183 259 -291
374 -180 612 -259 863 -285 170 -17 247 -10 335 34 129 65 207 153 207 236 0
142 -118 231 -305 231 -180 0 -331 41 -575 155 -172 81 -221 97 -360 120 -104
16 -104 16 -205 4z"
      />
      <path
        d="M14965 32551 c-74 -46 -89 -76 -180 -371 -36 -118 -77 -239 -91 -267
-117 -244 -457 -378 -759 -298 -140 37 -252 111 -362 240 -31 37 -70 73 -87
82 -157 81 -376 -167 -376 -427 0 -146 72 -302 195 -426 198 -200 476 -286
821 -254 442 41 758 239 1002 625 120 191 182 455 169 725 -4 97 -10 128 -36
197 -18 44 -42 96 -54 114 -48 71 -174 102 -242 60z"
      />
      <path
        d="M35398 32560 c-94 -12 -148 -31 -183 -64 -38 -36 -51 -77 -60 -196
l-6 -85 76 -245 c152 -488 194 -606 236 -669 48 -72 149 -167 217 -204 182
-99 424 -63 445 68 7 43 -4 88 -127 498 -125 417 -202 648 -238 720 -71 141
-191 200 -360 177z"
      />
      <path
        d="M26368 32431 c-85 -28 -137 -62 -209 -139 -111 -117 -116 -125 -123
-227 -20 -257 -105 -593 -226 -899 -70 -178 -103 -251 -257 -571 -77 -159
-146 -307 -153 -329 -8 -24 -11 -68 -8 -115 7 -121 73 -233 158 -269 56 -23
197 -28 258 -8 85 28 187 137 255 271 21 43 315 702 407 915 27 62 72 151 87
169 2 3 74 -65 161 -150 184 -181 271 -253 438 -365 195 -129 537 -296 732
-358 282 -89 498 -35 581 144 35 76 37 214 4 276 -33 61 -93 107 -202 155
-659 287 -1159 704 -1572 1311 -55 81 -116 160 -135 175 -46 35 -118 40 -196
14z"
      />
      <path
        d="M16081 32394 c-29 -8 -66 -23 -81 -34 -52 -37 -108 -110 -136 -178
-26 -60 -29 -79 -29 -177 0 -95 3 -116 23 -156 58 -118 141 -172 333 -220 166
-41 235 -62 356 -109 161 -63 231 -75 438 -75 206 0 262 13 320 70 67 68 100
217 71 318 -28 97 -77 135 -343 269 -323 162 -507 231 -760 288 -108 23 -120
24 -192 4z"
      />
      <path
        d="M10288 32265 c-81 -20 -239 -89 -332 -144 -102 -60 -171 -127 -203
-197 -24 -52 -25 -64 -20 -158 7 -126 21 -170 72 -222 54 -53 109 -69 223 -61
93 6 246 49 337 94 184 92 319 259 356 438 11 55 10 64 -9 107 -49 111 -265
184 -424 143z"
      />
      <path
        d="M11175 32238 c-48 -24 -111 -83 -131 -121 -43 -82 -38 -233 17 -447
36 -138 94 -264 222 -477 110 -184 204 -348 341 -595 110 -198 141 -240 197
-268 82 -42 176 -11 273 90 105 110 141 237 112 404 -35 208 -60 256 -656
1227 l-84 136 -111 36 c-61 21 -117 37 -125 37 -8 -1 -33 -10 -55 -22z"
      />
      <path
        d="M33607 32206 c-138 -50 -244 -260 -202 -401 17 -60 52 -95 150 -156
150 -92 227 -169 283 -287 40 -84 47 -169 19 -234 -48 -113 -242 -242 -482
-321 -44 -15 -204 -54 -355 -87 -293 -65 -363 -90 -438 -155 -51 -45 -113
-133 -155 -224 -28 -58 -32 -76 -32 -156 0 -80 3 -95 27 -136 37 -62 92 -111
233 -204 234 -154 385 -335 664 -800 95 -157 144 -225 249 -344 l39 -45 104
17 c183 30 259 83 295 203 41 140 35 304 -17 458 -46 137 -166 377 -254 506
-123 180 -195 289 -195 295 0 2 69 38 153 78 261 127 443 264 563 424 78 103
129 205 150 299 16 75 16 94 1 357 -15 258 -18 283 -45 362 -43 124 -85 188
-197 300 -107 107 -259 210 -362 246 -61 22 -142 24 -196 5z"
      />
      <path
        d="M31957 31916 c-70 -25 -114 -64 -210 -183 -119 -148 -197 -222 -273
-261 -123 -62 -243 -65 -452 -11 -109 28 -225 78 -392 170 -71 39 -78 41 -145
37 -92 -4 -156 -41 -275 -158 -99 -98 -120 -143 -120 -252 0 -89 29 -165 83
-220 83 -85 396 -216 615 -258 275 -53 567 -29 768 64 367 170 631 526 637
861 2 81 -1 96 -27 148 -18 37 -37 62 -53 68 -35 13 -111 10 -156 -5z"
      />
      <path
        d="M40155 31891 c-79 -20 -136 -71 -221 -197 -99 -148 -108 -179 -160
-529 -47 -317 -54 -493 -25 -629 27 -133 66 -210 141 -279 135 -125 290 -155
399 -76 41 29 62 61 87 131 10 28 19 190 34 591 28 782 28 762 6 826 -20 57
-72 123 -115 146 -35 17 -108 26 -146 16z"
      />
      <path
        d="M1765 31822 c-82 -39 -158 -161 -158 -256 0 -34 8 -67 23 -96 29 -57
143 -227 236 -352 80 -108 97 -147 96 -224 0 -61 -19 -107 -117 -285 -112
-204 -137 -319 -94 -433 26 -70 55 -91 160 -117 139 -35 206 -10 319 122 168
195 260 394 290 629 29 225 -37 529 -156 712 -88 136 -210 231 -366 283 -108
35 -182 41 -233 17z"
      />
      <path
        d="M18225 31593 c-120 -27 -263 -95 -335 -160 -83 -75 -149 -192 -170
-304 -17 -90 21 -150 130 -206 48 -25 66 -28 145 -28 86 0 95 2 192 47 121 55
228 124 273 175 53 60 70 108 70 198 -1 201 -66 286 -220 284 -36 -1 -74 -3
-85 -6z"
      />
      <path
        d="M5956 31425 c-85 -30 -112 -82 -143 -270 l-16 -100 32 -115 c41 -145
97 -290 173 -448 91 -190 172 -263 293 -263 69 0 134 29 175 79 49 59 108 190
120 263 18 119 -10 198 -162 451 -165 277 -224 345 -338 397 -53 24 -81 25
-134 6z"
      />
      <path
        d="M37290 31423 c-249 -41 -265 -47 -362 -140 -176 -170 -329 -391 -438
-633 -66 -146 -131 -354 -137 -437 -5 -64 -3 -78 21 -127 15 -31 45 -69 69
-89 58 -48 438 -282 1122 -692 155 -93 250 -135 465 -207 266 -89 354 -95 430
-32 72 61 80 145 25 259 -63 131 -108 166 -409 313 -293 145 -444 229 -690
388 -120 77 -176 119 -194 146 l-25 38 173 371 c225 485 223 480 224 560 0 53
-5 74 -27 111 -30 52 -177 179 -204 177 -10 -1 -29 -4 -43 -6z"
      />
      <path
        d="M175 31383 c-71 -26 -110 -57 -134 -106 -33 -67 -28 -106 17 -143
101 -84 445 -281 558 -319 95 -32 253 -8 329 50 85 64 85 152 0 253 -33 39
-281 183 -420 244 -62 27 -83 31 -185 35 -93 3 -125 0 -165 -14z"
      />
      <path
        d="M8490 31299 c-458 -24 -953 -100 -1080 -164 -121 -61 -191 -227 -176
-413 8 -95 106 -686 152 -912 101 -499 242 -822 481 -1104 132 -155 226 -217
308 -202 54 10 126 86 154 161 48 129 42 167 -72 466 -206 537 -327 1006 -327
1270 0 66 -17 56 169 94 64 13 206 35 316 50 452 60 601 92 746 158 139 63
187 128 196 266 11 151 -32 233 -151 291 -54 27 -80 32 -182 40 -136 11 -324
10 -534 -1z"
      />
      <path
        d="M21130 31288 c-41 -16 -74 -40 -130 -97 -151 -153 -189 -294 -135
-505 98 -388 326 -729 647 -967 318 -236 777 -296 1093 -144 145 69 328 255
509 517 107 155 142 248 166 439 8 69 7 94 -5 135 -39 134 -191 193 -325 127
-78 -39 -149 -115 -271 -288 -114 -161 -175 -226 -244 -256 -99 -44 -193 -34
-343 37 -131 61 -222 124 -329 227 -165 160 -269 343 -333 593 -28 105 -48
137 -112 173 -63 37 -110 39 -188 9z"
      />
      <path
        d="M38409 31052 c-109 -36 -149 -76 -149 -147 0 -54 30 -95 128 -179
168 -142 264 -254 496 -578 70 -98 146 -194 169 -214 90 -77 336 -171 410
-157 95 18 157 84 157 165 0 39 -12 72 -61 171 -133 264 -299 497 -494 693
-203 204 -294 252 -495 260 -84 3 -116 1 -161 -14z"
      />
      <path
        d="M15575 30881 c-237 -65 -351 -112 -380 -158 -26 -39 -31 -112 -11
-151 47 -91 198 -142 497 -167 243 -20 474 -70 670 -146 99 -38 185 -113 220
-191 20 -45 24 -69 23 -158 -1 -179 -61 -318 -191 -439 -85 -81 -113 -123
-113 -174 0 -57 24 -99 108 -188 101 -108 133 -123 237 -116 207 15 364 74
454 173 73 79 128 177 166 296 43 134 59 254 52 393 -12 223 -67 355 -211 503
-296 307 -553 428 -1061 501 -156 22 -414 35 -460 22z"
      />
      <path
        d="M18315 30621 c-253 -65 -347 -105 -471 -199 -180 -136 -295 -306
-335 -497 -48 -227 34 -624 166 -805 62 -85 164 -140 296 -161 171 -26 273 61
255 217 -3 27 -19 97 -35 155 -40 142 -45 191 -27 275 24 117 91 230 198 334
24 23 111 96 193 163 175 141 261 221 281 259 22 42 18 110 -9 149 -53 79
-172 138 -292 145 -58 3 -96 -3 -220 -35z"
      />
      <path
        d="M29700 30636 c-219 -78 -237 -92 -830 -670 -411 -400 -456 -425 -885
-496 -236 -39 -322 -49 -599 -71 -271 -21 -408 -81 -506 -220 -104 -149 -104
-305 1 -410 71 -72 138 -80 494 -59 387 24 784 88 994 162 115 40 248 107 329
165 153 109 485 405 754 671 150 149 200 193 210 185 7 -5 90 -71 183 -145 94
-74 212 -164 264 -201 269 -193 523 -289 766 -291 126 -1 176 15 238 77 72 73
138 222 138 314 0 97 -44 144 -188 197 -246 92 -359 169 -868 590 -126 105
-246 199 -265 208 -49 25 -148 23 -230 -6z"
      />
      <path
        d="M10413 30630 c-320 -36 -589 -150 -893 -376 -134 -99 -462 -385 -492
-429 -38 -55 -68 -155 -68 -229 0 -90 18 -230 41 -321 90 -352 479 -1002 789
-1316 113 -114 174 -149 260 -149 114 0 224 107 272 264 49 161 12 244 -334
746 -47 69 -112 168 -143 220 -64 105 -195 360 -195 379 0 46 87 128 251 234
243 158 475 227 713 213 276 -16 375 -18 446 -7 146 23 245 92 302 210 26 55
33 81 36 154 4 82 3 91 -27 152 -35 71 -80 113 -163 149 -65 28 -221 70 -343
91 -108 18 -352 27 -452 15z"
      />
      <path
        d="M110 30569 c-36 -4 -75 -11 -87 -14 l-23 -5 0 -240 c0 -132 3 -240 8
-241 4 0 99 -4 212 -8 187 -6 209 -9 249 -30 68 -36 147 -121 183 -198 28 -58
32 -78 32 -148 0 -91 -17 -151 -74 -266 -70 -142 -152 -249 -368 -477 l-122
-130 67 -51 c159 -119 255 -120 501 -5 287 134 528 371 632 620 91 219 81 374
-37 594 -229 426 -689 661 -1173 599z"
      />
      <path
        d="M24142 30549 c-101 -50 -150 -103 -258 -279 -307 -500 -395 -623
-594 -835 -186 -198 -258 -318 -268 -446 -6 -84 9 -128 58 -173 48 -43 106
-60 190 -54 109 8 137 27 330 224 217 221 271 291 458 585 415 655 417 658
417 769 0 97 -30 160 -100 208 -41 28 -56 32 -110 32 -49 0 -74 -6 -123 -31z"
      />
      <path
        d="M20230 30563 c-14 -2 -74 -16 -135 -30 -426 -100 -804 -316 -1114
-638 -194 -203 -321 -392 -366 -547 -128 -444 160 -933 727 -1233 183 -96 324
-131 508 -123 95 3 122 15 164 72 28 37 29 135 2 191 -36 73 -141 164 -266
230 -143 75 -300 178 -372 244 -115 105 -128 214 -46 384 121 251 269 419 528
604 118 84 654 436 773 509 144 86 172 112 172 162 0 52 -19 86 -66 116 -64
43 -387 80 -509 59z"
      />
      <path
        d="M40802 30514 c-73 -18 -147 -40 -165 -47 -141 -60 -185 -237 -75
-305 28 -18 239 -67 361 -85 l37 -6 0 240 c0 185 -3 239 -12 238 -7 0 -73 -16
-146 -35z"
      />
      <path
        d="M12644 30374 c-80 -80 -105 -172 -79 -295 17 -84 66 -187 188 -400
l84 -146 -71 -30 c-39 -16 -143 -47 -231 -67 -456 -108 -893 -219 -999 -256
-128 -44 -228 -91 -318 -148 -50 -33 -60 -44 -80 -97 -30 -80 -37 -258 -14
-336 19 -66 35 -91 211 -321 193 -252 321 -375 476 -458 174 -92 373 53 387
282 9 149 -40 236 -223 395 -76 66 -119 110 -112 114 15 8 309 79 677 164 325
74 434 106 680 197 96 36 193 71 215 78 67 21 135 59 181 102 104 96 154 310
97 422 -23 47 -51 74 -128 123 -96 63 -184 150 -410 408 -106 121 -207 230
-225 243 -63 47 -132 72 -198 72 -61 0 -64 -1 -108 -46z"
      />
      <path
        d="M3720 30384 c-101 -17 -159 -43 -199 -90 -76 -86 -56 -190 57 -296
56 -52 118 -90 187 -113 34 -11 214 -31 550 -60 819 -71 810 -71 863 -56 108
28 180 98 189 185 6 54 -7 79 -87 163 -64 66 -147 108 -335 171 -244 82 -399
102 -825 107 -249 4 -333 1 -400 -11z"
      />
      <path
        d="M35111 30334 c-84 -32 -149 -83 -240 -188 -138 -159 -228 -313 -267
-456 -24 -89 -24 -271 0 -360 20 -71 34 -102 68 -143 23 -27 27 -28 217 -48
106 -11 195 -18 197 -16 9 8 227 329 311 454 108 165 125 200 148 313 34 168
8 279 -87 371 -102 101 -215 124 -347 73z"
      />
      <path
        d="M2716 30029 c-56 -13 -94 -39 -111 -75 -19 -40 -19 -78 -1 -174 47
-250 22 -422 -100 -679 -102 -215 -261 -468 -349 -554 -63 -63 -154 -110 -243
-128 -82 -16 -261 -6 -367 21 -106 26 -175 25 -257 -4 -120 -42 -164 -92 -155
-171 8 -77 117 -205 232 -274 241 -146 738 -185 1083 -85 263 76 423 217 626
553 157 258 282 586 306 801 16 138 -20 351 -77 465 -103 203 -387 351 -587
304z"
      />
      <path
        d="M15321 29850 c-184 -67 -383 -187 -510 -306 -261 -246 -388 -574
-311 -804 57 -169 172 -344 312 -475 219 -205 541 -320 838 -301 206 13 522
90 588 143 104 84 109 226 10 276 -91 46 -346 87 -603 96 -242 8 -259 13 -352
107 -131 131 -154 288 -69 485 66 153 163 283 382 516 68 72 124 135 124 140
-1 14 -93 83 -150 111 -73 36 -180 41 -259 12z"
      />
      <path
        d="M6415 29701 c-91 -24 -119 -36 -160 -67 -77 -59 -93 -159 -50 -305
30 -100 51 -213 60 -329 6 -84 2 -141 -70 -985 -27 -314 -32 -506 -14 -566 17
-54 62 -105 123 -137 49 -26 57 -27 202 -27 150 0 151 0 199 30 64 40 97 90
146 219 52 140 71 238 108 581 80 721 81 932 6 1211 -40 152 -164 318 -274
367 -64 29 -184 32 -276 8z"
      />
      <path
        d="M25574 29645 c-40 -21 -74 -83 -76 -137 -1 -73 94 -390 170 -566 130
-301 288 -462 520 -532 160 -48 265 9 266 146 0 41 -10 71 -56 165 -71 144
-114 256 -184 479 -30 96 -67 199 -82 229 -73 145 -197 208 -442 225 -66 5
-92 3 -116 -9z"
      />
      <path
        d="M40115 29429 c-322 -41 -484 -195 -577 -547 -19 -74 -23 -112 -23
-252 0 -142 3 -176 23 -245 45 -154 72 -196 226 -350 216 -215 389 -321 640
-390 124 -33 368 -79 489 -91 l67 -7 0 246 0 246 -57 11 c-209 41 -395 96
-488 142 -69 35 -120 84 -156 150 -33 61 -34 66 -33 173 0 93 4 122 28 190 38
110 85 183 164 255 153 140 150 215 -17 380 -101 100 -127 109 -286 89z"
      />
      <path
        d="M32192 29366 c-105 -34 -163 -113 -226 -307 -56 -174 -76 -211 -145
-279 -127 -124 -353 -190 -552 -160 -96 14 -280 67 -366 106 -84 38 -127 44
-190 28 -207 -54 -350 -227 -356 -429 -4 -152 61 -235 231 -298 252 -92 407
-122 594 -114 289 12 468 45 631 117 237 104 487 332 591 537 69 138 81 202
81 428 0 187 -1 203 -23 250 -12 27 -30 58 -40 68 -47 52 -155 77 -230 53z"
      />
      <path
        d="M20500 29241 c-153 -50 -249 -250 -195 -410 19 -59 103 -156 157
-182 732 -361 1078 -551 1078 -592 0 -18 -74 -102 -330 -372 -333 -350 -353
-372 -501 -551 -151 -181 -164 -209 -163 -344 1 -130 15 -175 73 -231 46 -44
54 -48 182 -79 l135 -33 109 85 c110 85 224 207 925 983 259 288 284 322 315
442 27 103 13 387 -22 443 -50 81 -139 133 -891 524 -418 218 -659 318 -777
322 -38 2 -81 0 -95 -5z"
      />
      <path
        d="M4145 29197 c-90 -23 -155 -67 -262 -175 -303 -306 -597 -720 -801
-1127 -177 -354 -175 -543 7 -634 123 -62 279 -67 395 -13 98 46 157 111 260
287 190 322 411 679 493 793 44 61 133 142 157 142 7 0 34 -14 62 -31 224
-143 583 -366 694 -431 153 -89 325 -152 415 -151 62 0 129 29 184 78 47 43
64 89 50 139 -15 54 -106 185 -178 256 -97 96 -1152 814 -1248 850 -68 26
-165 33 -228 17z"
      />
      <path
        d="M35757 29135 c-56 -21 -119 -70 -161 -125 -20 -26 -81 -142 -135
-257 -155 -329 -221 -435 -352 -564 -178 -175 -360 -256 -674 -299 -202 -28
-242 -40 -304 -96 -97 -87 -135 -217 -97 -332 44 -132 259 -233 496 -234 160
0 384 54 568 138 350 159 629 500 840 1024 115 288 127 352 94 512 -26 126
-61 201 -104 228 -39 24 -114 26 -171 5z"
      />
      <path
        d="M25062 28966 c-183 -68 -338 -242 -393 -439 -46 -167 -26 -332 58
-477 51 -88 307 -343 433 -433 117 -83 360 -205 500 -251 235 -77 495 -111
787 -104 174 4 180 5 245 35 127 60 190 151 196 287 5 94 -8 144 -50 192 -61
70 -120 83 -418 94 -172 6 -231 12 -295 29 -262 72 -649 310 -787 484 l-38 49
0 229 c0 247 -6 281 -51 313 -33 23 -112 19 -187 -8z"
      />
      <path
        d="M29426 28699 c-59 -14 -143 -63 -223 -128 -129 -105 -250 -178 -365
-220 -119 -44 -133 -45 -458 -46 l-315 0 -60 -29 c-127 -63 -199 -162 -199
-276 0 -116 58 -201 179 -262 71 -36 151 -51 390 -72 175 -16 183 -16 295 4
438 79 655 173 845 364 131 132 201 297 193 455 -4 96 -28 146 -90 184 -51 32
-128 42 -192 26z"
      />
      <path
        d="M36866 28504 c-179 -28 -366 -90 -526 -176 -117 -62 -150 -103 -150
-187 0 -46 17 -66 78 -93 65 -29 156 -32 402 -13 248 19 344 16 447 -16 192
-59 340 -182 432 -359 56 -107 70 -167 85 -352 10 -119 19 -176 33 -208 40
-91 132 -140 263 -140 114 0 184 36 248 128 94 135 133 247 139 402 6 142 -16
246 -83 389 -56 120 -113 198 -210 291 -183 174 -436 295 -704 335 -117 18
-335 18 -454 -1z"
      />
      <path
        d="M38475 28439 c-134 -18 -202 -76 -191 -164 3 -23 49 -132 105 -245
55 -113 150 -322 211 -465 137 -317 167 -376 231 -448 122 -137 372 -242 496
-208 105 29 148 143 109 291 -14 53 -252 617 -340 805 -89 189 -222 352 -337
411 -62 31 -161 40 -284 23z"
      />
      <path
        d="M17854 28292 c-157 -55 -254 -174 -289 -355 -27 -141 -54 -352 -71
-550 -15 -182 -15 -203 0 -269 31 -137 93 -205 232 -258 l85 -32 53 26 c143
70 191 151 230 391 14 88 42 252 62 365 70 396 44 625 -79 684 -46 21 -157 21
-223 -2z"
      />
      <path
        d="M16561 28269 c-50 -26 -93 -81 -116 -149 -21 -62 -69 -1310 -53
-1383 5 -25 24 -69 41 -98 44 -75 116 -113 200 -106 70 6 130 40 179 99 95
117 164 245 187 343 27 116 82 541 88 670 5 123 3 154 -17 245 -28 136 -60
202 -131 273 -112 112 -275 158 -378 106z"
      />
      <path
        d="M8151 28204 c-99 -89 -124 -177 -107 -386 13 -173 -3 -254 -78 -400
-70 -135 -85 -205 -68 -321 17 -117 29 -153 64 -193 44 -49 81 -66 150 -66 79
0 167 43 254 124 255 239 335 560 239 956 -38 156 -78 222 -159 264 -32 17
-211 68 -236 68 -4 0 -31 -21 -59 -46z"
      />
      <path
        d="M0 27797 l0 -244 31 -7 c17 -3 78 -6 135 -6 92 0 117 4 235 41 222
68 277 105 287 194 15 140 -142 219 -485 245 -76 5 -153 13 -170 16 l-33 5 0
-244z"
      />
      <path
        d="M13460 28019 c-373 -46 -624 -175 -839 -433 -216 -258 -297 -469
-308 -798 -7 -178 3 -245 49 -353 34 -82 58 -111 113 -136 85 -39 174 -19 228
53 33 44 45 74 123 328 36 118 80 242 97 275 65 121 191 218 355 273 67 22 95
26 207 26 121 0 137 -2 223 -32 128 -44 210 -100 316 -215 69 -75 98 -99 127
-107 114 -31 252 89 321 277 16 44 21 82 21 158 1 115 -19 185 -84 296 -104
179 -299 313 -538 368 -109 26 -295 35 -411 20z"
      />
      <path
        d="M23725 27756 c-207 -48 -408 -170 -594 -361 -242 -248 -351 -522
-352 -875 0 -377 129 -755 333 -974 85 -93 157 -131 245 -131 52 0 68 5 105
30 25 17 53 49 66 75 20 39 23 55 18 115 -3 39 -22 129 -41 200 -88 324 -106
410 -112 520 -15 294 100 556 285 647 62 31 73 33 167 32 81 0 116 -6 184 -28
96 -32 305 -73 420 -82 252 -21 387 183 266 403 -94 172 -222 295 -355 342
-30 11 -125 36 -210 56 -180 43 -326 54 -425 31z"
      />
      <path
        d="M33015 27615 c-248 -51 -494 -90 -1090 -175 -995 -141 -964 -136
-1021 -156 -161 -54 -294 -226 -302 -391 -4 -64 -1 -80 26 -135 17 -37 61 -98
106 -148 154 -170 206 -279 336 -707 230 -755 341 -1037 438 -1110 56 -42 141
-67 199 -59 96 13 204 89 243 172 25 54 44 216 35 298 -14 131 -211 877 -336
1274 -39 123 -69 225 -67 227 15 15 220 53 443 80 148 19 358 50 465 70 379
69 706 137 766 159 80 29 192 104 248 167 68 76 90 141 91 270 0 98 -2 108
-27 146 -14 22 -43 48 -64 58 -75 35 -144 30 -489 -40z"
      />
      <path
        d="M15104 27626 c-110 -25 -195 -81 -231 -151 -38 -72 -8 -189 71 -281
40 -47 322 -233 471 -310 121 -63 187 -78 332 -72 119 4 183 25 236 76 40 38
77 114 84 173 6 47 4 52 -28 85 -51 54 -174 151 -264 209 -143 92 -393 236
-443 255 -68 26 -155 32 -228 16z"
      />
      <path
        d="M4435 27424 c-77 -11 -157 -38 -184 -63 -71 -66 -65 -159 23 -392
129 -340 174 -631 154 -977 -6 -107 -6 -169 2 -207 24 -115 114 -185 238 -185
161 0 347 128 419 289 72 161 79 445 18 688 -28 109 -125 391 -166 481 -114
252 -307 393 -504 366z"
      />
      <path
        d="M10448 27400 c-145 -23 -213 -110 -225 -285 -5 -59 -1 -80 17 -123
35 -80 90 -121 320 -236 326 -165 438 -209 700 -277 126 -33 165 -39 209 -35
114 13 199 75 255 187 108 215 55 432 -129 531 -28 14 -130 47 -227 72 -97 25
-228 66 -291 90 -63 25 -148 53 -188 62 -87 19 -357 27 -441 14z"
      />
      <path
        d="M36085 27377 c-65 -22 -99 -43 -138 -86 -98 -109 -99 -186 -6 -481
83 -262 219 -440 373 -485 58 -18 184 -20 256 -4 144 31 245 183 206 311 -7
24 -38 77 -70 118 -64 85 -129 208 -206 388 -44 103 -60 129 -101 166 -75 67
-225 102 -314 73z"
      />
      <path
        d="M18775 27373 c-55 -8 -299 -72 -360 -93 -119 -41 -175 -102 -175
-192 0 -88 91 -194 213 -248 61 -27 167 -39 452 -50 306 -11 393 -30 556 -119
78 -43 128 -59 220 -71 187 -23 293 59 259 200 -15 62 -96 186 -158 240 -84
75 -497 264 -680 311 -76 19 -266 33 -327 22z"
      />
      <path
        d="M1557 27259 c-122 -29 -188 -109 -171 -207 10 -57 71 -216 122 -317
67 -134 135 -228 246 -344 198 -203 314 -271 571 -331 425 -100 808 -46 1122
157 90 59 173 126 226 182 48 51 107 159 107 195 0 29 -64 136 -105 178 -58
58 -107 72 -223 65 -115 -6 -216 -37 -481 -147 -276 -114 -405 -133 -544 -80
-68 26 -166 112 -219 193 -20 28 -65 97 -101 152 -71 109 -162 209 -229 252
-88 55 -217 76 -321 52z"
      />
      <path
        d="M29880 27156 c-86 -30 -143 -67 -212 -139 -128 -132 -170 -216 -344
-678 -149 -397 -174 -480 -174 -581 0 -95 16 -150 58 -199 51 -59 99 -79 198
-79 152 0 225 48 341 223 118 180 152 257 330 757 120 339 143 419 143 505 0
179 -143 260 -340 191z"
      />
      <path
        d="M8960 27007 c-26 -13 -57 -43 -77 -71 -91 -132 -65 -268 81 -424 137
-148 461 -523 479 -555 43 -79 0 -195 -118 -313 -114 -114 -214 -154 -575
-232 -134 -29 -176 -35 -178 -25 -2 8 -13 11 -25 8 -12 -2 -78 -9 -147 -15
-262 -23 -321 -49 -380 -164 -31 -62 -35 -77 -35 -150 0 -131 38 -181 200
-263 83 -43 254 -75 491 -94 331 -27 611 38 851 197 126 83 282 242 376 384
175 265 222 488 163 770 -96 454 -316 729 -705 881 -116 45 -276 89 -326 89
-17 0 -51 -10 -75 -23z"
      />
      <path
        d="M40276 26939 c-177 -19 -368 -92 -469 -180 -108 -93 -402 -533 -469
-700 -124 -310 -41 -621 196 -738 106 -52 190 -57 238 -15 32 29 84 148 113
262 61 241 173 434 386 665 160 172 268 231 454 247 56 5 132 16 168 26 l67
16 0 133 0 133 -92 45 c-188 91 -394 128 -592 106z"
      />
      <path
        d="M0 26654 l0 -126 37 7 c21 3 42 10 46 15 13 12 11 124 -3 151 -16 31
-41 59 -62 70 -17 9 -18 1 -18 -117z"
      />
      <path
        d="M5669 26690 c-62 -11 -138 -45 -171 -77 -63 -60 -57 -147 14 -228 73
-83 422 -267 543 -285 78 -13 188 -5 250 17 60 21 113 78 131 141 l16 54 -28
29 c-50 52 -194 144 -371 237 -95 50 -187 95 -205 101 -50 16 -127 21 -179 11z"
      />
      <path
        d="M27955 26538 c-44 -5 -345 -11 -670 -12 -505 -2 -601 -5 -669 -19
-160 -34 -272 -110 -337 -230 -29 -53 -34 -72 -34 -132 0 -119 59 -193 193
-242 59 -22 92 -26 272 -34 341 -15 1035 -27 1040 -19 3 4 39 11 80 15 343 32
519 80 618 169 72 66 120 218 101 321 -19 103 -92 165 -219 185 -77 12 -263
11 -375 -2z"
      />
      <path
        d="M34585 26488 c-53 -19 -129 -74 -173 -123 -62 -71 -120 -194 -137
-290 -50 -294 -59 -912 -16 -1160 24 -141 90 -406 120 -485 43 -114 135 -201
247 -237 49 -15 57 -15 87 -1 146 70 191 229 153 540 -41 327 -45 727 -12
1189 14 201 8 395 -14 454 -39 103 -148 151 -255 113z"
      />
      <path
        d="M754 26455 c-50 -30 -74 -76 -74 -143 0 -41 12 -74 64 -183 75 -155
119 -271 177 -465 53 -177 86 -251 140 -309 79 -86 159 -117 345 -135 131 -13
168 -4 202 52 40 64 38 100 -12 274 -102 358 -218 602 -353 738 -71 73 -179
139 -274 170 -106 33 -161 34 -215 1z"
      />
      <path
        d="M19035 26365 c-64 -31 -76 -42 -164 -150 -176 -214 -234 -266 -349
-304 -178 -60 -477 7 -753 170 -36 22 -91 45 -122 51 -78 17 -144 -5 -246 -82
-153 -116 -201 -193 -201 -325 0 -67 5 -90 28 -140 38 -80 87 -121 217 -185
247 -120 442 -170 689 -178 310 -9 485 38 706 189 281 192 459 487 463 764 1
92 -16 146 -61 198 -24 28 -143 24 -207 -8z"
      />
      <path
        d="M15989 26305 c-515 -110 -816 -392 -958 -899 -57 -203 -76 -464 -50
-681 36 -295 155 -576 305 -715 173 -161 333 -205 438 -118 73 60 146 255 132
353 -7 46 -118 280 -166 350 -74 108 -101 198 -100 340 0 118 34 284 75 365
32 64 110 148 174 189 70 45 212 96 349 125 165 34 229 70 306 170 105 138 98
395 -14 489 -44 38 -89 47 -240 53 -117 3 -150 1 -251 -21z"
      />
      <path
        d="M13748 26256 l-108 -21 -39 -52 c-70 -93 -90 -187 -66 -304 23 -116
70 -250 210 -599 142 -355 171 -432 220 -595 44 -147 72 -197 129 -230 56 -33
141 -34 222 -4 97 37 200 160 250 301 24 68 33 170 23 255 -17 139 -123 415
-275 713 -175 345 -252 458 -363 529 -55 36 -60 36 -203 7z"
      />
      <path
        d="M38458 26260 c-40 -12 -98 -50 -98 -63 0 -7 7 -37 16 -67 22 -80 29
-269 15 -385 -13 -100 -49 -260 -96 -420 -45 -157 -119 -251 -219 -280 -28 -8
-132 -26 -230 -40 -99 -14 -187 -30 -196 -35 -23 -12 -64 -164 -51 -188 16
-31 87 -90 139 -116 109 -55 148 -61 402 -59 262 1 327 11 432 64 169 86 299
291 359 570 33 149 33 423 1 552 -26 103 -73 210 -130 294 -68 100 -138 150
-240 172 -60 12 -66 13 -104 1z"
      />
      <path
        d="M7615 26199 c-296 -40 -544 -170 -703 -368 -61 -76 -160 -270 -196
-384 -59 -184 -86 -431 -67 -612 41 -391 152 -705 359 -1015 148 -222 378
-494 472 -557 138 -94 250 -56 338 115 76 146 79 258 9 392 -19 36 -121 205
-227 375 -106 171 -210 344 -231 385 -79 156 -111 298 -112 495 -1 271 43 360
203 416 57 20 87 23 252 25 143 1 198 6 237 19 74 24 143 91 188 182 32 65 37
86 41 162 10 205 -58 315 -222 358 -67 18 -252 24 -341 12z"
      />
      <path
        d="M24102 26149 c-108 -21 -220 -118 -248 -217 -36 -124 15 -238 144
-320 100 -64 966 -366 1407 -491 232 -65 321 -88 530 -135 217 -49 328 -89
321 -115 -10 -34 -149 -743 -202 -1031 -49 -267 -57 -322 -58 -440 -1 -158 13
-209 72 -261 62 -54 116 -73 212 -73 98 -1 168 25 237 87 76 69 89 108 149
440 106 594 177 1048 225 1440 17 144 9 251 -25 321 -41 84 -75 100 -266 126
-508 70 -783 144 -1970 535 -223 73 -423 135 -445 138 -22 2 -59 1 -83 -4z"
      />
      <path
        d="M21395 26081 c-134 -48 -173 -81 -540 -452 -416 -421 -799 -872 -883
-1038 -34 -68 -37 -82 -37 -155 1 -168 59 -230 410 -441 524 -313 734 -475
943 -727 96 -116 148 -143 277 -143 76 0 99 4 145 26 108 51 170 140 215 309
28 108 31 159 11 199 -26 50 -425 424 -626 587 -48 38 -174 132 -282 209
l-197 140 83 85 c89 91 521 555 648 696 121 134 181 279 195 476 9 136 -26
203 -126 237 -52 17 -178 13 -236 -8z"
      />
      <path
        d="M35660 26065 c-157 -22 -256 -68 -338 -161 -173 -194 -154 -380 43
-424 28 -7 117 -11 199 -11 276 2 434 -22 675 -105 152 -52 245 -64 339 -44
58 12 60 13 95 74 51 87 67 138 67 215 0 119 -64 212 -207 305 -121 78 -266
129 -440 155 -95 14 -314 12 -433 -4z"
      />
      <path
        d="M33058 25910 c-92 -49 -167 -172 -176 -288 -6 -86 8 -136 94 -333 86
-199 97 -250 91 -414 -10 -259 -90 -418 -230 -456 -65 -17 -181 -11 -321 17
-220 44 -312 21 -408 -104 -66 -87 -92 -158 -92 -257 -1 -72 3 -88 33 -149 33
-67 35 -69 110 -103 114 -53 296 -110 384 -122 213 -29 411 0 587 86 82 41
108 60 195 147 82 83 111 121 161 211 99 178 166 368 195 560 18 115 15 379
-5 500 -23 141 -70 314 -102 377 -65 131 -196 272 -297 323 -65 32 -163 34
-219 5z"
      />
      <path
        d="M11325 25899 c-209 -28 -314 -88 -377 -217 -32 -64 -33 -71 -33 -192
0 -134 15 -202 57 -256 36 -46 76 -55 282 -65 279 -13 486 -27 795 -55 385
-34 530 -30 654 16 151 56 286 275 275 443 -4 61 -29 127 -66 174 -18 24 -108
38 -832 124 -277 33 -623 46 -755 28z"
      />
      <path
        d="M22267 25630 c-65 -17 -120 -51 -183 -115 -128 -131 -151 -228 -140
-605 10 -326 24 -393 102 -479 46 -51 97 -79 178 -96 60 -12 62 -12 119 18
106 56 169 151 186 288 27 199 43 473 39 649 -3 164 -6 190 -25 225 -26 50
-58 80 -104 99 -48 20 -126 27 -172 16z"
      />
      <path
        d="M4972 25402 c-170 -61 -258 -189 -251 -364 8 -184 77 -230 429 -285
87 -14 172 -18 350 -18 252 0 346 13 485 65 79 30 155 106 198 197 28 60 32
78 31 148 -1 44 -8 98 -16 120 -14 39 -18 41 -110 73 -123 41 -189 49 -558 62
-168 6 -350 13 -405 16 -82 4 -109 1 -153 -14z"
      />
      <path
        d="M40205 25381 c-128 -21 -181 -118 -136 -245 42 -121 74 -160 393
-474 192 -190 336 -341 383 -403 l76 -99 -49 -93 c-58 -111 -265 -388 -651
-872 -141 -176 -202 -282 -225 -392 l-7 -31 81 -31 c161 -62 296 -63 424 -3
80 38 291 207 389 311 l77 83 0 917 0 917 -172 170 c-151 148 -182 174 -243
201 -103 47 -226 63 -340 44z"
      />
      <path
        d="M30188 25336 c-170 -70 -330 -263 -387 -466 -49 -174 -141 -435 -153
-434 -19 1 -1132 372 -1183 394 -23 10 -45 22 -48 27 -3 4 -12 53 -22 108 -9
55 -24 116 -33 135 -37 76 -130 102 -215 59 -201 -103 -401 -310 -510 -529
-42 -84 -50 -186 -19 -246 26 -51 75 -99 128 -124 23 -11 153 -43 290 -70 406
-80 614 -142 989 -293 426 -171 473 -186 683 -214 100 -13 135 -14 185 -5 135
25 261 121 322 246 32 63 40 100 81 346 51 315 96 512 149 655 30 81 38 118
42 195 6 104 -8 149 -59 197 -53 49 -148 57 -240 19z"
      />
      <path
        d="M2050 25116 c-136 -49 -181 -68 -316 -135 -284 -141 -528 -339 -601
-490 -25 -50 -28 -67 -28 -156 0 -93 2 -105 32 -166 35 -70 109 -149 141 -149
34 0 171 39 241 68 38 16 136 70 218 120 81 50 234 138 338 197 221 124 244
140 285 200 77 111 91 256 36 363 -34 67 -101 141 -143 158 -48 21 -130 17
-203 -10z"
      />
      <path
        d="M16970 25000 c-117 -43 -204 -125 -230 -220 -23 -81 -9 -227 30 -333
22 -58 124 -221 208 -332 126 -166 570 -630 757 -793 234 -203 312 -248 409
-240 103 9 223 118 266 242 11 32 34 83 52 114 43 77 249 281 483 478 105 89
211 185 236 215 119 139 172 263 173 403 1 113 -17 169 -75 229 l-48 49 -58
-6 c-206 -25 -407 -128 -665 -343 -99 -82 -272 -251 -366 -357 l-61 -68 -57
48 c-50 43 -322 330 -684 724 -136 148 -166 175 -214 195 -47 20 -89 18 -156
-5z"
      />
      <path
        d="M23347 24970 c-103 -26 -227 -132 -282 -243 -29 -59 -31 -103 -9
-192 l16 -65 -69 -93 c-90 -120 -115 -163 -152 -270 -56 -160 -75 -273 -75
-452 0 -230 26 -400 150 -985 36 -168 74 -352 85 -410 16 -81 31 -125 67 -194
54 -103 85 -132 148 -142 102 -15 206 22 260 93 65 85 103 304 85 491 -16 169
-85 604 -166 1047 -16 92 -19 288 -5 390 8 58 59 305 64 312 3 4 241 -37 721
-126 815 -150 975 -166 1097 -107 31 15 71 43 89 63 76 87 131 276 109 377 -6
28 -22 65 -37 84 -50 66 -201 114 -433 137 -195 21 -467 66 -745 125 -815 174
-843 178 -918 160z"
      />
      <path
        d="M0 24052 c0 -501 2 -912 4 -912 6 0 240 260 349 389 54 63 123 156
154 205 31 50 78 125 104 166 64 100 79 139 79 205 0 77 -23 119 -148 276 -91
114 -177 207 -503 544 l-39 40 0 -913z"
      />
      <path
        d="M36204 24801 c-64 -40 -123 -145 -124 -219 0 -33 15 -73 70 -180 38
-75 92 -176 120 -223 85 -144 80 -217 -24 -374 -87 -130 -240 -278 -421 -406
-207 -147 -254 -203 -270 -324 -10 -76 19 -235 48 -257 69 -53 219 -80 329
-58 184 36 491 248 654 451 232 291 337 595 305 883 -35 309 -165 512 -431
670 -75 44 -90 50 -154 53 -58 4 -77 1 -102 -16z"
      />
      <path
        d="M9646 24640 c-172 -23 -224 -35 -303 -70 -117 -53 -186 -150 -218
-307 -28 -138 8 -205 130 -242 87 -27 184 -38 490 -56 400 -23 630 -47 840
-87 94 -18 132 -20 260 -15 83 4 179 13 214 22 128 31 225 106 300 233 68 113
65 189 -9 256 -70 63 -183 100 -480 160 -615 123 -903 148 -1224 106z"
      />
      <path
        d="M39715 24557 c-85 -62 -181 -144 -245 -208 -119 -120 -167 -154 -330
-229 -384 -176 -641 -259 -875 -280 -270 -26 -312 -37 -342 -94 -36 -68 -9
-202 55 -270 33 -35 120 -95 187 -128 146 -74 271 -84 521 -43 540 88 1007
298 1253 561 169 181 269 423 231 559 -14 51 -80 131 -135 165 -38 23 -153 70
-169 69 -6 0 -74 -46 -151 -102z"
      />
      <path
        d="M13070 24565 c-173 -27 -354 -101 -507 -207 -80 -56 -298 -251 -375
-337 -98 -109 -132 -219 -143 -466 l-7 -150 38 -31 c67 -53 102 -67 159 -62
79 7 159 52 244 137 99 100 253 235 322 281 146 100 341 142 501 109 91 -19
192 -74 269 -146 115 -108 201 -253 325 -546 82 -196 144 -260 265 -274 94
-11 186 45 253 156 70 115 100 231 86 331 -12 84 -250 666 -316 772 -33 51
-121 136 -212 201 -272 196 -598 279 -902 232z"
      />
      <path
        d="M3020 24286 c-184 -39 -310 -132 -361 -269 -20 -53 -24 -80 -24 -182
0 -111 2 -124 27 -171 69 -130 199 -169 348 -104 99 43 157 53 279 46 538 -30
691 -13 803 92 53 50 92 121 108 199 30 141 -67 242 -272 283 -220 44 -642
109 -738 114 -70 4 -127 1 -170 -8z"
      />
      <path
        d="M4432 23694 c-96 -48 -264 -264 -338 -434 -64 -148 -87 -255 -87
-420 -1 -444 194 -774 523 -884 157 -52 234 -43 306 36 83 93 109 211 64 298
-31 60 -125 202 -221 331 -94 127 -109 159 -109 241 0 71 15 108 119 296 108
195 133 313 90 426 -25 69 -54 90 -155 116 -101 26 -130 25 -192 -6z"
      />
      <path
        d="M34537 23699 c-117 -12 -313 -45 -412 -70 -164 -42 -264 -123 -335
-271 -72 -149 -77 -245 -17 -301 38 -35 129 -82 179 -91 21 -4 76 0 126 9 193
33 475 66 482 55 4 -7 51 -6 151 5 206 22 251 31 374 73 145 49 188 88 228
209 62 190 16 316 -136 368 -43 15 -93 18 -302 21 -137 1 -290 -2 -338 -7z"
      />
      <path
        d="M37273 23675 c-81 -18 -186 -68 -206 -99 -33 -49 -57 -144 -57 -220
0 -87 66 -484 145 -871 57 -278 203 -442 421 -475 82 -13 178 -1 226 27 17 10
39 34 50 53 38 69 21 189 -126 903 -103 496 -119 546 -189 615 -71 70 -156 91
-264 67z"
      />
      <path
        d="M27467 23629 c-225 -53 -335 -302 -215 -484 59 -88 173 -142 424
-199 308 -70 546 -209 814 -476 196 -195 250 -337 176 -463 -45 -78 -171 -186
-301 -259 -170 -94 -248 -132 -340 -163 -136 -47 -217 -111 -269 -213 -53
-104 -69 -211 -42 -275 47 -113 223 -185 382 -158 128 22 502 185 645 282 188
126 330 282 422 465 71 140 126 361 142 564 6 81 4 90 -35 209 -48 144 -72
194 -148 311 -158 245 -384 449 -643 584 -166 87 -346 152 -689 250 -130 38
-235 46 -323 25z"
      />
      <path
        d="M8976 23605 c-99 -41 -145 -99 -165 -211 -13 -72 -3 -137 35 -227
143 -340 198 -568 211 -872 3 -92 1 -154 -6 -182 -19 -70 -68 -123 -151 -166
-114 -57 -192 -70 -408 -69 -383 4 -518 -46 -567 -212 -51 -172 18 -290 229
-394 158 -78 311 -109 483 -99 543 34 960 391 1047 896 44 258 -2 677 -110
1013 -99 306 -237 465 -463 532 -68 20 -64 20 -135 -9z"
      />
      <path
        d="M24273 23545 c-177 -48 -278 -156 -323 -346 -24 -103 -53 -323 -76
-571 -18 -205 -13 -271 28 -353 48 -95 92 -131 220 -179 l66 -24 62 29 c80 37
152 112 179 184 12 31 32 125 46 209 13 85 38 229 55 320 40 224 51 348 40
476 -10 123 -29 178 -76 219 -57 50 -126 61 -221 36z"
      />
      <path
        d="M31295 23485 c-93 -14 -305 -43 -470 -66 -368 -50 -423 -67 -519
-163 -68 -66 -137 -175 -156 -244 -23 -87 25 -212 93 -245 109 -51 269 -70
527 -62 379 12 573 46 741 131 154 77 237 310 183 516 -19 76 -34 99 -82 126
-58 32 -128 34 -317 7z"
      />
      <path
        d="M10651 23429 c-138 -92 -205 -218 -250 -469 -34 -184 -44 -368 -38
-667 4 -211 10 -305 25 -389 22 -123 37 -160 82 -198 165 -138 416 78 486 419
10 47 18 190 24 435 6 244 14 405 26 485 23 165 21 198 -16 275 l-32 65 -112
43 c-62 23 -117 42 -123 42 -6 0 -38 -18 -72 -41z"
      />
      <path
        d="M16010 23440 c-25 -5 -79 -9 -121 -9 -125 -1 -251 -40 -476 -146
-129 -61 -160 -94 -195 -208 -40 -133 -15 -191 108 -249 150 -70 163 -72 569
-64 411 7 403 8 695 -81 384 -117 468 -138 570 -138 113 0 154 17 219 91 54
61 75 132 69 229 -11 174 -114 256 -523 418 -306 121 -464 157 -700 162 -93 2
-190 0 -215 -5z"
      />
      <path
        d="M5438 23328 c-172 -23 -258 -122 -258 -299 0 -204 81 -299 294 -349
219 -52 489 -68 854 -50 263 12 325 22 420 65 105 48 212 194 243 329 34 153
-56 272 -222 296 -81 11 -1254 19 -1331 8z"
      />
      <path
        d="M19805 23291 c-190 -62 -298 -106 -480 -196 -171 -85 -416 -223 -499
-282 -55 -39 -109 -111 -136 -181 -30 -79 -45 -224 -31 -294 32 -151 177 -234
320 -183 23 8 189 91 369 184 333 173 512 259 682 325 138 55 193 100 241 201
16 33 34 95 40 137 24 149 -12 222 -141 293 -48 26 -66 30 -150 32 -87 2 -105
-1 -215 -36z"
      />
      <path
        d="M2737 23059 c-72 -11 -182 -68 -272 -139 -44 -35 -207 -191 -362
-348 l-281 -284 -26 39 c-34 50 -76 160 -116 307 -67 242 -187 342 -427 354
-90 4 -106 2 -147 -18 -114 -55 -124 -170 -39 -435 114 -354 462 -1110 553
-1204 88 -90 199 -137 349 -147 116 -8 163 5 222 65 39 39 51 63 83 156 103
302 289 587 596 910 228 240 280 313 316 443 l18 63 -55 56 c-142 146 -274
205 -412 182z"
      />
      <path
        d="M12932 22965 c-72 -20 -306 -117 -416 -172 -455 -228 -681 -562 -736
-1089 l-11 -112 36 -116 c148 -475 550 -857 1100 -1046 194 -67 486 -150 546
-157 73 -7 180 11 246 43 66 32 136 109 162 180 58 154 -8 306 -164 383 -41
20 -143 52 -261 81 -348 87 -547 199 -816 459 -171 165 -249 301 -233 407 8
58 71 150 145 214 101 87 333 216 518 286 62 23 134 58 160 76 60 43 126 140
152 225 48 157 8 254 -135 319 -74 34 -208 43 -293 19z"
      />
      <path
        d="M25334 22906 c-232 -73 -391 -299 -370 -528 13 -141 68 -240 208
-374 256 -244 564 -375 951 -405 105 -8 147 -7 216 5 269 47 478 149 668 329
150 142 224 258 263 413 38 148 21 258 -49 317 -70 59 -154 56 -283 -8 -99
-50 -147 -85 -274 -200 -128 -116 -206 -160 -307 -174 -104 -15 -235 10 -401
76 -229 91 -333 192 -402 391 -36 102 -60 137 -111 158 -39 17 -55 17 -109 0z"
      />
      <path
        d="M32511 22884 c-407 -71 -728 -329 -861 -692 -59 -161 -78 -340 -61
-571 24 -312 101 -633 201 -831 85 -168 218 -283 387 -333 57 -17 95 -11 164
23 129 66 175 244 104 410 -152 359 -215 628 -219 925 -1 123 0 133 27 187 41
83 114 132 255 170 65 17 112 21 307 23 321 4 424 27 494 112 53 65 80 182 61
264 -28 117 -204 241 -427 300 -103 28 -313 34 -432 13z"
      />
      <path
        d="M20927 22876 c-55 -20 -112 -62 -140 -104 -28 -44 -46 -91 -88 -237
-82 -288 -174 -393 -401 -459 -162 -47 -312 -56 -510 -30 -162 21 -206 12
-281 -56 -95 -86 -186 -291 -174 -392 14 -113 93 -226 189 -268 107 -47 444
-49 665 -5 170 35 386 123 523 214 241 160 435 490 471 802 25 223 -40 450
-150 525 -41 27 -51 28 -104 10z"
      />
      <path
        d="M38853 22775 c-64 -32 -94 -76 -139 -208 -102 -296 -289 -581 -591
-899 -246 -258 -294 -329 -327 -480 -5 -23 3 -36 50 -87 67 -72 169 -143 242
-166 86 -28 193 -18 287 29 116 57 206 136 513 445 230 233 284 283 293 271
35 -49 94 -195 120 -297 37 -142 75 -217 141 -279 86 -81 218 -122 359 -112
158 12 207 105 163 314 -39 192 -204 596 -439 1080 -90 184 -111 219 -162 271
-93 95 -220 142 -384 143 -61 0 -85 -5 -126 -25z"
      />
      <path
        d="M7870 22690 c-62 -15 -218 -78 -344 -139 -162 -79 -343 -138 -794
-260 -418 -113 -473 -135 -580 -235 -98 -92 -123 -163 -86 -241 29 -63 143
-128 253 -146 70 -11 312 2 478 26 214 31 491 113 637 188 48 25 76 32 153 38
80 6 106 12 166 43 91 45 192 121 283 211 118 117 163 242 134 379 -15 73 -42
99 -128 126 -67 21 -114 24 -172 10z"
      />
      <path
        d="M14706 22305 c-264 -50 -484 -172 -682 -376 -147 -153 -219 -300
-231 -475 -5 -73 -3 -87 20 -137 20 -43 35 -61 71 -82 121 -71 291 4 563 249
184 165 311 190 566 112 301 -92 455 -224 521 -444 33 -114 85 -159 172 -150
73 7 178 63 255 135 209 198 212 499 6 719 -207 222 -515 385 -827 438 -141
24 -338 29 -434 11z"
      />
      <path
        d="M30402 22224 c-103 -51 -212 -203 -256 -355 -31 -106 -37 -175 -46
-539 -6 -218 -16 -409 -25 -475 -22 -163 -20 -193 18 -266 l32 -62 115 -43
114 -43 50 27 c204 110 290 350 317 885 18 355 -17 729 -76 818 -51 78 -150
99 -243 53z"
      />
      <path
        d="M34505 22244 c-312 -24 -635 -103 -843 -208 -53 -27 -84 -36 -121
-36 -159 0 -313 -81 -492 -260 -53 -53 -86 -95 -110 -144 -30 -62 -33 -78 -33
-155 0 -114 19 -155 89 -190 38 -19 70 -26 130 -29 74 -4 89 -1 195 38 63 24
162 66 220 95 185 93 335 141 855 280 324 86 362 99 433 145 222 143 264 286
115 394 -73 53 -131 68 -268 71 -66 1 -142 1 -170 -1z"
      />
      <path
        d="M17720 21981 c-80 -25 -139 -81 -164 -158 -57 -173 -62 -338 -21
-618 31 -212 107 -666 136 -815 37 -188 27 -382 -32 -638 -18 -82 -23 -92 -43
-92 -24 0 -190 29 -811 143 -731 134 -869 146 -994 83 -84 -43 -143 -128 -175
-253 -18 -70 -21 -179 -5 -220 36 -95 162 -149 414 -178 312 -36 556 -79 970
-169 496 -107 608 -128 685 -124 102 4 172 40 252 128 105 116 125 185 93 312
l-16 59 71 97 c98 131 114 161 154 282 60 179 71 254 70 450 -1 236 -30 426
-158 1020 -30 140 -64 305 -76 365 -23 112 -36 146 -96 246 -42 70 -78 89
-166 88 -34 0 -74 -4 -88 -8z"
      />
      <path
        d="M36315 21977 c-42 -20 -103 -90 -132 -154 -21 -45 -24 -66 -21 -117
3 -57 10 -72 72 -170 37 -58 110 -164 162 -234 156 -210 157 -247 20 -502 -46
-85 -94 -182 -106 -215 -48 -123 -23 -295 48 -337 40 -24 142 -48 201 -48 74
0 115 21 186 95 248 260 366 589 325 911 -39 311 -161 546 -348 670 -127 85
-332 135 -407 101z"
      />
      <path
        d="M16830 21813 c-79 -39 -152 -116 -179 -187 -12 -32 -32 -127 -46
-210 -13 -83 -40 -245 -60 -361 -34 -197 -36 -220 -32 -370 4 -174 14 -215 65
-269 37 -40 81 -56 150 -56 73 0 161 29 230 77 150 103 190 231 243 783 11
124 18 250 15 281 -14 144 -87 247 -209 298 -109 45 -115 45 -177 14z"
      />
      <path
        d="M22140 21771 c-19 -4 -48 -14 -65 -22 -16 -9 -199 -101 -405 -206
-206 -105 -436 -217 -510 -248 -216 -90 -231 -98 -276 -141 -108 -105 -154
-316 -94 -433 35 -71 174 -140 280 -141 78 0 284 61 485 145 195 80 623 313
718 388 54 44 110 133 132 208 27 92 27 260 1 313 -51 101 -161 158 -266 137z"
      />
      <path
        d="M2958 21464 c-54 -17 -94 -59 -104 -110 -11 -59 15 -244 76 -544 32
-157 81 -396 109 -532 56 -272 79 -337 146 -404 76 -76 177 -92 313 -49 155
49 202 113 210 285 3 77 -3 133 -32 300 -66 372 -127 671 -148 728 -47 126
-142 243 -236 291 -97 49 -239 64 -334 35z"
      />
      <path
        d="M23783 21344 c-100 -48 -147 -134 -147 -269 0 -79 3 -97 27 -142 45
-86 135 -150 342 -239 448 -194 704 -250 1034 -225 301 22 340 32 580 137 154
68 198 105 232 192 11 31 23 84 26 117 4 51 2 65 -19 95 -25 38 -96 80 -186
112 -92 32 -254 41 -529 28 -283 -14 -354 -8 -532 47 -585 179 -715 202 -828
147z"
      />
      <path
        d="M34550 21280 c-163 -10 -225 -23 -307 -61 -109 -52 -218 -204 -243
-339 -21 -114 26 -212 128 -261 44 -22 62 -24 322 -30 151 -4 460 -8 685 -8
450 -1 492 3 570 57 22 15 53 50 70 77 29 47 30 52 30 174 0 113 -2 131 -23
171 -13 25 -36 58 -50 73 -57 61 -185 106 -379 133 -128 18 -601 27 -803 14z"
      />
      <path
        d="M0 20910 c0 -170 2 -310 4 -310 9 0 193 235 229 291 39 62 83 163 93
215 6 33 5 35 -47 58 -65 28 -183 56 -238 56 l-41 0 0 -310z"
      />
      <path
        d="M40868 21205 c-80 -22 -130 -52 -263 -158 -91 -72 -178 -155 -291
-278 -188 -203 -312 -344 -398 -451 -77 -96 -264 -397 -278 -446 -13 -47 -6
-117 16 -163 34 -72 233 -307 451 -533 372 -385 554 -561 612 -590 63 -31 153
-56 205 -56 l38 0 0 292 0 292 -190 191 c-105 106 -190 199 -190 209 0 29 -59
224 -97 318 l-35 87 23 33 c36 51 230 306 366 481 l123 157 0 315 0 315 -22
-1 c-13 0 -44 -7 -70 -14z"
      />
      <path
        d="M10085 21200 c-200 -15 -363 -51 -489 -109 -172 -79 -261 -294 -212
-514 9 -42 27 -88 38 -103 30 -38 93 -64 158 -64 50 0 682 81 897 115 165 26
261 76 343 177 115 143 142 257 87 369 -30 62 -59 80 -184 115 -68 19 -454 28
-638 14z"
      />
      <path
        d="M4885 21159 c-108 -13 -239 -76 -407 -196 -266 -189 -497 -527 -565
-825 -25 -112 -23 -337 5 -451 53 -216 158 -366 347 -497 217 -149 337 -132
425 63 32 69 26 102 -41 238 -33 68 -85 165 -114 214 -64 110 -79 152 -73 219
6 66 41 135 128 252 89 117 176 197 362 330 168 120 247 199 267 265 16 53 16
172 -2 254 l-12 60 -65 33 c-74 37 -163 51 -255 41z"
      />
      <path
        d="M6635 21099 c-77 -19 -476 -66 -482 -56 -7 11 -121 2 -298 -23 -153
-22 -320 -77 -372 -123 -66 -58 -120 -211 -110 -313 16 -160 108 -210 401
-221 353 -12 805 51 957 134 73 39 144 120 189 215 97 202 83 281 -62 358 -67
36 -152 47 -223 29z"
      />
      <path
        d="M26805 21021 c-115 -52 -225 -255 -225 -416 0 -66 6 -92 46 -198 54
-142 178 -434 236 -557 51 -107 139 -197 291 -297 469 -310 1010 -286 1445 63
42 34 136 120 208 191 109 106 137 139 163 194 45 96 60 175 67 349 l7 155
-37 33 c-134 118 -232 91 -461 -127 -225 -215 -333 -288 -485 -328 -88 -23
-237 -22 -315 1 -228 67 -376 247 -555 677 -65 153 -110 217 -181 253 -64 32
-144 35 -204 7z"
      />
      <path
        d="M14681 20831 c-58 -22 -68 -29 -124 -80 -47 -44 -81 -108 -96 -181
-11 -57 -118 -655 -145 -815 -51 -296 -136 -938 -136 -1020 0 -79 24 -171 57
-213 38 -50 89 -68 268 -91 473 -64 871 -173 2010 -553 343 -114 363 -119 426
-115 83 5 148 36 215 102 64 63 89 130 81 216 -10 115 -100 211 -252 270 -594
228 -1272 445 -1720 549 -288 67 -331 78 -387 103 l-56 25 39 189 c57 272 154
786 194 1021 65 378 42 508 -102 578 -77 38 -191 44 -272 15z"
      />
      <path
        d="M22918 20830 c-95 -18 -210 -132 -248 -246 -12 -35 -39 -91 -62 -126
-53 -83 -231 -257 -455 -446 -196 -164 -263 -232 -330 -332 -125 -189 -129
-454 -7 -547 33 -24 39 -25 110 -20 137 12 269 67 445 186 173 117 339 262
512 449 59 64 112 117 117 117 32 0 210 -183 784 -807 141 -154 189 -179 294
-152 109 29 201 96 245 179 16 31 22 64 25 141 7 174 -33 279 -187 494 -204
283 -680 775 -968 999 -126 98 -196 126 -275 111z"
      />
      <path
        d="M19400 20773 c-129 -45 -202 -146 -250 -347 -24 -98 -25 -128 -5
-170 9 -18 104 -116 213 -218 321 -302 412 -377 736 -607 l158 -113 -99 -102
c-55 -55 -176 -184 -269 -285 -93 -102 -225 -244 -294 -317 -138 -145 -188
-220 -226 -338 -34 -107 -51 -266 -34 -326 30 -112 152 -162 312 -129 70 15
159 60 233 119 80 64 584 574 716 725 286 327 489 585 529 675 20 44 25 71 25
140 0 167 -54 225 -420 445 -504 304 -727 473 -901 687 -84 104 -121 135 -181
158 -66 24 -178 26 -243 3z"
      />
      <path
        d="M33665 20686 c-73 -32 -166 -184 -185 -303 -11 -68 -1 -140 28 -206
11 -23 115 -197 232 -387 225 -366 255 -419 294 -522 77 -204 86 -527 19 -666
-16 -34 -40 -64 -66 -83 -70 -51 -122 -61 -347 -68 -184 -6 -210 -9 -257 -30
-165 -73 -254 -306 -192 -505 26 -86 80 -144 164 -177 55 -21 75 -23 240 -24
166 0 188 2 290 29 253 66 455 195 581 372 59 82 147 268 178 377 82 283 86
513 15 872 -60 299 -227 641 -454 925 -181 228 -309 359 -378 390 -49 23 -119
25 -162 6z"
      />
      <path
        d="M1689 20620 c-623 -94 -1147 -340 -1379 -649 -109 -145 -188 -345
-175 -441 16 -110 93 -191 235 -250 85 -35 56 -46 250 94 77 55 169 135 240
206 111 112 120 119 240 177 296 145 583 252 780 293 58 12 176 28 263 35 177
16 224 33 252 91 31 65 10 180 -46 247 -55 67 -207 158 -319 191 -86 26 -199
28 -341 6z"
      />
      <path
        d="M38150 20384 c-8 -3 -54 -21 -102 -40 -115 -46 -178 -50 -395 -29
-286 28 -522 2 -626 -68 -93 -63 -151 -178 -145 -288 3 -54 9 -73 32 -103 60
-80 130 -104 453 -160 494 -86 596 -94 735 -57 177 46 278 133 328 282 30 88
26 262 -8 325 -27 53 -70 96 -117 118 -38 18 -129 30 -155 20z"
      />
      <path
        d="M11135 20223 c-115 -41 -200 -110 -254 -205 -43 -75 -57 -128 -96
-377 -46 -291 -85 -465 -142 -630 -37 -110 -47 -153 -51 -223 -4 -75 -1 -93
18 -133 68 -140 250 -134 428 14 35 29 83 78 108 109 45 55 124 203 124 229 0
27 151 473 160 473 20 0 1131 -372 1183 -395 l54 -25 13 -78 c20 -130 36 -174
75 -212 33 -32 42 -35 93 -35 49 0 67 6 127 42 206 124 375 309 477 522 26 53
32 79 32 131 0 148 -95 229 -315 270 -499 93 -746 165 -1188 347 -341 141
-477 178 -686 188 -91 5 -119 3 -160 -12z"
      />
      <path
        d="M8192 20209 c-78 -13 -198 -55 -262 -91 -161 -90 -268 -211 -376
-427 -114 -229 -164 -446 -164 -714 0 -421 108 -728 315 -898 155 -128 285
-132 395 -13 23 25 54 71 68 102 24 50 27 69 27 157 l-1 100 -58 130 c-104
233 -119 284 -124 420 -8 250 66 443 196 507 66 32 208 32 347 -2 189 -45 298
-29 383 60 110 113 157 274 116 397 -34 101 -56 124 -168 171 -55 23 -146 57
-201 74 -93 28 -113 31 -270 34 -93 1 -194 -1 -223 -7z"
      />
      <path
        d="M25405 20052 c-56 -27 -89 -65 -129 -152 -48 -105 -61 -184 -42 -258
14 -55 105 -236 186 -372 76 -127 93 -287 52 -485 -62 -294 -207 -406 -644
-500 -239 -51 -376 -303 -293 -540 24 -68 49 -102 98 -128 37 -19 60 -22 202
-25 145 -3 172 -1 280 23 402 90 663 282 827 609 94 188 154 414 166 629 18
304 -32 582 -143 814 -129 265 -408 457 -560 385z"
      />
      <path
        d="M30155 20048 c-226 -22 -331 -84 -434 -252 -42 -69 -55 -125 -41
-175 32 -119 166 -173 655 -265 564 -107 784 -123 1115 -80 234 31 322 65 408
158 56 62 86 129 103 234 14 87 1 139 -46 181 -64 56 -175 75 -585 101 -459
28 -496 32 -900 95 -104 16 -141 17 -275 3z"
      />
      <path
        d="M6348 19711 c-58 -37 -92 -79 -117 -147 -18 -46 -22 -80 -23 -197 0
-78 4 -176 11 -217 32 -219 41 -650 19 -963 -7 -95 -13 -269 -13 -387 0 -243
9 -285 71 -339 74 -65 173 -62 281 8 72 46 120 102 166 189 49 93 64 154 81
322 40 387 40 760 0 1010 -19 119 -76 360 -110 463 -47 143 -135 230 -271 270
-51 15 -52 15 -95 -12z"
      />
      <path
        d="M18735 19561 c-110 -63 -170 -164 -185 -308 -33 -320 -42 -479 -38
-638 6 -193 14 -221 76 -277 51 -46 98 -61 180 -56 89 5 147 35 227 114 75 75
119 159 136 259 14 84 6 558 -11 649 -31 161 -132 258 -290 280 -38 6 -53 2
-95 -23z"
      />
      <path
        d="M26805 19476 c-113 -36 -188 -106 -255 -238 -50 -100 -64 -168 -58
-293 4 -82 12 -120 42 -210 60 -173 120 -315 221 -515 172 -344 245 -457 338
-528 87 -66 82 -65 219 -38 120 23 123 24 160 66 41 47 74 123 84 198 12 92
-40 269 -176 607 -179 442 -207 517 -261 697 -44 146 -56 172 -96 210 -57 55
-135 70 -218 44z"
      />
      <path
        d="M2145 19300 c-186 -26 -336 -140 -434 -329 -78 -153 -117 -312 -127
-516 -11 -234 28 -418 124 -579 94 -158 186 -221 323 -221 68 0 79 3 113 29
29 22 36 33 32 50 -40 143 -45 362 -11 536 26 137 94 380 121 433 33 65 96
131 147 156 23 11 69 23 102 26 79 9 358 55 364 60 2 3 14 41 27 86 17 57 21
86 14 99 -23 44 -114 110 -193 142 l-82 32 -230 2 c-126 1 -257 -1 -290 -6z"
      />
      <path
        d="M32045 19195 c-134 -21 -234 -53 -360 -115 -147 -72 -225 -131 -348
-260 -225 -237 -347 -500 -347 -751 0 -93 38 -295 77 -409 125 -368 359 -595
742 -720 138 -45 230 -61 275 -49 51 14 110 70 142 135 22 46 26 64 22 121 -2
43 -12 85 -26 114 -24 49 -27 53 -356 426 -120 136 -225 262 -232 280 -8 18
-14 48 -14 66 0 51 55 151 123 224 112 119 226 167 571 242 129 28 192 37 197
30 7 -13 309 14 389 35 60 16 109 48 140 93 58 84 77 220 41 298 -45 99 -198
184 -386 215 -253 41 -490 51 -650 25z"
      />
      <path
        d="M35565 19184 c-296 -35 -417 -85 -495 -206 -54 -83 -74 -154 -67
-237 10 -117 16 -125 115 -161 114 -41 245 -60 422 -60 80 0 251 -7 380 -14
149 -9 252 -11 282 -6 71 14 146 56 205 116 43 45 56 67 74 125 36 118 15 255
-48 311 -57 52 -275 105 -528 128 -104 9 -274 11 -340 4z"
      />
      <path
        d="M9285 19152 c-71 -35 -126 -88 -157 -151 -19 -39 -23 -66 -26 -191
l-4 -145 51 -205 c97 -393 222 -839 317 -1130 19 -57 34 -109 34 -116 0 -6
-12 -14 -27 -17 -16 -3 -71 -15 -123 -26 -52 -11 -196 -32 -320 -46 -224 -26
-435 -63 -875 -150 -334 -66 -376 -81 -503 -173 -108 -80 -160 -176 -169 -318
-8 -120 19 -184 96 -223 74 -38 109 -36 377 18 426 86 507 99 1663 261 278 40
525 78 550 87 147 48 265 179 306 340 28 108 -2 193 -108 314 -126 143 -196
256 -252 406 -14 37 -64 194 -111 348 -152 500 -234 737 -322 926 -70 150
-142 209 -266 216 -63 4 -78 1 -131 -25z"
      />
      <path
        d="M38538 19151 c-74 -24 -128 -88 -128 -153 0 -42 27 -106 74 -177 54
-81 94 -119 168 -164 42 -25 54 -37 47 -48 -39 -61 68 -436 201 -704 114 -229
201 -326 365 -406 81 -39 178 -69 224 -69 42 0 109 39 133 78 17 27 23 51 22
92 0 47 -10 75 -67 190 -58 119 -207 529 -207 571 0 11 24 15 113 15 89 1 137
8 235 32 243 61 288 78 342 130 52 51 68 94 56 156 -16 83 -118 183 -230 225
-58 21 -260 41 -425 41 -127 0 -274 15 -356 35 -33 8 -109 39 -170 69 -60 30
-134 61 -164 70 -66 19 -196 29 -233 17z"
      />
      <path
        d="M0 18826 l0 -299 79 5 c89 5 133 25 168 78 63 92 -5 265 -171 437
l-75 78 -1 -299z"
      />
      <path
        d="M37171 18855 c-212 -47 -371 -199 -426 -405 -33 -125 -15 -190 73
-257 101 -77 198 -77 346 -2 109 56 154 88 214 153 70 78 87 131 78 254 -17
223 -100 298 -285 257z"
      />
      <path
        d="M28464 18809 c-129 -22 -234 -105 -304 -242 -74 -148 -77 -270 -7
-374 l32 -48 95 -13 c259 -34 845 -103 1000 -118 281 -26 559 -5 682 53 165
78 241 242 205 447 -20 114 -50 173 -101 199 -34 18 -74 22 -300 34 -144 7
-422 26 -620 43 -354 30 -581 36 -682 19z"
      />
      <path
        d="M23686 18675 c-60 -23 -99 -57 -167 -147 -123 -163 -172 -315 -150
-462 13 -85 40 -150 69 -164 9 -5 46 -12 82 -16 36 -4 84 -9 107 -12 l42 -6
47 73 c141 221 211 348 223 406 20 93 11 189 -22 240 -54 84 -147 120 -231 88z"
      />
      <path
        d="M573 18621 c-55 -34 -79 -86 -158 -356 -53 -177 -161 -358 -324 -542
l-91 -102 0 -327 0 -326 53 7 c221 29 404 110 514 226 45 49 182 244 273 389
158 255 201 371 201 545 0 161 -35 264 -123 361 -57 62 -108 95 -193 124 -73
25 -111 25 -152 1z"
      />
      <path
        d="M17639 18480 c-79 -42 -116 -121 -104 -217 3 -29 22 -110 41 -180
117 -435 109 -393 109 -568 0 -185 -16 -257 -86 -397 -107 -213 -298 -293
-518 -216 -96 33 -331 78 -443 85 -122 7 -174 -6 -235 -60 -57 -50 -78 -101
-77 -187 0 -69 3 -81 44 -159 94 -175 228 -296 381 -344 471 -145 689 -119
1019 122 381 278 570 690 530 1151 -26 300 -106 543 -250 758 -101 152 -200
224 -315 229 -45 2 -69 -2 -96 -17z"
      />
      <path
        d="M11587 18430 c-97 -25 -153 -71 -236 -195 -100 -149 -132 -211 -204
-395 -94 -237 -263 -726 -276 -795 -21 -113 -11 -179 37 -244 65 -87 196 -93
357 -16 113 54 238 193 322 359 46 91 298 762 323 862 29 111 27 234 -4 299
-27 59 -57 88 -110 110 -47 20 -158 28 -209 15z"
      />
      <path
        d="M4371 18337 c-51 -11 -57 -16 -88 -67 -69 -115 -91 -232 -60 -317 36
-96 137 -187 292 -263 186 -91 408 -128 655 -110 152 12 207 22 283 50 106 40
205 133 263 248 54 106 33 218 -48 267 -55 32 -95 37 -338 45 -296 9 -380 23
-597 96 -180 60 -267 72 -362 51z"
      />
      <path
        d="M22115 18326 c-269 -52 -487 -202 -650 -446 -109 -163 -179 -344
-210 -545 -23 -142 -16 -405 13 -540 52 -241 177 -515 257 -565 42 -26 125
-28 148 -2 57 62 68 163 50 422 -8 113 -13 252 -10 310 12 274 170 506 423
621 90 41 167 57 334 67 156 11 205 24 253 70 83 78 109 247 57 357 -27 57
-82 107 -175 160 -156 89 -332 122 -490 91z"
      />
      <path
        d="M3025 18311 c-69 -11 -126 -42 -169 -92 -70 -82 -85 -202 -35 -273
135 -191 165 -244 239 -422 53 -128 81 -176 127 -213 54 -45 123 -65 223 -66
76 0 91 3 138 29 86 48 146 131 158 219 7 52 -48 271 -107 422 -73 187 -190
331 -307 376 -63 24 -183 33 -267 20z"
      />
      <path
        d="M13320 18062 c-8 -5 -51 -13 -95 -17 -252 -21 -444 -66 -538 -124
-91 -56 -148 -160 -155 -283 -7 -123 37 -204 133 -244 66 -27 235 -38 382 -23
97 10 313 14 728 15 550 1 596 3 686 21 170 36 276 106 338 223 59 111 53 214
-17 292 -89 99 -157 115 -548 127 -519 17 -898 22 -914 13z"
      />
      <path
        d="M37315 17944 c-103 -16 -190 -48 -289 -106 -167 -98 -227 -199 -192
-320 22 -76 80 -132 224 -217 103 -61 198 -72 742 -92 272 -10 313 -9 368 5
157 40 280 124 325 224 33 72 30 170 -6 244 -63 129 -261 242 -471 268 -119
15 -592 11 -701 -6z"
      />
      <path
        d="M34670 17829 c-79 -32 -122 -91 -171 -234 -40 -120 -44 -198 -11
-261 42 -81 229 -228 377 -294 290 -131 810 -342 970 -393 172 -56 354 -87
501 -87 97 0 216 29 310 75 111 54 178 164 193 316 20 189 -36 298 -169 328
-25 6 -92 11 -150 11 -118 0 -260 19 -366 49 -39 11 -169 59 -290 107 -485
190 -883 343 -962 368 -87 27 -185 34 -232 15z"
      />
      <path
        d="M28466 17630 c-44 -14 -102 -76 -125 -134 -11 -28 -50 -148 -87 -266
-36 -118 -80 -242 -97 -275 -105 -198 -386 -327 -644 -295 -189 24 -348 112
-467 260 -83 102 -144 122 -235 76 -98 -50 -185 -179 -217 -324 -15 -65 -16
-89 -6 -155 24 -167 125 -332 274 -446 48 -38 162 -98 235 -124 264 -95 666
-71 948 58 294 134 566 443 668 760 44 138 51 190 52 395 0 211 -6 245 -64
361 -42 85 -151 135 -235 109z"
      />
      <path
        d="M40905 17574 c-99 -88 -245 -142 -390 -145 -27 0 -102 -15 -165 -32
l-115 -32 -3 -50 c-7 -107 29 -155 169 -225 159 -80 319 -120 485 -120 l74 0
0 325 c0 179 -1 325 -2 325 -2 -1 -25 -21 -53 -46z"
      />
      <path
        d="M20034 17380 c-77 -61 -168 -151 -310 -306 -314 -342 -783 -864 -826
-918 -108 -137 -136 -271 -106 -527 20 -176 -2 -161 1028 -697 168 -87 307
-150 475 -215 112 -43 140 -50 205 -51 66 -1 81 3 130 30 100 56 160 167 160
292 0 141 -84 247 -248 316 -63 27 -564 282 -787 402 -66 36 -142 84 -169 108
l-50 43 37 47 c20 26 146 163 280 304 419 442 607 658 654 752 25 50 28 65 28
160 -1 114 -13 165 -52 218 -34 45 -84 68 -221 100 l-119 29 -109 -87z"
      />
      <path
        d="M29532 17451 c-135 -47 -243 -242 -229 -413 11 -133 85 -241 200
-293 29 -13 124 -42 211 -65 88 -22 207 -59 265 -82 153 -59 227 -76 374 -88
222 -18 362 5 428 71 48 48 79 143 79 238 -1 142 -55 197 -335 338 -321 162
-498 231 -740 288 -126 30 -181 31 -253 6z"
      />
      <path
        d="M25420 17291 c-118 -38 -210 -142 -244 -274 -28 -105 1 -167 126
-273 155 -132 229 -257 236 -400 4 -70 1 -90 -17 -128 -43 -87 -127 -146 -294
-205 -146 -51 -263 -70 -544 -86 -451 -27 -489 -37 -623 -174 -105 -106 -155
-204 -155 -305 0 -110 22 -145 205 -326 161 -160 243 -266 315 -411 52 -103
160 -347 226 -513 72 -178 85 -205 158 -319 l56 -89 130 5 c94 3 142 9 173 21
153 64 231 354 172 635 -46 220 -87 332 -234 649 -36 79 -67 148 -69 154 -2
11 8 15 198 73 235 72 403 157 543 275 126 107 231 254 261 365 22 83 63 517
58 605 -10 144 -75 298 -184 433 -73 91 -224 221 -299 258 -64 32 -149 45
-194 30z"
      />
      <path
        d="M23885 17145 c-287 -66 -518 -190 -717 -385 -106 -104 -141 -162
-159 -268 -26 -146 44 -336 143 -388 38 -20 115 -17 165 6 24 10 82 56 130
102 116 109 168 143 413 265 225 112 315 174 390 267 52 63 109 164 129 228
12 35 10 40 -19 83 -36 52 -61 67 -161 95 -95 26 -188 25 -314 -5z"
      />
      <path
        d="M1258 17108 c-66 -18 -118 -98 -118 -185 0 -70 28 -151 155 -448 195
-457 230 -530 300 -635 121 -183 245 -270 380 -270 164 1 279 34 315 92 36 56
23 104 -84 321 -52 105 -144 307 -205 448 -156 366 -180 411 -265 495 -83 83
-216 155 -331 179 -79 17 -95 17 -147 3z"
      />
      <path
        d="M32909 17071 c-152 -49 -327 -231 -407 -422 -82 -198 -84 -477 -6
-726 34 -109 70 -155 147 -193 56 -27 180 -60 226 -60 33 0 109 78 143 148 32
65 33 68 29 181 -11 312 -5 347 87 524 l57 109 0 106 c0 170 -31 259 -105 308
-41 28 -126 40 -171 25z"
      />
      <path
        d="M2770 16910 c-143 -17 -224 -53 -298 -134 -61 -68 -93 -144 -100
-243 -4 -60 -1 -79 18 -117 26 -54 84 -107 160 -146 75 -38 114 -47 265 -60
285 -25 479 -86 1010 -320 289 -128 381 -181 494 -286 l60 -55 103 11 c130 14
162 23 219 59 75 47 93 96 97 264 7 271 -35 347 -264 484 -336 200 -585 318
-900 426 -183 63 -304 93 -453 113 -112 14 -303 16 -411 4z"
      />
      <path
        d="M37559 16790 c-100 -21 -118 -29 -153 -71 -49 -57 -31 -140 43 -198
44 -35 271 -141 407 -191 115 -42 149 -60 212 -109 52 -42 95 -103 113 -163
26 -89 2 -279 -71 -553 -57 -211 -33 -355 78 -462 86 -84 256 -107 397 -53 87
34 171 121 213 221 96 232 138 549 98 739 -59 279 -310 597 -574 729 -239 120
-526 161 -763 111z"
      />
      <path
        d="M6379 16670 c-214 -38 -440 -129 -597 -240 -249 -175 -460 -472 -628
-881 -109 -266 -135 -374 -114 -489 47 -268 122 -340 287 -278 94 35 168 117
238 264 221 462 262 531 395 665 129 130 264 213 430 262 105 31 111 33 294
57 111 14 163 26 202 45 106 52 174 164 174 289 0 80 -37 157 -97 202 -90 68
-285 125 -422 123 -31 0 -104 -9 -162 -19z"
      />
      <path
        d="M14510 16671 c-140 -14 -254 -97 -300 -219 -30 -77 -25 -198 9 -259
53 -93 116 -112 421 -123 193 -7 238 -12 315 -33 162 -45 359 -144 558 -280
105 -71 176 -134 229 -200 l38 -49 0 -221 c0 -236 6 -281 44 -316 36 -34 83
-37 166 -10 157 49 297 174 376 334 100 205 95 408 -13 595 -51 88 -298 334
-423 422 -260 184 -551 296 -881 343 -130 18 -428 27 -539 16z"
      />
      <path
        d="M34925 16561 c-77 -27 -105 -44 -196 -117 -111 -89 -203 -148 -302
-194 -153 -72 -174 -75 -527 -75 l-315 0 -57 -28 c-263 -129 -270 -417 -15
-542 78 -37 113 -44 399 -70 l166 -16 162 31 c386 74 596 164 775 332 147 137
223 311 214 491 -5 96 -37 147 -112 183 -62 29 -122 30 -192 5z"
      />
      <path
        d="M39915 16331 c-207 -63 -265 -101 -281 -186 -9 -47 22 -112 69 -146
43 -31 157 -71 252 -88 33 -6 127 -15 210 -21 231 -16 554 -94 703 -169 33
-17 63 -35 67 -41 21 -34 25 12 25 278 l0 289 -37 11 c-222 66 -539 113 -758
111 -120 0 -139 -3 -250 -38z"
      />
      <path
        d="M12300 16221 c-383 -82 -554 -162 -730 -340 -98 -99 -149 -187 -180
-307 -61 -237 23 -376 217 -362 87 6 153 38 263 125 184 145 327 221 488 259
53 13 113 15 297 13 342 -5 347 -5 419 29 80 38 140 92 174 156 55 106 27 245
-64 326 -86 75 -163 97 -443 125 -209 21 -240 19 -441 -24z"
      />
      <path
        d="M0 15955 l0 -287 35 -48 c49 -67 65 -119 65 -214 0 -101 -21 -190
-65 -283 l-35 -71 0 -275 0 -274 27 -14 c48 -25 222 -8 363 37 174 56 302 204
374 431 36 115 47 199 47 340 0 145 -24 260 -77 370 -57 119 -261 320 -442
438 -77 49 -217 119 -264 130 l-28 7 0 -287z"
      />
      <path
        d="M35579 16107 c-81 -31 -131 -86 -192 -215 -30 -63 -32 -74 -32 -177
l0 -110 56 -105 c66 -123 125 -223 225 -376 61 -94 82 -119 135 -155 104 -70
165 -81 239 -44 64 33 89 85 116 245 19 117 16 139 -43 325 -70 218 -187 464
-253 533 -74 76 -174 108 -251 79z"
      />
      <path
        d="M28990 16088 c-78 -30 -169 -123 -199 -203 -52 -139 -50 -252 9 -371
39 -80 88 -133 244 -268 69 -61 123 -113 119 -117 -9 -8 -487 -125 -835 -204
-136 -31 -303 -71 -371 -90 -98 -27 -456 -150 -749 -259 -66 -25 -150 -89
-194 -148 -89 -122 -122 -318 -74 -443 21 -55 60 -94 158 -159 114 -75 252
-213 502 -501 204 -235 258 -288 338 -327 49 -25 80 -32 140 -36 73 -4 79 -3
114 25 124 100 164 236 119 414 -24 97 -67 185 -209 431 -56 97 -102 181 -102
186 0 22 173 78 444 143 160 38 318 76 351 84 685 163 881 227 1102 357 99 59
102 62 122 117 35 97 44 158 39 285 -6 141 -24 200 -90 287 -24 30 -106 138
-183 240 -215 283 -390 451 -568 543 -82 43 -142 46 -227 14z"
      />
      <path
        d="M30962 16091 c-29 -10 -64 -29 -79 -42 -70 -65 -143 -222 -143 -309
0 -100 78 -249 287 -550 164 -234 259 -390 341 -557 74 -151 75 -165 10 -225
-53 -49 -200 -152 -286 -202 -149 -86 -310 -138 -477 -157 -90 -9 -131 -8
-258 6 -218 25 -396 7 -492 -50 -58 -34 -122 -107 -152 -173 -34 -76 -42 -189
-19 -260 38 -113 133 -179 336 -232 188 -49 318 -63 525 -57 259 8 431 50 660
162 201 98 329 189 646 461 218 188 247 232 256 389 9 160 -26 353 -97 519
-163 388 -386 750 -654 1063 -171 200 -280 258 -404 214z"
      />
      <path
        d="M9839 16006 c-2 -2 -56 -9 -119 -16 -370 -37 -602 -141 -836 -374
-150 -151 -220 -262 -266 -421 -20 -70 -23 -102 -23 -265 0 -207 9 -256 58
-315 64 -79 185 -99 280 -47 80 43 127 115 177 274 24 73 56 158 72 189 54
104 160 184 312 236 75 26 92 28 226 27 139 0 150 -2 265 -38 66 -21 158 -53
205 -73 107 -44 154 -42 264 10 148 70 243 192 266 342 20 127 -13 228 -96
288 -82 60 -381 155 -556 177 -82 10 -221 14 -229 6z"
      />
      <path
        d="M21174 15921 c-52 -13 -94 -44 -114 -86 -24 -49 -25 -76 -6 -138 31
-103 107 -175 296 -278 208 -112 375 -234 415 -302 34 -59 46 -120 34 -181
-12 -66 -85 -218 -157 -326 -68 -102 -228 -270 -335 -351 -107 -81 -571 -390
-806 -538 -107 -67 -200 -130 -207 -139 -21 -27 -17 -105 6 -135 37 -47 79
-64 210 -82 249 -35 394 -19 670 71 372 122 713 347 977 643 181 203 268 344
314 506 18 64 23 104 22 200 -1 141 -21 230 -82 360 -127 272 -349 484 -691
664 -137 71 -227 98 -375 111 -123 11 -127 11 -171 1z"
      />
      <path
        d="M26453 15415 c-90 -45 -244 -221 -342 -390 -118 -206 -165 -538 -99
-696 15 -34 22 -38 148 -82 72 -26 137 -47 144 -47 19 0 359 486 409 586 40
78 77 236 77 324 0 127 -62 261 -143 310 -47 29 -129 27 -194 -5z"
      />
      <path
        d="M7361 15240 c-188 -33 -262 -93 -295 -239 -22 -97 -21 -249 3 -343
40 -155 165 -421 271 -574 103 -149 203 -304 199 -308 -2 -2 -76 -40 -164 -84
-88 -44 -203 -108 -255 -143 -230 -154 -400 -374 -446 -579 -16 -67 -16 -92 0
-339 17 -287 28 -346 80 -456 112 -237 475 -503 657 -482 59 7 141 49 176 92
38 44 82 144 95 213 23 122 -16 184 -177 281 -176 106 -285 253 -293 392 -5
88 9 127 69 193 120 132 355 240 666 306 475 101 517 118 620 254 69 91 115
197 121 278 10 144 -35 214 -220 338 -152 102 -241 177 -327 273 -70 79 -279
387 -385 567 -91 153 -141 223 -223 308 l-68 70 -104 -18z"
      />
      <path
        d="M2921 15228 c-53 -28 -91 -91 -91 -149 0 -90 84 -234 176 -301 27
-20 118 -68 202 -106 259 -120 522 -268 807 -454 96 -62 135 -94 149 -121 l20
-35 -114 -244 c-258 -553 -280 -606 -280 -677 0 -94 25 -137 128 -225 l85 -73
116 18 c196 31 230 48 346 171 241 257 412 558 510 902 44 157 32 247 -47 325
-49 49 -201 145 -948 596 -345 208 -399 235 -658 322 -157 53 -210 66 -276 70
-72 4 -86 2 -125 -19z"
      />
      <path
        d="M39821 15234 c-77 -21 -290 -125 -386 -189 -334 -222 -551 -626 -481
-896 60 -229 280 -515 494 -643 314 -187 640 -216 1077 -94 133 37 172 56 213
102 87 100 53 224 -72 261 -130 39 -323 66 -536 76 -258 11 -277 17 -366 104
-153 150 -164 324 -38 565 63 120 149 230 324 418 l151 162 -31 28 c-57 49
-150 100 -201 111 -63 14 -81 13 -148 -5z"
      />
      <path
        d="M13850 15234 c-164 -7 -386 -25 -539 -45 -402 -52 -661 -125 -853
-241 -156 -95 -558 -444 -849 -739 l-176 -178 -142 114 c-446 356 -645 470
-918 521 -122 23 -282 16 -339 -14 -57 -29 -108 -92 -156 -190 -41 -84 -58
-171 -44 -225 15 -62 74 -105 209 -153 210 -76 344 -169 849 -585 124 -103
240 -195 258 -204 76 -39 192 -21 357 57 122 57 158 88 671 585 377 365 457
424 649 478 124 34 509 93 725 110 317 25 392 39 500 97 117 62 214 203 225
326 11 115 -49 231 -137 266 -44 17 -157 25 -290 20z"
      />
      <path
        d="M17779 15150 c-26 -4 -62 -15 -81 -24 -40 -19 -367 -347 -450 -451
-88 -110 -559 -852 -610 -962 -55 -117 -46 -225 27 -311 68 -78 170 -92 280
-37 106 54 151 109 316 380 236 389 329 518 525 727 178 188 249 300 272 429
15 78 -7 158 -55 201 -45 41 -143 62 -224 48z"
      />
      <path
        d="M1668 15110 c-69 -37 -105 -111 -89 -182 10 -44 117 -249 196 -378
158 -255 417 -540 579 -638 113 -69 313 -92 445 -52 95 29 141 75 141 140 0
49 -26 86 -116 165 -189 165 -266 255 -488 563 -154 214 -211 272 -317 324
-154 75 -279 95 -351 58z"
      />
      <path
        d="M36660 15081 c-89 -29 -140 -74 -230 -201 -29 -41 -118 -160 -197
-263 -145 -189 -186 -257 -198 -333 -6 -42 -6 -42 77 -127 97 -99 140 -119
294 -138 77 -9 112 -9 155 0 78 16 181 68 264 133 39 29 75 57 82 61 17 10 63
-70 113 -198 103 -265 213 -416 384 -529 117 -77 182 -100 286 -99 173 0 290
74 290 184 0 65 -20 115 -85 210 -69 100 -115 193 -184 366 -90 227 -198 393
-371 577 -53 56 -119 131 -148 168 -99 126 -226 186 -407 194 -55 2 -111 0
-125 -5z"
      />
      <path
        d="M40866 14955 c-97 -97 -121 -139 -112 -194 7 -47 63 -126 139 -197
l62 -59 3 138 c1 75 1 197 0 270 l-3 132 -89 -90z"
      />
      <path
        d="M33010 14994 c-167 -70 -222 -130 -223 -244 0 -65 62 -281 100 -347
32 -55 94 -110 157 -139 113 -52 552 -153 816 -188 473 -63 807 -44 1115 64
186 66 273 131 328 248 29 61 32 76 32 162 0 82 -4 102 -26 145 -68 134 -247
184 -481 135 -227 -47 -431 -48 -793 -4 -488 60 -663 96 -811 169 -49 25 -94
45 -100 45 -5 -1 -56 -21 -114 -46z"
      />
      <path
        d="M22950 14938 c-107 -54 -121 -148 -55 -367 49 -164 43 -260 -25 -397
-53 -109 -133 -193 -353 -371 -99 -81 -198 -164 -220 -186 -65 -65 -83 -135
-51 -203 45 -95 245 -179 370 -155 190 37 379 94 484 146 190 95 362 277 433
455 42 108 51 157 50 305 0 114 -5 161 -26 250 -81 349 -194 493 -421 536 -80
15 -138 11 -186 -13z"
      />
      <path
        d="M14846 14894 c-220 -53 -374 -212 -422 -436 -20 -92 -9 -142 43 -194
84 -84 206 -108 319 -62 156 63 286 164 331 256 25 51 28 67 27 147 -3 170
-51 269 -141 294 -49 14 -84 13 -157 -5z"
      />
      <path
        d="M6080 14477 c-131 -211 -168 -287 -178 -371 -16 -123 15 -204 100
-262 78 -54 182 -31 268 59 106 110 190 267 210 392 17 107 -15 265 -61 301
-12 10 -177 34 -226 34 -12 0 -48 -48 -113 -153z"
      />
      <path
        d="M18633 14395 c-51 -13 -130 -43 -175 -66 -70 -37 -103 -64 -218 -179
-149 -149 -292 -338 -360 -475 -68 -137 -101 -367 -65 -453 50 -119 201 -165
324 -97 80 44 135 102 254 272 165 232 243 293 377 293 76 0 146 -22 261 -82
183 -95 333 -227 440 -385 84 -124 126 -222 189 -440 12 -45 32 -86 50 -107
40 -45 121 -80 169 -71 106 19 223 109 291 225 91 154 93 273 8 530 -128 392
-445 773 -786 944 -213 107 -543 147 -759 91z"
      />
      <path
        d="M15307 14050 c-92 -24 -182 -102 -256 -224 -29 -49 -160 -337 -404
-891 -36 -82 -79 -174 -95 -203 l-28 -52 -175 168 c-195 187 -236 223 -361
310 -175 122 -461 273 -695 366 -188 75 -361 94 -487 56 -91 -29 -144 -72
-185 -152 -30 -60 -34 -75 -35 -155 -1 -67 3 -97 17 -124 28 -57 85 -107 162
-144 38 -18 133 -63 210 -100 553 -263 1037 -685 1402 -1225 50 -74 106 -149
125 -167 49 -46 104 -58 181 -38 122 31 190 78 296 209 57 70 58 72 65 151 11
126 45 317 83 469 69 278 172 538 393 996 78 162 149 313 157 335 8 22 15 72
15 112 0 125 -77 256 -169 287 -60 20 -166 28 -216 16z"
      />
      <path
        d="M3102 13813 c-47 -23 -105 -91 -234 -275 -142 -204 -208 -275 -295
-321 -97 -50 -179 -69 -303 -70 -297 -1 -551 172 -656 446 -49 127 -82 157
-176 157 -110 0 -271 -116 -340 -246 -94 -176 -71 -435 56 -628 172 -258 466
-411 878 -457 229 -25 490 33 704 159 311 181 493 410 605 762 31 95 34 116
33 220 0 96 -4 122 -21 155 -51 98 -163 142 -251 98z"
      />
      <path
        d="M96 13766 c-22 -8 -51 -24 -65 -34 l-26 -19 -3 -832 -2 -832 35 -14
c47 -20 132 -18 180 4 69 31 153 129 225 264 55 102 66 148 112 462 50 340 54
486 20 633 -12 51 -36 120 -53 153 -83 156 -294 263 -423 215z"
      />
      <path
        d="M34371 13685 c-250 -44 -497 -177 -712 -382 -188 -180 -311 -369
-386 -591 -31 -94 -35 -115 -31 -183 10 -169 118 -267 255 -230 57 15 133 88
298 281 73 85 164 185 201 221 138 132 297 214 476 245 167 29 281 0 376 -95
66 -65 104 -130 127 -220 19 -74 120 -315 167 -399 82 -144 200 -201 330 -156
94 32 165 128 220 294 41 126 48 254 20 355 -28 104 -125 347 -163 412 -158
266 -529 447 -939 458 -109 3 -180 0 -239 -10z"
      />
      <path
        d="M40931 13658 c-11 -24 -25 -65 -30 -93 -6 -27 -20 -331 -32 -674 -24
-684 -24 -686 32 -772 62 -98 59 -141 59 761 0 451 -2 820 -4 820 -2 0 -13
-19 -25 -42z"
      />
      <path
        d="M29090 13576 c-68 -36 -144 -117 -182 -193 -30 -61 -33 -77 -36 -174
-5 -125 14 -215 75 -356 36 -84 261 -466 541 -918 l130 -210 117 -37 117 -38
53 25 c58 28 119 89 141 140 35 86 26 231 -27 435 -32 123 -100 274 -189 420
-84 136 -183 307 -333 575 -157 281 -163 289 -219 328 -53 35 -123 36 -188 3z"
      />
      <path
        d="M26323 13515 c-81 -22 -125 -48 -241 -143 -249 -202 -301 -242 -377
-291 -216 -141 -438 -200 -677 -180 -120 10 -161 19 -348 81 -141 46 -161 50
-225 46 -163 -11 -295 -145 -295 -300 0 -62 18 -103 76 -179 92 -120 230 -212
392 -263 291 -91 610 -93 874 -6 99 33 301 134 407 203 224 148 560 454 619
565 67 125 100 325 65 392 -37 71 -161 106 -270 75z"
      />
      <path
        d="M32472 13489 c-68 -13 -137 -49 -277 -144 -315 -213 -429 -318 -456
-419 -17 -64 -7 -163 24 -229 24 -52 32 -60 84 -85 50 -23 71 -27 154 -27 92
0 99 2 175 40 290 148 565 316 673 412 91 80 114 169 67 269 -46 99 -119 151
-249 178 -83 17 -128 19 -195 5z"
      />
      <path
        d="M9796 13150 c-235 -37 -450 -153 -632 -342 -181 -188 -275 -395 -275
-609 0 -81 25 -162 59 -192 42 -37 168 -25 243 24 31 19 82 72 138 142 210
264 315 326 526 314 175 -10 354 -67 531 -169 116 -66 163 -82 224 -75 64 8
130 43 215 114 127 107 166 178 166 299 0 150 -58 230 -232 315 -151 75 -278
121 -424 155 -102 23 -146 27 -300 30 -99 1 -207 -1 -239 -6z"
      />
      <path
        d="M39512 13105 c-157 -35 -230 -125 -184 -227 31 -72 76 -113 199 -183
288 -166 384 -197 544 -177 134 16 194 59 226 159 l18 56 -50 45 c-28 25 -80
65 -117 89 -81 52 -391 216 -438 231 -60 19 -133 22 -198 7z"
      />
      <path
        d="M21175 13053 c-74 -21 -92 -68 -71 -190 43 -244 -3 -588 -106 -787
-53 -101 -170 -223 -240 -250 -43 -17 -48 -17 -80 -2 -19 9 -62 48 -96 86 -84
93 -144 122 -268 128 -213 10 -310 -58 -284 -200 25 -132 96 -254 220 -378
100 -100 159 -140 287 -195 213 -92 437 -115 599 -62 249 82 503 365 598 667
47 146 59 260 52 480 -6 218 -15 262 -74 384 -74 154 -170 254 -285 298 -52
20 -209 34 -252 21z"
      />
      <path
        d="M36342 12984 c-59 -29 -109 -85 -186 -208 -70 -111 -90 -173 -120
-371 -71 -453 -78 -581 -46 -747 36 -184 118 -302 256 -369 98 -46 175 -51
250 -14 60 29 104 84 125 155 5 19 21 328 34 687 24 650 24 653 5 715 -48 155
-183 219 -318 152z"
      />
      <path
        d="M37520 12919 c-77 -12 -171 -53 -223 -98 -44 -39 -95 -138 -103 -204
-6 -43 -4 -51 22 -79 43 -45 213 -171 312 -230 181 -107 597 -317 662 -333
145 -38 361 -2 485 81 76 50 108 101 113 179 5 79 -21 142 -95 230 -45 53 -74
73 -264 182 -244 139 -437 234 -524 258 -73 20 -293 28 -385 14z"
      />
      <path
        d="M5058 12854 c-105 -32 -120 -99 -64 -292 81 -276 110 -375 152 -512
93 -300 148 -457 182 -523 79 -152 196 -200 401 -166 143 24 188 79 198 245
l5 99 -81 260 c-184 582 -201 626 -293 730 -51 58 -165 133 -236 155 -76 24
-194 26 -264 4z"
      />
      <path
        d="M26578 12596 c-68 -28 -110 -62 -162 -130 -48 -65 -80 -126 -101
-201 -24 -81 -16 -262 15 -360 69 -218 219 -544 382 -832 87 -154 162 -241
243 -284 l43 -23 124 22 c115 20 125 23 151 54 41 47 73 114 86 179 22 105
-18 256 -161 614 -192 478 -223 560 -283 758 -40 129 -78 187 -142 214 -49 20
-130 16 -195 -11z"
      />
      <path
        d="M12355 12475 c-197 -45 -326 -86 -417 -133 -139 -72 -923 -504 -1033
-570 -122 -73 -215 -159 -307 -282 -76 -102 -90 -134 -96 -211 -7 -95 12 -161
54 -199 45 -39 79 -39 219 2 91 26 214 83 720 336 336 167 651 320 700 340
211 82 349 24 454 -191 24 -48 54 -95 67 -103 40 -27 93 -28 164 -4 115 38
199 101 260 192 137 206 97 461 -105 664 -123 124 -253 180 -440 189 -77 4
-111 0 -240 -30z"
      />
      <path
        d="M18032 12409 c-281 -25 -508 -92 -663 -194 -91 -61 -206 -186 -252
-273 -56 -106 -79 -205 -80 -342 -1 -177 37 -292 131 -392 82 -87 164 -113
266 -84 71 21 127 70 159 139 13 29 39 108 56 177 18 68 33 125 34 126 21 17
105 49 169 64 252 60 520 46 1028 -56 276 -56 294 -55 362 18 26 27 62 80 80
116 30 61 33 74 33 162 0 89 -2 100 -37 173 -78 163 -176 229 -428 291 -309
75 -583 99 -858 75z"
      />
      <path
        d="M34000 12261 c-84 -27 -151 -95 -175 -175 -28 -94 -20 -157 59 -474
159 -637 316 -1144 490 -1587 80 -203 116 -329 99 -345 -4 -5 -116 -53 -248
-108 -242 -101 -808 -347 -965 -420 -91 -42 -204 -115 -258 -166 -83 -77 -99
-187 -43 -301 84 -170 278 -245 446 -173 380 162 936 420 1335 619 283 141
345 176 397 223 34 31 71 76 82 99 35 73 29 108 -49 265 -180 366 -289 652
-449 1182 -80 268 -206 699 -271 934 -34 121 -67 234 -75 252 -22 52 -92 121
-154 150 -59 29 -172 41 -221 25z"
      />
      <path
        d="M32519 12226 c-50 -18 -92 -49 -135 -101 l-36 -44 -146 6 c-135 5
-156 3 -252 -20 -197 -48 -378 -136 -528 -255 -179 -142 -336 -295 -805 -784
-207 -217 -231 -245 -267 -320 -45 -92 -49 -131 -20 -188 49 -96 141 -160 231
-160 100 0 300 91 434 199 106 85 273 240 594 550 279 270 336 317 487 393
114 58 281 128 306 128 18 0 24 -12 260 -550 109 -250 255 -568 323 -706 106
-217 131 -260 179 -308 75 -77 129 -100 231 -100 133 0 288 68 345 151 61 89
25 245 -118 506 -35 65 -97 188 -136 275 -71 153 -96 214 -342 812 -140 342
-144 350 -200 410 -58 62 -138 98 -246 110 -104 12 -115 12 -159 -4z"
      />
      <path
        d="M16013 12085 c-116 -15 -186 -49 -250 -119 -57 -62 -94 -135 -157
-309 -68 -190 -101 -325 -145 -602 -66 -410 -139 -806 -161 -872 l-22 -62 -31
12 c-20 8 -170 156 -385 378 -194 200 -369 372 -388 383 -82 43 -185 27 -243
-39 -68 -78 -93 -275 -50 -390 26 -70 52 -105 235 -322 171 -201 267 -304 637
-685 290 -300 261 -281 393 -257 175 32 291 159 374 407 45 136 210 894 271
1247 68 396 93 508 147 670 25 73 27 93 27 235 0 150 -1 157 -28 208 -34 65
-94 132 -116 131 -9 -1 -57 -7 -108 -14z"
      />
      <path
        d="M2264 12021 c-191 -52 -396 -199 -487 -348 -99 -162 -116 -332 -61
-603 56 -278 226 -602 414 -790 156 -157 306 -207 481 -160 43 12 88 55 120
114 l22 42 -26 40 c-15 21 -71 80 -125 130 -139 130 -276 336 -329 499 -36
112 -38 275 -4 385 44 144 83 204 217 335 132 129 164 173 164 221 0 115 -201
185 -386 135z"
      />
      <path
        d="M28240 12030 c-67 -12 -123 -35 -184 -74 -100 -64 -131 -143 -106
-268 22 -116 61 -169 213 -290 198 -158 457 -313 760 -456 206 -97 268 -113
374 -95 80 14 209 75 272 129 84 73 113 189 70 284 -38 83 -41 86 -429 295
-334 180 -436 239 -605 349 -77 51 -158 99 -180 108 -53 21 -129 28 -185 18z"
      />
      <path
        d="M3875 11881 c-170 -81 -1123 -606 -1147 -631 -53 -57 -55 -128 -4
-214 88 -151 264 -344 456 -501 148 -121 263 -235 322 -319 24 -34 73 -91 110
-127 115 -115 212 -128 378 -48 88 41 90 43 106 92 26 77 22 176 -10 240 -36
75 -198 269 -368 443 l-148 150 76 60 c41 32 155 110 252 172 206 133 322 215
376 268 137 133 82 308 -129 410 -94 46 -181 48 -270 5z"
      />
      <path
        d="M22195 11876 c-69 -30 -112 -119 -102 -208 4 -26 19 -87 36 -135 41
-122 51 -210 51 -461 0 -119 5 -245 10 -280 26 -156 90 -294 185 -399 l50 -55
60 11 c53 10 66 17 120 71 34 33 76 85 94 114 94 156 142 552 98 803 -19 107
-69 267 -104 336 -48 95 -148 170 -268 202 -72 18 -189 19 -230 1z"
      />
      <path
        d="M23373 11799 c-80 -40 -142 -157 -143 -269 0 -82 13 -127 90 -325
188 -482 313 -947 327 -1216 6 -119 5 -127 -13 -132 -67 -20 -307 -62 -529
-92 -437 -59 -551 -84 -695 -154 -92 -45 -125 -74 -157 -138 -25 -51 -28 -69
-28 -153 1 -146 40 -211 164 -268 83 -38 290 -49 634 -34 311 14 488 31 720
67 231 36 406 80 460 116 89 59 136 165 144 324 4 90 -2 139 -61 485 -77 458
-114 642 -163 810 -102 358 -258 643 -468 857 -137 140 -196 165 -282 122z"
      />
      <path
        d="M37257 11674 c-106 -16 -346 -78 -390 -100 -109 -55 -158 -175 -104
-255 32 -48 75 -66 244 -99 177 -35 325 -50 489 -50 108 0 125 -2 171 -25 66
-33 157 -129 191 -202 23 -49 27 -71 27 -143 0 -96 -16 -153 -76 -273 -67
-135 -156 -250 -372 -481 -60 -63 -106 -120 -102 -126 12 -19 116 -91 158
-110 108 -48 200 -34 394 60 140 67 271 153 366 240 129 119 245 298 299 460
26 79 31 111 31 190 0 110 -11 149 -84 296 -138 277 -355 468 -653 573 -49 17
-127 38 -174 46 -103 18 -296 18 -415 -1z"
      />
      <path
        d="M39795 11643 c-60 -8 -141 -35 -178 -59 -39 -26 -76 -89 -77 -131 0
-43 33 -118 90 -203 68 -102 120 -202 167 -325 92 -238 206 -424 361 -588 54
-56 123 -133 153 -172 106 -133 176 -179 317 -211 100 -22 231 -16 290 14 l42
22 0 511 0 510 -22 -6 c-60 -17 -171 -78 -246 -135 -46 -34 -84 -61 -85 -59
-44 67 -65 109 -101 202 -24 62 -58 142 -76 177 -141 280 -423 481 -635 453z"
      />
      <path
        d="M25381 11455 c-102 -49 -187 -136 -240 -247 -60 -125 -156 -511 -201
-805 -43 -275 -34 -775 16 -943 32 -107 108 -200 197 -241 55 -25 73 -24 135
7 127 63 172 195 192 561 18 329 64 617 170 1074 53 226 77 399 66 473 -9 56
-57 123 -101 142 -19 8 -63 14 -97 14 -52 0 -77 -6 -137 -35z"
      />
      <path
        d="M8817 11269 c-129 -17 -222 -79 -246 -166 -6 -21 -11 -102 -11 -179
0 -258 42 -326 290 -474 381 -227 879 -437 1189 -500 148 -30 337 -42 478 -30
154 12 203 23 276 62 99 53 166 150 189 277 23 121 -19 207 -136 283 -78 50
-136 67 -276 79 -236 20 -424 68 -705 181 -209 85 -516 221 -605 270 -83 45
-199 129 -244 177 -33 34 -62 37 -199 20z"
      />
      <path
        d="M6100 11109 c-52 -17 -105 -41 -118 -53 -53 -51 -33 -146 47 -229 28
-30 54 -43 116 -62 139 -42 246 -64 390 -80 233 -25 323 -49 460 -118 60 -31
102 -63 166 -127 76 -75 93 -100 137 -191 63 -132 86 -219 116 -450 42 -311
64 -385 133 -431 125 -83 239 -114 353 -98 158 22 204 66 231 220 32 186 7
425 -66 627 -196 539 -570 873 -1109 989 -113 25 -142 27 -441 30 l-320 4 -95
-31z"
      />
      <path
        d="M1145 11045 c-23 -8 -59 -34 -86 -63 -57 -62 -82 -136 -109 -322 -25
-168 -61 -295 -140 -485 -80 -192 -79 -229 10 -309 58 -52 102 -69 185 -69
123 0 200 52 314 213 100 142 168 300 195 454 48 267 22 393 -105 511 -86 79
-173 102 -264 70z"
      />
      <path
        d="M23 11013 l-23 -4 0 -512 0 -511 30 29 c17 16 68 81 114 145 46 63
143 192 215 285 135 175 171 241 171 313 0 33 -8 46 -77 114 -92 91 -136 113
-270 133 -98 15 -119 16 -160 8z"
      />
      <path
        d="M19723 10745 c-85 -15 -129 -39 -160 -83 -47 -71 -207 -798 -267
-1217 -40 -277 -65 -684 -47 -753 22 -79 98 -154 206 -204 40 -18 67 -23 140
-23 105 0 135 13 228 98 l59 54 9 109 c35 428 160 1098 251 1344 13 36 28 64
34 63 5 -1 133 -43 284 -94 254 -86 281 -93 355 -93 72 0 86 3 142 33 34 18
71 43 83 56 20 22 21 27 11 75 -17 78 -59 159 -110 210 -81 81 -535 321 -718
380 -181 58 -346 73 -500 45z"
      />
      <path
        d="M13300 10656 c-126 -35 -212 -76 -240 -115 -28 -40 -40 -98 -40 -191
0 -117 16 -202 52 -285 39 -88 42 -160 9 -228 -12 -25 -63 -101 -113 -169
-360 -486 -419 -582 -437 -714 -26 -180 70 -359 239 -444 78 -39 144 -57 305
-82 184 -28 511 -31 690 -5 145 21 394 81 450 109 88 44 151 130 174 236 19
90 15 120 -21 156 l-33 33 -140 6 c-77 4 -270 9 -430 13 -252 5 -508 23 -522
38 -9 9 70 141 177 296 128 186 169 254 214 352 67 148 103 332 91 473 -16
197 -64 344 -142 434 -33 39 -146 123 -162 120 -3 0 -58 -15 -121 -33z"
      />
      <path
        d="M31835 10660 c-130 -43 -647 -314 -743 -390 -60 -47 -108 -117 -129
-190 -13 -46 -14 -66 -5 -125 20 -124 28 -140 81 -174 26 -17 78 -38 115 -48
99 -24 164 -9 356 81 85 40 232 108 327 150 94 43 207 99 250 126 105 65 213
157 240 205 29 52 30 124 2 182 -28 57 -109 129 -189 167 -86 40 -212 47 -305
16z"
      />
      <path
        d="M17216 10514 c-201 -49 -328 -192 -327 -369 0 -132 55 -237 150 -288
76 -42 570 -146 1000 -212 l234 -36 116 32 c64 18 136 42 161 54 87 44 156
136 186 250 36 134 -7 250 -114 308 -48 26 -475 122 -1029 231 -247 49 -285
52 -377 30z"
      />
      <path
        d="M35577 10434 c-63 -19 -141 -94 -156 -149 -30 -109 -2 -222 91 -371
182 -291 594 -923 603 -924 6 0 66 -66 135 -147 126 -148 232 -243 333 -299
46 -26 62 -29 142 -29 80 0 97 3 155 31 120 57 170 122 170 222 0 73 -85 237
-187 363 -141 173 -264 342 -430 594 -91 138 -195 294 -231 349 -80 120 -214
264 -291 311 -102 62 -231 81 -334 49z"
      />
      <path
        d="M5185 10402 c-110 -40 -154 -64 -225 -123 -137 -114 -250 -298 -291
-475 -19 -81 -21 -113 -16 -232 16 -394 193 -613 533 -657 76 -10 126 14 180
87 23 32 46 67 49 78 4 13 -8 48 -33 99 -81 162 -79 330 6 537 30 71 50 112
146 298 80 156 77 207 -17 304 -63 65 -128 100 -199 107 -43 5 -69 0 -133 -23z"
      />
      <path
        d="M27543 10405 c-80 -22 -120 -41 -189 -91 -82 -58 -124 -126 -124
-201 0 -50 2 -55 61 -118 179 -191 535 -331 1043 -412 l172 -27 -8 -75 c-9
-79 -37 -209 -163 -751 -102 -438 -98 -415 -93 -481 5 -74 37 -115 128 -170
80 -49 128 -62 205 -57 88 5 163 46 256 137 84 83 130 166 205 366 76 204 216
826 253 1125 44 353 38 433 -38 509 -76 76 -236 106 -360 68 -123 -38 -351 -5
-741 106 -107 31 -233 63 -279 71 -102 19 -258 20 -328 1z"
      />
      <path
        d="M11580 10004 c-148 -40 -258 -159 -289 -314 -37 -187 -66 -583 -112
-1575 -15 -327 -9 -535 19 -597 19 -43 64 -86 107 -102 11 -5 65 -14 120 -22
655 -90 1333 -204 1595 -269 196 -49 402 -73 655 -77 124 -2 145 0 186 19 109
49 188 176 197 315 4 69 2 83 -22 132 -34 68 -101 129 -173 156 -31 11 -155
36 -277 55 -227 35 -331 56 -496 99 -226 60 -558 116 -925 156 -185 20 -341
43 -350 51 -8 9 24 527 66 1044 46 581 47 698 9 798 -49 129 -153 173 -310
131z"
      />
      <path
        d="M6615 9963 c-85 -19 -134 -52 -164 -112 -18 -35 -74 -255 -105 -411
-53 -264 -44 -551 25 -775 29 -98 100 -238 159 -317 158 -209 420 -397 640
-458 153 -43 418 -36 505 13 79 45 106 124 63 187 -30 46 -157 129 -340 225
-221 116 -275 183 -317 390 -47 234 -47 322 -2 658 11 78 22 160 24 182 7 62
-11 161 -37 213 -28 56 -105 131 -166 162 -74 38 -215 59 -285 43z"
      />
      <path
        d="M38480 9846 c-196 -125 -203 -139 -208 -403 -4 -217 2 -260 53 -374
32 -69 35 -83 29 -133 -13 -113 37 -180 194 -258 77 -38 95 -42 173 -46 100
-5 163 11 228 58 73 53 152 226 153 335 0 77 -17 116 -88 195 -71 80 -82 103
-109 236 -29 136 -61 205 -133 285 -47 52 -183 159 -201 159 -3 0 -44 -25 -91
-54z"
      />
      <path
        d="M39727 9801 c-86 -35 -115 -65 -169 -177 -42 -85 -52 -116 -56 -172
-6 -92 8 -131 78 -208 74 -82 96 -127 120 -254 32 -170 90 -265 223 -364 112
-84 101 -82 197 -20 47 29 98 62 113 71 31 20 73 83 87 134 5 18 10 118 11
223 2 207 -7 265 -57 355 -20 36 -24 58 -24 124 0 136 -37 184 -207 265 -71
34 -87 37 -173 39 -75 2 -105 -1 -143 -16z"
      />
      <path
        d="M29945 9756 c-22 -7 -51 -18 -64 -25 -47 -26 -114 -97 -137 -146 -45
-95 -30 -276 52 -624 71 -303 166 -519 306 -700 37 -47 86 -111 109 -141 96
-127 178 -197 368 -315 165 -102 211 -111 349 -70 66 20 126 77 136 128 9 46
-28 216 -61 282 -36 72 -134 220 -254 380 -179 240 -187 256 -279 550 -135
434 -192 569 -263 630 -70 60 -171 80 -262 51z"
      />
      <path
        d="M3023 9535 c-271 -91 -592 -239 -748 -344 -185 -124 -358 -280 -394
-354 -40 -83 -19 -158 71 -253 106 -112 175 -122 320 -46 51 27 137 77 190
110 247 156 541 312 759 402 166 68 208 89 221 114 68 126 -53 342 -219 392
-66 20 -86 18 -200 -21z"
      />
      <path
        d="M31861 9434 c-184 -49 -321 -205 -368 -419 -23 -101 -26 -465 -5
-560 61 -280 206 -571 359 -721 195 -191 421 -304 703 -350 207 -34 421 10
558 114 69 53 97 165 59 239 -49 95 -241 193 -475 243 -188 40 -308 120 -375
248 -49 93 -106 287 -119 407 -18 165 17 291 119 428 90 121 95 185 19 255
-118 109 -320 158 -475 116z"
      />
      <path
        d="M514 9410 c-189 -96 -327 -262 -386 -465 -20 -70 -23 -101 -22 -250
0 -165 1 -173 33 -270 151 -454 533 -783 1046 -901 102 -23 149 -28 313 -32
162 -4 200 -2 236 12 51 19 89 58 105 106 10 31 9 38 -17 75 -49 67 -122 110
-331 191 -108 42 -231 94 -273 114 -209 101 -350 283 -394 508 -30 155 -14
272 66 475 80 201 78 270 -11 359 -108 108 -247 138 -365 78z"
      />
      <path
        d="M21503 9370 c-55 -8 -177 -68 -250 -123 -48 -36 -215 -226 -322 -367
-97 -126 -200 -295 -366 -595 -209 -378 -228 -426 -294 -708 -29 -123 -37
-176 -31 -198 28 -98 145 -229 224 -250 69 -19 107 0 210 105 84 84 101 109
225 326 174 306 385 644 681 1089 131 198 243 376 250 396 7 21 10 63 8 96 -7
96 -89 195 -179 219 -60 15 -103 18 -156 10z"
      />
      <path
        d="M9855 9181 c-326 -18 -514 -55 -683 -137 -189 -91 -289 -253 -268
-433 19 -162 106 -221 296 -200 124 13 659 37 1000 45 330 7 372 15 462 80 94
69 166 212 154 308 -8 72 -58 167 -113 215 -109 97 -435 143 -848 122z"
      />
      <path
        d="M17646 9120 c-68 -22 -96 -77 -96 -189 0 -87 24 -133 113 -218 81
-77 146 -119 422 -270 225 -124 323 -188 426 -280 126 -112 204 -212 220 -281
10 -44 9 -45 -104 -472 -105 -394 -140 -554 -133 -604 16 -104 97 -204 204
-254 47 -22 71 -27 138 -27 72 0 87 4 156 38 84 41 151 106 186 179 35 76 148
410 181 535 37 141 48 176 87 258 38 80 38 165 0 290 -39 129 -85 219 -168
326 -147 191 -333 364 -526 490 -106 69 -156 95 -425 223 -89 42 -204 107
-265 149 -59 40 -127 82 -152 92 -51 20 -218 30 -264 15z"
      />
      <path
        d="M24743 8700 c-113 -10 -176 -30 -253 -81 -95 -64 -210 -235 -210
-314 0 -66 48 -122 150 -176 43 -22 59 -23 345 -32 206 -5 302 -12 307 -20 4
-7 74 -15 198 -21 165 -8 207 -7 308 8 64 10 135 25 157 35 111 49 202 243
171 366 -11 42 -72 100 -134 128 -62 28 -257 67 -454 92 -150 18 -460 27 -585
15z"
      />
      <path
        d="M27115 8623 c-432 -39 -777 -268 -1015 -674 -147 -250 -167 -509 -66
-843 94 -308 282 -533 561 -674 115 -57 355 -132 426 -132 115 0 220 142 206
278 -8 80 -48 150 -140 249 -148 159 -462 524 -474 551 -36 81 9 195 122 308
113 113 217 155 575 232 134 29 176 35 178 25 2 -9 15 -11 40 -8 20 3 101 12
179 20 220 24 274 48 325 141 77 143 55 293 -54 370 -99 71 -166 96 -333 123
-202 34 -397 46 -530 34z"
      />
      <path
        d="M4340 8595 c-25 -8 -68 -34 -97 -57 -43 -36 -787 -877 -962 -1088
-180 -216 -209 -343 -106 -458 24 -27 72 -64 107 -83 60 -32 68 -34 163 -34
89 0 106 3 157 28 125 61 163 101 591 609 445 529 497 595 534 676 52 110 28
225 -65 319 -85 85 -219 122 -322 88z"
      />
      <path
        d="M15891 8394 c-148 -30 -221 -97 -221 -201 0 -108 159 -260 315 -302
22 -5 175 -22 340 -36 165 -13 457 -38 649 -55 332 -29 352 -30 404 -14 84 24
136 64 168 129 24 51 26 60 15 96 -14 48 -127 172 -182 199 -130 67 -401 148
-584 176 -160 24 -799 30 -904 8z"
      />
      <path
        d="M35062 8400 c-95 -12 -165 -43 -207 -93 -47 -55 -58 -95 -52 -186 4
-69 14 -97 100 -296 169 -393 246 -641 347 -1130 50 -241 92 -372 167 -525 73
-148 151 -231 258 -275 61 -25 82 -29 170 -29 120 0 133 4 390 126 238 113
423 189 545 223 199 57 231 68 292 109 76 50 108 99 108 169 0 65 -19 104 -73
154 -141 130 -416 170 -647 95 -109 -36 -443 -132 -445 -129 -7 11 -316 1111
-331 1175 -8 36 -13 76 -10 88 3 12 38 50 77 84 78 70 109 120 109 178 0 79
-57 127 -225 193 -158 62 -399 91 -573 69z"
      />
      <path
        d="M39735 8206 c-496 -128 -777 -416 -905 -924 -43 -173 -54 -287 -47
-491 9 -261 68 -502 167 -676 108 -189 326 -356 464 -355 87 1 168 77 217 204
25 64 32 97 33 157 1 75 0 78 -67 210 -38 74 -86 161 -108 195 -74 115 -105
250 -89 390 23 208 70 326 164 419 97 97 219 150 451 199 208 44 313 137 355
315 25 105 -8 279 -63 328 -56 52 -81 58 -267 60 -170 3 -179 2 -305 -31z"
      />
      <path
        d="M2345 8102 c-72 -34 -131 -113 -331 -442 -386 -638 -640 -1065 -675
-1136 -67 -134 -53 -206 56 -279 85 -56 127 -68 240 -69 92 -1 105 1 167 31
37 17 87 50 111 72 78 72 339 478 490 761 123 232 249 551 287 728 21 98 21
103 5 153 -22 64 -74 118 -156 159 -78 40 -141 47 -194 22z"
      />
      <path
        d="M33800 8057 c-147 -41 -229 -164 -217 -323 5 -71 17 -109 120 -399
70 -194 92 -427 63 -670 -33 -286 -83 -443 -165 -525 -74 -73 -166 -88 -317
-50 -106 26 -393 169 -513 255 -139 100 -187 119 -296 120 -288 1 -415 -168
-305 -403 53 -114 181 -221 463 -388 445 -264 876 -270 1336 -20 108 59 126
75 216 194 271 360 357 891 229 1407 -14 55 -31 120 -39 145 -127 411 -146
456 -225 543 -93 102 -232 147 -350 114z"
      />
      <path
        d="M37820 8059 c-30 -5 -118 -25 -195 -44 -77 -19 -234 -57 -350 -85
-115 -28 -239 -60 -275 -72 -255 -83 -363 -229 -291 -393 49 -110 141 -184
256 -204 94 -17 365 2 512 34 138 31 487 119 690 175 264 73 363 148 363 276
0 65 -31 123 -94 181 -113 104 -238 144 -443 142 -65 -1 -143 -5 -173 -10z"
      />
      <path
        d="M14888 8037 c-97 -31 -116 -84 -88 -253 37 -224 20 -378 -64 -585
-83 -205 -290 -546 -388 -640 -143 -137 -329 -168 -617 -102 -105 24 -120 25
-175 14 -81 -17 -177 -62 -204 -96 -48 -60 -37 -123 40 -223 124 -161 349
-254 687 -284 255 -22 547 24 731 117 128 65 212 138 316 277 181 243 324 527
403 803 42 144 55 240 47 350 -19 309 -165 520 -423 610 -76 27 -199 33 -265
12z"
      />
      <path
        d="M22383 8030 c-159 -57 -303 -166 -441 -332 -252 -305 -414 -534 -480
-678 -52 -113 -111 -277 -118 -331 -18 -128 75 -231 248 -275 68 -18 123 -13
203 17 80 30 137 88 239 242 95 145 204 289 560 735 110 139 209 272 218 295
55 137 -18 281 -165 321 -91 25 -205 28 -264 6z"
      />
      <path
        d="M5775 7901 c-88 -22 -136 -66 -150 -138 -13 -68 56 -278 139 -428
116 -207 404 -485 563 -545 184 -69 467 8 499 135 14 57 -4 97 -74 160 -238
214 -354 356 -458 554 -45 86 -138 188 -201 220 -89 45 -229 63 -318 42z"
      />
      <path
        d="M29771 7685 c-91 -20 -164 -59 -208 -109 -22 -25 -82 -131 -137 -242
-92 -184 -105 -204 -170 -269 -81 -81 -180 -137 -326 -186 -86 -28 -103 -31
-190 -27 -158 7 -274 69 -467 250 -116 109 -173 142 -270 158 -147 24 -319
-52 -402 -177 -46 -69 -55 -113 -37 -177 23 -77 141 -248 238 -344 344 -338
716 -445 1160 -333 316 80 591 242 825 485 227 235 323 437 323 677 0 150 -40
247 -117 284 -49 24 -141 28 -222 10z"
      />
      <path
        d="M7830 7583 c-46 -7 -133 -58 -163 -95 -26 -32 -31 -49 -38 -130 -5
-51 -10 -178 -12 -283 l-2 -190 30 -60 c17 -32 43 -68 58 -80 79 -56 347 17
475 129 92 81 158 237 150 354 -16 218 -261 392 -498 355z"
      />
      <path
        d="M10385 7580 c-82 -13 -274 -66 -381 -105 -553 -204 -1018 -632 -1295
-1190 -70 -140 -85 -218 -68 -345 13 -90 36 -145 78 -184 25 -24 42 -30 88
-34 50 -3 66 0 118 28 128 67 199 139 405 409 250 329 486 509 808 618 95 32
119 36 212 37 185 2 282 -48 460 -231 58 -60 122 -117 144 -127 46 -22 124
-23 165 -2 65 34 162 160 202 264 26 69 36 193 20 259 -72 293 -261 506 -504
567 -120 31 -363 50 -452 36z"
      />
      <path
        d="M24215 7519 c-112 -12 -288 -46 -363 -70 -53 -18 -76 -20 -155 -14
-143 11 -243 -21 -434 -136 -105 -64 -182 -139 -219 -215 -25 -50 -30 -74 -32
-155 l-4 -95 38 -34 c51 -44 114 -71 184 -77 69 -7 238 25 390 72 203 63 322
82 892 140 365 37 405 46 519 106 116 62 179 135 179 209 0 71 -74 157 -176
205 -127 60 -546 93 -819 64z"
      />
      <path
        d="M16333 7211 c-82 -21 -149 -66 -253 -171 -247 -248 -511 -603 -705
-945 -208 -366 -273 -593 -205 -715 54 -96 183 -154 340 -153 103 0 170 23
250 86 65 51 98 100 288 418 180 303 370 602 417 657 20 23 57 56 81 73 l45
30 67 -42 c660 -419 785 -491 932 -540 141 -46 194 -49 265 -15 101 47 151
109 142 178 -7 50 -103 195 -181 273 -37 36 -198 154 -406 296 -844 578 -833
571 -965 575 -44 2 -95 -1 -112 -5z"
      />
      <path
        d="M31275 7076 c-138 -34 -207 -91 -334 -280 -153 -227 -307 -479 -329
-537 -44 -119 -52 -374 -17 -553 83 -430 367 -756 786 -904 126 -45 435 -112
513 -112 135 0 236 38 424 159 l112 73 0 81 c0 99 -17 133 -87 177 -65 41
-136 66 -240 84 -146 26 -343 84 -433 128 -67 33 -101 58 -170 127 -140 140
-186 254 -177 441 8 168 68 314 244 591 55 85 107 174 116 197 23 56 22 156
-2 207 -23 51 -89 111 -138 124 -50 14 -205 12 -268 -3z"
      />
      <path
        d="M4803 6801 c-53 -25 -76 -60 -75 -116 0 -57 26 -112 110 -236 82
-122 96 -149 129 -243 31 -88 41 -237 23 -328 -45 -231 -239 -515 -502 -735
-104 -86 -301 -217 -418 -277 -123 -63 -156 -88 -170 -130 -35 -106 71 -204
279 -257 176 -45 344 -8 645 143 341 170 584 415 760 763 103 204 141 352 134
527 -4 96 -10 129 -42 222 -87 258 -294 544 -449 619 -134 65 -335 88 -424 48z"
      />
      <path
        d="M24295 6661 c-127 -6 -158 -10 -203 -30 -92 -41 -206 -189 -255 -331
-28 -81 -27 -219 2 -340 113 -468 193 -681 336 -896 74 -110 120 -139 220
-138 100 1 175 32 246 103 79 79 102 145 96 266 -4 79 -12 110 -62 245 -44
121 -85 267 -85 305 0 13 139 28 325 35 491 20 648 39 745 91 234 124 307 467
139 651 l-34 37 -660 4 c-363 3 -727 2 -810 -2z"
      />
      <path
        d="M12504 6616 c-128 -31 -210 -109 -266 -251 -52 -134 -50 -228 13
-575 32 -176 55 -269 199 -813 50 -192 89 -352 85 -355 -4 -4 -156 -10 -338
-13 -380 -7 -431 -15 -593 -94 -106 -51 -160 -105 -196 -194 -20 -48 -23 -74
-23 -186 0 -140 12 -181 67 -232 52 -49 92 -55 438 -64 369 -10 546 -22 705
-50 61 -10 131 -19 157 -19 273 0 528 265 528 550 0 116 -93 655 -165 955 -18
77 -63 244 -99 370 -100 354 -136 531 -136 677 0 103 -23 165 -84 224 -81 79
-169 100 -292 70z"
      />
      <path
        d="M39990 6524 c-111 -16 -152 -33 -177 -70 -25 -38 -23 -126 4 -185 60
-134 185 -188 808 -354 176 -47 323 -85 328 -85 4 0 7 125 7 279 l0 278 -127
31 c-241 58 -620 123 -708 121 -22 -1 -83 -7 -135 -15z"
      />
      <path
        d="M6874 6402 c-55 -19 -90 -43 -127 -88 -38 -45 -53 -81 -92 -218 -45
-157 -65 -211 -204 -559 -61 -152 -111 -281 -111 -286 0 -4 27 -38 60 -74 133
-145 277 -166 469 -68 89 46 127 94 218 274 101 203 204 430 233 518 18 51 21
81 18 149 -5 106 -33 163 -124 250 -108 104 -231 141 -340 102z"
      />
      <path
        d="M3660 6391 c-66 -12 -111 -34 -147 -70 -51 -51 -57 -84 -44 -240 7
-80 11 -279 9 -481 -2 -307 0 -352 16 -405 26 -86 92 -149 204 -193 l84 -32
74 42 c138 79 227 168 264 266 33 87 54 276 54 492 0 215 -10 279 -62 373 -41
77 -162 200 -221 226 -56 23 -164 34 -231 22z"
      />
      <path
        d="M0 6103 l0 -278 88 -27 c92 -28 196 -89 221 -129 19 -32 26 -201 12
-299 -7 -46 -29 -146 -49 -224 -42 -164 -45 -239 -15 -307 28 -63 86 -125 147
-157 69 -36 188 -37 289 -3 107 36 166 98 248 257 77 149 103 214 124 304 20
86 19 252 -1 343 -58 256 -252 471 -571 632 -87 44 -377 145 -457 160 l-36 7
0 -279z"
      />
      <path
        d="M20295 6369 c-183 -27 -234 -209 -100 -357 36 -41 64 -60 335 -238
235 -153 350 -295 400 -490 63 -246 -30 -605 -227 -880 -70 -98 -83 -129 -83
-202 0 -75 37 -138 106 -178 53 -31 249 -94 293 -94 37 0 172 67 247 123 111
82 254 288 327 473 180 451 47 1022 -331 1416 -141 146 -284 250 -491 353
-159 79 -294 100 -476 74z"
      />
      <path
        d="M22572 6286 c-50 -33 -104 -78 -120 -98 -28 -35 -29 -40 -20 -90 15
-80 72 -296 123 -458 97 -313 155 -581 155 -720 0 -111 101 -249 209 -283 67
-21 183 -22 244 -1 60 20 132 84 162 143 50 100 71 244 55 374 -18 147 -173
667 -246 825 -88 193 -228 314 -410 356 l-61 14 -91 -62z"
      />
      <path
        d="M37418 6306 c-158 -56 -239 -287 -158 -446 31 -61 98 -122 176 -159
344 -164 902 -458 977 -514 37 -28 67 -53 67 -56 0 -15 -93 -118 -364 -404
-436 -462 -594 -651 -621 -740 -31 -104 -11 -276 40 -343 32 -41 70 -58 215
-95 l121 -30 76 58 c42 32 99 78 126 103 67 59 942 1021 1042 1145 96 119 133
248 121 427 -12 176 -20 206 -68 255 -61 62 -117 97 -370 232 -694 368 -855
447 -1085 532 -144 53 -219 62 -295 35z"
      />
      <path
        d="M29906 6255 c-117 -30 -243 -117 -333 -230 -135 -170 -300 -439 -444
-725 -105 -209 -129 -281 -129 -394 l0 -69 93 -94 92 -94 81 0 c204 0 285 78
662 642 180 270 278 405 381 528 73 88 92 128 93 199 1 41 -6 62 -28 100 -33
53 -107 113 -162 131 -58 20 -237 23 -306 6z"
      />
      <path
        d="M11263 6199 c-238 -31 -453 -148 -590 -321 -88 -110 -232 -333 -297
-460 -82 -157 -128 -274 -165 -419 -70 -270 -79 -492 -31 -769 34 -199 43
-231 75 -281 61 -96 214 -92 325 8 96 86 125 178 140 438 23 383 65 529 239
824 90 151 127 194 188 215 96 33 141 20 223 -64 62 -63 100 -78 163 -66 88
17 291 194 343 301 26 54 29 70 29 160 0 95 -2 104 -37 178 -45 93 -121 177
-192 212 -101 49 -251 65 -413 44z"
      />
      <path
        d="M2117 6046 c-52 -19 -67 -34 -93 -90 l-23 -50 29 -35 c50 -64 103
-108 192 -164 238 -149 533 -250 729 -249 195 1 255 171 104 294 -65 53 -221
137 -348 187 -213 85 -506 138 -590 107z"
      />
      <path
        d="M19745 5758 c-11 -6 -130 -120 -265 -253 -252 -250 -343 -323 -431
-346 -63 -16 -106 -2 -224 77 -182 120 -289 165 -396 165 -80 0 -125 -18 -169
-67 -63 -72 -62 -177 2 -278 95 -149 241 -278 402 -356 136 -66 237 -90 411
-97 130 -5 147 -4 239 21 245 65 459 209 607 409 107 143 163 288 163 422 0
57 -5 80 -28 128 -33 64 -118 150 -171 172 -40 18 -112 19 -140 3z"
      />
      <path
        d="M26470 5556 c-151 -34 -223 -119 -223 -266 0 -123 41 -235 116 -317
84 -91 168 -124 399 -157 436 -63 858 -138 958 -172 41 -13 91 -37 110 -52
l36 -27 -58 -325 c-95 -534 -111 -658 -105 -785 11 -206 40 -269 152 -331 82
-46 123 -45 174 2 49 43 130 167 166 254 25 60 186 771 280 1240 43 215 44
292 2 384 -48 105 -137 181 -278 240 -466 193 -1094 327 -1519 325 -85 0 -180
-6 -210 -13z"
      />
      <path
        d="M16571 5430 c-67 -14 -131 -49 -153 -84 -16 -26 -19 -44 -15 -116 4
-74 12 -105 61 -235 138 -363 175 -618 157 -1085 -4 -97 -2 -110 21 -162 32
-69 86 -112 163 -129 152 -34 373 89 462 256 56 107 67 168 67 375 0 224 -15
303 -105 567 -100 290 -168 416 -279 516 -81 73 -147 101 -249 103 -47 2 -106
-1 -130 -6z"
      />
      <path
        d="M35470 5430 c-154 -18 -271 -73 -336 -158 -32 -42 -74 -151 -74 -192
0 -23 78 -90 205 -175 106 -71 714 -377 825 -416 99 -34 261 -38 383 -10 345
80 435 288 219 508 -49 50 -90 75 -284 176 -263 137 -451 221 -558 248 -88 23
-271 32 -380 19z"
      />
      <path
        d="M40301 5361 c-205 -77 -358 -371 -401 -771 -13 -117 -11 -136 32
-270 61 -191 144 -326 280 -455 141 -134 265 -193 433 -202 104 -6 152 8 182
53 39 60 14 141 -100 337 -30 51 -67 126 -83 167 -25 67 -28 88 -28 200 -1
125 -1 126 57 315 50 161 60 205 64 292 6 97 4 105 -22 159 -57 116 -160 184
-289 191 -53 3 -85 -2 -125 -16z"
      />
      <path
        d="M13712 5262 c-130 -47 -159 -129 -103 -295 86 -255 225 -466 422
-643 145 -130 235 -180 415 -229 172 -47 312 -66 484 -66 285 0 508 68 742
224 156 104 246 199 288 306 l20 52 -31 61 c-47 92 -110 155 -177 174 -115 34
-253 3 -581 -130 -252 -103 -319 -120 -439 -114 -75 3 -100 9 -155 36 -80 39
-173 135 -236 242 -107 181 -233 319 -338 368 -77 36 -228 43 -311 14z"
      />
      <path
        d="M1957 5090 c-112 -43 -160 -117 -139 -210 21 -90 229 -615 298 -753
39 -76 158 -188 248 -233 91 -45 143 -51 238 -30 139 31 198 86 198 182 0 39
-17 103 -69 251 -73 211 -168 456 -209 537 -30 61 -118 155 -184 197 -120 77
-274 101 -381 59z"
      />
      <path
        d="M8727 5016 c-106 -29 -200 -126 -238 -245 -32 -102 -29 -241 6 -351
47 -147 103 -239 274 -452 246 -308 566 -618 749 -725 63 -37 174 -40 239 -5
167 88 256 357 186 564 -35 104 -82 165 -257 337 -213 210 -355 379 -508 605
-66 98 -139 196 -161 217 -65 64 -178 85 -290 55z"
      />
      <path
        d="M33905 4882 c-33 -8 -253 -113 -500 -237 -281 -142 -506 -248 -623
-294 -205 -81 -246 -103 -379 -203 -101 -76 -137 -115 -175 -193 -51 -100 -65
-272 -29 -351 58 -128 188 -133 517 -20 651 223 953 370 1242 605 126 102 173
155 214 239 28 56 32 75 32 146 0 131 -50 254 -123 303 -40 27 -88 28 -176 5z"
      />
      <path
        d="M30785 4579 c-160 -21 -373 -103 -516 -199 -153 -102 -330 -320 -460
-564 -45 -85 -52 -107 -53 -155 0 -104 63 -181 149 -181 80 0 335 174 534 364
147 141 185 163 283 172 176 16 316 -62 400 -222 80 -153 115 -330 123 -625 3
-101 8 -204 11 -228 l6 -44 66 6 c138 11 246 77 299 180 75 149 137 364 153
535 33 354 -112 725 -330 841 -180 96 -464 147 -665 120z"
      />
      <path
        d="M7190 4549 c-205 -34 -284 -190 -162 -322 71 -75 172 -142 263 -172
87 -29 161 -75 193 -118 42 -57 25 -218 -54 -502 -22 -77 -53 -174 -70 -215
-109 -261 -116 -288 -84 -355 35 -75 160 -122 298 -112 108 7 218 61 318 155
198 186 293 399 320 712 11 137 11 138 -16 241 -52 194 -118 311 -242 430
-118 112 -231 176 -395 223 -148 42 -259 53 -369 35z"
      />
      <path
        d="M671 4364 c-117 -31 -156 -78 -154 -184 1 -69 44 -295 94 -495 62
-249 170 -404 344 -493 44 -23 92 -49 107 -59 25 -16 28 -16 84 9 95 43 139
80 169 142 24 49 27 64 22 128 -7 110 -95 611 -117 672 -83 220 -320 342 -549
280z"
      />
      <path
        d="M22765 4247 c-84 -61 -182 -144 -245 -208 -127 -128 -161 -151 -331
-230 -424 -194 -669 -268 -956 -289 -195 -14 -240 -32 -269 -105 -19 -50 -14
-114 15 -180 49 -111 263 -239 440 -264 152 -21 531 47 821 148 539 186 842
436 961 794 50 148 31 238 -70 329 -45 40 -184 110 -216 107 -5 0 -73 -46
-150 -102z"
      />
      <path
        d="M26680 4310 c-351 -79 -744 -142 -1880 -300 -201 -27 -393 -59 -428
-70 -125 -40 -209 -116 -275 -249 -37 -75 -42 -92 -42 -155 0 -90 31 -158 116
-252 85 -93 139 -168 186 -260 52 -99 81 -184 198 -566 52 -168 118 -375 146
-460 63 -190 179 -460 217 -507 84 -104 224 -131 349 -69 132 66 181 183 170
407 -4 86 -16 150 -65 341 -91 358 -205 763 -275 985 -42 132 -60 202 -53 206
27 17 226 51 448 79 260 32 434 61 838 141 322 64 362 74 435 111 202 102 289
227 290 418 0 75 -3 90 -27 131 -18 30 -41 53 -65 65 -54 26 -176 28 -283 4z"
      />
      <path
        d="M5482 4269 c-207 -27 -340 -113 -415 -270 -51 -106 -68 -178 -74
-315 -6 -134 9 -236 52 -351 32 -83 58 -119 113 -154 103 -65 214 -60 324 15
l62 43 13 82 c7 45 24 115 37 154 37 110 113 267 129 267 8 0 75 -29 149 -64
205 -97 456 -203 523 -221 32 -8 79 -15 103 -15 l43 0 35 75 c66 137 57 183
-62 313 -168 184 -444 358 -650 411 -146 37 -260 46 -382 30z"
      />
      <path
        d="M19080 4121 c-106 -23 -180 -113 -180 -218 0 -91 64 -163 255 -288
364 -237 568 -488 690 -848 43 -125 60 -207 90 -452 20 -161 30 -210 51 -253
44 -89 115 -128 258 -138 l81 -6 101 103 c99 102 102 106 117 174 63 278 46
502 -58 774 -106 279 -277 533 -530 787 -195 196 -353 299 -533 345 -84 22
-283 33 -342 20z"
      />
      <path
        d="M34640 4101 c-47 -11 -148 -33 -225 -51 -77 -17 -175 -42 -217 -56
-231 -76 -417 -293 -522 -606 -79 -235 41 -407 296 -425 141 -10 207 36 267
189 49 126 98 207 166 274 160 157 319 160 471 8 138 -138 230 -299 362 -630
114 -284 132 -322 188 -381 136 -145 393 -216 532 -147 68 34 116 90 164 194
154 333 210 412 454 642 71 66 199 180 284 254 138 118 227 211 300 311 l23
32 -34 97 c-72 208 -138 259 -334 257 -170 -1 -294 -44 -486 -168 -155 -101
-251 -179 -430 -351 -89 -86 -165 -152 -169 -147 -4 4 -46 62 -93 128 -199
281 -367 432 -587 526 -157 67 -272 81 -410 50z"
      />
      <path
        d="M3235 3766 c-61 -15 -83 -26 -115 -61 -21 -22 -25 -38 -28 -98 -3
-60 6 -110 53 -302 31 -126 85 -360 121 -520 120 -539 181 -739 290 -961 125
-256 221 -314 493 -299 229 13 400 64 668 198 209 105 469 271 678 434 75 59
109 94 139 141 l39 63 -24 51 c-13 28 -46 71 -76 98 -67 61 -146 90 -269 97
-82 5 -101 2 -200 -27 -179 -53 -278 -95 -509 -218 -149 -79 -243 -122 -290
-134 -38 -10 -73 -18 -77 -18 -7 0 -76 207 -107 320 -11 41 -43 176 -71 300
-104 460 -156 642 -205 725 -96 164 -321 257 -510 211z"
      />
      <path
        d="M39695 3483 c-74 -10 -134 -34 -159 -64 -22 -26 -26 -41 -26 -92 0
-40 7 -75 20 -102 22 -46 249 -334 317 -402 82 -83 230 -133 390 -133 95 0
148 22 189 76 25 33 29 46 29 104 0 65 -1 67 -69 169 -138 204 -343 387 -485
431 -58 19 -131 23 -206 13z"
      />
      <path
        d="M11130 3445 c-84 -16 -228 -64 -305 -102 -213 -107 -515 -368 -697
-603 -234 -302 -400 -667 -478 -1050 -24 -116 -25 -190 -5 -283 27 -127 80
-187 166 -187 59 0 115 31 190 105 79 78 91 101 199 370 136 339 196 450 325
605 168 202 343 334 500 376 67 18 203 18 279 -2 82 -20 282 -118 392 -190
100 -66 237 -191 298 -274 105 -141 103 -140 165 -140 72 0 135 35 223 123
188 189 195 458 18 697 -120 161 -309 309 -545 425 -260 128 -500 170 -725
130z"
      />
      <path
        d="M17345 3368 c-185 -23 -249 -76 -249 -208 0 -72 14 -117 53 -169 38
-50 425 -234 662 -314 181 -61 261 -78 472 -97 99 -9 127 -9 178 5 79 20 149
58 212 115 106 95 115 214 25 308 -51 52 -120 72 -287 82 -203 13 -340 54
-579 174 -102 51 -162 70 -286 91 -50 8 -100 17 -111 19 -11 1 -51 -1 -90 -6z"
      />
      <path
        d="M38190 3365 c-104 -18 -190 -41 -274 -75 -137 -55 -335 -148 -379
-179 -107 -73 -179 -186 -262 -408 l-38 -101 26 -44 c14 -24 41 -59 61 -78 32
-30 44 -35 99 -38 82 -5 163 16 274 71 116 58 302 132 383 153 86 22 245 23
329 0 193 -50 330 -174 404 -364 50 -131 75 -276 97 -572 11 -148 28 -203 81
-264 52 -59 108 -80 194 -73 54 4 76 11 121 41 125 83 220 210 243 327 21 102
-25 757 -61 864 -57 171 -245 405 -436 542 -243 173 -569 248 -862 198z"
      />
      <path
        d="M14495 3355 c-128 -24 -229 -68 -370 -159 -209 -136 -340 -319 -381
-533 -20 -105 -17 -322 6 -546 l20 -189 42 -75 c68 -119 140 -176 298 -233
l74 -28 88 77 88 76 -8 95 c-4 52 -7 199 -7 325 1 223 2 233 28 320 69 223
140 321 391 539 74 64 96 102 96 167 0 93 -60 149 -180 168 -79 13 -96 13
-185 -4z"
      />
      <path
        d="M29205 3166 c-71 -18 -103 -37 -162 -98 -146 -151 -203 -325 -168
-514 45 -246 176 -674 247 -804 144 -265 204 -318 348 -308 124 9 228 78 287
190 26 48 28 62 28 158 0 103 -2 108 -58 258 -55 149 -93 300 -79 314 12 13
143 27 302 33 613 23 738 46 857 162 161 157 178 439 35 586 l-36 37 -776 -1
c-579 0 -788 -4 -825 -13z"
      />
      <path
        d="M2045 3032 c-32 -11 -66 -33 -97 -63 -83 -83 -77 -148 28 -322 119
-195 151 -271 315 -750 59 -174 111 -309 130 -338 57 -89 147 -150 250 -169
130 -25 257 39 316 158 24 48 28 70 28 137 0 44 3 82 6 85 10 9 -205 641 -245
720 -58 113 -183 296 -256 374 -79 84 -145 128 -243 161 -87 29 -159 31 -232
7z"
      />
      <path
        d="M15275 2899 c-228 -22 -331 -92 -342 -232 -5 -54 -2 -73 17 -111 38
-80 117 -122 325 -176 185 -47 328 -126 523 -286 152 -125 282 -284 403 -488
103 -175 193 -253 327 -281 129 -28 180 -15 260 65 62 62 79 128 63 238 -18
126 -51 200 -168 377 -133 202 -208 297 -319 409 -193 192 -403 329 -647 420
-156 58 -297 79 -442 65z"
      />
      <path
        d="M9065 2854 c-241 -27 -519 -111 -780 -235 -150 -72 -222 -161 -246
-308 -17 -103 8 -254 54 -321 56 -83 135 -99 257 -55 358 128 484 158 780 185
348 32 448 83 542 277 51 106 61 198 33 311 l-17 70 -71 21 c-40 12 -106 28
-147 36 -75 15 -330 27 -405 19z"
      />
      <path
        d="M22965 2853 c-138 -18 -216 -37 -291 -72 -196 -92 -294 -194 -294
-306 0 -49 38 -131 76 -166 45 -41 145 -106 207 -135 92 -42 276 -59 747 -70
302 -8 318 -6 452 55 180 81 258 252 189 409 -32 71 -120 151 -224 202 -149
73 -270 91 -602 89 -126 -1 -243 -4 -260 -6z"
      />
      <path
        d="M27625 2805 c-44 -30 -96 -73 -116 -96 l-36 -41 12 -67 c20 -103 51
-218 121 -448 107 -357 137 -490 150 -667 14 -193 87 -297 240 -342 158 -46
324 24 385 162 57 127 69 317 31 474 -32 131 -147 509 -192 628 -67 178 -147
288 -264 364 -56 36 -197 89 -234 87 -9 0 -53 -25 -97 -54z"
      />
      <path
        d="M32278 2694 c-34 -18 -90 -90 -125 -163 -28 -55 -28 -59 -27 -241 0
-202 12 -294 64 -493 51 -200 117 -300 233 -353 57 -26 72 -29 177 -29 128 0
172 14 252 77 l41 32 -6 116 c-21 384 -166 832 -310 955 -56 49 -194 115 -238
115 -19 -1 -46 -7 -61 -16z"
      />
      <path
        d="M667 2625 c-97 -36 -237 -174 -291 -287 -62 -130 -166 -611 -166
-767 0 -97 22 -160 76 -220 37 -42 69 -62 171 -111 l125 -60 52 31 c94 57 155
109 180 152 15 26 56 159 105 347 173 664 174 665 121 756 -35 61 -83 104
-164 144 -67 34 -143 39 -209 15z"
      />
      <path
        d="M6430 2507 c-115 -26 -212 -102 -264 -206 -104 -205 -157 -587 -110
-794 62 -271 299 -568 564 -706 91 -47 221 -94 327 -117 158 -35 443 -18 549
32 90 43 96 154 13 229 -49 44 -223 126 -454 215 -98 38 -204 108 -238 158
-86 123 -84 236 8 603 41 160 46 194 42 259 -9 154 -90 277 -207 313 -76 23
-165 29 -230 14z"
      />
      <path
        d="M25976 2299 c-106 -15 -256 -100 -298 -167 -30 -49 -22 -131 17 -172
41 -43 143 -107 190 -119 54 -13 338 -31 510 -31 119 0 149 3 203 22 149 52
218 178 161 295 -37 77 -164 153 -289 172 -77 13 -409 12 -494 0z"
      />
      <path
        d="M33741 2279 c-151 -27 -213 -63 -226 -132 -10 -52 24 -119 82 -158
53 -37 258 -131 398 -183 274 -103 358 -224 326 -469 -7 -48 -34 -178 -62
-290 -57 -228 -60 -293 -20 -399 57 -149 167 -215 345 -206 209 11 322 121
401 393 39 137 55 256 55 415 0 194 -30 306 -124 463 -131 219 -322 388 -545
482 -151 64 -258 86 -426 90 -82 2 -174 0 -204 -6z"
      />
      <path
        d="M1481 2218 c-51 -25 -92 -78 -122 -157 -19 -49 -22 -79 -24 -251 -3
-213 -19 -326 -61 -439 -33 -88 -84 -176 -132 -231 -104 -119 -257 -320 -279
-368 -33 -71 -41 -179 -19 -254 32 -110 146 -198 257 -198 54 0 153 35 213 75
27 18 118 107 203 197 l153 163 79 160 c98 197 144 310 179 440 23 89 27 118
27 260 0 138 -3 171 -23 240 -34 116 -77 190 -158 272 -110 111 -197 139 -293
91z"
      />
      <path
        d="M12867 1983 c-65 -22 -139 -90 -166 -152 -44 -103 -54 -167 -48 -311
4 -120 9 -151 46 -280 129 -449 209 -656 294 -758 85 -103 226 -186 337 -199
45 -5 52 -2 136 55 49 33 101 76 116 96 l28 35 -15 72 c-25 118 -53 224 -105
394 -120 395 -141 488 -164 707 -20 186 -69 273 -185 329 -62 30 -204 36 -274
12z"
      />
      <path
        d="M17681 1879 c-122 -24 -183 -64 -215 -141 -21 -49 -20 -141 1 -186
50 -108 374 -349 641 -478 238 -115 482 -184 652 -184 132 0 209 18 329 76
110 54 183 117 207 180 21 56 15 153 -16 250 l-23 71 -97 -17 c-53 -10 -128
-26 -168 -35 -113 -28 -219 -18 -317 29 -145 68 -334 177 -478 273 -78 53
-167 107 -197 121 -104 46 -219 61 -319 41z"
      />
      <path
        d="M11543 1795 c-99 -30 -185 -105 -227 -194 -17 -38 -21 -64 -20 -146
0 -96 2 -106 58 -255 47 -126 86 -267 86 -310 0 -17 -130 -31 -367 -40 -298
-12 -484 -26 -576 -45 -320 -66 -466 -446 -266 -695 l32 -40 783 0 c526 0 800
4 834 11 103 22 208 124 280 272 46 92 65 193 56 285 -9 84 -93 416 -152 600
-68 212 -206 465 -285 524 -65 48 -150 60 -236 33z"
      />
      <path
        d="M31229 1731 c-39 -13 -70 -31 -118 -70 -82 -66 -123 -135 -211 -358
-166 -419 -252 -565 -451 -763 -241 -242 -422 -315 -652 -264 -92 20 -295 117
-417 199 -130 87 -208 160 -301 280 -43 56 -86 108 -95 116 -30 25 -114 17
-169 -16 -69 -40 -175 -150 -208 -216 -44 -89 -62 -196 -48 -293 13 -95 65
-212 130 -293 l41 -53 1020 0 1019 0 65 73 c301 335 504 736 595 1174 44 213
12 399 -79 464 -30 22 -86 31 -121 20z"
      />
      <path
        d="M24342 1605 c-122 -84 -146 -201 -82 -408 36 -115 271 -462 424 -624
236 -251 585 -454 871 -508 177 -34 428 -1 518 68 53 40 80 107 75 188 -3 57
-8 74 -34 106 -45 56 -148 106 -294 142 -351 86 -698 372 -931 767 -86 145
-151 214 -246 261 -65 32 -86 37 -161 41 -86 3 -88 3 -140 -33z"
      />
      <path
        d="M22335 1616 c-184 -43 -353 -153 -385 -251 -17 -50 -8 -160 20 -248
17 -54 25 -67 38 -63 9 2 51 10 92 16 41 7 108 20 149 31 84 21 156 24 230 8
86 -18 393 -184 582 -315 191 -132 297 -170 444 -161 196 13 295 92 295 237 0
114 -65 191 -305 362 -238 170 -472 282 -750 359 -133 37 -311 48 -410 25z"
      />
      <path
        d="M39984 1611 c-45 -28 -131 -160 -141 -218 -14 -83 -8 -360 11 -463
49 -278 108 -434 196 -513 71 -64 129 -82 265 -82 122 0 169 14 246 76 38 30
39 32 39 97 -2 348 -152 846 -297 989 -62 61 -200 133 -254 133 -19 0 -48 -9
-65 -19z"
      />
      <path
        d="M38267 1556 c-136 -51 -198 -144 -202 -305 l-3 -93 100 -302 c97
-294 145 -409 223 -531 73 -113 166 -234 216 -279 l51 -46 253 0 253 0 16 34
c33 70 20 126 -61 260 -121 199 -164 300 -313 736 -54 157 -109 308 -123 335
-32 64 -107 135 -177 171 -67 33 -173 42 -233 20z"
      />
      <path
        d="M8344 1526 c-43 -14 -121 -63 -144 -91 -12 -14 -12 -40 -2 -161 23
-276 90 -538 191 -748 58 -120 121 -185 231 -241 130 -66 179 -57 254 48 72
101 78 123 83 287 6 171 -10 315 -57 506 -54 220 -121 328 -238 382 -49 23
-73 27 -167 29 -71 2 -124 -2 -151 -11z"
      />
      <path
        d="M36940 1424 c-302 -37 -676 -212 -1106 -519 -193 -138 -247 -186
-290 -256 l-37 -62 24 -46 c58 -110 150 -169 305 -193 93 -15 160 -3 341 57
140 47 183 66 473 217 195 101 293 135 311 107 28 -45 109 -326 159 -554 17
-77 33 -148 36 -157 5 -17 30 -18 350 -18 189 0 344 3 344 6 0 18 -98 447
-135 589 -96 370 -225 664 -331 751 -78 65 -123 78 -274 80 -74 1 -151 0 -170
-2z"
      />
      <path
        d="M26807 1283 l-88 -77 6 -50 c16 -149 25 -418 16 -501 -25 -243 -104
-416 -264 -582 l-70 -73 406 0 406 0 26 44 c38 65 75 162 92 241 21 96 18 335
-5 557 -16 152 -23 184 -47 230 -75 145 -151 207 -319 264 -38 13 -70 24 -70
24 -1 0 -41 -35 -89 -77z"
      />
      <path
        d="M4410 1288 c-314 -13 -403 -40 -507 -151 -101 -108 -154 -253 -132
-357 21 -97 91 -158 204 -179 28 -5 345 -11 705 -13 739 -3 730 -4 812 70 66
59 88 118 88 234 0 279 -148 365 -680 398 -182 11 -208 11 -490 -2z"
      />
      <path
        d="M14633 1206 c-80 -30 -128 -66 -155 -119 -26 -50 -28 -128 -5 -173
42 -81 170 -156 300 -175 57 -8 80 -16 83 -28 21 -69 144 -369 252 -616 l39
-90 970 -3 971 -2 60 64 c114 122 151 232 158 476 l5 165 -49 37 c-92 70 -135
74 -239 24 -57 -28 -98 -60 -202 -159 -71 -69 -160 -150 -198 -180 -260 -210
-566 -230 -798 -54 -68 52 -114 104 -178 203 -48 75 -137 246 -137 265 0 6 13
26 30 45 39 45 47 93 25 150 -13 34 -30 54 -78 88 -82 58 -94 63 -174 82 -51
12 -136 17 -343 20 -268 3 -277 3 -337 -20z"
      />
      <path
        d="M32795 1040 c-22 -5 -88 -27 -147 -50 -220 -85 -411 -128 -687 -155
-226 -23 -279 -32 -348 -65 -90 -41 -155 -111 -206 -218 -35 -74 -41 -99 -44
-167 -3 -56 1 -99 14 -145 l18 -64 74 -23 c170 -51 428 -69 620 -43 238 33
655 176 800 275 56 39 115 114 137 175 37 105 25 281 -27 387 -36 75 -115 111
-204 93z"
      />
      <path
        d="M20659 935 c-51 -52 -97 -101 -101 -109 -5 -9 -18 -59 -30 -113 -18
-79 -22 -130 -22 -263 0 -153 2 -174 31 -280 17 -63 36 -127 42 -142 l11 -28
360 0 361 0 -15 28 c-27 53 -88 238 -106 327 -11 50 -28 165 -40 257 -11 92
-25 190 -31 218 -11 53 -55 119 -99 151 -35 26 -133 49 -206 49 l-62 0 -93
-95z"
      />
      <path
        d="M6315 761 c-94 -44 -238 -246 -280 -395 -10 -32 -27 -128 -39 -213
l-23 -153 323 0 c299 0 323 1 328 18 2 9 7 134 11 277 7 289 4 315 -53 398
-57 82 -173 111 -267 68z"
      />
      <path
        d="M3070 369 c-185 -28 -227 -49 -341 -166 -85 -88 -169 -185 -169 -197
0 -3 185 -6 410 -6 469 0 425 -10 425 100 0 84 -17 113 -122 207 -90 80 -90
80 -203 62z"
      />
      <path
        d="M22587 361 c-61 -20 -84 -33 -160 -90 -83 -62 -123 -158 -97 -235
l12 -36 771 0 c509 0 768 3 761 10 -5 5 -93 49 -194 96 -404 190 -628 255
-920 269 -97 4 -124 2 -173 -14z"
      />
      <path
        d="M7382 279 c-100 -17 -161 -67 -188 -154 l-18 -55 34 -35 34 -35 435
0 434 0 -18 20 c-23 25 -226 142 -355 204 -129 62 -224 77 -358 55z"
      />
      <path
        d="M33401 180 c-73 -27 -148 -73 -204 -127 -26 -25 -47 -47 -47 -49 0
-2 151 -4 335 -4 l335 0 0 30 c0 69 -56 127 -149 154 -76 22 -202 20 -270 -4z"
      />
    </g>
  </svg>
)

DoodleSticks.defaultProps = {
  fill: '#ffffff',
  opacity: 1,
  radialGradient: null,
}

DoodleSticks.propTypes = {
  fill: PropTypes.string,
  opacity: PropTypes.number,
  radialGradient: PropTypes.string,
}

export default DoodleSticks
