import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * LinkEmptyIcon
 *
 */
const LinkEmptyIcon = ({ className }) => (
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M 2,8 V 5 C 2,4.20435 2.31607,3.44129 2.87868,2.87868 3.44129,2.31607 4.20435,2 5,2 5.79565,2 6.55871,2.31607 7.12132,2.87868 7.68393,3.44129 8,4.20435 8,5 V 8 C 8,8.26522 8.10536,8.51957 8.29289,8.70711 8.48043,8.89464 8.73478,9 9,9 9.26522,9 9.51957,8.89464 9.70711,8.70711 9.89464,8.51957 10,8.26522 10,8 V 5 C 10,3.67392 9.47322,2.40215 8.53553,1.46447 7.59785,0.526784 6.32608,-1.60592e-7 5,-2.18557e-7 3.67392,-2.76522e-7 2.40215,0.526784 1.46447,1.46447 0.526784,2.40215 -1.60592e-7,3.67392 -2.18557e-7,5 L -3.49691e-7,8 C -3.61284e-7,8.26522 0.105356,8.51957 0.292892,8.70711 0.480428,8.89464 0.734783,9 1,9 1.26522,9 1.51957,8.89464 1.70711,8.70711 1.89464,8.51957 2,8.26522 2,8 Z m 8,7 V 12 C 10,11.7348 9.89464,11.4804 9.70711,11.2929 9.51957,11.1054 9.26522,11 9,11 8.73478,11 8.48043,11.1054 8.29289,11.2929 8.10536,11.4804 8,11.7348 8,12 v 3 C 8,15.7956 7.68393,16.5587 7.12132,17.1213 6.55871,17.6839 5.79565,18 5,18 4.20435,18 3.44129,17.6839 2.87868,17.1213 2.31607,16.5587 2,15.7956 2,15 V 12 C 2,11.7348 1.89464,11.4804 1.70711,11.2929 1.51957,11.1054 1.26522,11 1,11 0.734783,11 0.480428,11.1054 0.292892,11.2929 0.105356,11.4804 -5.12944e-7,11.7348 -5.24537e-7,12 l -1.31134e-7,3 C -7.13636e-7,16.3261 0.526783,17.5979 1.46447,18.5355 2.40215,19.4732 3.67392,20 5,20 6.32608,20 7.59785,19.4732 8.53553,18.5355 9.47322,17.5979 10,16.3261 10,15 Z"
      fill="inherit"
    />
  </svg>
)

LinkEmptyIcon.defaultProps = {
  className: null,
}

LinkEmptyIcon.propTypes = {
  className: PropTypes.string,
}

export default LinkEmptyIcon
