// Pages
import { AdminDashboard } from '../AdminDashboard'
import { AdminOrganizations } from '../AdminOrganizations'
import { AdminReports } from '../AdminReports'
import { AdminSettings } from '../AdminSettings'
import { AdminUsers } from '../AdminUsers'
import { EventApiConfig } from '../EventApiConfig'
import { EventAttendees } from '../EventAttendees'
import { EventCheckIn } from '../EventCheckIn'
import { EventBadgeBuilder } from '../EventBadgeBuilder'
import { EventEmails } from '../EventEmails'
import { EventExhibitorDetail } from '../EventExhibitorDetail'
import { EventExhibitors } from '../EventExhibitors'
import { EventKiosk } from '../EventKiosk'
import { EventLeads } from '../EventLeads'
import { EventMaterials } from '../EventMaterials'
import { EventQualifiers } from '../EventQualifiers'
import { ExhibitorEventHome } from '../ExhibitorEventHome'
import { ExhibitorEventLicenses } from '../ExhibitorEventLicenses'
import { ExhibitorEventReports } from '../ExhibitorEventReports'
import { ExhibitorReports } from '../ExhibitorReports'
import { Login } from '../Login'
import { MultiFactorAuthentication } from '../MultiFactorAuthentication'
import { OrganizerEventHome } from '../OrganizerEventHome'
import { OrganizerEventLicenses } from '../OrganizerEventLicenses'
import { OrganizerEventReports } from '../OrganizerEventReports'
import { OrganizationDashboard } from '../OrganizationDashboard'
import { OrganizerReports } from '../OrganizerReports'
import { OrganizationUsers } from '../OrganizationUsers'
import { PasswordReset } from '../PasswordReset'
import { PasswordResetRequest } from '../PasswordResetRequest'
import { UserProfile } from '../UserProfile'

const routes = [
  // ----------------------------- Auth Routes ----------------------------- //
  {
    path: '/',
    page: Login,
    type: 'public',
  },
  {
    path: '/login',
    page: Login,
    type: 'public',
  },
  {
    path: '/verification',
    page: MultiFactorAuthentication,
    type: 'public',
  },
  {
    path: '/password-reset',
    page: PasswordReset,
    type: 'public',
  },
  {
    path: '/set-password',
    page: PasswordReset,
    type: 'public',
  },
  {
    path: '/password-reset-request',
    page: PasswordResetRequest,
    type: 'public',
  },
  // ----------------------------- General Routes ----------------------------- //
  {
    path: '/profile',
    page: UserProfile,
    type: 'private',
  },
  // ----------------------------- Admin Routes ----------------------------- //
  {
    path: '/dashboard',
    page: AdminDashboard,
    type: 'private',
  },
  {
    path: '/users',
    page: AdminUsers,
    type: 'private',
  },
  {
    path: '/organizations',
    page: AdminOrganizations,
    type: 'private',
  },
  {
    path: '/reports',
    page: AdminReports,
    type: 'private',
  },
  {
    path: '/settings',
    page: AdminSettings,
    type: 'private',
  },
  // ----------------------------- Organization Routes ----------------------------- //
  {
    path: '/organization/:orgId/dashboard',
    page: OrganizationDashboard,
    type: 'private',
  },
  {
    path: '/organization/:orgId/users',
    page: OrganizationUsers,
    type: 'private',
  },
  {
    path: '/organization/:orgId/reports',
    page: OrganizerReports,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/home',
    page: OrganizerEventHome,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/reports',
    page: OrganizerEventReports,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/emails',
    page: EventEmails,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/data-management/attendees',
    page: EventAttendees,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/data-management/api-config',
    page: EventApiConfig,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/lead-retrieval/exhibitors',
    page: EventExhibitors,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/lead-retrieval/exhibitors/:eventExhibitorId',
    page: EventExhibitorDetail,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/lead-retrieval/licenses',
    page: OrganizerEventLicenses,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/check-in/settings',
    page: EventCheckIn,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/kiosk',
    page: EventKiosk,
    type: 'private',
  },
  {
    path: '/organization/:orgId/event/:eventId/check-in/badge-builder',
    page: EventBadgeBuilder,
    type: 'private',
  },
  // ----------------------------- Exhibitor Routes ----------------------------- //
  {
    path: '/exhibitor/:orgId/dashboard',
    page: OrganizationDashboard,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/users',
    page: OrganizationUsers,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/reports',
    page: ExhibitorReports,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/event/:eventId/home',
    page: ExhibitorEventHome,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/event/:eventId/leads',
    page: EventLeads,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/event/:eventId/app-setup/materials',
    page: EventMaterials,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/event/:eventId/app-setup/qualifiers',
    page: EventQualifiers,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/event/:eventId/licenses',
    page: ExhibitorEventLicenses,
    type: 'private',
  },
  {
    path: '/exhibitor/:orgId/event/:eventId/reports',
    page: ExhibitorEventReports,
    type: 'private',
  },
]

export default routes
