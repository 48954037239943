import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Email from '../../assets/images/email.svg'
import Link from '../../assets/images/link.svg'
import PhoneNumber from '../../assets/images/phone_number.svg'
import User from '../../assets/images/user.svg'

// Components
import { Modal } from '../Modal'
import { TextInput } from '../TextInput'
import { Toggle } from '../Toggle'

const DEFAULT = {
  firstName: '',
  lastName: '',
  email: '',
}

const AddUserModal = ({
  endOfFormContent,
  loadingUser,
  onSubmit,
  editUser,
  roles,
  setEditUser,
  showUserModal,
  setShowUserModal,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: { ...DEFAULT, [roles.key]: 'Admin' },
  })

  useEffect(() => {
    if (editUser) {
      reset({ ...editUser, [roles.key]: editUser[roles.key] })
    }
  }, [editUser])

  const renderUserRoles = () => {
    if (!roles.showRoleDescriptions) {
      return (
        <Controller
          name={roles.key}
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <Toggle
              className="mt-4 self-center"
              disabled={loadingUser}
              label="Admin"
              name={roles.key}
              onChange={(checked) => onChange(checked ? 'Admin' : 'Member')}
              checked={value === 'Admin'}
              ref={ref}
            />
          )}
        />
      )
    }

    return (
      <fieldset className="mt-2 pl-0.5 pt-1">
        <div className="flex-col space-y-2">
          <Controller
            name={roles.key}
            control={control}
            render={({ field: { onChange, value } }) =>
              roles.options.map((r) => (
                <div key={r.label} className="flex flex-row">
                  <input
                    disabled={r.disabled}
                    id={r.label}
                    name="notification-method"
                    type="radio"
                    className="mt-1.5 h-4 w-4 cursor-pointer border-gray-300 text-purple focus:ring-purple disabled:cursor-not-allowed disabled:opacity-75"
                    onChange={(e) => onChange(e.target.checked && r.label)}
                    checked={value === r.label}
                  />

                  <label
                    htmlFor={r.label}
                    className={mergeClassNames(
                      'ml-3 flex cursor-pointer flex-col items-start text-sm font-medium leading-6 text-gray-900',
                      r.disabled && 'cursor-not-allowed opacity-25',
                    )}
                  >
                    {r.label}

                    {r.description && (
                      <span
                        disabled={r.disabled}
                        className={mergeClassNames(
                          'cursor-pointer text-start text-sm font-normal italic text-gray-dark',
                          r.disabled && 'cursor-not-allowed opacity-25',
                        )}
                      >
                        {r.description}
                      </span>
                    )}
                  </label>
                </div>
              ))
            }
          />
        </div>
      </fieldset>
    )
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: () => {
            setTimeout(() => {
              reset(DEFAULT)
              setEditUser(false)
            }, 500)
          },
        },
        {
          type: 'submit',
          label: editUser ? 'Save' : 'Add User',
          onClick: handleSubmit(onSubmit),
        },
      ]}
      icon={<img src={editUser ? Edit : Add} alt={editUser ? 'Edit' : 'Add'} />}
      content={
        <div className="mt-3 flex flex-col text-center sm:mt-5">
          <div className="mb-1 flex flex-col sm:mb-4 sm:flex-row sm:space-x-2">
            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="User" className="ml-2 h-4" src={User} />}
              data-testid="firstName"
              disabled={loadingUser}
              error={errors.firstName && 'This field is required'}
              fullWidth
              id="firstName"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="firstName"
              nunito
              label="First Name"
              placeholder="First Name"
              {...register('firstName', { required: true })}
            />

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="User" className="ml-2 h-4" src={User} />}
              data-testid="lastName"
              disabled={loadingUser}
              error={errors.lastName && 'This field is required'}
              fullWidth
              id="lastName"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="lastName"
              nunito
              label="Last Name"
              placeholder="Last Name"
              {...register('lastName', { required: true })}
            />
          </div>

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
            data-testid="email"
            disabled={loadingUser}
            error={errors.email && 'This field is required'}
            fullWidth
            id="email"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="email"
            nunito
            label="Email"
            placeholder="Email"
            {...register('email', { required: true })}
          />

          {roles.allowRoleChange && renderUserRoles()}

          {editUser && (
            <div className="mt-4 space-y-4">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="PhoneNumber" className="ml-1.5 h-4" src={PhoneNumber} />}
                data-testid="phoneNumber"
                disabled
                fullWidth
                id="phoneNumber"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="phoneNumber"
                nunito
                label="Phone Number"
                placeholder="Phone Number"
                value={editUser.phoneNumber}
              />

              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="Multifactor" className="ml-1.5 h-4" src={Link} />}
                data-testid="mfaDevice"
                disabled
                fullWidth
                id="mfaDevice"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="mfaDevice"
                nunito
                label="Multifactor Authentication Settings"
                placeholder="Authentication"
                value={editUser.mfaDevice}
              />
            </div>
          )}

          {endOfFormContent && (
            <span className="mt-9 text-left text-xs text-gray">{endOfFormContent}</span>
          )}
        </div>
      }
      loading={loadingUser}
      onClose={() => {
        reset(DEFAULT)
        setEditUser(false)
        setShowUserModal(false)
      }}
      open={showUserModal}
      setOpen={setShowUserModal}
      title={editUser ? 'Edit' : 'Add a User'}
    />
  )
}

AddUserModal.defaultProps = {
  editUser: null,
  endOfFormContent: null,
  roles: null,
}

AddUserModal.propTypes = {
  editUser: PropTypes.object,
  endOfFormContent: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  roles: PropTypes.object,
  showUserModal: PropTypes.bool.isRequired,
  setEditUser: PropTypes.func.isRequired,
  setShowUserModal: PropTypes.func.isRequired,
}

export default AddUserModal
