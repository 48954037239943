import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.mjs'

const config = resolveConfig(tailwindConfig)
const {
  theme: { colors },
} = config

export const configureActiveBackgroundStyles = (event) => {
  if (!event) return 'bg-gray-200'

  if (event.status === 'Active') {
    return 'bg-status-green'
  }

  if (event.status === 'Closed') {
    return 'bg-error-light'
  }

  return 'bg-blue-200'
}

export const configureActiveTextStyle = (event) => {
  if (!event) return 'text-gray-600'

  if (event.status === 'Active') {
    return 'text-role-admin'
  }

  if (event.status === 'Closed') {
    return 'text-red'
  }

  return 'text-blue-dark'
}

export const CHART_COLORS = [
  colors.purple.DEFAULT,
  colors.teal.DEFAULT,
  colors.blue.DEFAULT,
  colors.purple[900],
  colors.teal[900],
  colors.blue[900],
  colors.purple.light,
  colors.teal.light,
  colors.blue.light,
  colors.purple.dark,
  colors.teal.dark,
  colors.blue.dark,
]

export default colors
