import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { CogIcon } from '../../components/CogIcon'
import { DownloadIcon } from '../../components/DownloadIcon'
import { StateContainer } from '../../components/StateContainer'

// Store
import { TaskStoreContext } from '../../stores/TaskStore'

// Utils & Service
import { toast } from '../../utils/helpers'
import { createExhibitorLeadExport } from '../../services/exhibitors.service'

/**
 *
 * ExhibitorReports
 *
 */
const ExhibitorReports = observer(() => {
  // Context
  const { setParent, setTask, setType } = useContext(TaskStoreContext)
  const { orgId } = useParams()

  // State
  const [loadingExport, setLoadingExport] = useState(false)

  const handleError = (message) => toast(message, 'error')

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="h-full w-full flex-col space-y-4 overflow-y-auto p-3">
          <div className="mb-4 flex flex-col">
            <div className="flex flex-row items-center space-x-1">
              <CogIcon className="w-4 fill-black" />
              <span className="text-md  font-bold">Reports</span>
            </div>

            <span className="text-xs">Download data as Excel files or PDFs.</span>
          </div>

          <div>
            <span className="text-md font-bold">Excel Files</span>

            <div className="flex flex-row items-center justify-between gap-6">
              <span className="text-sm font-semibold">All Events Summary Report</span>
              <Button
                className="text-xs"
                icon={<DownloadIcon className="h-5 stroke-white sm:h-6" />}
                label="Download"
                loading={loadingExport}
                onClick={async () => {
                  const task = await createExhibitorLeadExport(
                    orgId,
                    handleError,
                    setLoadingExport,
                  )

                  if (task) {
                    setType('Download')
                    setParent({
                      type: 'exhibitor-leads',
                      label: 'Leads Summary',
                      id: task.id,
                      exhibitorId: orgId,
                      fileName: `Lead_Summary`,
                    })
                    setTask(task)
                  }
                }}
              />
            </div>
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default ExhibitorReports
