// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/** Emails */

/**
 * Gets the global email templates using the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const getGlobalEmails = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the global email with the specified `emailId`.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateGlobalEmail = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const data = await axios.patch(`/global-default-email-templates/${payload.id}/`, payload)

    setLoading(false)
    setSuccess('Email updated successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Sends the email preview using the specified `templateId` and `payload`
 * @param {string} templateId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const sendEmailPreview = async (
  templateId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/global-default-email-templates/${templateId}/preview/`,
      payload,
    )

    setLoading(false)
    setSuccess('Preview sent successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** Fonts */

/**
 * Adds new Font.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const addGlobalFont = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post('/global-fonts/', payload)

    setLoading(false)
    setSuccess('Font created.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the Fonts.
 */
export const getGlobalFonts = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the Font.
 * @param {string} fontId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateGlobalFont = async (
  fontId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const data = await axios.patch(`/global-fonts/${fontId}/`, payload)

    setLoading(false)
    setSuccess('Font updated successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the Font.
 * @param {string} fontId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteGlobalFont = async (
  fontId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const data = await axios.delete(`/global-fonts/${fontId}/`)

    setLoading(false)
    setSuccess('Font deleted successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** Popups */

/**
 * Gets the global popups configured.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getGlobalPopups = async (setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get('/global-default-popups')

    setLoading(false)
    return data.results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the global popups configured.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateGlobalPopups = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/global-default-popups/${payload.id}/`, payload)

    setLoading(false)
    setSuccess('Popup updated successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** Kiosk Settings */

/**
 * Gets the global default kiosk settings.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getGlobalDefaultKioskSettings = async (
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get('/global-default-kiosk-settings/default/')

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the global default kiosk settings.
 * @param {string} adminPassword
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateGlobalDefaultKioskSettings = async (
  adminPassword,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch('/global-default-kiosk-settings/default/', {
      admin_password: adminPassword,
    })

    setLoading(false)
    setSuccess('Admin password updated successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** Stock Sizes */

/**
 * Gets the list of global paper stock sizes.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getGlobalStockSizes = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds a global paper stock size.
 * @param {object} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const addGlobalStockSize = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post('/global-paper-stock-size/', payload)

    setLoading(false)
    setSuccess('Paper stock size created.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates a global paper stock size.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateGlobalStockSize = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/global-paper-stock-size/${payload.id}/`, payload)

    setLoading(false)
    setSuccess('Paper stock size updated successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes a global paper stock size.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const deleteGlobalStockSize = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.delete(`/global-paper-stock-size/${id}/`)

    setLoading(false)
    setSuccess('Paper stock size deleted successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
