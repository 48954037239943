// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of badges.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getBadges = async (setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get('/badges/?expand=event&limit=99999')

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds a new badge for the specified `event`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` badge
 */
export const addBadge = async (eventId, payload, setError, setLoading, setSuccess) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${eventId}/badges/`, payload)

    setLoading(false)
    setSuccess('Badge created successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified badge for the specified `event`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` badge
 */
export const updateBadge = async (eventId, payload, setError, setLoading) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/events/${eventId}/badges/${payload.id}/`, payload)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds a new badge image for the specified `event`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` badge
 */
export const addBadgeImage = async (eventId, payload, setError, setLoading, setSuccess) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${eventId}/badge-images/`, payload)

    setLoading(false)
    setSuccess('Badge image created successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets a badge image using the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` badge
 */
export const getBadgeImage = async (url, setError, setLoading) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes a badge using the specified `eventId` and `badgeId`.
 * @param {string} eventId
 * @param {string} badgeId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteBadge = async (eventId, badgeId, setError, setLoading, setSuccess) => {
  setLoading(true)

  try {
    await axios.delete(`/events/${eventId}/badges/${badgeId}/`)

    setLoading(false)
    setSuccess('Badge deleted successfully!')
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
