/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import Datepicker from 'tailwind-datepicker-react'
import dayjs from 'dayjs'

// Images
import Calendar from '../../assets/images/calendar.svg'

/**
 *
 * DatePicker
 *
 */
const DatePicker = ({
  disabled,
  error,
  format,
  label,
  name,
  onChange,
  placeholder,
  position,
  show,
  setShow,
  value,
}) => {
  // Refs
  const datePickerRef = useRef()
  const inputRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!(inputRef?.current && datePickerRef?.current)) return
      if (
        !inputRef.current.contains(event.target) &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', (event) => handleClickOutside(event))

    return () => {
      document.removeEventListener('mousedown', (event) => handleClickOutside(event))
    }
  }, [datePickerRef, inputRef, setShow])

  return (
    <div className="flex w-full flex-col place-items-start">
      {label && (
        <label htmlFor={name} className="pb-1 font-nunito text-sm font-medium text-gray-700">
          {label}
        </label>
      )}

      <div className="relative w-full" ref={datePickerRef}>
        <Datepicker
          disabled={disabled}
          options={{
            autoHide: true,
            todayBtn: true,
            clearBtn: false,
            maxDate: new Date('2030-01-01'),
            minDate: new Date('1950-01-01'),
            theme: {
              background: 'bg-white',
              todayBtn: 'bg-purple border-purple hover:bg-purple-600 px-2 py-1.5',
              clearBtn: '',
              icons:
                'bg-white hover:bg-purple-600 text-purple hover:text-white sm:text-sm text-xs group px-2 py-1.5',
              text: 'font-nunito text-black hover:bg-purple-600 hover:text-white',
              disabledText: 'px-1 py-0 opacity-50 cursor-not-allowed',
              input: '',
              inputIcon: '',
              selected: 'text-white bg-teal hover:bg-teal-dark px-1 py-0',
            },
            icons: {
              prev: () => (
                <ArrowLeftIcon className="h-3 stroke-purple group-hover:stroke-white sm:h-4" />
              ),
              next: () => (
                <ArrowRightIcon className="h-3 stroke-purple group-hover:stroke-white sm:h-4" />
              ),
            },
            datepickerClassNames: position || 'top-12',
            defaultDate: new Date(),
            language: 'en',
            inputPlaceholderProp: 'Select a Date',
            inputIdProp: name,
          }}
          onChange={onChange}
          ref={datePickerRef}
          show={show}
          setShow={!disabled && setShow}
        >
          <div className="placeholder:text-md relative block w-full appearance-none rounded-2xl border border-gray-550 bg-white px-3 py-2.5 pl-9 pr-4 font-nunito text-sm text-gray-900 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:placeholder:text-base">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
              <img alt="Event" className="ml-1.5 h-5" src={Calendar} />
            </div>

            <input
              type="text"
              className="text-md w-full border-none p-0 focus:outline-none disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50 sm:text-base"
              disabled={disabled}
              placeholder={placeholder}
              value={value ? dayjs(value).format(format) : ''}
              onFocus={() => setShow(true)}
              readOnly
              ref={inputRef}
            />
          </div>
        </Datepicker>
      </div>

      {error && (
        <div className="mt-1 w-full bg-error-light px-2 py-1">
          <p className="text-sm font-medium text-error-dark" id={`error:${label}`}>
            {error}
          </p>
        </div>
      )}
    </div>
  )
}

DatePicker.defaultProps = {
  disabled: false,
  error: null,
  format: 'MMMM DD, YYYY',
  label: null,
  placeholder: 'Select a Date',
  position: null,
}

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  format: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  position: PropTypes.string,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default DatePicker
