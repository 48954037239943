import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Parser } from 'html-to-react'

// Components
import { Modal } from '../Modal'

// Utils
import { replaceMergeFields } from './helpers'

/**
 *
 * ErrorPopup
 *
 */
const ErrorPopup = ({ attendee, config, close, mode, override }) => {
  // Auto close after 7 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      close()
    }, [7000]) // 7 seconds

    return () => clearTimeout(timeout)
  }, [])

  const actions = [
    {
      type: 'cancel',
      label: 'Close',
      onClick: close,
    },
  ]

  if (mode === 'staff') {
    actions.push({
      background: 'bg-red',
      type: 'submit',
      label: 'Print',
      onClick: override,
    })
  }

  return (
    <Modal
      actions={actions}
      content={
        <div className="mt-4 flex flex-col items-center">
          <span className="text-center text-2xl font-bold">
            {replaceMergeFields(attendee, config.header)}
          </span>
          <p className="mt-4 text-center text-gray">
            {Parser().parse(replaceMergeFields(attendee, config.body))}
          </p>
        </div>
      }
      icon={<ExclamationTriangleIcon className="h-8 text-white" />}
      iconBackground="bg-red"
      open
    />
  )
}

ErrorPopup.defaultProps = {
  mode: 'attendee',
  override: () => {},
}

ErrorPopup.propTypes = {
  attendee: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  mode: PropTypes.string,
  override: PropTypes.func,
}

export { ErrorPopup }
