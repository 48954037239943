/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

/**
 *
 * Alert
 *
 */
const Alert = ({ className, message, onClose, title, type }) => {
  const configureBackground = () => {
    switch (type) {
      case 'success':
        return 'bg-success-light'
      case 'error':
        return 'bg-error-light'
      case 'warning':
        return 'bg-warning-light'
      case 'info':
        return 'bg-info-light'
      default:
        return null
    }
  }

  const configureTitleColor = () => {
    switch (type) {
      case 'success':
        return 'text-success'
      case 'error':
        return 'text-error-dark'
      case 'warning':
        return 'text-warning-dark'
      case 'info':
        return 'text-info-dark'
      default:
        return null
    }
  }

  const configureTextColor = () => {
    switch (type) {
      case 'success':
        return 'text-success'
      case 'error':
        return 'text-error'
      case 'warning':
        return 'text-warning'
      case 'info':
        return 'text-info'
      default:
        return null
    }
  }

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon size={48} strokeWidth={2} className="text-success" />
      case 'error':
        return <XCircleIcon size={48} strokeWidth={2} className="text-error-icon" />
      case 'warning':
        return <ExclamationTriangleIcon size={48} strokeWidth={2} className="text-warning-icon" />
      case 'info':
        return <InformationCircleIcon size={48} strokeWidth={2} className="text-info-icon" />
      default:
        return null
    }
  }

  return (
    <div className={mergeClassNames(`rounded-md ${configureBackground()} p-4`, className)}>
      <div className="relative flex">
        <svg className="h-10 w-10" viewBox="0 0 40 40">
          {renderIcon()}
        </svg>

        <div className="mx-3">
          {title && <h3 className={`text-sm font-medium ${configureTitleColor()}`}>{title}</h3>}
          {message && (
            <div className={`mt-2 text-sm ${configureTextColor()}`}>
              <p>{message}</p>
            </div>
          )}
        </div>

        <button
          className="absolute -right-2 -top-2 flex h-6 w-6 place-items-center justify-center"
          onClick={onClose}
          type="button"
        >
          <svg className="h-4 w-4" viewBox="0 0 40 40">
            <XMarkIcon size={48} strokeWidth={2} className={configureTextColor()} />
          </svg>
        </button>
      </div>
    </div>
  )
}

Alert.defaultProps = {
  className: null,
  title: null,
  type: 'success',
  onClose: null,
}

Alert.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default Alert
