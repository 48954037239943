/* eslint-disable import/no-cycle */
import { createContext } from 'react'
import { action, observable } from 'mobx'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  type: null,
  organization: null,
  organizationId: null,
  event: null,
  eventId: null,
}

const TYPES = {
  type: observable,
  organization: observable,
  organizationId: observable,
  event: observable,
  eventId: observable,
}

export class NavigationStore extends BaseStore {
  setType = action((type) => {
    this.type = type
  })

  setOrganization = action((organization) => {
    this.organization = organization
  })

  setOrganizationId = action((organizationId) => {
    // Clear out previous details
    this.organization = null
    this.event = null
    this.eventId = null

    this.organizationId = organizationId
  })

  setEvent = action((event) => {
    this.event = event
  })

  setEventId = action((eventId) => {
    // Clear out previous details
    this.event = null

    this.eventId = eventId
  })

  updateActiveEntity = action(async (type, id) => {
    this.type = type
    this.organizationId = id
  })

  resetEvent = action(() => {
    this.event = null
    this.eventId = null
  })

  resetNavigation = action(() => {
    this.type = null
    this.organization = null
    this.organizationId = null
    this.event = null
    this.eventId = null
  })
}

export const navigation = new NavigationStore(INITIAL_STATE, TYPES)
export const NavigationStoreContext = createContext(navigation)
