// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

// ---------------------------- General ----------------------------

/**
 * Gets the list of organizations the authenticated user has access to.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getOrganizations = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url || '/organizations/?limit=20')

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the organization from the specified `id`.
 * @param {string} id event details
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getOrganization = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/organizations/${id}/`)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds the specified organization from the `payload`.
 * @param {*} payload event details
 * @param {*} setError
 * @param {*} setLoading
 * @param {*} setSuccess
 * @returns `data` created organization
 */
export const addOrganization = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post('/organizations/', payload)

    setLoading(false)
    setSuccess(`${data.name} created.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified organization from the `payload`.
 * @param {*} payload organization details
 * @param {*} setError
 * @param {*} setLoading
 * @param {*} setSuccess
 * @returns `data` updated organization
 */
export const updateOrganization = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/organizations/${payload.id}/`, payload)

    setLoading(false)
    setSuccess(`${data.name} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the specified organization with `id`.
 * @param {string} id event details
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteOrganization = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/organizations/${id}/`)

    setLoading(false)
    setSuccess('Organization deleted.')
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Gets the organization kpis with the specified `id`.
 * @param {string} id event details
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getOrganizationKpis = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/organizations/${id}/kpis/`)

    setSuccess(data)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// ---------------------------- Users ----------------------------

/**
 * Gets the users from the organization using the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getOrganizationUsers = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds a new user role with the specified `payload`.
 * @param {object} payload user role details
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const addOrganizationUser = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/organizations/${payload.organization}/roles/`, payload)

    setLoading(false)
    setSuccess('User added to organization.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates a user role with the specified `payload`.
 * @param {object} payload user role details
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateOrganizationRole = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/organizations/${payload.organization}/roles/${payload.id}/?expand=user`,
      payload,
    )

    setLoading(false)
    setSuccess(`${data.user.firstName} ${data.user.lastName} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
