// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Get licenses.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getLicenses = async (url, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Update license with specified `licenseId`.
 * @param {string} eventId
 * @param {string} exhibitorId
 * @param {string} licenseId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateLicense = async (
  eventId,
  exhibitorId,
  licenseId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/exhibitors/${exhibitorId}/licenses/${licenseId}/?expand=assignee`,
      payload,
    )
    setLoading(false)
    setSuccess(
      data.assignee
        ? `${data.assignee.user.firstName} ${data.assignee.user.lastName} assigned to license ${data.licenseId}`
        : `License ${data.licenseId} has been unassigned.`,
    )
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Update license with specified `licenseId` and create new user with details from `payload`.
 * @param {string} eventId
 * @param {string} exhibitorId
 * @param {string} licenseId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateLicenseAndCreateUser = async (
  eventId,
  exhibitorId,
  licenseId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/exhibitors/${exhibitorId}/licenses/${licenseId}/?expand=assignee`,
      payload,
    )
    setLoading(false)
    setSuccess(
      `${data.assignee.user.firstName} ${data.assignee.user.lastName} assigned to license ${data.licenseId}.`,
    )
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
