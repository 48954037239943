import React from 'react'
import { getAngle } from 'konva'
import _ from 'lodash'

// Components
import { EditIcon } from '../../components/EditIcon'
import { LocationIcon } from '../../components/LocationIcon'
import { OrganizationIcon } from '../../components/OrganizationIcon'
import { SuitcaseIcon } from '../../components/SuitcaseIcon'
import { QRIcon } from '../../components/QRIcon'
import { UserIcon } from '../../components/UserIcon'
import qrCodeImage from './qr-code-placeholder.png'

export const IS_UUID = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

/**
 * Handles configuring a text element for a merge field.
 * @param {string} content Merge field label
 * @returns text element configuration
 */
const configureTextElement = (content, accessor) => ({
  attrs: {
    width: 200,
    height: 50,
    text: content,
    accessor,
    fontSize: 20,
    align: 'center',
  },
  className: 'Text',
})

export const PRESET_BLOCKS = [
  {
    name: 'Full Name',
    icon: <UserIcon className="h-5 stroke-white" />,
    element: configureTextElement('{{ First Name }} {{ Last Name }}', 'fullName'),
    className: 'Text',
    editable: false,
  },
  {
    name: 'Job Title',
    icon: <SuitcaseIcon className="h-5 stroke-white" />,
    element: configureTextElement('{{ Job Title }}', 'title'),
    className: 'Text',
    editable: false,
  },
  {
    name: 'Company',
    icon: <OrganizationIcon className="h-5 stroke-white" />,
    element: configureTextElement('{{ Company }}', 'companyName'),
    className: 'Text',
    editable: false,
  },
  {
    name: 'Location',
    icon: <LocationIcon className="h-5 stroke-white" />,
    element: configureTextElement('{{ Location }}', 'presetLocation'),
    className: 'Text',
    editable: false,
  },
  {
    name: 'QR Code',
    icon: <QRIcon className="w-[18px] fill-white" />,
    element: {
      attrs: {
        width: 130,
        height: 130,
        src: qrCodeImage,
        qrImage: true,
      },
      className: 'Image',
    },
    className: 'Image',
    editable: false,
  },
]

export const CUSTOM_BLOCKS = [
  {
    name: 'Text',
    icon: <EditIcon className="h-5 fill-white" />,
    element: configureTextElement('Enter text here', null),
    className: 'Text',
    editable: true,
  },
  {
    name: 'Merge Field',
    icon: <EditIcon className="h-5 fill-white" />,
    element: configureTextElement('', null),
    className: 'Text',
    editable: false,
  },
  {
    name: 'Rectangle',
    icon: <EditIcon className="h-5 fill-white" />,
    element: {
      attrs: {
        width: 100,
        height: 100,
      },
      className: 'Rect',
    },
    className: 'Rect',
    editable: false,
  },
  {
    name: 'Image',
    icon: <EditIcon className="h-5 fill-white" />,
    className: 'Image',
    editable: true,
  },
]

/**
 * Handles finding the location of an element within the config object.
 * @param {object} config
 * @param {string} id
 * @returns location object
 */
export const findElementLocation = (config, id) => {
  const location = {
    panelIndex: null,
    layerIndex: null,
    groupElementIndex: null,
    elementIndex: null,
  }

  // If no `id` is provided, return the location object as is
  if (!id) return location

  // Loop through panels and layers to find the matching element
  _.forEach(_.keys(config), (panel, panelIndex) => {
    _.forEach(config[panel].children, (layer, layerIndex) => {
      // Matching layer return early
      if (layer.attrs.id === id) {
        location.panelIndex = panelIndex
        location.layerIndex = layerIndex
        return
      }

      // Find the element within the layer
      const element = _.findIndex(layer.children, (child) => {
        if (child.attrs.id === id) return true
        if (child.children)
          return _.findIndex(child.children, (groupChild) => groupChild.attrs.id === id) !== -1
        return false
      })

      // If the element is found, update the location object
      if (element !== -1) {
        location.panelIndex = panelIndex
        location.layerIndex = layerIndex
        location.elementIndex = element

        // If the element found is a group, find the nested element index
        if (config[`${panelIndex + 1}`].children[layerIndex].children[element].children) {
          const elementIndex = _.findIndex(
            config[`${panelIndex + 1}`].children[layerIndex].children[element].children,
            (groupChild) => groupChild.attrs.id === id,
          )

          if (elementIndex !== -1) {
            location.groupElementIndex = elementIndex
          }
        }
      }
    })
  })

  return location
}

/**
 * Handles rotating a point around the origin.
 * @param {object} position
 * @param {number} rad
 * @returns updated x and y positioning
 */
const rotatePoint = ({ x, y }, rad) => {
  const rcos = Math.cos(rad)
  const rsin = Math.sin(rad)
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin }
}

/**
 * Rotates the specified `node` around its center by the specified `rotation` angle.
 * @param {object} node
 * @param {number} rotation
 * @returns updated x and y positioning
 */
export const rotateAroundCenter = (node, rotation) => {
  // current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
  const topLeft = { x: -node.width() / 2, y: -node.height() / 2 }
  const current = rotatePoint(topLeft, getAngle(node.rotation()))
  const rotated = rotatePoint(topLeft, getAngle(rotation))
  const dx = rotated.x - current.x
  const dy = rotated.y - current.y

  return {
    x: node.x() + dx,
    y: node.y() + dy,
  }
}

/**
 * Pulls all fonts used in the badge.
 * @param {string} defaultFont
 * @param {object} configObject
 * @returns list of fonts used in the badge
 */
export const getGlobalFontsFromBadge = (defaultFont, configObject) => {
  const customFonts = []
  const googleFonts = []

  const isCustomFont = IS_UUID.test(defaultFont)

  // If the default font is not a custom font, add it to the list of Google Fonts
  if (!isCustomFont && defaultFont) {
    googleFonts.push({ font: defaultFont, weights: ['regular', 'italic'] })
  } else if (defaultFont) {
    customFonts.push(defaultFont)
  }

  // Pull all nested fonts from the config
  _.forEach(configObject, (panel) => {
    _.forEach(panel.children, (layer) => {
      _.forEach(layer.children, (element) => {
        if (element.className === 'Text' && element.attrs.fontFamily) {
          if (element.attrs.fontId) {
            customFonts.push(element.attrs.fontId)
          } else {
            googleFonts.push({ font: element.attrs.fontFamily, weights: ['regular', 'italic'] })
          }
        } else if (element.className === 'Group') {
          _.forEach(element.children, (groupElement) => {
            if (groupElement.className === 'Text' && groupElement.attrs.fontFamily) {
              if (groupElement.attrs.fontId) {
                customFonts.push(groupElement.attrs.fontId)
              } else {
                googleFonts.push({
                  font: groupElement.attrs.fontFamily,
                  weights: ['regular', 'italic'],
                })
              }
            }
          })
        }
      })
    })
  })

  return [_.uniqBy(googleFonts, 'font'), _.uniq(customFonts)]
}
