// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of exhibitors.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getExhibitors = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets an exhibitor with the specified `id`.
 * @param {string} eventId
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getExhibitor = async (
  eventId,
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/events/${eventId}/exhibitors/${id}/?expand=tasklist_items,exhibitor`,
    )

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds the specified exhibitor from the `payload` to the event with the specified `id`.
 * @param {string} id
 * @param {object} payload exhibitor details
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` created exhibitor
 */
export const addExhibitor = async (
  id,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${id}/exhibitors/`, payload)

    setLoading(false)
    setSuccess(`${data.exhibitor} added.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the exhibitor with the specified `id`.
 * @param {string} eventId
 * @param {string} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateExhibitor = async (
  eventId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/exhibitors/${payload.id}/?expand=exhibitor,license_counts`,
      payload,
    )
    setLoading(false)
    setSuccess(`${data.exhibitor.name} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Delete the exhibitor with the specified `id`.
 * @param {string} eventId
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteExhibitor = async (
  eventId,
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/events/${eventId}/exhibitors/${id}/`)

    setLoading(false)
    setSuccess('Exhibitor deleted.')
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Get the status for the exhibitor import specified by `taskId`
 * @param {string} eventId
 * @param {string} taskId
 * @param {func} setError
 * @param {func} setLoading
 * @returns response
 */
export const uploadExhibitorList = async (eventId, file, setError, setLoading) => {
  setLoading(true)

  try {
    const response = await axios.post(`/events/${eventId}/exhibitor_imports/`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the status for the exhibitor import specified by `taskId`
 * @param {string} eventId
 * @param {string} taskId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns the task status
 */
export const getExhibitorImportStatus = async (eventId, taskId) => {
  try {
    return await axios.get(`/events/${eventId}/exhibitor_imports/${taskId}/`)
  } catch (err) {
    return null
  }
}

/**
 * Gets the event exhibitor KPIs using the specified `eventId`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getExhibitorsKPI = async (eventId, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/events/${eventId}/exhibitors/kpis/`)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the exhibitor KPIs using the specified `eventId` and `eventExhibitorId`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getExhibitorKPI = async (
  eventId,
  eventExhibitorId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/events/${eventId}/exhibitors/${eventExhibitorId}/kpis/`)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Create exhibitors export.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `export` object
 */
export const createExhibitorsExport = async (
  eventId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${eventId}/exhibitor_exports/`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get exhibitors export.
 * @param {string} eventId
 * @param {string} exportId
 * @returns `data` results
 */
export const getExhibitorsExportStatus = async (eventId, exportId) => {
  try {
    const data = await axios.get(`/events/${eventId}/exhibitor_exports/${exportId}/`)
    return data
  } catch (err) {
    return null
  }
}

/**
 * Create exhibitor lead export.
 * @param {string} exhibitorId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `export` object
 */
export const createExhibitorLeadExport = async (
  exhibitorId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/organizations/${exhibitorId}/leads/exports/`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get exhibitor lead export.
 * @param {string} exhibitorId
 * @param {string} exportId
 * @returns `data` results
 */
export const getExhibitorLeadExportStatus = async (exhibitorId, exportId) => {
  try {
    const data = await axios.get(`/organizations/${exhibitorId}/leads/exports/${exportId}/`)
    return data
  } catch (err) {
    return null
  }
}
