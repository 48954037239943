import _ from 'lodash'

const SKIP_KEYS = [
  'custom_data',
  'available_custom_fields',
  'attendee_categories',
  'check_ins_by_category',
  'check_ins_by_location',
]

/**
 * Convert keys from camelCase to lower_snake_case
 *
 * @param {*} data Data to be transformed
 */
const convertOutgoingData = (data) => {
  const formattedData = _.isArray(data) ? [] : {}

  _.forEach(data, (value, key) => {
    let formattedValue = value

    // If the value is also an object or array, recursively update keys
    // Manually override converter to skip `custom_data` key to preserve formatting of nested data
    if (
      (_.isPlainObject(formattedValue) || _.isArray(formattedValue)) &&
      !SKIP_KEYS.includes(key)
    ) {
      formattedValue = convertOutgoingData(formattedValue)
    }

    formattedData[_.snakeCase(key)] = formattedValue
  })
  return formattedData
}

export default convertOutgoingData
