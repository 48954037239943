import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { FilePond, registerPlugin } from 'react-filepond'
import S3Upload from 'react-s3-uploader/s3upload'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import axios from 'axios'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Utils & Service
import instance from '../../services/instance'
import { addFile } from '../../services/file.service'
import { getErrorMessage } from '../../utils/helpers'

// Styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
)

/**
 * FileUploader
 *
 * A list of props for `FilePond` can be found here: https://pqina.nl/filepond/docs/patterns/api/filepond-instance/#properties
 */
const FileUploader = ({
  allowResize,
  autoSave,
  dropZoneLabel = null,
  fileLoaded,
  generateThumbnail,
  handleUploadToServer,
  isPublic,
  onUploaded,
  setFileLoaded,
  showImagePreviewAfterUpload,
  signingUrl,
  type,
  whiteBackground,
  ...rest
}) => {
  // Ref
  const pondRef = useRef()

  /**
   * Need to define default in here because passing in null/undefined value for prop doesn't
   * work as expected (prop still passes through and "null" displays in drop zone)
   */
  const defaultDropZoneLabel =
    'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>'

  /**
   * Upload the file to the server.
   * @param {string} fileName
   * @param {object} fileToUpload
   * @param {object} abortUpload
   * @param {func} load
   */
  const uploadFile = async (fileName, fileToUpload, abortUpload, load) => {
    let uploadedFile = { uid: '1' }

    // Pass `fileName` to `handleUploadToServer` to be handled by the parent
    if (handleUploadToServer) {
      await handleUploadToServer(fileToUpload)
    }
    // Upload file to server
    else {
      uploadedFile = await addFile(fileToUpload, abortUpload.token)
    }

    // Pass `uploadedFile` to `onUploaded` to be handled by the parent
    if (onUploaded) {
      onUploaded(uploadedFile)
    }

    // Call `load` with the file to complete the request with FilePond
    load(uploadedFile)

    // Remove file from file pond
    if (pondRef.current && !showImagePreviewAfterUpload) {
      pondRef.current.removeFile()
    }
  }

  /**
   * Gets the upload `signedUrl` for the specified `file`
   * @param {object} file
   * @param {func} callback
   */
  const getSignedUrl = (file, callback, overrideName) => {
    const name = overrideName
      ? overrideName.replace(/[^\w\d_\-.]+/gi, '')
      : file.name.replace(/[^\w\d_\-.]+/gi, '')

    instance.post(`/signed_url`, { key: name }).then(({ data: { url, filePath } }) => {
      callback({
        signedUrl: url,
        fileName: name,
        filePath,
      })
    })
  }

  /**
   * Processes the file with AWS.
   * - Calls `progress`, `error` and load` for FilePond
   * @param {string} fieldName
   * @param {object} file
   * @param {object} metadata
   * @param {function} load
   * @param {function} error
   * @param {function} progress
   * @returns abortUpload function
   */
  const awsProcess = (fieldName, file, metadata, load, error, progress) => {
    const abortUpload = axios.CancelToken.source()

    // When we are uploading an image that can be resized, we need to override the name
    // of the thumbnail image to ensure it includes what we expect
    let overrideName = null
    if (fieldName !== 'filepond') overrideName = fieldName

    const upload = new S3Upload({
      contentDisposition: 'auto',
      files: [file],
      getSignedUrl: (f, callback) => getSignedUrl(f, callback, overrideName),
      onFinishS3Put: async (info) => {
        // Upload the file to the server
        uploadFile(info.fileName, info, abortUpload, load)
      },
      onProgress: (percent, status, f) => {
        const loaded = f.size * (percent / 100)
        progress(true, loaded, f.size)
      },
      onError: (err) => {
        error(err)
      },
      scrubFilename: (filename) => filename.replace(/[^\w\d_\-.]+/gi, ''),
      uploadRequestHeaders: {},
    })
    return upload.abortUpload || abortUpload.cancel
  }

  /**
   * Processes the file with GCP.
   * - Calls `progress`, `error` and `load` for FilePond
   * @param {string} fieldName
   * @param {object} file
   * @param {object} metadata
   * @param {function} load
   * @param {function} error
   * @param {function} progress
   * @returns abortUpload function
   */
  const gcpProcess = (fieldName, file, metadata, load, error, progress) => {
    const abortUpload = axios.CancelToken.source()

    // When we are uploading an image that can be resized, we need to override the name
    // of the thumbnail image to ensure it includes what we expect
    let overrideName = null
    if (fieldName !== 'filepond' && generateThumbnail) overrideName = fieldName

    const name = overrideName
      ? overrideName.replace(/[^\w\d_\-.]+/gi, '')
      : file.name.replace(/[^\w\d_\-.]+/gi, '')

    // Get the signed GCP url
    instance
      .get(
        `/gcp/sign/?objectName=${name.replaceAll(' ', '')}&contentType=${encodeURIComponent(
          file.type,
        )}${isPublic ? '&public=true' : ''}`,
        {
          cancelToken: abortUpload.token,
        },
      )
      .then(({ signedUrl, fileName }) => {
        // Upload the file to the signedURL
        axios
          .put(signedUrl, file, {
            cancelToken: abortUpload.token,
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: (progressEvent) =>
              progress(true, progressEvent.loaded, progressEvent.total),
          })
          .then(async () => {
            const fileUrl = `${
              isPublic ? import.meta.env.VITE_GCP_PUBLIC_URL : import.meta.env.VITE_GCP_URL
            }${fileName}`
            const fileToUpload = { name: fileName, url: fileUrl }

            // Upload the file to the server
            uploadFile(fileUrl, fileToUpload, abortUpload, load)
          })
          .catch((err) => {
            error(getErrorMessage(err))
          })
      })
      .catch((err) => {
        error(getErrorMessage(err))
      })

    return abortUpload.cancel
  }

  /**
   * Processes the file via `type
   * @param {string} fieldName
   * @param {object} file
   * @param {object} metadata
   * @param {function} load
   * @param {function} error
   * @param {function} progress
   * @returns abortUpload function
   */
  const uploadFileProcess = (fieldName, file, metadata, load, error, progress) => {
    let abortUpload

    if (type === 'aws') {
      abortUpload = awsProcess(fieldName, file, metadata, load, error, progress)
    } else if (type === 'gcp') {
      abortUpload = gcpProcess(fieldName, file, metadata, load, error, progress)
    } else {
      // Upload the file to the server
      uploadFile(null, { name: file.name, file }, abortUpload, load)
    }

    return abortUpload
  }

  return (
    <>
      <FilePond
        className={mergeClassNames(
          whiteBackground && `bg-override`,
          pondRef.current?.props.disabled && 'cursor-not-allowed',
        )}
        credits={false}
        allowImageTransform={allowResize}
        imageTransformVariantsIncludeOriginal
        imageTransformVariants={
          generateThumbnail && {
            thumb_small_: (transforms) => {
              const updatedTransforms = transforms
              updatedTransforms.resize = {
                size: {
                  width: 512,
                  height: 512,
                },
              }

              return updatedTransforms
            },
          }
        }
        instantUpload={autoSave}
        labelIdle={dropZoneLabel || defaultDropZoneLabel}
        onaddfile={(error) => {
          if (!error) {
            setFileLoaded(true)
          }
        }}
        onremovefile={(error) => {
          if (!error) {
            setFileLoaded(false)
          }
        }}
        ref={pondRef}
        server={{
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            let abortUpload = null
            if (_.isArray(file)) {
              _.forEach(file, (f) => {
                if (f.name !== null) {
                  pondRef.current.props.server.process(
                    `${f.name}${f.file.name}`,
                    f.file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort,
                  )
                }
              })
            } else {
              abortUpload = uploadFileProcess(
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort,
              )
            }

            return {
              abort: () => {
                // If `abortUpload` is not null, call it to abort the request
                if (abortUpload !== null && _.isFunction(abortUpload)) {
                  abortUpload()
                }

                // Abort with FilePond
                abort()
              },
            }
          },
        }}
        {...rest}
      />

      {fileLoaded && (
        <div className="mr-2 mt-[-15px] flex justify-end">
          <button
            type="button"
            onClick={() => {
              pondRef.current.removeFile()
              setFileLoaded(false)
            }}
          >
            <span className="text-xs">Cancel Upload</span>
          </button>
        </div>
      )}
    </>
  )
}

FileUploader.defaultProps = {
  allowResize: false,
  autoSave: false,
  generateThumbnail: false,
  handleUploadToServer: undefined,
  isPublic: false,
  onUploaded: undefined,
  setFileLoaded: () => {},
  showImagePreviewAfterUpload: false,
  signingUrl: null,
  type: 'aws',
  whiteBackground: false,
}

FileUploader.propTypes = {
  allowResize: PropTypes.bool,
  autoSave: PropTypes.bool,
  dropZoneLabel: PropTypes.string,
  fileLoaded: PropTypes.bool,
  generateThumbnail: PropTypes.bool,
  handleUploadToServer: PropTypes.func,
  isPublic: PropTypes.bool,
  onUploaded: PropTypes.func,
  setFileLoaded: PropTypes.func,
  showImagePreviewAfterUpload: PropTypes.bool,
  signingUrl: PropTypes.string,
  type: PropTypes.string,
  whiteBackground: PropTypes.bool,
}

export default FileUploader
