import React from 'react'
import PropTypes from 'prop-types'
import { renderToStaticMarkup } from 'react-dom/server'

// Components
import { DoodleSticks } from '../DoodleSticks'
import { TicketIcon } from '../TicketIcon'

// Styles
import baseColors from '../../utils/colors'

/**
 * DataTile
 *
 * - Displays data in a tile
 */
const DataTile = ({ color, icon, label, value }) => {
  const background = {
    blue: 'radial-gradient(94.91% 583.81% at 100.14% 100%, rgba(104, 162, 225, 0.6) 0%, rgba(255, 255, 255, 0.240625) 72.66%, rgba(255, 255, 255, 0) 100%)',
    green:
      'radial-gradient(96.3% 551.89% at 100.05% 100%, rgba(110, 229, 205, 0.6) 0%, rgba(255, 255, 255, 0.2875) 78.12%, rgba(255, 255, 255, 0) 100%)',
    purple:
      'radial-gradient(96.3% 551.89% at 100.05% 100%, rgba(140, 86, 246, 0.6) 0%, rgba(255, 255, 255, 0.2875) 78.12%, rgba(255, 255, 255, 0) 100%)',
    red: 'radial-gradient(96.3% 551.89% at 100.05% 100%, rgba(255, 186, 186, 0.6) 0%, rgba(255, 255, 255, 0.2875) 78.12%, rgba(255, 255, 255, 0) 100%)',
  }

  const borderColor = {
    blue: 'border-blue',
    green: 'border-green',
    purple: 'border-purple-700',
    red: 'border-pink',
  }

  const iconColor = {
    green: baseColors.teal.DEFAULT,
    purple: baseColors.purple.DEFAULT,
    red: '#F9BDBE',
  }

  const doodle = encodeURIComponent(
    renderToStaticMarkup(<DoodleSticks opacity={0.3} radialGradient={background[color]} />),
  )

  const backgroundColor = {
    blue: 'bg-blue',
    green: 'bg-teal',
    purple: 'bg-purple',
    red: 'bg-pink',
  }

  return (
    <div
      className={`flex w-full flex-row items-center space-x-4 rounded-md border-[1px] p-4 ${borderColor[color]} bg-white`}
      style={{
        backgroundImage: `url('data:image/svg+xml;utf8, ${doodle}')`,
        backgroundSize: 'cover',
      }}
    >
      <div>
        {icon ? (
          <div
            className={`${backgroundColor[color]} m-3 flex items-center justify-center rounded-md`}
            style={{ height: '50px', width: '50px', boxShadow: '3px 4px 11px 0px #00000040' }}
          >
            {icon}
          </div>
        ) : (
          <TicketIcon fill={iconColor[color]} />
        )}
      </div>

      <div className="flex flex-col">
        <span className="text-sm font-bold text-dark">{label}</span>
        <span className="text-3xl font-bold text-dark">{value}</span>
      </div>
    </div>
  )
}

DataTile.defaultProps = {
  color: 'green',
  icon: null,
}

DataTile.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

export default DataTile
