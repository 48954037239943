import React from 'react'
import PropTypes from 'prop-types'
import { Parser } from 'html-to-react'

// Components
import { ChatIcon } from '../ChatIcon'
import { Modal } from '../Modal'

// Utils
import { replaceMergeFields } from './helpers'

/**
 *
 * PrivacyPopup
 *
 */
const PrivacyPopup = ({ attendee, config, close }) => (
  <Modal
    actions={[
      {
        type: 'cancel',
        label: 'Opt-Out & Print',
        onClick: () => close(false),
        background: 'bg-red',
      },
      {
        type: 'submit',
        label: 'Agree & Print',
        onClick: () => close(true),
      },
    ]}
    content={
      <div className="mt-4 flex flex-col items-center">
        <span className="text-center text-2xl font-bold">
          {replaceMergeFields(attendee, config.header)}
        </span>
        <p className="mt-4 text-center text-gray">
          {Parser().parse(replaceMergeFields(attendee, config.body))}
        </p>
      </div>
    }
    icon={<ChatIcon className="h-6 fill-white" />}
    open
  />
)

PrivacyPopup.propTypes = {
  attendee: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
}

export { PrivacyPopup }
