import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Component
import { LinkIcon } from '../LinkIcon'
import { LinkEmptyIcon } from '../LinkEmptyIcon'
import { TextInput } from '../TextInput'

/**
 *
 * SizeControls
 *
 */
const SizeControls = ({ element, updateElement }) => {
  // State
  const [heightLocked, setHeightLocked] = useState(true)
  const [imageHeight, setImageHeight] = useState(element?.attrs.height)
  const [imageWidth, setImageWidth] = useState(element?.attrs.width)

  useEffect(() => {
    if (element.className === 'Image') {
      setHeightLocked(element?.attrs.lockRatio)
      setImageHeight(element?.attrs.height)
      setImageWidth(element?.attrs.width)
    }
  }, [element?.attrs])

  /**
   * Updates the width of the image element. If the height is locked, also updates the height using the aspect ratio.
   * @param {Event} e
   */
  const updateImageElementWidth = (e) => {
    if (heightLocked && (!e.target.value || e.target.value === '0')) {
      setImageWidth(element.attrs.width)
      return
    }

    if (heightLocked) {
      const aspectRatio = element.attrs.height / element.attrs.width
      updateElement({
        width: parseInt(e.target.value, 10),
        height: parseInt(e.target.value, 10) * aspectRatio,
      })
      return
    }

    updateElement({ width: parseInt(e.target.value, 10) })
  }

  /**
   * Updates the height of the image element.
   * @param {Event} e
   */
  const updateImageElementHeight = (e) => {
    updateElement({ height: parseInt(e.target.value, 10) })
  }

  return (
    <div className="space-y-1">
      <span className="text-sm font-bold">Size</span>

      <div className="mb-4 flex">
        <div className="flex gap-4">
          <div className="flex items-center gap-2">
            <label className="text-sm font-bold" htmlFor="width">
              W
            </label>

            <TextInput
              name="width"
              className="h-[45px] w-[80px] rounded-md border-gray-400 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              type="number"
              value={imageWidth && Math.round(imageWidth)}
              onBlur={updateImageElementWidth}
              onChange={(e) => setImageWidth(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  updateImageElementWidth(e)
                }
              }}
            />
          </div>

          <div className="flex items-center gap-2">
            <label className="text-sm font-bold" htmlFor="height">
              H
            </label>

            <TextInput
              name="height"
              disabled={heightLocked}
              className="h-[45px] w-[80px] rounded-md border-gray-400 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              type="number"
              value={imageHeight && Math.round(imageHeight)}
              onBlur={updateImageElementHeight}
              onChange={(e) => setImageHeight(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  updateImageElementHeight(e)
                }
              }}
            />
          </div>
        </div>

        <button
          className="ml-3"
          type="button"
          onClick={() => {
            setHeightLocked(!heightLocked)
            updateElement({ lockRatio: !heightLocked })
          }}
          aria-label={heightLocked ? 'Unlock proportions' : 'Lock proportions'}
          title={heightLocked ? 'Unlock proportions' : 'Lock proportions'}
        >
          {heightLocked ? (
            <LinkIcon className="fill-black" />
          ) : (
            <LinkEmptyIcon className="fill-black" />
          )}
        </button>
      </div>
    </div>
  )
}

SizeControls.propTypes = {
  element: PropTypes.object.isRequired,
  updateElement: PropTypes.func.isRequired,
}

export default SizeControls
