import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { RadioGroup } from '@headlessui/react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 *
 * RadioInput
 *
 */
const RadioInput = ({ horizontal, label, labelClassName, name, onChange, options, value }) => (
  <RadioGroup id={name} value={value} onChange={onChange}>
    <RadioGroup.Label className={labelClassName}>{label}</RadioGroup.Label>
    <div className={horizontal ? 'flex flex-row space-x-6' : ''}>
      {_.map(options, (o) => (
        <RadioGroup.Option key={o.id} value={o.id}>
          {({ checked }) => (
            <div className="flex items-center">
              <input
                disabled={o.disabled}
                id={o.id}
                name="notification-method"
                type="radio"
                defaultChecked={checked}
                className="h-4 w-4 border-gray-300 text-purple focus:ring-purple-600 disabled:opacity-50 disabled:hover:cursor-not-allowed"
              />

              <label
                htmlFor={o.id}
                className={mergeClassNames(
                  'ml-3 block text-sm font-medium leading-6 text-gray-900',
                  o.disabled && 'opacity-50 hover:cursor-not-allowed',
                )}
              >
                {o.label}
              </label>
            </div>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
)

RadioInput.defaultProps = {
  horizontal: false,
  name: 'radio-input',
}

RadioInput.propTypes = {
  horizontal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
}

export default RadioInput
