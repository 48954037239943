import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Text } from 'react-konva'
import _ from 'lodash'

// Utils
import { rotateAroundCenter } from '../../pages/EventBadgeBuilder/helpers'

/**
 *
 * BadgeText
 *
 */
const BadgeText = ({
  autoFontSize,
  data,
  draggable,
  editable,
  inGroup,
  onChange,
  panelRef,
  transformerId,
  visible,
}) => {
  // Ref
  const ref = useRef()

  /**
   * Fits the text to the height of the node.
   */
  const fitTextToHeight = () => {
    // Set the initial font size and set auto height to allow for resizing
    let fontSize = 1
    ref.current.height('auto')

    // Update the font size until the text fits within the maximum height, or the maximum font size is reached
    ref.current.fontSize(fontSize)
    while (ref.current.height() < data.attrs.height && fontSize < data.attrs.fontSize) {
      fontSize += 1
      ref.current.fontSize(fontSize)
    }

    // Use the last font size that fits within the height
    ref.current.fontSize(fontSize - 1)

    // Set the height back to the original size
    ref.current.height(data.attrs.height)
  }

  useEffect(() => {
    if (autoFontSize) {
      fitTextToHeight()
    }
  }, [data])

  return (
    <Text
      {...data.attrs}
      visible={visible}
      fontSize={parseInt(data.attrs.fontSize, 10) || 16}
      text={data.attrs.uppercase ? data.attrs.text.toUpperCase() : data.attrs.text}
      className="Text"
      draggable={inGroup ? false : draggable}
      fill={data.attrs.fill}
      fontFamily={data.attrs.fontFamily}
      height={data.attrs.height}
      id={data.attrs.id}
      inGroup={inGroup}
      onDblClick={() => {
        // Don't allow editing content
        if (!editable) return

        // Find transformer off of parent
        let parent = ref.current.getParent()
        if (inGroup) parent = parent.getParent()
        const transformer = parent.children.find((c) => c.attrs.id === transformerId)

        // Hide the node and remove from the transformer
        ref.current.hide()
        transformer.nodes(_.filter(transformer.nodes(), (n) => n.attrs.id !== data.attrs.id))

        // Get the position of the text node
        const textPosition = ref.current.absolutePosition()

        // Configure the position of the textarea
        const areaPosition = {
          x: panelRef.current.content.getBoundingClientRect().left + textPosition.x,
          y: panelRef.current.content.getBoundingClientRect().top + textPosition.y,
        }

        // Create a textarea element
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)

        // Match as many styles as we can from the text node
        textarea.value = ref.current.text()
        textarea.style.position = 'absolute'
        textarea.style.top = `${areaPosition.y}px`
        textarea.style.left = `${areaPosition.x}px`
        textarea.style.width = `${ref.current.width() - ref.current.padding() * 2}px`
        textarea.style.height = `${ref.current.height() - ref.current.padding() * 2 + 5}px`
        textarea.style.fontSize = `${ref.current.fontSize()}px`
        textarea.style.border = 'none'
        textarea.style.padding = '0px'
        textarea.style.margin = '0px'
        textarea.style.overflow = 'hidden'
        textarea.style.background = 'none'
        textarea.style.outline = 'none'
        textarea.style.resize = 'none'
        textarea.style.lineHeight = ref.current.lineHeight()
        textarea.style.fontFamily = ref.current.fontFamily()
        textarea.style.transformOrigin = 'left top'
        textarea.style.textAlign = ref.current.align()
        textarea.style.color = ref.current.fill()

        const rotation = ref.current.rotation()
        let transform = ''
        if (rotation) {
          transform += `rotateZ(${rotation}deg)`
        }

        let px = 0
        // Slightly adjust the position of the textarea for different browsers
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
        if (isFirefox) {
          px += 2 + Math.round(ref.current.fontSize() / 20)
        }
        transform += `translateY(-${px}px)`
        textarea.style.transform = transform

        // Reset height before adjusting the height to the text node
        textarea.style.height = 'auto'
        // Set the height of the textarea
        textarea.style.height = `${textarea.scrollHeight + 3}px`

        // Focus on the textarea
        textarea.focus()

        /**
         * Handles clicking outside of the textarea.
         * @param {object} e
         */
        const handleClickOutside = (e) => {
          if (e.target !== textarea) {
            onChange(data.attrs.id, { text: textarea.value })
            removeTextarea()
          }
        }

        /**
         * Removes the textarea from the DOM and shows the node.
         */
        const removeTextarea = () => {
          textarea.parentNode.removeChild(textarea)
          window.removeEventListener('click', handleClickOutside)

          ref.current.show()
        }

        /**
         * Handles setting the width of the textarea.
         * @param {number} newWidth
         */
        const setTextareaWidth = (newWidth) => {
          let updatedWidth = newWidth
          if (!updatedWidth) {
            // set width for placeholder
            updatedWidth = ref.current.placeholder.length * ref.current.fontSize()
          }
          // some extra fixes on different browsers
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
          // const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

          if (isSafari || isFirefox) {
            updatedWidth = Math.ceil(updatedWidth)
          }

          const isEdge = document.documentMode || /Edge/.test(navigator.userAgent)
          if (isEdge) {
            updatedWidth += 1
          }
          textarea.style.width = `${updatedWidth}px`
        }

        // Add event listeners to the textarea for handling keydown events
        textarea.addEventListener('keydown', (e) => {
          // Update the text node and hide the textarea on enter
          if (e.code === 13 && !e.shiftKey) {
            onChange(data.attrs.id, { text: textarea.value })
            removeTextarea()
          }

          // Hide the textarea on escape, canceling the changes
          if (e.code === 27) {
            removeTextarea()
          }
        })

        textarea.addEventListener('keydown', () => {
          // Set the width of the textarea to the width of the text node
          const scale = ref.current.getAbsoluteScale().x
          setTextareaWidth(ref.current.width() * scale)

          // Reset height before adjusting the height to the text node
          textarea.style.height = 'auto'
          textarea.style.height = `${textarea.scrollHeight + ref.current.fontSize()}px`
        })

        // Add a click event listener to the window to handle clicking outside of the textarea
        setTimeout(() => {
          window.addEventListener('click', handleClickOutside)
        })
      }}
      onDragEnd={(e) => {
        onChange(data.attrs.id, {
          x: e.target.x(),
          y: e.target.y(),
        })
      }}
      onTransformEnd={() => {
        let parent = ref.current.getParent()
        if (inGroup) parent = parent.getParent()
        const transformer = parent.children.find((c) => c.attrs.id === transformerId)

        // Transformer only changes the scale of the node, so we need to
        // manually update the width and height of the node using the new
        // scale values.
        const scaleX = transformer.scaleX()
        const scaleY = transformer.scaleY()

        // Update the width and height of the node
        const updatedWidth = Math.max(5, transformer.width() * scaleX)
        const updatedHeight = Math.max(transformer.height() * scaleY)

        // Get current transformer nodes
        const currentNodes = transformer.nodes()

        // Reset the scales of the transformer nodes
        currentNodes.forEach((n) => {
          n.scaleX(1)
          n.scaleY(1)
        })

        // Determine updated position and rotation
        const rotation = transformer.rotation()
        const updatedPosition = rotateAroundCenter(transformer, rotation)

        onChange(data.attrs.id, {
          x: updatedPosition.x,
          y: updatedPosition.y,
          rotation: transformer.rotation(),
          width: updatedWidth,
          height: updatedHeight,
        })
      }}
      ref={ref}
      width={data.attrs.width}
    />
  )
}

BadgeText.defaultProps = {
  autoFontSize: false,
  draggable: true,
  editable: true,
  inGroup: false,
  onChange: () => {},
  panelRef: {},
  visible: true,
}

BadgeText.propTypes = {
  autoFontSize: PropTypes.bool,
  data: PropTypes.object.isRequired,
  draggable: PropTypes.bool,
  editable: PropTypes.bool,
  inGroup: PropTypes.bool,
  onChange: PropTypes.func,
  panelRef: PropTypes.object,
  transformerId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
}

export default BadgeText
