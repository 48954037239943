/* eslint-disable indent */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dayjs from 'dayjs'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { RichTextInput } from '../RichTextInput'
import { TextInput } from '../TextInput'

// Service
import { updateGlobalPopups } from '../../services/global.service'
import { updatePopupConfiguration } from '../../services/kiosks.service'

/**
 *
 * Individual Popup Item
 * - Handles saving changes for the popup
 */
const Popup = ({
  defaultPopup,
  eventId,
  kioskId,
  popup,
  adminSettings,
  updateData,
  handleError,
  handleSuccess,
}) => {
  // State
  const [updatedPopup, setUpdatedPopup] = useState(popup)
  const [lastUpdated, setLastUpdated] = useState(null)

  /**
   * Update popup configuration
   */
  const updateConfiguration = async (data) => {
    // Prevent saving with empty header or body
    // We reset back to the default value when the user clicks outside the input
    if (!data.header || !data.body) return

    const updatedData = adminSettings
      ? await updateGlobalPopups(data, handleError, () => {}, handleSuccess)
      : await updatePopupConfiguration(
          eventId,
          kioskId,
          data,
          () => {},
          () => {},
        )

    if (updatedData) {
      updateData(updatedData)
      setLastUpdated(dayjs())
    }
  }

  /**
   * Debounce updating the popup configuration
   */
  const savePopupChanges = useCallback(_.debounce(updateConfiguration, 500), [popup.id])

  return (
    <div className="py-2">
      <div className="flex flex-row items-center justify-between">
        <span className="text-lg font-semibold">{updatedPopup.name}</span>
        {lastUpdated && !adminSettings && (
          <span className="text-xs text-gray-500">
            Last updated {lastUpdated.format('MM/DD/YYYY h:mm A')}
          </span>
        )}
      </div>

      <div className="mt-2 flex flex-col space-y-6 md:mt-0 md:flex-row md:space-x-6 md:space-y-0">
        <div className="flex w-full flex-col space-y-2">
          <TextInput
            className={mergeClassNames(
              'rounded-lg border-gray-400 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple',
              adminSettings &&
                'text-md h-rounded-2xl border-gray-550 bg-white text-start font-nunito sm:text-base',
            )}
            data-testid={`header-${popup.identifier}`}
            fullWidth
            id={`header-${popup.identifier}`}
            key={`header-${popup.identifier}`}
            inputStyles="rounded-none"
            label={adminSettings ? 'Default Header' : 'Header'}
            labelStyles={adminSettings ? 'text-black' : ''}
            onBlur={() => {
              // Reset back to default header if the value was cleared
              if (updatedPopup.header === '') {
                setUpdatedPopup({ ...updatedPopup, header: defaultPopup?.header })
              }
            }}
            onChange={(e) => {
              const payload = { ...updatedPopup, header: e.target.value }
              savePopupChanges(payload)
              setUpdatedPopup(payload)
            }}
            placeholder="Enter header here"
            value={updatedPopup.header}
          />

          {!adminSettings && (
            <button
              className="mt-2 self-end text-xs text-purple"
              onClick={() => setUpdatedPopup({ ...updatedPopup, header: defaultPopup?.header })}
              type="button"
            >
              Reset to Default
            </button>
          )}
        </div>

        <div className="flex w-full flex-col space-y-2">
          <div
            className={mergeClassNames(
              'text-sm font-medium',
              adminSettings ? 'text-black' : 'text-gray-700',
            )}
          >
            {adminSettings ? 'Default Body' : 'Body'}
          </div>

          <RichTextInput
            className={
              !adminSettings
                ? 'rounded-lg border-gray-400 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple'
                : ''
            }
            defaultValue={!adminSettings ? defaultPopup?.body : ''}
            id={popup.identifier}
            onChange={(e) => {
              const payload = { ...updatedPopup, body: e }
              savePopupChanges(payload)
              setUpdatedPopup(payload)
            }}
            resetOnBlur
            placeholder="Enter body here"
            toolbarStyles={!adminSettings ? 'border-gray-400' : null}
            value={updatedPopup.body}
          />
        </div>
      </div>
    </div>
  )
}

Popup.defaultProps = {
  adminSettings: false,
  defaultPopup: null,
  eventId: null,
  handleError: () => {},
  handleSuccess: () => {},
}

Popup.propTypes = {
  adminSettings: PropTypes.bool,
  defaultPopup: PropTypes.object,
  eventId: PropTypes.string,
  kioskId: PropTypes.string.isRequired,
  popup: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleError: PropTypes.func,
  handleSuccess: PropTypes.func,
}

export default Popup
