import React from 'react'
import PropTypes from 'prop-types'

const SuitcaseIcon = ({ className }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.5 12.2554C16.7207 13.3805 13.6827 14 10.5 14C7.3173 14 4.2793 13.3805 1.5 12.2554M14.5 5V3C14.5 1.89543 13.6046 1 12.5 1H8.5C7.39543 1 6.5 1.89543 6.5 3V5M10.5 11H10.51M3.5 19H17.5C18.6046 19 19.5 18.1046 19.5 17V7C19.5 5.89543 18.6046 5 17.5 5H3.5C2.39543 5 1.5 5.89543 1.5 7V17C1.5 18.1046 2.39543 19 3.5 19Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

SuitcaseIcon.defaultProps = {
  className: null,
}

SuitcaseIcon.propTypes = {
  className: PropTypes.string,
}

export default SuitcaseIcon
