import React, { useRef } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Group, Rect } from 'react-konva'

// Components
import { BadgeImage } from '../BadgeImage'
import { BadgeText } from '../BadgeText'
import { BadgeRectangle } from '../BadgeRectangle'

/**
 *
 * BadgeGroup
 *
 */
const BadgeGroup = ({
  autoFontSize,
  badgeImages,
  data,
  defaultFont,
  kiosk,
  onChange,
  panelRef,
  transformerId,
  visible,
}) => {
  // Ref
  const ref = useRef()

  return (
    <Group
      {...data.attrs}
      className="Group"
      x={data.attrs.x}
      y={data.attrs.y}
      draggable
      onDragEnd={(e) => {
        // Update group position
        const updatedGroup = { ...data }
        updatedGroup.attrs.x = e.target.x()
        updatedGroup.attrs.y = e.target.y()

        // Update child element positions
        updatedGroup.children = _.map(data.children, (child) => {
          const updatedChild = { ...child }

          // Find matching child ref
          const childRef = e.target.children.find((c) => c.attrs.id === child.attrs.id)
          updatedChild.attrs.x = childRef.x()
          updatedChild.attrs.y = childRef.y()
          return updatedChild
        })

        onChange(data.attrs.id, updatedGroup)
      }}
      onTransformEnd={() => {
        // Find transformer off of parent
        const transformer = ref.current
          .getParent()
          .children.find((c) => c.attrs.id === transformerId)

        // Transformer only changes the scale of the node, so we need to
        // manually update the width and height of the node using the new
        // scale values.
        const scaleX = transformer.scaleX()
        const scaleY = transformer.scaleY()

        // Update the width and height of the node
        const updatedWidth = Math.max(5, transformer.width() * scaleX)
        const updatedHeight = Math.max(transformer.height() * scaleY)

        // Get current transformer nodes
        const currentNodes = transformer.nodes()

        // Reset the scales of the transformer nodes
        currentNodes.forEach((n) => {
          n.scaleX(1)
          n.scaleY(1)
        })

        const updatedGroup = { ...data }
        updatedGroup.attrs.x = transformer.x()
        updatedGroup.attrs.y = transformer.y()
        updatedGroup.attrs.width = updatedWidth
        updatedGroup.attrs.height = updatedHeight

        onChange(data.attrs.id, updatedGroup)
      }}
      ref={ref}
      visible={visible}
    >
      <Rect
        className="Rect"
        fill={data.attrs.fill} // Background
        id={`group:${data.attrs.id}:base`}
        stroke={data.attrs.stroke} // Border
        strokeWidth={data.attrs.strokeWidth} // Border
        height={data.attrs.height}
        width={data.attrs.width}
        x={0}
        y={0}
      />

      {_.map(data.children, (child) => {
        const { id } = child.attrs

        let base64 = null
        if (kiosk && child.className === 'Image' && !child.attrs.qrImage) {
          // Find the matching image from the `badgeImages`
          const match = _.find(badgeImages, (i) => child.attrs.badgeImagePath.includes(i.id))
          if (match) {
            base64 = match.base64
          }
        } else {
          base64 = _.has(badgeImages, id) ? badgeImages[id]?.base64 : null
        }

        switch (child.className) {
          case 'Image':
            return (
              <BadgeImage
                base64={base64}
                data={child}
                inGroup
                key={id}
                onChange={onChange}
                transformerId={transformerId}
              />
            )
          case 'Rect':
            return (
              <BadgeRectangle
                data={child}
                inGroup
                key={id}
                onChange={onChange}
                transformerId={transformerId}
              />
            )
          case 'Text':
            return (
              <BadgeText
                autoFontSize={autoFontSize}
                data={child}
                editable={child.attrs.editable}
                font={defaultFont}
                inGroup
                key={id}
                onChange={onChange}
                panelRef={panelRef}
                transformerId={transformerId}
              />
            )
          default:
            return null
        }
      })}
    </Group>
  )
}

BadgeGroup.defaultProps = {
  autoFontSize: false,
  badgeImages: null,
  kiosk: false,
  panelRef: {},
  visible: true,
}

BadgeGroup.propTypes = {
  autoFontSize: PropTypes.bool,
  badgeImages: PropTypes.array,
  data: PropTypes.object.isRequired,
  defaultFont: PropTypes.string.isRequired,
  kiosk: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  panelRef: PropTypes.object,
  transformerId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
}

export default BadgeGroup
