import { React, useState, useContext } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'

// Components
import { Alert } from '../../components/Alert'
import { AuthGradient } from '../../components/AuthGradient'
import { Button } from '../../components/Button'
import { VerificationCodeInput } from '../../components/VerificationCodeInput'

// Images
import Badger from '../../assets/images/badgr_text_logo.svg'
import Fetch from '../../assets/images/fetch_text_logo.svg'
import EventstackLogo from '../../assets/images/eventstack_logo.svg'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Service & Utils
import { loginConfirm, resendLoginCode } from '../../services/user.service'
import useFlashMessage from '../../hooks/FlashMessage'

const CODE_LENGTH = 6

/**
 *
 * MultiFactorAuthentication
 *
 */
const MultiFactorAuthentication = observer(() => {
  // Context
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { getUpdatedUser, setCurrentTokens, setCurrentUser, user } = useContext(UserStoreContext)

  // State
  const [device, setDevice] = useState()
  const [loading, setLoading] = useState(false)

  const redirectTo = searchParams.get('redirect')

  // Messages
  const { message: success, showMessage: showSuccess } = useFlashMessage(null)
  const { message: error, showMessage: showError } = useFlashMessage(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: null,
    },
  })

  const { handleSubmit: handleSubmitResend, setValue: setValueResend } = useForm()

  const mfaOptions = ['Email', 'Phone']

  /**
   * Handles login confirm form submission
   * - Redirects to dashboard if successful
   * @param {object} data
   */
  const submitLoginConfirmForm = async (data) => {
    const results = await loginConfirm(
      { code: data.code, email: user.email },
      showError,
      setLoading,
      setCurrentTokens,
      getUpdatedUser,
    )

    if (results) {
      navigate('/')
    }
  }

  /**
   * Handles re-sending verification form submission
   * @param {object} data
   */
  const submitResendForm = async (data) => {
    if (!location.state.password)
      navigate(`/login${redirectTo ? `?redirect=${redirectTo}` : ''}`, { replace: true })

    const results = await resendLoginCode(
      redirectTo,
      { ...data, email: user.email, password: location.state.password },
      showError,
      setLoading,
    )

    if (results) {
      setCurrentUser({ email: user.email, ...results })
      showSuccess(user.message)
    }
  }

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-between overflow-hidden bg-[#181823] px-8 py-12 text-white">
      <AuthGradient />

      <div className="w-64 self-center sm:ml-8 sm:w-[184px] sm:self-start">
        <img src={EventstackLogo} alt="" />
      </div>

      <div className="z-1 relative max-w-[675px] sm:mx-auto sm:w-2/3">
        <h2 className="text-center text-3xl font-bold">Two Factor Authentication</h2>

        <p className="mt-2 text-center text-sm">
          Or{' '}
          <a
            href={`/login${redirectTo ? `?redirect=${redirectTo}` : ''}`}
            className="ffont-medium text-purple hover:text-purple"
          >
            Log In With A Different Account
          </a>
        </p>

        <form className="mt-8" onSubmit={handleSubmit(submitLoginConfirmForm)}>
          {success && <Alert className="mb-6" message={success} title="Success" type="success" />}
          {error && <Alert className="mb-6" message={error} title="Error" type="error" />}

          <p className="mb-4 mt-2 text-center text-sm">
            Enter the verification code sent to <span className="font-bold">{user.address}</span>.
          </p>

          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md">
            <Controller
              name="code"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <VerificationCodeInput
                  error={errors.code && 'This field is required'}
                  handleSubmitCode={(v) => onChange(v)}
                  containerStyles={{ alignItems: 'center' }}
                  codeLength={CODE_LENGTH}
                />
              )}
            />
          </div>

          <div className="my-4 flex flex-wrap justify-center gap-2 sm:mb-12 sm:mt-4 md:flex-nowrap">
            {mfaOptions.map((mfaOption) => (
              <Button
                disabled={loading && device !== mfaOption}
                type="button"
                key={mfaOption}
                id={mfaOption}
                className="flex h-auto w-full items-center border-transparent bg-transparent text-purple hover:bg-transparent md:w-auto"
                label={`Resend Verification via ${mfaOption}`}
                loading={loading && device === mfaOption}
                onClick={() => {
                  setDevice(mfaOption)
                  setValueResend('mfaDevice', mfaOption)
                  handleSubmitResend(submitResendForm)()
                }}
              />
            ))}
          </div>

          <Button
            className="text-md flex h-12 items-center rounded-md border-purple sm:h-[60px] sm:text-base"
            loading={loading}
            label="Verify"
            fullWidth
          />
        </form>
      </div>

      <div className="flex items-center justify-center gap-x-6 text-lg font-bold sm:justify-start">
        <img src={Fetch} alt="" className="h-[39px]" /> +{' '}
        <img src={Badger} alt="" className="h-[30px]" />
      </div>
    </div>
  )
})

export default MultiFactorAuthentication
