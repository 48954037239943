import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

// Components
import { Reports } from '../../components/Reports'
import { StateContainer } from '../../components/StateContainer'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Utils & Service
import { getOrganizationReport } from '../../services/reports.service'

/**
 *
 * OrganizerReports
 *
 */
const OrganizerReports = observer(() => {
  // Context
  const { organization } = useContext(NavigationStoreContext)
  const { orgId } = useParams()

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="flex h-full w-full flex-col space-y-2 overflow-y-auto p-3">
          <Reports
            getReport={getOrganizationReport}
            reportId={orgId}
            badgrReportTitle={organization.name}
            badgrReportSubtitle="Event Summary Report"
            fetchReportTitle={organization.name}
            fetchReportSubtitle="Event Summary Report"
          />
        </div>
      </StateContainer>
    </div>
  )
})

export default OrganizerReports
