import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

/**
 * NotFound
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */
const NotFound = observer(() => {
  // Context
  const { resetEvent, resetNavigation, updateActiveEntity } = useContext(NavigationStoreContext)
  const { isEEUser, user } = useContext(UserStoreContext)
  const navigate = useNavigate()

  return (
    <div className="flex h-full w-full flex-col bg-white pb-12 pt-16">
      <main className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-indigo-600">
              4
              <span role="img" aria-label="Crying Face">
                😢
              </span>
              4
            </p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Page not found.
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6 flex justify-center">
              <Button
                label="Back to Dashboard"
                background="bg-purple border-purple hover:bg-purple-600"
                onClick={() => {
                  // If ee user navigate using navigation context
                  if (isEEUser) {
                    resetNavigation()
                    updateActiveEntity(null, null)
                    navigate('/dashboard', { replace: true })
                  }
                  // Otherwise, use the user's active organization
                  else {
                    const activeType =
                      user.activeOrganization.type === 'Organizer' ? 'organization' : 'exhibitor'

                    resetEvent()
                    updateActiveEntity(activeType, user.activeOrganization.id)
                    navigate(`${activeType}/${user.activeOrganization.id}/dashboard`, {
                      replace: true,
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
})

export default NotFound
