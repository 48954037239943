/** @type {import('tailwindcss').Config} */

import tailwindForms from '@tailwindcss/forms'

export default {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './node_modules/tailwind-datepicker-react/dist/**/*.js',
  ],
  darkMode: 'class',
  theme: {
    extend: {
      animation: {
        'spin-slow': 'spin 2s linear infinite',
      },
      colors: {
        white: {
          DEFAULT: '#ffffff',
        },
        black: {
          500: '#0D120E',
          light: '#0D120E',
          DEFAULT: '#000000',
        },
        gray: {
          100: '#F9FAFB',
          200: '#F3F4F6',
          300: '#E5E7EB',
          400: '#D1D5DB',
          500: '#9CA3AF',
          550: '#636E7D',
          600: '#6B7280',
          700: '#4B5563',
          800: '#374151',
          900: '#1F2937',
          lighter: '#F8F8F8',
          light: '#E5E7EB',
          DEFAULT: '#374151',
          dark: '#111827',
        },
        purple: {
          100: '#F2ECFF',
          200: '#E0D0FF',
          300: '#D5C1FB',
          400: '#C2A3FF',
          500: '#B189FF',
          600: '#A071FC',
          700: '#8C56F6',
          800: '#8048ED',
          900: '#6F3AD8',
          light: '#A071FC',
          DEFAULT: '#8C56F6',
          dark: '#4E259D',
        },
        teal: {
          100: '#F1FFFC',
          200: '#E9FFFB',
          300: '#DCFFF8',
          400: '#CCFFF5',
          500: '#B5FBED',
          600: '#93F7E2',
          700: '#6EE5CD',
          800: '#53DCC0',
          900: '#46C5AB',
          light: '#93F7E2',
          DEFAULT: '#6EE5CD',
          dark: '#2AA48B',
        },
        blue: {
          100: '#F4FAFF',
          200: '#D9EDFF',
          300: '#B9DEFF',
          400: '#93CCFF',
          500: '#6EB9FB',
          600: '#55A3E7',
          700: '#4298E2',
          800: '#2D87D7',
          900: '#1775C7',
          light: '#6EB9FB',
          DEFAULT: '#55A3E7',
          dark: '#0360B0',
        },
        darkBlue: {
          DEFAULT: '#1F2937',
          dark: '#111827',
        },
        dark: {
          100: '#ECE9EC',
          200: '#C6C2C6',
          300: '#B3ADB3',
          400: '#8B868B',
          500: '#746E74',
          600: '#625C62',
          700: '#423C42',
          800: '#2F2A2F',
          900: '#211D21',
          light: '#625C62',
          DEFAULT: '#0D0B0D',
          dark: '#0D0B0D',
        },
        green: {
          DEFAULT: '#27AD92',
        },
        pink: {
          DEFAULT: '#FFBABA',
        },
        red: {
          400: '#EF7979',
          500: '#EB5757',
          light: '#EF7979',
          DEFAULT: '#EB5757',
        },
        orange: {
          50: '#fff8ed',
          100: '#fff0d4',
          200: '#ffdda8',
          300: '#ffc371',
          400: '#ffa94d',
          500: '#fe8211',
          600: '#ef6607',
          700: '#c64c08',
          800: '#9d3c0f',
          900: '#7e3310',
          950: '#441706',
          DEFAULT: '#ffa94d',
        },
        warning: {
          light: '#FFFBEB',
          DEFAULT: '#B45309',
          dark: '#92400E',
          icon: '#FBBF24',
        },
        error: {
          light: '#FEF2F2',
          DEFAULT: '#B91C1C',
          dark: '#991B1B',
          icon: '#F87171',
        },
        info: {
          light: '#EFF6FF',
          DEFAULT: '#1E40AF',
          icon: '#60A5FA',
        },
        success: {
          light: '#D1FAE5',
          DEFAULT: '#059669',
        },
        background: {
          DEFAULT: '#E4E9F0',
        },
        role: {
          user: '#5F28CC',
          admin: '#065F46',
        },
        status: {
          green: '#D1FAE5',
          purple: '#F0E9FF',
          blue: '#DDEDFF',
          darkBlue: '#0A3C72',
        },
      },
      fontSize: {
        base: 14,
      },
      screens: {
        tall: { raw: '(min-height: 700px)' },
        short: { raw: '(max-height: 700px)' },
      },
      fontFamily: {
        nunito: ['Nunito Sans', 'sans-serif'],
      },
    },
  },
  // eslint-disable-next-line global-require
  plugins: [tailwindForms],
}
