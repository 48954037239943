import React from 'react'
import PropTypes from 'prop-types'

const LocationIcon = ({ className }) => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.1569 15.4996C14.2202 16.4362 12.2616 18.3949 10.9138 19.7427C10.1327 20.5237 8.86768 20.5242 8.08663 19.7431C6.76234 18.4188 4.84159 16.4981 3.84315 15.4996C0.718951 12.3754 0.718951 7.31011 3.84315 4.18592C6.96734 1.06172 12.0327 1.06172 15.1569 4.18592C18.281 7.31011 18.281 12.3754 15.1569 15.4996Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 9.84277C12.5 11.4996 11.1569 12.8428 9.5 12.8428C7.84315 12.8428 6.5 11.4996 6.5 9.84277C6.5 8.18592 7.84315 6.84277 9.5 6.84277C11.1569 6.84277 12.5 8.18592 12.5 9.84277Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

LocationIcon.defaultProps = {
  className: null,
}

LocationIcon.propTypes = {
  className: PropTypes.string,
}

export default LocationIcon
