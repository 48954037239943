import React from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 *
 * CheckBox
 *
 */
const CheckBox = ({ className, disabled, label, name, onChange, value }) => (
  <div className={mergeClassNames('flex  items-center space-x-2', className)}>
    <input
      disabled={disabled}
      id={name}
      name={name}
      type="checkbox"
      className="appearance-none rounded border-gray-400 checked:bg-purple checked:text-purple focus:ring-purple-600 disabled:cursor-not-allowed disabled:opacity-50"
      checked={value}
      onChange={onChange}
    />

    <label htmlFor={name} className="text-sm font-medium text-gray-700">
      {label}
    </label>
  </div>
)

CheckBox.defaultProps = {
  className: null,
  disabled: false,
  label: null,
}

CheckBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default CheckBox
