import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Toggle } from '../Toggle'

/**
 *
 * BackgroundControls
 *
 */
const BackgroundControls = ({ element, updateElement }) => (
  <div className="space-y-1">
    <div className="my-2 flex items-center gap-2 text-sm">
      <Toggle
        label="Panel Background (Doesn’t Print)"
        name="is-background"
        checked={element.attrs.isBackground}
        onChange={(checked) => {
          updateElement({
            isBackground: checked,
            visibility: checked ? 'none' : 'always',
            lockRatio: !checked,
            x: checked ? 0 : element.attrs.x,
            y: checked ? 0 : element.attrs.y,
          })
        }}
      />
    </div>
  </div>
)

BackgroundControls.propTypes = {
  element: PropTypes.object.isRequired,
  updateElement: PropTypes.func.isRequired,
}

export default BackgroundControls
