/* eslint-disable indent */
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Layer, Line, Stage, Transformer as KonvaTransformer } from 'react-konva'

// Components
import { BadgeGroup } from '../BadgeGroup'
import { BadgeImage } from '../BadgeImage'
import { BadgeRectangle } from '../BadgeRectangle'
import { BadgeText } from '../BadgeText'

// Utils & Styles
import baseColors from '../../utils/colors'
import { ANCHORS } from './helpers'

const Transformer = ({ anchors, id, selectedElements }) => {
  // Ref
  const ref = useRef()

  useEffect(() => {
    const stage = ref.current.getStage()

    // If there are `selectedElements`, add those to the transformer
    if (selectedElements && selectedElements.length > 0) {
      const updatedSelectedNodes = []

      _.forEach(selectedElements, (element) => {
        // Find the node for this `element`
        const selectedNode = stage.findOne(`#${element.attrs.id}`)

        // If found, add it to the transformer
        if (selectedNode) {
          updatedSelectedNodes.push(selectedNode)
        }
      })

      ref.current.nodes(updatedSelectedNodes)
    } else {
      ref.current.nodes([])
    }

    ref.current.getLayer().batchDraw()
  }, [selectedElements])

  return (
    <KonvaTransformer
      id={id}
      ref={ref}
      enabledAnchors={anchors}
      flipEnabled={false}
      rotateEnabled={anchors.length !== 0}
      boundBoxFunc={(oldBox, newBox) => {
        // limit resize
        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
          return oldBox
        }
        return newBox
      }}
    />
  )
}

Transformer.defaultProps = {
  selectedElements: null,
}

Transformer.propTypes = {
  anchors: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  selectedElements: PropTypes.array,
}

/**
 *
 * BadgePanel
 *
 * An individual badge panel (frame) that can be modified.
 * - `Base` eleemnts or layers cannot be dragged
 */
const BadgePanel = ({
  badgeBackground,
  badgeImages,
  defaultFont,
  previewRef,
  savedPanel,
  setActiveElement,
  setTemporaryGroup,
  showGrid,
  tempGroup,
  updatePanel,
}) => {
  // State
  const [panel, setPanel] = useState(savedPanel)
  const [layers, setLayers] = useState(null)
  const [selectedElements, setSelectedElements] = useState(null)

  // Ref
  const panelRef = useRef()

  const setLayersFromPanel = () => {
    const updatedLayers = _.map(savedPanel.children, (c) => ({
      ref: createRef(),
      id: c.attrs.id,
    }))

    setLayers(updatedLayers)
    setPanel(savedPanel)
  }

  useEffect(() => {
    setLayersFromPanel()
  }, [])

  useEffect(() => {
    // If the panel ID has changed, update state
    if (savedPanel.attrs.config.id !== panel.attrs.config.id) {
      setLayersFromPanel()
    } else if (layers && savedPanel.children?.length > layers.length) {
      const newLayer = {
        ref: createRef(),
        id: savedPanel.children[savedPanel.children.length - 1].attrs.id,
      }

      setLayers([...layers, newLayer])
      setPanel({ ...savedPanel })
    }
    // If there are fewer layers in the panel than in the state, remove the missing layer
    else if (layers && savedPanel.children?.length < layers.length) {
      const updatedLayers = _.filter(layers, (layer) => {
        const match = _.find(savedPanel.children, (c) => c.attrs.id === layer.id)
        return !!match
      })

      setLayers(updatedLayers)
      setPanel({ ...savedPanel })
    }
    // Otherwise, check the layers for visibility changes
    else if (layers) {
      _.forEach(layers, (layer) => {
        // Skip over base layer, this cannot be hidden
        if (layer.id.includes('base')) return

        const match = _.find(savedPanel.children, (c) => c.attrs.id === layer.id)

        // Hide or show the layer
        if (!match.attrs.visible) {
          layer.ref.current.hide()
        } else if (match) {
          layer.ref.current.show()
        }
      })

      setPanel({ ...savedPanel })
    }
  }, [savedPanel])

  useEffect(() => {
    setTimeout(() => {
      panelRef.current?.draw()
    }, 500)
  }, [panelRef, panel])

  useEffect(() => {
    if (!tempGroup) {
      setSelectedElements(null)
    }
  }, [tempGroup])

  const anchors = useMemo(() => {
    let updatedAnchors = ANCHORS.FULL

    // If the selected element is an image and the ratio is locked, disable width and height anchors
    if (selectedElements && selectedElements.length === 1) {
      const selectedElement = selectedElements[0]
      if (selectedElement.className === 'Image' && selectedElement.attrs.lockRatio)
        updatedAnchors = ANCHORS.CORNERS
      if (selectedElement.attrs.inGroup) updatedAnchors = []
    }

    return updatedAnchors
  }, [_.map(selectedElements, (e) => e.attrs)])

  /**
   * Renders child elements.
   * @param {object} child child element to render
   * @returns
   */
  const renderChild = (child, transformerId) => {
    const { id } = child.attrs
    switch (child.className) {
      case 'Image':
        return (
          <BadgeImage
            base64={_.has(badgeImages, id) ? badgeImages[id]?.base64 : null}
            data={
              // If the child is a background image, set the width and height to the panel size
              child.attrs.isBackground
                ? {
                    ...child,
                    attrs: {
                      ...child.attrs,
                      width: panel.attrs.width,
                      height: panel.attrs.height,
                    },
                  }
                : child
            }
            draggable={id !== 'base' && !child.attrs.isBackground}
            key={id}
            onChange={updatePanel}
            transformerId={transformerId}
          />
        )
      case 'Rect':
        return (
          <BadgeRectangle
            data={child}
            draggable={id !== 'base'}
            key={id}
            onChange={updatePanel}
            transformerId={transformerId}
          />
        )
      case 'Text':
        return (
          <BadgeText
            data={child}
            draggable
            editable={child.attrs.editable}
            font={defaultFont}
            key={id}
            onChange={updatePanel}
            panelRef={panelRef}
            transformerId={transformerId}
          />
        )
      case 'Group':
        return (
          <BadgeGroup
            badgeImages={badgeImages}
            data={child}
            defaultFont={defaultFont}
            key={id}
            onChange={updatePanel}
            panelRef={panelRef}
            transformerId={transformerId}
          />
        )
      default:
        return null
    }
  }

  const renderGridLines = () => {
    const xSize = panel.attrs.width
    const ySize = panel.attrs.height
    let cellSize = 30 // Start with a cell size of 30

    // Adjust cell size for x dimension
    while (xSize % cellSize !== 0) {
      cellSize -= 1
    }

    const xSteps = xSize / cellSize
    const ySteps = ySize / cellSize

    const lines = []
    _.forEach(_.range(xSteps + 1), (i) => {
      lines.push(
        <Line
          x={i * cellSize}
          points={[0, 0, 0, ySize]}
          stroke={baseColors.gray[300]}
          strokeWidth={1}
          key={`x:${i}`}
        />,
      )
    })

    _.forEach(_.range(ySteps + 1), (i) => {
      lines.push(
        <Line
          y={i * cellSize}
          points={[0, 0, xSize, 0]}
          stroke={baseColors.gray[300]}
          strokeWidth={1}
          key={`y:${i}`}
        />,
      )
    })

    // Add center lines with strokeWidth of 3
    lines.push(
      <Line
        x={xSize / 2}
        points={[0, 0, 0, ySize]}
        stroke={baseColors.teal[800]}
        strokeWidth={2}
        key="center-horizontal"
      />,
      <Line
        y={ySize / 2}
        points={[0, 0, xSize, 0]}
        stroke={baseColors.teal[800]}
        strokeWidth={2}
        key="center-vertical"
      />,
    )

    return lines
  }

  if (!layers || layers.length !== panel.children.length) return null

  return (
    <Stage
      id={panel.attrs.config?.id || panel.attrs.id}
      height={panel.attrs.height}
      onMouseDown={(e) => {
        // Selected stage or a base element, clear all selections
        if (e.target === e.target.getStage() || e.target.attrs.id === 'base') {
          setSelectedElements(null)
          setActiveElement(null)
          setTemporaryGroup(null)

          return
        }

        // Selected the transformer, do nothing
        const clickedOnTransformer = e.target.getParent().className === 'Transformer'
        if (clickedOnTransformer) {
          return
        }

        let elementToSelect = null
        const parent = e.target.getParent()

        if (
          e.target?.attrs.className === 'Group' ||
          e.target.getParent()?.attrs.className === 'Group'
        ) {
          // Filter out the base element (background)
          const children = _.filter(parent.children, (c) => !c.attrs.id.includes('base'))

          elementToSelect = {
            className: 'Group',
            attrs: parent.attrs,
            children: _.map(children, (c) => ({
              className: c.attrs.className,
              attrs: c.attrs,
            })),
          }
        } else {
          elementToSelect = { className: e.target?.attrs.className, attrs: e.target?.attrs }
        }

        // Selected an element with the shift key, add to the selection list
        if (e.target && e.evt.shiftKey) {
          const selected =
            selectedElements && selectedElements.length > 0
              ? [...selectedElements, elementToSelect]
              : [elementToSelect]
          setSelectedElements(selected)
          setTemporaryGroup(selected)
          setActiveElement(null)
        }
        // Selected an element without the shift key, set selection to this element
        // and open settings
        else if (
          e.target &&
          (selectedElements?.length === 0 ||
            _.find(
              selectedElements,
              (element) => element.attrs.id === elementToSelect.attrs.id,
            )) === undefined
        ) {
          setSelectedElements([elementToSelect])
          setActiveElement(elementToSelect)
        }
        // Otherwise, clear all selections
        else {
          setSelectedElements(null)
          setActiveElement(null)
          setTemporaryGroup(null)
        }
      }}
      onDblClick={(e) => {
        // Selected stage or a base element, clear all selections
        if (
          e.target === e.target.getStage() ||
          e.target.attrs.id === 'base' ||
          e.target.attrs.id.includes('base')
        ) {
          setSelectedElements(null)
          setActiveElement(null)
          setTemporaryGroup(null)

          return
        }

        // Selected the transformer, do nothing
        const clickedOnTransformer = e.target.getParent().className === 'Transformer'
        if (clickedOnTransformer) {
          return
        }

        // For elements that are double clicked while being in a group,
        // open the settings for that element
        if (e.target.getParent()?.attrs.className === 'Group') {
          const elementToSelect = {
            className: e.target?.attrs.className,
            attrs: e.target?.attrs,
          }

          setSelectedElements([elementToSelect])
          setActiveElement(elementToSelect)
        }

        // Check if the double clicked element is a text element and not already selected
        if (
          e.target?.attrs.className === 'Text' &&
          (selectedElements?.length === 0 ||
            _.find(selectedElements, (element) => element.attrs.id === e.target?.attrs.id)) ===
            undefined
        ) {
          const elementToSelect = {
            className: e.target?.attrs.className,
            attrs: e.target?.attrs,
          }
          setSelectedElements([elementToSelect])
          setActiveElement(elementToSelect)
        }
      }}
      ref={previewRef || panelRef}
      width={panel.attrs.width}
    >
      {/* Base layer for background and gridlines */}
      <Layer id={panel.children[0].attrs.id} ref={layers[0].ref}>
        {/* Render base background (either rect or an image) */}
        {badgeBackground
          ? renderChild(badgeBackground, `layer:base`)
          : renderChild(panel.children[0].children[0], `layer:base`)}

        {/* Render gridlines */}
        {showGrid && renderGridLines()}

        {/* Render all other children */}
        {_.map(
          _.filter(
            panel.children[0].children,
            (c) => c.attrs.id !== 'base' && !c.attrs.isBackground,
          ),
          (child) => renderChild(child, `layer:${0}`),
        )}

        <Transformer anchors={anchors} id={`layer:${0}`} selectedElements={selectedElements} />
      </Layer>

      {/* All other layers */}
      {_.map(
        _.filter(panel.children, (c) => c.attrs.id !== 'base'),
        (layer, index) => (
          <Layer id={layer.attrs.id} key={layer.attrs.id} ref={layers[index + 1].ref}>
            {_.map(layer.children, (child) => renderChild(child, `layer:${index}`))}

            <Transformer
              anchors={anchors}
              id={`layer:${index}`}
              selectedElements={selectedElements}
            />
          </Layer>
        ),
      )}
    </Stage>
  )
}

BadgePanel.defaultProps = {
  previewRef: null,
  tempGroup: null,
}

BadgePanel.propTypes = {
  badgeBackground: PropTypes.object,
  badgeImages: PropTypes.object.isRequired,
  defaultFont: PropTypes.string.isRequired,
  previewRef: PropTypes.object,
  savedPanel: PropTypes.object.isRequired,
  setActiveElement: PropTypes.func.isRequired,
  setTemporaryGroup: PropTypes.func.isRequired,
  showGrid: PropTypes.bool.isRequired,
  tempGroup: PropTypes.array,
  updatePanel: PropTypes.func.isRequired,
}

export default BadgePanel
