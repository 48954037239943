import React from 'react'
import PropTypes from 'prop-types'

// Components
import { CalendarIcon } from '../CalendarIcon'
import { ExhibitorIconSolid } from '../ExhibitorIconSolid'
import { LocationIconSolid } from '../LocationIconSolid'
import { TicketSimpleIcon } from '../TicketSimpleIcon'
import { UsersIcon } from '../UsersIcon'

/**
 *
 * EventHeader
 */
const EventHeader = ({ event }) =>
  event ? (
    <div>
      <div className="flex flex-row place-items-center space-x-2">
        <TicketSimpleIcon className="h-4 fill-darkBlue-dark stroke-darkBlue-dark lg:h-6" />
        <span className="text-lg font-semibold text-gray-dark lg:text-2xl">{event.name}</span>
      </div>

      <div className="flex flex-row space-x-8 text-xs font-medium text-gray-500 lg:text-sm">
        {event.displayAttendeeCount && (
          <div className="flex flex-row place-items-center space-x-2">
            <UsersIcon className="fill-gray-500" />
            <span>{event.numAttendees}</span>
          </div>
        )}

        {event.displayExhibitorCount && (
          <div className="flex flex-row place-items-center space-x-2">
            <ExhibitorIconSolid className="fill-gray-500" />
            <span>{event.numExhibitors}</span>
          </div>
        )}

        <div className="flex flex-row place-items-center space-x-2">
          <LocationIconSolid className="fill-gray-500" />
          <span>{event.location || 'N/A'}</span>
        </div>

        <div className="flex flex-row place-items-center space-x-2">
          <CalendarIcon className="fill-gray-500" />
          <span>{event.dateRange || 'N/A'}</span>
        </div>
      </div>
    </div>
  ) : null

EventHeader.propTypes = {
  event: PropTypes.object.isRequired,
}

export default EventHeader
