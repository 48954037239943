import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useReactToPrint } from 'react-to-print'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { ArrowPathIcon } from '@heroicons/react/20/solid'

// Components
import { Button } from '../../components/Button'
import { DownloadIcon } from '../../components/DownloadIcon'
import { EventHeader } from '../../components/EventHeader'
import { StateContainer } from '../../components/StateContainer'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { TaskStoreContext } from '../../stores/TaskStore'

// Images
import FetchGray from '../../assets/images/fetch_gray.svg'
import FetchTextLogo from '../../assets/images/fetch_text_logo.svg'
import ReportHeader from '../../assets/images/report_header.svg'

// Utils & Service
import { createLeadsExport } from '../../services/leads.service'
import { getEventExhibitorReport } from '../../services/reports.service'
import colors, { CHART_COLORS } from '../../utils/colors'
import { toast } from '../../utils/helpers'

const CustomTick = ({ value, x, y }) => {
  if (value === 'Hot')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥳
        </text>
      </g>
    )
  if (value === 'Warm')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🤔
        </text>
      </g>
    )
  if (value === 'Cold')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥶
        </text>
      </g>
    )
  return null
}

CustomTick.propTypes = {
  value: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

/**
 *
 * ExhibitorEventReports
 *
 */
const ExhibitorEventReports = observer(() => {
  // Context
  const { setParent, setTask, setType } = useContext(TaskStoreContext)
  const { event, organization } = useContext(NavigationStoreContext)

  // State
  const [printing, setPrinting] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)

  // Fetch
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState(null)

  // Ref
  const reportRef = useRef()

  const handleError = (message) => toast(message, 'error')

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    onAfterPrint: () => setPrinting(false),
  })

  useEffect(() => {
    setError(false)

    const getReportData = async () => {
      const data = await getEventExhibitorReport(
        event.id,
        event?.eventExhibitor?.id,
        (err) => {
          handleError(err)
          setError(true)
        },
        setLoading,
      )

      if (data) {
        // Convert chart data into proper formats
        const leadsByTemperature = _.map(data.leadsByTemperature, (value, key) => {
          let color = colors.purple.DEFAULT
          let temperature = 'Warm'
          if (key === 'great') {
            color = colors.teal.DEFAULT
            temperature = 'Hot'
          }
          if (key === 'cold') {
            color = colors.blue.DEFAULT
            temperature = 'Cold'
          }

          return {
            temperature,
            value,
            color,
          }
        })

        const leadsByUser = _.map(data.leadsByUser, (d, index) => {
          let name = `${index}`

          if (d.userFirstName && d.userLastName) {
            name = `${d.userFirstName} ${d.userLastName}`
          } else if (!d.userFirstName || !d.userLastName) {
            name = `${d.userFirstName || d.userLastName}`
          }

          return {
            color: CHART_COLORS[index],
            id: `${index}`,
            value: d.totalLeadCount,
            label: name,
          }
        })

        const leadsPerDay = _.map(data.leadsPerDay, (value, key) => {
          const index = _.findIndex(_.keys(data.leadsPerDay), (k) => k === key)
          return {
            id: `${key}`,
            label: dayjs(key, 'YYYYMMDD').format('MM/DD/YYYY'),
            value,
            color:
              index < CHART_COLORS.length
                ? CHART_COLORS[index]
                : CHART_COLORS[index - CHART_COLORS.length],
          }
        })

        setReportData({ ...data, leadsByTemperature, leadsByUser, leadsPerDay })
      }
    }

    if (event && organization && event.eventExhibitor) {
      getReportData()
    }
  }, [event, organization])

  /**
   * Configures a data tile using the specified `label` and `value`.
   * @param {string} label
   * @param {string} value
   */
  const configureTile = (label, value) => (
    <div
      className={mergeClassNames(
        'flex w-full flex-col items-center rounded-lg p-4',
        printing && 'py-5',
      )}
      style={{
        background: 'linear-gradient(135deg, #000000 0%, #4E259D 50%, #A071FC 100%)',
      }}
    >
      <span className={mergeClassNames('text-sm font-semibold', printing && 'text-lg')}>
        {label}
      </span>
      <span className={mergeClassNames('text-2xl font-bold', printing && 'text-4xl')}>
        {value}
      </span>
    </div>
  )

  const configureStaffWithMostLeads = (data) => {
    let name = `${data?.userDeviceName || 'N/A'}`

    if (data && data?.userFirstName && data?.userLastName) {
      name = `${data.userFirstName} ${data.userLastName}`
    } else if (data && (!data?.userFirstName || !data?.userLastName)) {
      name = `${data?.userFirstName || data?.userLastName}`
    }

    return name
  }

  const renderLoadingOrError = () => {
    if (loading) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
          <span className="text-2xl font-bold">Loading...</span>

          <span className="flex items-center pr-3">
            <div className="h-10 w-10">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    if (error) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold text-red">Error generating report.</span>
        </div>
      )
    }

    return null
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="mb-3 flex flex-col items-start justify-between space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <EventHeader event={event} />
          </div>

          <div className="mt-1.5 flex flex-col">
            <span className="text-md font-bold">Reports</span>

            <div>
              <span className="text-md font-bold">Excel Files</span>

              <div className="flex flex-row items-center justify-between gap-6">
                <span className="text-sm font-semibold">Full Leads Report</span>
                <Button
                  className="text-xs"
                  disabled={!event || !event.eventExhibitor}
                  icon={<DownloadIcon className="h-5 stroke-white sm:h-6" />}
                  label="Download"
                  loading={loadingExport}
                  onClick={async () => {
                    const task = await createLeadsExport(
                      event.id,
                      event.eventExhibitor.id,
                      handleError,
                      setLoadingExport,
                    )

                    if (task) {
                      setType('Download')
                      setParent({
                        type: 'leads',
                        label: 'Leads Summary',
                        id: task.id,
                        eventExhibitorId: event.eventExhibitor.id,
                        eventId: event.id,
                        eventName: event.name,
                        fileName: `${event.name}-Leads`,
                      })
                      setTask(task)
                    }
                  }}
                />
              </div>
            </div>

            <span className="text-md mt-4 font-bold">PDF Reports</span>

            {/* Fetch Report */}

            <div className="mb-2 flex flex-row items-center justify-between">
              <span className="text-sm font-semibold">Event Summary Report</span>

              <Button
                disabled={loading || error}
                label="Print Fetch Report"
                onClick={() => {
                  setPrinting(true)

                  setTimeout(() => {
                    handlePrint()
                  }, 200)
                }}
              />
            </div>

            {reportData && !loading && !error ? (
              <div className="relative max-w-[1200px] bg-white" ref={reportRef}>
                <img
                  alt="logo"
                  className="absolute inset-x-auto top-32 z-0 h-[85%] w-full"
                  src={FetchGray}
                />

                <div className="flex flex-col gap-8">
                  <div
                    className="z-10 flex items-center px-10 py-8"
                    style={{
                      backgroundImage: `url(${ReportHeader})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <img className="block h-16 w-auto" src={FetchTextLogo} alt="Fetch" />
                  </div>

                  <div className="z-10 flex flex-col items-center">
                    <span className="text-3xl font-bold">{organization.name}</span>
                    <span className="text-2xl font-bold">{event.name}</span>
                    <span className="text-xl">Event Summary Report</span>
                    <span className="text-lg text-gray-600">
                      Date Range: {dayjs(event.startsAt).format('MM/DD/YYYY')} -{' '}
                      {dayjs(event.endsAt).format('MM/DD/YYYY')}
                    </span>
                  </div>

                  <div className="flex flex-row gap-14 px-4">
                    <div className="z-10 flex shrink-0 flex-col justify-around px-4 text-center text-white">
                      {configureTile(
                        'Total Leads Scanned',
                        reportData.totalLeadCount.toLocaleString(),
                      )}
                      {configureTile(
                        'Total Licenses Purchased',
                        reportData.totalPurchasedLicenseCount.toLocaleString(),
                      )}
                      {configureTile(
                        'Total Booth Staff',
                        reportData.totalBoothStaffCount.toLocaleString(),
                      )}
                      {configureTile(
                        'Busiest Day',
                        dayjs(reportData.busiestDay).format('MM/DD/YYYY'),
                      )}
                      {configureTile(
                        'Staff with Most Leads',
                        configureStaffWithMostLeads(reportData.staffWithMostLeads),
                      )}
                    </div>

                    <div
                      className={mergeClassNames(
                        'z-10 flex w-full flex-col gap-10 px-3',
                        printing === 'badgr' && 'gap-16',
                      )}
                    >
                      <div className="flex w-11/12 flex-col gap-2 rounded-xl bg-purple-dark px-2 py-3">
                        <span className="text-2xl font-bold text-white">Leads Breakdown</span>

                        <div className="flex h-full w-full flex-row justify-between gap-5">
                          <div className="flex h-full shrink-0 flex-col justify-around">
                            {_.map(reportData?.leadsByTemperature, (data) => (
                              <div className="flex flex-row items-center gap-1">
                                <div
                                  className="h-4 w-4 rounded-sm"
                                  style={{ background: data.color }}
                                />
                                <span className="wrap text-xs font-medium text-white">
                                  {data.value.toLocaleString()} {data.temperature}
                                </span>
                              </div>
                            ))}
                          </div>

                          <div className="h-[180px] w-full">
                            <ResponsiveBar
                              axisBottom={{
                                renderTick: CustomTick,
                              }}
                              axisTop={null}
                              axisRight={null}
                              axisLeft={null}
                              borderRadius="8px"
                              colors={({ data }) => data.color}
                              data={reportData?.leadsByTemperature}
                              enableLabel={false}
                              enableGridX={false}
                              indexBy="temperature"
                              isInteractive={false}
                              keys={['value']}
                              margin={{ top: 0, right: 30, bottom: 35, left: 0 }}
                              padding={0.4}
                              theme={{
                                axis: {
                                  ticks: {
                                    text: {
                                      fill: '#96959E',
                                      fontSize: '12px',
                                      fontWeight: '500',
                                    },
                                  },
                                },
                                grid: {
                                  line: {
                                    stroke: '#F3F4F6',
                                    strokeOpacity: 0.2,
                                    strokeWidth: 1,
                                    strokeDasharray: '6',
                                  },
                                },
                              }}
                              valueScale={{ type: 'linear' }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex w-11/12 flex-col gap-2 rounded-xl bg-blue-200 px-2 py-3">
                        <span className="text-2xl font-bold text-dark">Leads by Device</span>

                        <div className="flex h-60 w-full flex-row gap-20">
                          <div className="flex h-full w-full flex-col flex-wrap gap-1 text-sm">
                            {_.map(reportData?.leadsByUser, (data) => (
                              <div className="flex w-fit flex-col justify-center">
                                <span className="font-medium" style={{ color: data.color }}>
                                  {data.value.toLocaleString()}
                                </span>
                                <span className="wrap font-medium text-dark">{data.label}</span>
                              </div>
                            ))}
                          </div>

                          <div className="flex h-full w-[450px]">
                            <ResponsivePie
                              colors={{ datum: 'data.color' }}
                              data={reportData?.leadsByUser}
                              innerRadius={0.6}
                              isInteractive={false}
                              enableArcLabels={false}
                              enableArcLinkLabels={false}
                              layers={['arcs']}
                              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex w-11/12 flex-col gap-2 rounded-xl bg-purple-dark px-2 py-3">
                        <span className="text-2xl font-bold text-white">Leads By Date</span>

                        <div className="flex h-full w-full flex-row justify-between gap-6">
                          <div className="flex h-full max-h-[280px] shrink-0 flex-col justify-around overflow-y-hidden">
                            {_.map(reportData?.leadsPerDay, (data) => (
                              <div className="flex flex-row items-center gap-1 text-xs font-semibold">
                                <div
                                  className="rounded-sm px-1"
                                  style={{ background: data.color }}
                                >
                                  <span className="text-dark">{data.value.toLocaleString()}</span>
                                </div>
                                <span className="wrap text-white">
                                  {dayjs(data.label).format('MM/DD')}
                                </span>
                              </div>
                            ))}
                          </div>

                          <div className="h-[250px] w-full">
                            <ResponsiveBar
                              axisBottom={{
                                tickSize: 0,
                                tickPadding: 10,
                                tickRotation: 0,
                                tickValues: 4,
                                format: (value) => `${dayjs(value).format('MM/DD')}`,
                              }}
                              axisTop={null}
                              axisRight={null}
                              axisLeft={null}
                              borderRadius="8px"
                              colors={({ data }) => data.color}
                              data={reportData?.leadsPerDay}
                              enableLabel={false}
                              enableGridX={false}
                              indexBy="label"
                              isInteractive={false}
                              keys={['value']}
                              margin={{ top: 0, right: 20, bottom: 25, left: 0 }}
                              padding={0.4}
                              theme={{
                                axis: {
                                  ticks: {
                                    text: {
                                      fill: '#FFFFFF',
                                      fontSize: '12px',
                                      fontWeight: '500',
                                    },
                                  },
                                },
                                grid: {
                                  line: {
                                    stroke: '#F3F4F6',
                                    strokeOpacity: 0.2,
                                    strokeWidth: 1,
                                    strokeDasharray: '6',
                                  },
                                },
                              }}
                              valueScale={{ type: 'linear' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span
                    className={mergeClassNames(
                      'z-10 mx-6 mt-10 block text-center text-xs text-gray-500',
                      printing === 'badgr' && 'block',
                    )}
                  >
                    CONFIDENTIALITY NOTICE: This document is for the sole use of the intended
                    recipient(s) and may contain confidential and privileged information. Any
                    unauthorized review, use, disclosure or distribution is prohibited.
                  </span>
                </div>
              </div>
            ) : (
              renderLoadingOrError()
            )}
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default ExhibitorEventReports
