import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import _ from 'lodash'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { ArrowPathIcon } from '@heroicons/react/20/solid'

// Components
import { Button } from '../../components/Button'
import { DownloadIcon } from '../../components/DownloadIcon'
import { EventHeader } from '../../components/EventHeader'
import { StateContainer } from '../../components/StateContainer'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { TaskStoreContext } from '../../stores/TaskStore'

// Images
import EventstackLogo from '../../assets/images/eventstack_logo.svg'
import BadgrGray from '../../assets/images/badgr_gray.svg'
import BadgrTextLogo from '../../assets/images/badgr_text_logo.svg'
import FetchGray from '../../assets/images/fetch_gray.svg'
import FetchTextLogo from '../../assets/images/fetch_text_logo.svg'
import ReportHeader from '../../assets/images/report_header.svg'

// Utils & Service
import { createEventTransactionReport, getEventReport } from '../../services/reports.service'
import colors, { CHART_COLORS } from '../../utils/colors'
import { toast } from '../../utils/helpers'

const CustomTick = ({ value, x, y }) => {
  if (value === 'Hot')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥳
        </text>
      </g>
    )
  if (value === 'Warm')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🤔
        </text>
      </g>
    )
  if (value === 'Cold')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥶
        </text>
      </g>
    )
  return null
}

CustomTick.propTypes = {
  value: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

/**
 *
 * OrganizerEventReports
 *
 */
const OrganizerEventReports = observer(() => {
  // Context
  const { setParent, setTask, setType } = useContext(TaskStoreContext)
  const { event, organization } = useContext(NavigationStoreContext)

  // State
  const [loadingTransaction, setLoadingTransaction] = useState(false)
  const [loadingFetch, setLoadingFetch] = useState(false)
  const [loadingBadgr, setLoadingBadgr] = useState(false)
  const [fetchError, setFetchError] = useState(null)
  const [badgrError, setBadgrError] = useState(null)
  const [fetchReportData, setFetchReportData] = useState(null)
  const [badgrReportData, setBadgrReportData] = useState(null)
  const [printing, setPrinting] = useState(false)

  // Ref
  const fetchReportRef = useRef()
  const badgrReportRef = useRef()

  const handleError = (message) => toast(message, 'error')

  const handlePrintFetch = useReactToPrint({
    content: () => fetchReportRef.current,
    onAfterPrint: () => setPrinting(false),
  })
  const handlePrintBadgr = useReactToPrint({
    content: () => badgrReportRef.current,
    onAfterPrint: () => setPrinting(false),
  })

  useEffect(() => {
    setBadgrError(false)
    setFetchError(false)

    const getReportData = async () => {
      if (event.enableBadging) {
        const badgingData = await getEventReport(
          {
            type: 'badgr',
            eventId: event.id,
            orgId: organization.id,
          },
          (err) => {
            handleError(err)
            setBadgrError(true)
          },
          setLoadingBadgr,
        )

        if (badgingData) {
          // Convert chart data into proper formats
          const checkInsByCategory = _.map(badgingData.checkInsByCategory, (value, key) => {
            const data = {
              id: key,
              label: key,
              ...value,
            }

            _.forEach(event.attendeeCategories, (_value, category) => {
              const index = _.findIndex(_.keys(event.attendeeCategories), (k) => k === category)
              data[`${category}Color`] = CHART_COLORS[index]
            })

            return data
          })

          const categoryTotals = {}
          _.forEach(_.keys(event.attendeeCategories), (category) => {
            const index = _.findIndex(_.keys(event.attendeeCategories), (k) => k === category)
            const total = _.reduce(checkInsByCategory, (acc, data) => acc + data[category], 0)
            categoryTotals[category] = {
              value: total,
              color: CHART_COLORS[index],
            }
          })

          const checkInsByLocation = _.map(badgingData.checkInsByLocation, (value, key) => {
            const index = _.findIndex(_.keys(badgingData.checkInsByLocation), (k) => k === key)
            return {
              id: key,
              label: key,
              value,
              color: CHART_COLORS[index],
            }
          })

          const checkInsByDay = _.map(badgingData.checkInsByDay, (value, key) => {
            const index = _.findIndex(_.keys(badgingData.checkInsByDay), (k) => k === key)
            return {
              id: `${key}`,
              label: dayjs(key, 'YYYYMMDD').format('MM/DD/YYYY'),
              value,
              color: CHART_COLORS[index],
            }
          })

          setBadgrReportData({
            ...badgingData,
            checkInsByLocation,
            checkInsByDay,
            checkInsByCategory,
            categoryTotals,
          })
        }
      }

      if (event.enableLeadRetrieval) {
        const leadData = await getEventReport(
          {
            type: 'fetch',
            eventId: event.id,
            orgId: organization.id,
          },
          (err) => {
            handleError(err)
            setFetchError(true)
          },
          setLoadingFetch,
        )

        if (leadData) {
          // Convert lead data into the proper format
          const leadsByTemperature = _.map(leadData.leadsByTemperature, (value, key) => {
            let color = colors.purple.DEFAULT
            let temperature = 'Warm'
            if (key === 'great') {
              color = colors.teal.DEFAULT
              temperature = 'Hot'
            }
            if (key === 'cold') {
              color = colors.blue.DEFAULT
              temperature = 'Cold'
            }

            return {
              temperature,
              value,
              color,
            }
          })

          setFetchReportData({ ...leadData, leadsByTemperature })
        }
      }
    }

    if (event && organization) {
      getReportData()
    }
  }, [event, organization])

  /**
   * Configures a data tile using the specified `label` and `value`.
   * @param {string} label
   * @param {string} value
   */
  const configureTile = (label, value, width) => (
    <div
      className={mergeClassNames(
        'flex flex-col items-center rounded-lg p-4',
        printing && 'py-5',
        width || 'w-full',
      )}
      style={{
        background: 'linear-gradient(135deg, #000000 0%, #4E259D 50%, #A071FC 100%)',
      }}
    >
      <span className={mergeClassNames('text-sm font-semibold', printing && 'text-lg')}>
        {label}
      </span>
      <span className={mergeClassNames('text-2xl font-bold', printing && 'text-4xl')}>
        {value}
      </span>
    </div>
  )

  const renderLoadingOrError = (loading, error) => {
    if (loading) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
          <span className="text-2xl font-bold">Loading...</span>

          <span className="flex items-center pr-3">
            <div className="h-10 w-10">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    if (error) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold text-red">Error generating report.</span>
        </div>
      )
    }

    return null
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="h-full w-full flex-col space-y-3 overflow-y-auto overflow-x-hidden p-3">
          <div className="mb-3 flex flex-col items-start justify-between space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <EventHeader event={event} />
          </div>

          <div className="mt-1.5 flex flex-col">
            <span className="text-md font-bold">Reports</span>

            <span className="text-xs">Download event data as Excel files or PDFs.</span>

            <span className="text-md mt-4 font-bold">Excel Files</span>

            <div className="flex flex-row items-center justify-between gap-6">
              <span className="text-sm font-semibold">Transaction Report</span>
              <Button
                className="text-xs"
                icon={
                  loadingTransaction ? null : <DownloadIcon className="h-5 stroke-white sm:h-6" />
                }
                label="Download"
                loading={loadingTransaction}
                onClick={async () => {
                  const task = await createEventTransactionReport(
                    event.id,
                    handleError,
                    setLoadingTransaction,
                  )

                  if (task) {
                    setType('Download')
                    setParent({
                      type: 'event-transaction',
                      label: 'Transaction Report',
                      id: event.id,
                      fileName: `${event.name}_Transactions`,
                    })
                    setTask(task)
                  }
                }}
              />
            </div>

            <span className="text-md mt-4 font-bold">PDF Reports</span>

            {/* Fetch Report */}

            {event.enableLeadRetrieval && (
              <div className="mb-2 flex flex-row items-center justify-between">
                <span className="text-sm font-semibold">Event Fetch Lead Retrieval Data</span>

                <Button
                  disabled={!fetchReportData}
                  label="Print Fetch Report"
                  onClick={() => {
                    setPrinting('fetch')

                    setTimeout(() => {
                      handlePrintFetch()
                    }, 200)
                  }}
                />
              </div>
            )}

            {fetchReportData && !loadingFetch ? (
              <div className="relative max-w-[1200px] bg-white" ref={fetchReportRef}>
                <img
                  alt="logo"
                  className="absolute inset-x-auto top-32 z-0 h-[85%] w-full"
                  src={FetchGray}
                />

                <div
                  className={mergeClassNames(
                    'flex flex-col gap-10',
                    printing === 'fetch' && 'gap-14',
                  )}
                >
                  <div
                    className="z-10 flex flex-row items-center justify-between px-10 py-4"
                    style={{
                      backgroundImage: `url(${ReportHeader})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <img className="block h-auto w-60" src={EventstackLogo} alt="Eventstack" />
                    <img className="block h-auto w-36" src={FetchTextLogo} alt="Fetch" />
                  </div>

                  <div className="z-10 flex flex-col items-center">
                    <span className="text-3xl font-bold">{event.name}</span>
                    <span className="text-2xl">Fetch Lead Retrieval Data</span>
                    <span className="text-lg text-gray-600">
                      Event Dates: {dayjs(event.startsAt).format('MM/DD/YYYY')} -{' '}
                      {dayjs(event.endsAt).format('MM/DD/YYYY')}
                    </span>
                  </div>

                  <div className="z-10 flex justify-around gap-12 px-8 text-center text-white">
                    {configureTile(
                      'Total Exhibiting Companies',
                      fetchReportData.totalExhibitingCompanies,
                      'basis-1/3',
                    )}
                    {configureTile(
                      'Total Licenses Purchased',
                      fetchReportData.appLicenseCount,
                      'basis-1/3',
                    )}
                    {configureTile(
                      'Total Devices Rented',
                      fetchReportData.rentalDeviceCount,
                      'basis-1/3',
                    )}
                  </div>

                  <div className="z-10 flex justify-center gap-12 px-4 text-center text-white">
                    {configureTile(
                      'Total Free Licenses Activated',
                      fetchReportData.activatedFreeLicenseCount,
                      'basis-1/3',
                    )}
                    {configureTile(
                      'Total Revenue',
                      `$${fetchReportData.totalRevenue.toLocaleString()}`,
                      'basis-1/3',
                    )}
                  </div>

                  <div
                    className={mergeClassNames(
                      'z-10 flex w-full flex-col gap-6 px-8 sm:flex-row',
                      printing === 'fetch' && 'px-8',
                    )}
                  >
                    <div className="flex w-full flex-col gap-3 rounded-xl bg-purple-dark px-4 py-6">
                      <span className="text-2xl font-bold text-white">Leads Breakdown</span>

                      <div className="flex h-44 w-full flex-row justify-between gap-6">
                        <div className="flex h-full shrink-0 flex-col justify-around">
                          {_.map(fetchReportData.leadsByTemperature, (data) => (
                            <div className="flex flex-row items-center gap-1">
                              <div
                                className="h-4 w-4 rounded-sm"
                                style={{ background: data.color }}
                              />
                              <span className="wrap text-xs font-medium text-white">
                                {data.value.toLocaleString()} {data.temperature}
                              </span>
                            </div>
                          ))}
                        </div>

                        <div className="h-full w-[230px]">
                          <ResponsiveBar
                            axisBottom={{
                              renderTick: CustomTick,
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisLeft={null}
                            borderRadius="8px"
                            colors={({ data }) => data.color}
                            data={fetchReportData.leadsByTemperature}
                            enableLabel={false}
                            enableGridX={false}
                            indexBy="temperature"
                            isInteractive={false}
                            keys={['value']}
                            margin={{ top: 0, right: 20, bottom: 35, left: 0 }}
                            padding={0.4}
                            theme={{
                              axis: {
                                ticks: {
                                  text: {
                                    fill: '#96959E',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                  },
                                },
                              },
                              grid: {
                                line: {
                                  stroke: '#F3F4F6',
                                  strokeOpacity: 0.2,
                                  strokeWidth: 1,
                                  strokeDasharray: '6',
                                },
                              },
                            }}
                            valueScale={{ type: 'linear' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full flex-col gap-3 rounded-xl bg-blue-200 px-4 py-6">
                      <span className="text-2xl font-bold text-dark">Purchase Breakdown</span>

                      <div className="flex h-44 w-full flex-row gap-16">
                        <div className="flex h-full shrink-0 flex-col justify-start gap-5">
                          <div className="flex flex-col justify-center">
                            <span className="font-medium text-purple">
                              {fetchReportData.appLicenseCount.toLocaleString()}
                            </span>
                            <span className="font-medium text-dark">Apps</span>
                          </div>
                          <div className="flex flex-col justify-center">
                            <span className="font-medium text-blue">
                              {fetchReportData.rentalDeviceCount.toLocaleString()}
                            </span>
                            <span className="font-medium text-dark">Devices</span>
                          </div>
                        </div>

                        <div className="flex h-full w-[200px]">
                          <ResponsivePie
                            colors={{ datum: 'data.color' }}
                            data={[
                              {
                                color: colors.purple.DEFAULT,
                                id: 'apps',
                                value: fetchReportData.appLicenseCount,
                                label: 'Apps',
                              },
                              {
                                color: colors.blue.DEFAULT,
                                id: 'devices',
                                value: fetchReportData.rentalDeviceCount,
                                label: 'Devices',
                              },
                            ]}
                            innerRadius={0.6}
                            isInteractive={false}
                            enableArcLabels={false}
                            enableArcLinkLabels={false}
                            layers={['arcs']}
                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <span
                    className={mergeClassNames(
                      'z-10 mx-6 mt-10 block text-center text-xs text-gray-500',
                      printing === 'fetch' && 'block',
                    )}
                  >
                    CONFIDENTIALITY NOTICE: This document is for the sole use of the intended
                    recipient(s) and may contain confidential and privileged information. Any
                    unauthorized review, use, disclosure or distribution is prohibited.
                  </span>
                </div>
              </div>
            ) : (
              renderLoadingOrError(loadingFetch, fetchError)
            )}

            {/* Badgr Report */}

            {event.enableBadging && (
              <div className="mb-2 flex flex-row items-center justify-between">
                <span className="text-sm font-semibold">Event Badgr Check-In Data</span>

                <Button
                  disabled={!badgrReportData}
                  label="Print Badgr Report"
                  onClick={() => {
                    setPrinting('badgr')

                    setTimeout(() => {
                      handlePrintBadgr()
                    }, 200)
                  }}
                />
              </div>
            )}

            {badgrReportData && !loadingBadgr ? (
              <div className="relative max-w-[1200px] bg-white" ref={badgrReportRef}>
                <img
                  alt="logo"
                  className="absolute inset-x-auto top-32 z-0 h-[85%] w-full"
                  src={BadgrGray}
                />

                <div className="flex flex-col gap-8">
                  <div
                    className="z-10 flex flex-row items-center justify-between px-10 py-4"
                    style={{
                      backgroundImage: `url(${ReportHeader})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <img className="block h-auto w-60" src={EventstackLogo} alt="Eventstack" />
                    <img className="block h-auto w-36" src={BadgrTextLogo} alt="Badgr" />
                  </div>

                  <div className="z-10 flex flex-col items-center">
                    <span className="text-3xl font-bold">{event.name}</span>
                    <span className="text-2xl">Badgr Check-In Data</span>
                    <span className="text-lg text-gray-600">
                      Date Range: {dayjs(event.startsAt).format('MM/DD/YYYY')} -{' '}
                      {dayjs(event.endsAt).format('MM/DD/YYYY')}
                    </span>
                  </div>

                  <div className="flex flex-row gap-14 px-4">
                    <div className="z-10 flex shrink-0 flex-col justify-around px-4 text-center text-white">
                      {configureTile('Total Registrants', badgrReportData.totalAttendeeCount)}
                      {configureTile(
                        'Checked-in',
                        badgrReportData.totalAttendeeCount -
                          badgrReportData.notCheckedInAttendeeCount,
                      )}
                      {configureTile('No Shows', badgrReportData.notCheckedInAttendeeCount)}
                      {configureTile('Total Reprints', badgrReportData.reprintCount)}
                      {configureTile(
                        'Total Quick Badges Created',
                        badgrReportData.quickBadgesCount,
                      )}
                    </div>

                    <div
                      className={mergeClassNames(
                        'z-10 flex w-full flex-col gap-10 px-3',
                        printing === 'badgr' && 'gap-16',
                      )}
                    >
                      <div className="flex w-5/6 flex-col gap-2 rounded-xl bg-purple-dark px-2 py-3">
                        <span className="text-2xl font-bold text-white">Check-In By Category</span>

                        <div className="flex h-full w-full flex-row justify-between gap-6">
                          <div className="flex h-full w-[150px] shrink-0 flex-col justify-around">
                            {_.map(badgrReportData.categoryTotals, (data, key) => (
                              <div className="flex flex-row items-center gap-1 text-xs font-semibold">
                                <div
                                  className="rounded-sm px-1"
                                  style={{ background: data.color }}
                                >
                                  <span className="text-dark">{data.value.toLocaleString()}</span>
                                </div>
                                <span className="wrap truncate text-white">{key}</span>
                              </div>
                            ))}
                          </div>

                          <div className="h-[250px] w-[700px]">
                            <ResponsiveBar
                              axisBottom={{
                                tickSize: 0,
                                tickPadding: 10,
                                tickRotation: 0,
                                tickValues: 4,
                                format: (value) => `${dayjs(value).format('MM/DD')}`,
                              }}
                              axisTop={null}
                              axisRight={null}
                              axisLeft={null}
                              borderRadius="8px"
                              colors={CHART_COLORS}
                              colorsBy="indexValue"
                              data={badgrReportData.checkInsByCategory}
                              enableLabel={false}
                              enableGridX
                              enableGridY={false}
                              indexBy="label"
                              isInteractive={false}
                              keys={_.keys(event.attendeeCategories)}
                              margin={{
                                top: 0,
                                right: printing === 'badgr' ? 80 : 30,
                                bottom: 25,
                                left: 10,
                              }}
                              padding={0.4}
                              theme={{
                                axis: {
                                  ticks: {
                                    text: {
                                      fill: '#FFFFFF',
                                      fontSize: '12px',
                                      fontWeight: '500',
                                    },
                                  },
                                },
                                grid: {
                                  line: {
                                    stroke: '#F3F4F6',
                                    strokeOpacity: 0.2,
                                    strokeWidth: 1,
                                    strokeDasharray: '6',
                                  },
                                },
                              }}
                              valueScale={{ type: 'linear' }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex w-5/6 flex-col gap-2 rounded-xl bg-blue-200 px-2 py-3">
                        <span className="text-2xl font-bold text-dark">Check-In By Location</span>

                        <div className="flex h-52 w-full flex-row gap-20">
                          <div className="flex h-full w-[150px] flex-col justify-around text-sm">
                            {_.map(badgrReportData.checkInsByLocation, (data) => (
                              <div className="flex flex-col justify-center">
                                <span className="font-medium" style={{ color: data.color }}>
                                  {data.value}
                                </span>
                                <span className="wrap font-medium text-dark">{data.label}</span>
                              </div>
                            ))}
                          </div>

                          <div className="flex h-full w-[200px]">
                            <ResponsivePie
                              colors={{ datum: 'data.color' }}
                              data={badgrReportData.checkInsByLocation}
                              innerRadius={0.6}
                              isInteractive={false}
                              enableArcLabels={false}
                              enableArcLinkLabels={false}
                              layers={['arcs']}
                              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex w-5/6 flex-col gap-2 rounded-xl bg-purple-dark px-2 py-3">
                        <span className="text-2xl font-bold text-white">Check-In By Date</span>

                        <div className="flex h-full w-full flex-row justify-between gap-6">
                          <div className="flex h-full max-h-[350px] w-[100px] shrink-0 flex-col justify-around overflow-y-hidden">
                            {_.map(badgrReportData.checkInsByDay, (data) => (
                              <div className="flex flex-row items-center gap-1 text-xs font-semibold">
                                <div
                                  className="rounded-sm px-1"
                                  style={{ background: data.color }}
                                >
                                  <span className="text-dark">{data.value.toLocaleString()}</span>
                                </div>
                                <span className="wrap text-white">
                                  {dayjs(data.label).format('MM/DD')}
                                </span>
                              </div>
                            ))}
                          </div>

                          <div className="h-[250px] w-[750px]">
                            <ResponsiveBar
                              axisBottom={{
                                tickSize: 0,
                                tickPadding: 10,
                                tickRotation: 0,
                                tickValues: 4,
                                format: (value) => `${dayjs(value).format('MM/DD')}`,
                              }}
                              axisTop={null}
                              axisRight={null}
                              axisLeft={null}
                              borderRadius="8px"
                              colors={({ data }) => data.color}
                              data={badgrReportData.checkInsByDay}
                              enableLabel={false}
                              enableGridX={false}
                              indexBy="label"
                              isInteractive={false}
                              keys={['value']}
                              margin={{ top: 0, right: 20, bottom: 25, left: 0 }}
                              padding={0.4}
                              theme={{
                                axis: {
                                  ticks: {
                                    text: {
                                      fill: '#FFFFFF',
                                      fontSize: '12px',
                                      fontWeight: '500',
                                    },
                                  },
                                },
                                grid: {
                                  line: {
                                    stroke: '#F3F4F6',
                                    strokeOpacity: 0.2,
                                    strokeWidth: 1,
                                    strokeDasharray: '6',
                                  },
                                },
                              }}
                              valueScale={{ type: 'linear' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span
                    className={mergeClassNames(
                      'z-10 mx-6 mt-10 block text-center text-xs text-gray-500',
                      printing === 'badgr' && 'block',
                    )}
                  >
                    CONFIDENTIALITY NOTICE: This document is for the sole use of the intended
                    recipient(s) and may contain confidential and privileged information. Any
                    unauthorized review, use, disclosure or distribution is prohibited.
                  </span>
                </div>
              </div>
            ) : (
              renderLoadingOrError(loadingBadgr, badgrError)
            )}
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default OrganizerEventReports
