import React, { useCallback, useRef, useState } from 'react'
import * as PopperJS from '@popperjs/core'
import { usePopper } from 'react-popper'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

const Tooltip = ({
  children,
  content,
  display,
  enterDelay = 250,
  fullWidth,
  leaveDelay = 150,
  placement = 'bottom',
}) => {
  // State
  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  // Refs
  const enterTimeout = useRef()
  const leaveTimeout = useRef()

  // Context
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  })

  const handleMouseEnter = useCallback(() => {
    if (leaveTimeout.current) clearTimeout(leaveTimeout.current)
    enterTimeout.current = setTimeout(() => setIsOpen(true), enterDelay)
  }, [enterDelay])

  const handleMouseLeave = useCallback(() => {
    if (enterTimeout.current) clearTimeout(enterTimeout.current)
    leaveTimeout.current = setTimeout(() => setIsOpen(false), leaveDelay)
  }, [leaveDelay])

  return (
    <>
      <div
        ref={setReferenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={mergeClassNames('relative', fullWidth && 'w-full')}
      >
        {children}
      </div>

      {display && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={`transition-opacity ${isOpen ? 'z-10 opacity-100' : '-z-10 opacity-0'}`}
        >
          {content}
        </div>
      )}
    </>
  )
}

Tooltip.defaultProps = {
  display: true,
  enterDelay: 250,
  fullWidth: false,
  leaveDelay: 150,
  placement: 'bottom',
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  display: PropTypes.bool,
  enterDelay: PropTypes.number,
  fullWidth: PropTypes.bool,
  leaveDelay: PropTypes.number,
  placement: PropTypes.oneOf(PopperJS.placements),
}

export default Tooltip
