import React from 'react'
import ReactDOM from 'react-dom/client'
// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register'

// Local
import trunk from './configureStore'
import App from './App'
import './index.css'

registerSW({ immediate: true })

const root = document.getElementById('root')
trunk.init().then(() => ReactDOM.createRoot(root).render(<App />))
