import React from 'react'
import PropTypes from 'prop-types'

const ExhibitorIcon = ({ className }) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.78 11.5C4.33228 11.5 4.78 11.0523 4.78 10.5C4.78 11.0523 5.22772 11.5 5.78 11.5H15.78C16.3323 11.5 16.78 11.0523 16.78 10.5C16.78 11.0523 17.2277 11.5 17.78 11.5H19.78V17.5H18.78C18.2277 17.5 17.78 17.9477 17.78 18.5C17.78 17.9477 17.3323 17.5 16.78 17.5H4.78C4.22772 17.5 3.78 17.9477 3.78 18.5C3.78 17.9477 3.33228 17.5 2.78 17.5H1.78V11.5H3.78ZM3.78 5.5H1.78078L2.78078 1.5H18.7792L19.7792 5.5H17.78C17.2277 5.5 16.78 5.94772 16.78 6.5C16.78 5.94772 16.3323 5.5 15.78 5.5H5.78C5.22772 5.5 4.78 5.94772 4.78 6.5C4.78 5.94772 4.33228 5.5 3.78 5.5Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

ExhibitorIcon.defaultProps = {
  className: null,
}

ExhibitorIcon.propTypes = {
  className: PropTypes.string,
}

export default ExhibitorIcon
