/* eslint-disable import/no-cycle */
// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the list of users the user has access to.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getUsers = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url || '/users/')

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Add the user with the specified `payload`.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @return `data` created user
 */
export const addUser = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post('/users/', payload)

    setLoading(false)
    setSuccess(`${data.firstName} ${data.lastName} added.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Update the user with the specified `payload`.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @return `data` updated user
 */
export const updateUser = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/users/${payload.id}/`, payload)

    setLoading(false)
    setSuccess(`${data.firstName} ${data.lastName} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Delete the user with the specified `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteUser = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/users/${id}/`)

    setLoading(false)
    setSuccess('User deleted.')
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Resend an invitation to the user with the specified `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const resendUserInvitation = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(id)

  try {
    await axios.patch(`/users/${id}/`, { reinviteUser: true })

    setLoading(false)
    setSuccess('User invitation sent.')
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
