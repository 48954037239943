import React from 'react'
import PropTypes from 'prop-types'

const LocationIcon = ({ className }) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.05025 2.05025C4.78392 -0.683417 9.21608 -0.683418 11.9497 2.05025C14.6834 4.78392 14.6834 9.21608 11.9497 11.9497L7 16.8995L2.05025 11.9497C-0.683418 9.21608 -0.683418 4.78392 2.05025 2.05025ZM7.42857 4.42857C7.42857 4.19188 7.23669 4 7 4C6.76331 4 6.57143 4.19188 6.57143 4.42857V6.57143L4.42857 6.57143C4.19188 6.57143 4 6.76331 4 7C4 7.23669 4.19188 7.42857 4.42857 7.42857H6.57143V9.57143C6.57143 9.80812 6.76331 10 7 10C7.23669 10 7.42857 9.80812 7.42857 9.57143V7.42857H9.57143C9.80812 7.42857 10 7.23669 10 7C10 6.76331 9.80812 6.57143 9.57143 6.57143H7.42857V4.42857Z"
      fill="inherit"
    />
  </svg>
)

LocationIcon.defaultProps = {
  className: null,
}

LocationIcon.propTypes = {
  className: PropTypes.string,
}

export default LocationIcon
