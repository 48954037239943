/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { twMerge as mergeClassNames } from 'tailwind-merge'

const ColorPicker = ({ onChange, color, disabled, id, transparentOption, tooltip }) => {
  // State
  const [showColorPicker, setShowColorPicker] = useState(false)

  // Ref
  const colorPickerRef = useRef()
  const colorPickerButtonRef = useRef()

  const PRESETS = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#FFFFFF',
  ]

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!(colorPickerRef?.current && colorPickerButtonRef?.current)) return
      if (
        !colorPickerRef.current.contains(event.target) &&
        !colorPickerButtonRef.current.contains(event.target)
      ) {
        setShowColorPicker(false)
      }
    }

    document.addEventListener('mousedown', (event) => handleClickOutside(event))

    return () => {
      document.removeEventListener('mousedown', (event) => handleClickOutside(event))
    }
  }, [colorPickerRef, colorPickerButtonRef, setShowColorPicker])

  return (
    <div>
      <button
        data-testid={id}
        disabled={disabled}
        ref={colorPickerButtonRef}
        style={{ backgroundColor: color }}
        className={mergeClassNames(
          'h-[30px] w-[30px] rounded-full border bg-white',
          disabled && 'cursor-not-allowed',
          color === 'transparent' ? 'border-gray-dark' : 'border-gray-400',
        )}
        onClick={() => setShowColorPicker(!showColorPicker)}
        aria-label={tooltip}
        title={tooltip}
        type="button"
      ></button>

      {showColorPicker && colorPickerButtonRef.current && (
        <div
          className="absolute z-10"
          ref={colorPickerRef}
          style={{
            top: `${colorPickerButtonRef.current.getBoundingClientRect().top + 20}px`,
            left: `${colorPickerButtonRef.current.getBoundingClientRect().left - 450}px`,
          }}
        >
          <SketchPicker
            onChangeComplete={onChange}
            color={color}
            presetColors={transparentOption ? [...PRESETS, 'transparent'] : PRESETS}
          />
        </div>
      )}
    </div>
  )
}

ColorPicker.defaultProps = {
  disabled: false,
  id: null,
  tooltip: null,
  transparentOption: false,
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  transparentOption: PropTypes.bool,
}

export default ColorPicker
