import React from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 *
 * TextAlignControls
 *
 */
const TextAlignControls = ({ updateElement, element }) => (
  <div className="flex justify-between gap-2">
    <button
      className={mergeClassNames(
        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
        element.attrs.align === 'left' && 'bg-purple-400 hover:bg-purple-200',
      )}
      onClick={() => updateElement({ align: 'left' })}
      aria-label="Align text left"
      title="Align text left"
      type="button"
    >
      <box-icon type="regular" color="black" name="align-left" size="sm" />
    </button>
    <button
      className={mergeClassNames(
        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
        element.attrs.align === 'center' && 'bg-purple-400 hover:bg-purple-200',
      )}
      onClick={() => updateElement({ align: 'center' })}
      aria-label="Align text center"
      title="Align text center"
      type="button"
    >
      <box-icon type="regular" color="black" name="align-middle" size="sm" />
    </button>
    <button
      className={mergeClassNames(
        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
        element.attrs.align === 'right' && 'bg-purple-400 hover:bg-purple-200',
      )}
      onClick={() => updateElement({ align: 'right' })}
      aria-label="Align text right"
      title="Align text right"
      type="button"
    >
      <box-icon type="regular" color="black" name="align-right" size="sm" />
    </button>
  </div>
)

TextAlignControls.propTypes = {
  element: PropTypes.object.isRequired,
  updateElement: PropTypes.func.isRequired,
}

export default TextAlignControls
