import React from 'react'
import PropTypes from 'prop-types'

const ExhibitorIconSolid = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.55253 8H0.217899V14.4H1.77432V16H3.33074V14.4H12.6693V16H14.2257V14.4H15.7821V8H13.4475V4.8H16L14.8327 0H1.16732L0 4.8H2.55253V8ZM4.10895 4.8H11.891V8H4.10895V4.8Z"
      fill="inherit"
    />
  </svg>
)

ExhibitorIconSolid.defaultProps = {
  className: null,
}

ExhibitorIconSolid.propTypes = {
  className: PropTypes.string,
}

export default ExhibitorIconSolid
