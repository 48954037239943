/* @flow */
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

// Components
import { Alert } from '../../components/Alert'
import { AuthGradient } from '../../components/AuthGradient'
import { AuthSplash } from '../../components/AuthSplash'
import { Button } from '../../components/Button'
import { PasswordInput } from '../../components/PasswordInput'
import { TextInput } from '../../components/TextInput'

// Images
import Badger from '../../assets/images/badgr_text_logo.svg'
import Fetch from '../../assets/images/fetch_text_logo.svg'
import Shield from '../../assets/images/shield_security.svg'
import SMS from '../../assets/images/sms.svg'
import EventstackLogo from '../../assets/images/eventstack_logo.svg'

// Service
import { login } from '../../services/user.service'

// Store
import { RootStoreContext } from '../../stores/RootStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils
import useFlashMessage from '../../hooks/FlashMessage'

/**
 *
 * Login
 *
 */
const Login = () => {
  // Context
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isExhibitor } = useContext(RootStoreContext)
  const { setCurrentUser } = useContext(UserStoreContext)

  const redirect = searchParams.get('redirect')

  // State
  const [loading, setLoading] = useState(false)

  // Messages
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const { message: redirectedMessage, showMessage: showRedirectedMessage } = useFlashMessage(null)

  useEffect(() => {
    if (!location.pathname.includes('login')) navigate('/login', { replace: true })
  }, [location])

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm()

  /**
   * Handles form submission
   * - Redirects to verification page if successful
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const loginData = await login(redirect, data, showError, setLoading)

    if (loginData) {
      setCurrentUser({ email: data.email, ...loginData })
      navigate(`/verification${redirect ? `?redirect=${redirect}` : ''}`, {
        state: { password: data.password },
      })
    }
  }

  /**
   * Check if the user has been redirected to this page with a URL parameter
   *
   * - `confirmed` indicates that a user has been sent to this page from `ConfirmAccount`
   * - `reset` indicates that a user has been sent to this page from `ResetPassword`
   * - `created` indicates that a user has been sent to this page from an email confirmation link
   */
  useEffect(() => {
    if (location && location.search === '?confirmed=true')
      showRedirectedMessage('Your email address has been confirmed. You may now log in.')
    else if (location && location.search === '?reset=true')
      showRedirectedMessage('Your password has been changed. You may now log in.')
    else if (location && location.search === '?created=true')
      showRedirectedMessage('Your account has been created. You may now log in.')
  }, [])

  return (
    <div className="flex h-full w-full">
      <div className="hidden xl:block xl:basis-1/2">
        <AuthSplash exhibitor={redirect === 'exhibitor' || isExhibitor} />
      </div>
      <div className="relative flex h-full basis-full flex-col justify-between overflow-hidden bg-[#181823] px-8 py-12 text-white xl:basis-1/2">
        <AuthGradient />

        {redirect === 'exhibitor' || isExhibitor ? (
          <div className="w-[400px] self-center xl:w-2/3">
            <img src={Fetch} alt="" className="h-20" />

            <div className="flex items-center justify-end gap-x-3">
              <span className="font-bold">Powered by</span>{' '}
              <img src={EventstackLogo} alt="" className="h-10" />
            </div>
          </div>
        ) : (
          <div className="w-64 self-center sm:ml-8 sm:w-[184px] sm:self-start">
            <img src={EventstackLogo} alt="" />
          </div>
        )}

        <div className="max-w-[675px] sm:mx-auto sm:w-2/3">
          <h2 className="text-3xl font-bold">Portal Sign In</h2>

          <form className="z-1 relative mt-8" id="login-form" onSubmit={handleSubmit(onSubmit)}>
            {/* Status Messages */}
            {redirectedMessage && (
              <Alert className="mb-6" message={redirectedMessage} title="Success" type="success" />
            )}
            {error && <Alert className="mb-6" message={error} title="Error" type="error" />}

            {/* Form Fields */}
            <div className="space-y-10">
              <TextInput
                className="flex h-12 w-full rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
                data-testid="email"
                error={errors.email && 'This field is required'}
                autoComplete="email"
                id="email"
                icon={
                  <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
                    <img src={SMS} alt="email" className="h-5 stroke-purple" />
                  </div>
                }
                inputStyles="bg-transparent text-white text-md sm:text-base"
                name="email"
                placeholder="Email"
                type="email"
                {...register('email', { required: true })}
              />

              <Controller
                error={errors.password && 'This field is required'}
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <PasswordInput
                    id="password"
                    className="flex h-12 justify-center rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
                    icon={
                      <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
                        <img src={Shield} alt="email" className="h-5 stroke-purple" />
                      </div>
                    }
                    inputStyles="bg-transparent text-white text-md sm:text-base"
                    placeholder="Password"
                    renderIconStyles="fill-white"
                    autoComplete="new-password"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="mx-auto mb-12 mt-4 text-sm">
                <span className="pr-1">Forgot your password?</span>
                <a
                  href={`/password-reset-request${redirect ? `?redirect=${redirect}` : ''}`}
                  className="hover:text-purple-hover font-medium text-purple"
                >
                  Reset Password
                </a>
              </div>
            </div>

            <Button
              className="text-md flex h-12 items-center rounded-md border-purple sm:h-[60px] sm:text-base"
              fullWidth
              label="Sign in"
              loading={loading}
            />
          </form>
        </div>

        {redirect !== 'exhibitor' && !isExhibitor ? (
          <div className="flex items-center justify-center gap-x-6 text-lg font-bold sm:justify-start">
            <img src={Fetch} alt="" className="h-[39px]" /> +{' '}
            <img src={Badger} alt="" className="h-[30px]" />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default Login
