import React from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 *
 * FontStyleControls
 *
 */
const FontStyleControls = ({ element, updateElement }) => (
  <div className="flex">
    <button
      onClick={() => {
        let updatedStyle = 'bold'
        if (element.attrs.fontStyle === 'bold') {
          updatedStyle = 'normal'
        } else if (element.attrs.fontStyle === 'bold italic') {
          updatedStyle = 'italic'
        } else if (element.attrs.fontStyle === 'italic') {
          updatedStyle = 'bold italic'
        }

        updateElement({ fontStyle: updatedStyle })
      }}
      className={mergeClassNames(
        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
        element.attrs.fontStyle.includes('bold') && 'bg-purple-400 hover:bg-purple-200',
      )}
      aria-label="Format bold"
      title="Format bold"
      type="button"
    >
      <box-icon type="regular" color="black" name="bold" size="sm" />
    </button>
    <button
      onClick={() => {
        let updatedStyle = 'italic'
        if (element.attrs.fontStyle === 'italic') {
          updatedStyle = 'normal'
        } else if (element.attrs.fontStyle === 'bold italic') {
          updatedStyle = 'bold'
        } else if (element.attrs.fontStyle === 'bold') {
          updatedStyle = 'bold italic'
        }
        updateElement({ fontStyle: updatedStyle })
      }}
      className={mergeClassNames(
        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
        element.attrs.fontStyle.includes('italic') && 'bg-purple-400 hover:bg-purple-200',
      )}
      aria-label="Format italic"
      title="Format italic"
      type="button"
    >
      <box-icon type="regular" color="black" name="italic" size="sm" />
    </button>
    <button
      onClick={() => {
        let updatedDecoration = 'underline'
        if (element.attrs.textDecoration === 'underline') {
          updatedDecoration = ''
        }
        updateElement({ textDecoration: updatedDecoration })
      }}
      className={mergeClassNames(
        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
        element.attrs.textDecoration === 'underline' && 'bg-purple-400 hover:bg-purple-200',
      )}
      aria-label="Format underline"
      title="Format underline"
      type="button"
    >
      <box-icon type="regular" color="black" name="underline" size="sm" />
    </button>
  </div>
)

FontStyleControls.propTypes = {
  element: PropTypes.object.isRequired,
  updateElement: PropTypes.func.isRequired,
}

export default FontStyleControls
