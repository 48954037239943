import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

// Components
import { KeyIcon } from '../KeyIcon'
import { Modal } from '../Modal'
import { PhoneNumberInput } from '../PhoneNumberInput'
import { TextInput } from '../TextInput'

// Service
import { confirmPhoneChange, requestPhoneChange, updateUser } from '../../services/user.service'

// Utils
import { toast } from '../../utils/helpers'

/**
 * Renders a modal for configuring a phone number for MFA.
 */
const MfaModal = ({ setCurrentUser, setShowMfaReminder, user }) => {
  // State
  const [loadingVerification, setLoadingVerification] = useState(false)
  const [verifying, setVerifying] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    register,
  } = useForm({
    defaultValues: {
      phoneNumber: '',
      code: '',
    },
  })

  /**
   * Handles submitting the phone number verification form.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    if (verifying) {
      await confirmPhoneChange(
        data.code,
        handleErrors,
        setLoadingVerification,
        () => {
          handleSuccess(
            'Multi-Factor Authentication is now configured to go to your phone number.',
          )
          setVerifying(false)
        },
        setCurrentUser,
      )

      await updateUser(
        { id: user.id, hasViewedMfaNotification: true, mfaDevice: 'Phone' },
        handleErrors,
        () => {},
        () => {},
        setCurrentUser,
      )
    } else {
      await requestPhoneChange(data.phoneNumber, handleErrors, setLoadingVerification, (m) => {
        handleSuccess(m)
        setVerifying(true)
      })
    }
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Not Now',
          onClick: () =>
            updateUser(
              { id: user.id, hasViewedMfaNotification: true },
              handleErrors,
              () => {},
              () => setTimeout(() => setShowMfaReminder(true), 750),
              setCurrentUser,
            ),
        },
        {
          type: 'submit',
          label: verifying ? 'Confirm Code' : 'Add Phone Number',
          onClick: handleSubmit(onSubmit),
        },
      ]}
      icon={<KeyIcon className="h-8 stroke-white" />}
      content={
        <div className="mt-3 flex flex-col text-center sm:mt-5">
          <div className="col-span-full">
            <PhoneNumberInput control={control} errors={errors} name="phoneNumber" />
          </div>

          {verifying && (
            <div className="col-span-full mt-4 flex flex-col">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<KeyIcon className="ml-1.5 h-4 stroke-purple" />}
                data-testid="code"
                error={errors.code && 'This field is required'}
                fullWidth
                id="code"
                inputStyles="rounded-none font-nunito"
                name="code"
                nunito
                placeholder="Verification Code"
                {...register('code', { required: true })}
              />

              <button
                className="mr-1 place-self-end"
                type="button"
                onClick={async () => {
                  await requestPhoneChange(
                    getValues().phoneNumber,
                    handleErrors,
                    setLoadingVerification,
                    (m) => {
                      handleSuccess(m)
                      setVerifying(true)
                    },
                  )
                }}
              >
                <span className="text-sm font-medium text-purple">Resend Verification Code</span>
              </button>
            </div>
          )}
        </div>
      }
      description={[
        'Multi-Factor Authentication is configured to go to your email address. Add your phone number below to verify via text message.',
        'This can be adjusted in your Profile at any time.',
      ]}
      loading={loadingVerification}
      open
      title="Configure Multi-Factor Authentication"
    />
  )
}

MfaModal.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  setShowMfaReminder: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

export default MfaModal
