import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import {
  ArrowUpTrayIcon,
  PlusIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Email from '../../assets/images/email.svg'
import Inbox from '../../assets/images/inbox.svg'
import Memo from '../../assets/images/memo.svg'
import User from '../../assets/images/user.svg'
import Suitcase from '../../assets/images/suitcase.svg'

// Files
import template from '../../assets/files/attendee_import_template.xlsx'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { DownloadIcon } from '../../components/DownloadIcon'
import { EventHeader } from '../../components/EventHeader'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { PhoneNumberInput } from '../../components/PhoneNumberInput'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { UserIcon } from '../../components/UserIcon'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { TaskStoreContext } from '../../stores/TaskStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import {
  addAttendee,
  deleteAttendee,
  getAttendees,
  updateAttendee,
  uploadAttendeeList,
} from '../../services/attendees.service'
import { getEvent, updateEvent } from '../../services/events.service'

// Utils & Styles
import { handlePagination, toast } from '../../utils/helpers'

const DEFAULT = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  title: '',
}

/**
 *
 * EventAttendees
 *
 */
const EventAttendees = observer(() => {
  // Context
  const { status, setParent, setTask, setType } = useContext(TaskStoreContext)
  const { event, setEvent } = useContext(NavigationStoreContext)
  const { isEEUser, isOrganizationAdmin, user } = useContext(UserStoreContext)
  const { eventId, orgId } = useParams()

  const readOnly = event ? event.enableRegistrationSync : false

  // State
  const [loadingAttendees, setLoadingAttendees] = useState(false)
  const [loadingSync, setLoadingSync] = useState(false)
  const [attendees, setAttendees] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState(null)
  const [showAttendeeModal, setShowAttendeeModal] = useState(false)
  const [loadingAttendee, setLoadingAttendee] = useState(false)
  const [editAttendee, setEditAttendee] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [loadingImport, setLoadingImport] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [importedFile, setImportedFile] = useState(null)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Ref
  const searchInputRef = useRef(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = (m) => toast(m, 'success')

  /**
   * Gets the updated list of attendees; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedAttendeeList = async (url) => {
    const response = await getAttendees(url, handleErrors, setLoadingAttendees, () => {})

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setAttendees(response.results)
    }
  }

  useEffect(() => {
    const getUpdatedEvent = async () => {
      const e = await getEvent(
        orgId,
        eventId,
        () => {},
        () => {},
        () => {},
      )
      setEvent(e)
    }

    getUpdatedEvent()
  }, [status])

  /**
   * When the filter or row count changes, get the updated list of attendees.
   */
  useEffect(() => {
    if (filter) {
      getUpdatedAttendeeList(`/events/${eventId}/attendees/?limit=${perPage}&${filter}`)
    } else {
      getUpdatedAttendeeList(`/events/${eventId}/attendees/?limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterAttendees = useCallback(_.debounce(updateSearch, 500), [])

  const resetForm = () => {
    setEditAttendee(false)
    setShowAttendeeModal(false)
    reset(DEFAULT)
  }

  /**
   * Handles triggering a registration sync.
   */
  const triggerRegistrationSync = async () => {
    const result = await updateEvent(
      { id: eventId, triggerAttendeeSyncAllTime: true },
      handleErrors,
      setLoadingSync,
      () => {},
    )

    if (result) {
      handleSuccesses('Attendee sync triggered.')
    }
  }

  /**
   * Handles submitting the add or edit attendee forms.
   * @param {object} data
   */
  const onSubmit = (data) => {
    if (editAttendee) {
      updateAttendee(eventId, data, handleErrors, setLoadingAttendee, (m) => {
        getUpdatedAttendeeList(`/events/${eventId}/attendees/?limit=${perPage}`)
        handleSuccesses(m)
        resetForm()
      })
    } else {
      addAttendee(eventId, data, handleErrors, setLoadingAttendee, (m) => {
        getUpdatedAttendeeList(`/events/${eventId}/attendees/?limit=${perPage}`)
        handleSuccesses(m)
        resetForm()
      })
    }
  }

  let actions = [
    {
      type: 'cancel',
      label: 'Cancel',
      onClick: () => {
        setTimeout(() => {
          reset(DEFAULT)
          setEditAttendee(false)
        }, 500)
      },
    },
    {
      type: 'submit',
      label: editAttendee ? 'Save' : 'Add Attendee',
      onClick: handleSubmit(onSubmit),
    },
  ]

  if (event && event.enableRegistrationSync) {
    actions = [
      {
        type: 'cancel',
        label: 'Close',
        onClick: () => {
          setTimeout(() => {
            setEditAttendee(false)
          }, 500)
        },
      },
    ]
  } else if (editAttendee) {
    actions.push({
      type: 'delete',
      label: 'Delete',
      onClick: () =>
        deleteAttendee(eventId, editAttendee.id, handleErrors, setLoadingAttendee, (m) => {
          getUpdatedAttendeeList(`/events/${eventId}/attendees/?limit=${perPage}`)
          handleSuccesses(m)
          resetForm()
        }),
    })
  }

  const columns = [
    {
      id: 'name',
      grow: 1,
      name: 'Name',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => (
        <div className="flex flex-col">
          <span className="text-sm font-bold text-dark">
            {row.firstName} {row.lastName}
          </span>

          <span className="text-xs text-gray-600">{row.email}</span>
        </div>
      ),
      sortable: true,
      sortBy: 'first_name',
    },
    {
      id: 'companyName',
      grow: 1,
      name: 'Company/Title',
      selector: (row) => row.companyName,
      cell: (row) => (
        <div className="flex flex-col">
          {!row.companyName && !row.title ? (
            <span className="text-xs text-gray-600">N/A</span>
          ) : (
            <>
              <span className="text-sm font-bold text-dark">{row.companyName}</span>

              <span className="text-xs text-gray-600">{row.title}</span>
            </>
          )}
        </div>
      ),
      sortable: true,
      sortBy: 'company_name',
    },
  ]

  if (isOrganizationAdmin(orgId) && isEEUser) {
    columns.push({
      id: 'view/edit',
      grow: 0.25,
      name: '',
      cell: (row) => (
        <button
          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5 disabled:cursor-not-allowed disabled:opacity-50"
          disabled={row.id === user.id}
          type="button"
          onClick={() => {
            const attendeeToEdit = {
              id: row.id,
              firstName: row.firstName,
              lastName: row.lastName,
              email: row.email,
              companyName: row.companyName,
              title: row.title,
              phoneNumber: row.phoneNumber,
            }

            reset(attendeeToEdit)

            setEditAttendee(attendeeToEdit)
            setShowAttendeeModal(true)
          }}
        >
          {readOnly ? 'View' : 'Edit'}
        </button>
      ),
      center: true,
    })
  }

  const configureModalIcon = () => {
    if (event && event.enableRegistrationSync) return <UserIcon className="stroke-white" />
    return editAttendee ? <img src={Edit} alt="Icon" /> : <img src={Add} alt="Icon" />
  }

  const configureModalTitle = () => {
    if (event && event.enableRegistrationSync) return 'View'
    return editAttendee ? 'Edit' : 'Add Attendee'
  }

  const renderFileUploader = () => {
    if (uploadFile) {
      return (
        <div className="flex flex-col">
          <FileUploader
            acceptedFileTypes={[
              'text/csv',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
            autoSave
            handleUploadToServer={async (file) => {
              setImportedFile(file)
              setUploadFile(null)
            }}
            id="file"
            maxFiles={1}
            type={null}
          />

          <div className="-mt-4 mr-2 flex flex-row place-items-center justify-between">
            <span className="text-xs italic text-gray-500">
              Upload a single file that is .xlsx, .xlsm or .csv
            </span>

            <button className="place-self-end" type="button" onClick={() => setUploadFile(null)}>
              <span className="text-xs">Cancel Upload</span>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-row items-center space-x-4 self-center">
        <span className="text-sm text-gray-500">
          {importedFile ? importedFile.name : 'Upload File'}
        </span>

        <Button
          background="bg-white"
          icon={<img src={Inbox} alt="Upload" className="h-5" />}
          label={importedFile ? 'Change' : 'Upload'}
          onClick={() => setUploadFile(true)}
          outlined
        />
      </div>
    )
  }

  const hasCompletedRegistrationConfig =
    event &&
    event.registrationType &&
    event.apiKey &&
    event.externalAccountId &&
    event.externalEventId

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="mb-3 flex flex-col items-start justify-between space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <EventHeader event={event} />

            <div className="flex flex-row items-center space-x-1 sm:flex-col sm:items-end sm:space-y-1">
              {!readOnly && isOrganizationAdmin(orgId) && isEEUser && (
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  icon={<ArrowUpTrayIcon className="h-5 stroke-white sm:h-5" />}
                  label="Import Attendees"
                  onClick={() => setShowImportModal(true)}
                />
              )}

              {readOnly && isEEUser && hasCompletedRegistrationConfig && (
                <div className="flex flex-col space-y-1">
                  <Button
                    background="bg-purple border-purple hover:bg-purple-600"
                    loading={loadingSync}
                    label="Resync Attendees"
                    onClick={triggerRegistrationSync}
                  />

                  {event.attendeesLastSyncedAt && !event.attendeeSyncStatusMessage && (
                    <span className="text-xs">
                      Last Updated {dayjs(event.attendeesLastSyncedAt).format('MM/DD/YYYY h:mm A')}
                    </span>
                  )}

                  {event.attendeeSyncStatusMessage && (
                    <span className="text-xs text-error">{event.attendeeSyncStatusMessage}</span>
                  )}
                </div>
              )}

              {event && event.attendeeListModifiedAt && (
                <span className="text-xs font-semibold">
                  Last {readOnly ? 'Sync' : 'Import'}:{' '}
                  {dayjs(event.attendeeListModifiedAt).format('MM/DD/YYYY h:mm A')}
                </span>
              )}
            </div>
          </div>

          <span className="text-md font-bold">Attendees</span>

          <div className="flex w-full flex-row items-center justify-between">
            <TextInput
              className="w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      filterAttendees('')
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                filterAttendees(e.target.value)
                setSearchTerm(e.target.value)
              }}
              placeholder="Looking for something?"
              ref={searchInputRef}
              type="search"
              value={searchTerm}
            />

            {!readOnly && isOrganizationAdmin(orgId) && isEEUser && (
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                icon={<PlusIcon className="h-5 sm:h-6" />}
                label="Add Attendee"
                onClick={() => setShowAttendeeModal(true)}
              />
            )}
          </div>

          <DataTable
            columns={columns}
            data={attendees}
            defaultSortFieldId="name"
            defaultSortAsc
            onChangePage={(page) =>
              handlePagination(
                page,
                currentPage,
                perPage,
                totalRows,
                pages,
                setCurrentPage,
                getUpdatedAttendeeList,
                `/events/${eventId}/attendees/?limit=`,
                filter,
              )
            }
            onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
            onSort={(column, direction) => {
              const d = direction === 'asc' ? '' : '-'
              const url = `/events/${eventId}/attendees/?order_by=${d}${column.sortBy}&limit=${perPage}`
              getUpdatedAttendeeList(url)
            }}
            pagination
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginationTotalRows={totalRows}
            paginationServer
            progressPending={loadingAttendees}
            sortServer
          />
        </div>
      </StateContainer>

      <Modal
        actions={actions}
        icon={configureModalIcon()}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <div className="flex flex-col sm:flex-row sm:space-x-2">
              <TextInput
                className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="User" className="ml-2 h-4" src={User} />}
                data-testid="firstName"
                disabled={(event && event.enableRegistrationSync) || loadingAttendee}
                error={errors.firstName && 'This field is required'}
                fullWidth
                id="firstName"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="firstName"
                nunito
                label="First Name"
                placeholder="First Name"
                {...register('firstName', { required: true })}
              />

              <TextInput
                className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="User" className="ml-2 h-4" src={User} />}
                data-testid="lastName"
                disabled={(event && event.enableRegistrationSync) || loadingAttendee}
                error={errors.lastName && 'This field is required'}
                fullWidth
                id="lastName"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="lastName"
                nunito
                label="Last Name"
                placeholder="Last Name"
                {...register('lastName', { required: true })}
              />
            </div>

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
              data-testid="email"
              disabled={(event && event.enableRegistrationSync) || loadingAttendee}
              error={errors.email && 'This field is required'}
              fullWidth
              id="email"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="email"
              nunito
              label="Email"
              placeholder="Email"
              {...register('email')}
            />

            <PhoneNumberInput
              control={control}
              disabled={(event && event.enableRegistrationSync) || loadingAttendee}
              errors={errors}
              label="Phone Number"
              name="phoneNumber"
            />

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Company" className="ml-1.5 h-4" src={Suitcase} />}
              data-testid="company"
              disabled={(event && event.enableRegistrationSync) || loadingAttendee}
              error={errors.companyName && 'This field is required'}
              fullWidth
              id="company"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="companyName"
              nunito
              label="Company"
              placeholder="Company"
              {...register('companyName')}
            />

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Title" className="ml-1.5 h-4" src={Memo} />}
              data-testid="title"
              disabled={(event && event.enableRegistrationSync) || loadingAttendee}
              error={errors.title && 'This field is required'}
              fullWidth
              id="title"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="title"
              nunito
              label="Title"
              placeholder="Job Title"
              {...register('title')}
            />
          </div>
        }
        loading={loadingAttendee}
        onClose={() => {
          setShowAttendeeModal(false)

          setTimeout(() => {
            reset(DEFAULT)
            setEditAttendee(false)
          }, 500)
        }}
        open={showAttendeeModal}
        setOpen={setShowAttendeeModal}
        title={configureModalTitle()}
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setUploadFile(null)
              setImportedFile(null)
            },
          },
          {
            type: 'submit',
            label: 'Import',
            onClick: async () => {
              const task = await uploadAttendeeList(
                eventId,
                { file: importedFile.file },
                handleErrors,
                setLoadingImport,
              )

              if (task) {
                // Clear & close the modal
                setUploadFile(null)
                setImportedFile(null)
                setShowImportModal(false)

                // Set up the task to watch
                setType('Upload')
                setParent({ type: 'event', id: task.event })
                setTask(task)
              }
            },
          },
        ]}
        icon={<DownloadIcon className="h-5 stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <Button
              background="bg-green-50"
              className="self-center"
              label="Download Template"
              onClick={() => {
                // Download the template
                const downloadLink = document.createElement('a')
                downloadLink.href = template
                downloadLink.download = 'Executivevents Attendee Import Template.xlsx'
                downloadLink.target = '_blank'
                downloadLink.click()
                downloadLink.remove()
              }}
            />

            {renderFileUploader()}
          </div>
        }
        loading={loadingImport}
        onClose={() => {
          setShowImportModal(false)
        }}
        open={showImportModal}
        setOpen={setShowImportModal}
        title="Import"
      />
    </div>
  )
})

export default EventAttendees
