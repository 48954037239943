import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { renderToStaticMarkup } from 'react-dom/server'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { DoodleSticks } from '../DoodleSticks'

// Utils
import { joinClassNames } from '../../utils/helpers'

const DOODLE_BACKGROUND = {
  backgroundImage: `url('data:image/svg+xml;utf8, ${encodeURIComponent(
    renderToStaticMarkup(<DoodleSticks fill="#8c56f6" opacity={0.05} />),
  )}')`,
  backgroundSize: 'cover',
  boxShadow: '0px 8px 15px rgba(140, 86, 246, 0.1)',
}

const TAB_BACKGROUND = {
  backgroundColor: '#8c56f626',
}

/**
 *
 * TabSelector
 *
 * - Displays tab options to toggle between
 * - Displays content of tab underneath
 */
const TabSelector = ({ tabs, tabStyle, center }) => {
  // State
  const [active, setActive] = useState(tabs[0])

  return (
    <div className="w-full">
      <div>
        <nav
          className={mergeClassNames(
            'flex',
            center && 'justify-center',
            tabStyle === 'default' && 'space-x-2 sm:space-x-6',
          )}
          aria-label="Tabs"
        >
          {tabs.map((tab) => {
            const activeTab = active.id === tab.id

            return (
              <button
                type="button"
                key={tab.name}
                onClick={() => setActive(tab)}
                className={joinClassNames(
                  activeTab
                    ? 'bg-[#8c56f626] text-purple-700'
                    : 'bg-[#ffffff52] text-gray-900 hover:bg-purple-200 hover:text-purple-700',
                  'px-1.5 py-1 text-sm sm:px-3.5',
                  tabStyle === 'tabs'
                    ? 'w-[197px] border-b-[3px] font-bold sm:text-xs'
                    : 'rounded-full font-medium sm:text-lg',
                  activeTab && tabStyle === 'tabs' ? ' border-purple' : null,
                  !activeTab && tabStyle === 'tabs' ? ' border-transparent' : null,
                )}
                style={
                  activeTab ? (tabStyle === 'tabs' ? TAB_BACKGROUND : DOODLE_BACKGROUND) : null
                }
                aria-current={activeTab ? 'page' : undefined}
              >
                {tab.name}
              </button>
            )
          })}
        </nav>
      </div>

      <div className="mt-4 flex">{active.content}</div>
    </div>
  )
}

TabSelector.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabStyle: PropTypes.oneOf(['default', 'tabs']),
  center: PropTypes.bool,
}

TabSelector.defaultProps = {
  tabStyle: 'default',
  center: false,
}

export default TabSelector
