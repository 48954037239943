import React, { useEffect, useLayoutEffect } from 'react'
import { createBrowserHistory } from 'history'
import { RouterStore } from '@superwf/mobx-react-router'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import * as Sentry from '@sentry/react'
import { Toaster } from 'react-hot-toast'

// Routes
import { Routes } from './pages/Routes'

// Utils
import { useImmerState } from './utils/helpers'

// The env variable is not converted to a bool - must check for the explicit string
if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  const CONFIG = {
    release: import.meta.env.VITE_SENTRY_RELEASE_GIT_HASH,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    ignoreErrors: ['Non-Error promise rejection captured with value'],
  }

  if (import.meta.env.VITE_SENTRY_REPLAY_ENABLED === 'true') {
    CONFIG.replaysSessionSampleRate = 0.1
    CONFIG.replaysOnErrorSampleRate = 1.0
    CONFIG.integrations = [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ]
  }

  Sentry.init(CONFIG)
}

// Create mobx store with history
const browserHistory = createBrowserHistory()
const routingStore = new RouterStore(browserHistory)
const stores = {
  routing: routingStore,
}

const ONE_HOUR = 3600000 // milliseconds

const RefreshBrowserRouter = ({ ...rest }) => {
  /**
   * Reloads the window after ONE_HOUR has elapsed
   */
  useEffect(() => {
    const id = setInterval(() => {
      window.location.reload(true)
    }, ONE_HOUR)

    return () => clearInterval(id)
  }, [])

  return <BrowserRouter {...rest} />
}

function App() {
  // State
  const [history, setHistory] = useImmerState({
    action: routingStore.history.action,
    location: routingStore.history.location,
  })

  useLayoutEffect(() => routingStore.subscribe(setHistory), [])

  return (
    <Provider {...stores}>
      <Toaster />
      <RefreshBrowserRouter
        location={history.location}
        navigationType={history.action}
        navigator={routingStore.history}
      >
        <Routes />
      </RefreshBrowserRouter>
    </Provider>
  )
}

export default App
