/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { renderToStaticMarkup } from 'react-dom/server'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'

// Components
import { DoodleSticks } from '../DoodleSticks'

// Images
import CheckCircle from '../../assets/images/check_circle.svg'
import Tasklist from '../../assets/images/tasklist.svg'

const EMAIL_TASKS = [
  'send_event_reminder_email',
  'send_kbyg_for_exhibitors',
  'send_attendee_scan_and_go_email',
  'create_event_emails',
]
const ATTENDEE_TASKS = ['upload_attendees']
const API_TASKS = ['sync_api']
const EXHIBITOR_TASKS = ['upload_exhibitors']
const ORGANIZER_LICENSES_TASKS = ['set_license_pricing_and_dates']
const EXHIBITOR_LICENSES_TASKS = ['has_purchased_licenses', 'has_assigned_licenses']
const KIOSK_TASKS = ['set_up_badgr_kiosk']
const BADGE_TASKS = ['create_badge']
const QUALIFIER_TASKS = ['has_configured_qualifiers']
const MATERIAL_TASKS = ['has_configured_materials']
const REPORT_TASKS = ['has_downloaded_event_data']

/**
 * TasklistTile
 *
 * - Displays task list
 */
const TasklistTile = ({ eventUrlPrefix, tasklist }) => {
  // Context
  const navigate = useNavigate()

  const doodle = encodeURIComponent(
    renderToStaticMarkup(<DoodleSticks fill="#000000" opacity={0.04} />),
  )

  /**
   * Navigates the user to the appropriate page based on the task identifier.
   * @param {string} identifier
   */
  const configureNavigate = (identifier) => {
    if (EMAIL_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/emails`)
    } else if (ATTENDEE_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/data-management/attendees`)
    } else if (API_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/data-management/api-config`)
    } else if (EXHIBITOR_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/lead-retrieval/exhibitors`)
    } else if (ORGANIZER_LICENSES_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/lead-retrieval/licenses`)
    } else if (EXHIBITOR_LICENSES_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/licenses`)
    } else if (KIOSK_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/check-in/settings`)
    } else if (BADGE_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/check-in/badge-builder`)
    } else if (QUALIFIER_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/app-setup/qualifiers`)
    } else if (MATERIAL_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/app-setup/materials`)
    } else if (REPORT_TASKS.includes(identifier)) {
      navigate(`${eventUrlPrefix}/reports`)
    }
  }

  return (
    <div className="h-max-[400px] flex h-full w-full flex-col rounded-lg bg-white p-3 shadow-lg">
      <div
        className="relative flex h-32 w-full place-content-center rounded-[10px] bg-teal"
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8, ${doodle}')`,
          backgroundSize: 'cover',
        }}
      >
        <img className="absolute top-[-10px] h-[8.5rem]" src={Tasklist} alt="Tasklist" />
      </div>

      <span className="text-md mt-3 font-bold">Tasklist</span>

      <div className="mt-6 flex flex-col space-y-2">
        {_.map(
          _.sortBy(tasklist, (t) => t.status),
          (t) => (
            <div
              className={mergeClassNames(
                'flex w-full flex-row place-items-center justify-between space-x-2 rounded-full bg-[#F8F8F8] px-3 py-2',
                !t.isComplete && 'border-[1px] border-purple',
              )}
              key={`task:${t.title}`}
            >
              <div className="flex w-full flex-row items-center space-x-2">
                {!t.isComplete ? (
                  <div className="h-4 w-4 flex-none rounded-full bg-gray-400" />
                ) : (
                  <img alt="Complete" className="h-4" src={CheckCircle} />
                )}

                <span className="text-sm font-bold">{t.title}</span>
              </div>

              <button onClick={() => configureNavigate(t.identifier)} type="button">
                <ArrowRightIcon className="w-6 fill-purple hover:fill-purple-800" />
              </button>
            </div>
          ),
        )}
      </div>
    </div>
  )
}

TasklistTile.propTypes = {
  eventUrlPrefix: PropTypes.string.isRequired,
  tasklist: PropTypes.array.isRequired,
}

export default TasklistTile
