import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * UnlockIcon
 *
 */
const UnlockIcon = ({ className }) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 0.5C4.23858 0.5 2 2.73858 2 5.5V7.5C0.895431 7.5 0 8.39543 0 9.5V14.5C0 15.6046 0.89543 16.5 2 16.5H12C13.1046 16.5 14 15.6046 14 14.5V9.5C14 8.39543 13.1046 7.5 12 7.5H4V5.5C4 3.84315 5.34315 2.5 7 2.5C8.39651 2.5 9.57246 3.45512 9.90555 4.74926C10.0432 5.28411 10.5884 5.6061 11.1232 5.46844C11.6581 5.33078 11.9801 4.78559 11.8424 4.25074C11.2874 2.09442 9.33117 0.5 7 0.5Z"
      fill="inherit"
    />
  </svg>
)

UnlockIcon.defaultProps = {
  className: null,
}

UnlockIcon.propTypes = {
  className: PropTypes.string,
}

export default UnlockIcon
