import React from 'react'
import PropTypes from 'prop-types'

const MoneyIcon = ({ fill }) => (
  <svg
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-[55px] sm:w-[73px]"
  >
    <g filter="url(#filter0_d_17_908)">
      <rect
        x="8.66669"
        y="7"
        width="50"
        height="50"
        rx="6"
        fill={fill}
        shapeRendering="crispEdges"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7436 21.5C21.639 21.5 20.7436 22.3954 20.7436 23.5V33.5C20.7436 34.6046 21.639 35.5 22.7436 35.5H23.9743V25H40.1282V23.5C40.1282 22.3954 39.2328 21.5 38.1282 21.5H22.7436ZM27.2052 30.5C27.2052 29.3954 28.1006 28.5 29.2052 28.5H44.5898C45.6943 28.5 46.5898 29.3954 46.5898 30.5V40.5C46.5898 41.6046 45.6943 42.5 44.5898 42.5H29.2052C28.1006 42.5 27.2052 41.6046 27.2052 40.5V30.5ZM36.8975 39C38.6818 39 40.1282 37.433 40.1282 35.5C40.1282 33.567 38.6818 32 36.8975 32C35.1132 32 33.6667 33.567 33.6667 35.5C33.6667 37.433 35.1132 39 36.8975 39Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_17_908"
        x="0.666687"
        y="0"
        width="72"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="4" />
        <feGaussianBlur stdDeviation="5.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17_908" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17_908" result="shape" />
      </filter>
    </defs>
  </svg>
)

MoneyIcon.defaultProps = {
  fill: '#8C56F6',
}

MoneyIcon.propTypes = {
  fill: PropTypes.string,
}

export default MoneyIcon
