import React from 'react'
import PropTypes from 'prop-types'
import { RxBorderWidth } from 'react-icons/rx'

// Components
import { ColorPicker } from '../ColorPicker'
import { TextInput } from '../TextInput'

/**
 *
 * BorderControls
 *
 */
const BorderControls = ({ element, updateElement }) => (
  <div className="space-y-1">
    <span className="text-sm font-bold">Border</span>

    <div className="flex items-center gap-2">
      <RxBorderWidth size={22} />

      <TextInput
        name="strokeWidth"
        className="h-[45px] w-[80px] rounded-md border-gray-400 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
        type="number"
        value={element.attrs.strokeWidth}
        onChange={(e) => {
          updateElement({ strokeWidth: parseInt(e.target.value, 10) })
        }}
      />

      <ColorPicker
        color={element.attrs.stroke}
        onChange={(color) => {
          updateElement({ stroke: color.hex })
        }}
        tooltip="Border color"
      />
    </div>
  </div>
)

BorderControls.propTypes = {
  element: PropTypes.object.isRequired,
  updateElement: PropTypes.func.isRequired,
}

export default BorderControls
