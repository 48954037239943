import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Component
import { TextInput } from '../TextInput'

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
}

/**
 * VerificationCodeInput
 *
 * A component that renders a series of inputs for entering a verification code.
 * - The user can enter the code by typing or pasting.
 * - The user can navigate between inputs using the arrow keys.
 * - The user can delete a value by pressing backspace.
 *
 * Based on https://github.com/suweya/react-verification-code-input
 */
const VerificationCodeInput = ({ codeLength, handleSubmitCode, error, inputStyle, maskInput }) => {
  // State
  const [values, setValues] = useState(_.fill(Array(codeLength), null))
  const [refs, setRefs] = useState([])

  useEffect(() => {
    setRefs(_.map(_.range(codeLength), () => createRef()))
  }, [])

  /**
   * Handles submitting the code when we have engough values.
   * @param {array} updatedValues
   */
  const triggerChange = (updatedValues) => {
    const val = updatedValues.join('')

    if (handleSubmitCode && val.length >= codeLength) {
      handleSubmitCode(val)
    }
  }

  /**
   * Handles the `onChange` event for each input.
   * @param {object} e
   * @param {number} index
   */
  const onChange = (e, index) => {
    if (e.target.value === '') {
      return
    }

    let next
    const { value } = e.target
    const updatedValues = Object.assign([], values)

    // If `value` is longer than 1, it means the user pasted a string
    // into the input. We want to split that string into individual
    // characters and set them into the inputs.
    if (value > 1) {
      const split = value.split('')
      split.forEach((item, i) => {
        const cursor = index + i
        if (cursor < codeLength) {
          updatedValues[cursor] = item
        }
      })

      setValues(updatedValues)
    }
    // Otherwise, we want to set the corresponding value to `index`
    else {
      updatedValues[index] = value
      setValues(updatedValues)
    }

    // Configure the next input to receive focus
    // If the user pasted a string, we want to focus the last input
    if (value.length > 1) {
      next = refs[codeLength - 1]
    }
    // If we still have inputs to focus on, go to the next one
    else if (index < codeLength - 1) {
      next = refs[index + 1]
    }
    // Otherwise, we don't want to change the focus at all
    else {
      next = refs[index]
    }

    // Focus the next input
    if (next) {
      next.current.focus()
      next.current.select()
    }

    triggerChange(updatedValues)
  }

  /**
   * Handles any key events for the inputs.
   * @param {object} e
   * @param {number} index
   */
  const onKeyDown = (e, index) => {
    const prevIndex = index - 1
    const nextIndex = index + 1
    const prev = refs[prevIndex]
    const next = refs[nextIndex]

    const updatedValues = [...values]

    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault()

        if (values[index]) {
          updatedValues[index] = null
          setValues(updatedValues)
          triggerChange(updatedValues)
        } else if (prev) {
          updatedValues[prevIndex] = null
          prev.current.focus()
          setValues(updatedValues)
          triggerChange(updatedValues)
        }
        break
      case KEY_CODE.left:
        e.preventDefault()
        if (prev) {
          prev.current.focus()
        }
        break
      case KEY_CODE.right:
        e.preventDefault()
        if (next) {
          next.current.focus()
        }
        break
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault()
        break
      default:
        break
    }
  }

  const getStyles = (i) => {
    if (inputStyle === 'separated') {
      return mergeClassNames(
        'h-[99px] w-[67px] rounded-xl border border-gray-600',
        error && 'border-red-600',
      )
    }
    return `border-y-0 border-l-0 border-gray-600 bg-gray${
      i === refs.length - 1 ? ' rounded-r-lg border-r-0' : ''
    }${i === 0 ? ' rounded-l-lg' : ''}`
  }

  const getInputStyles = () => {
    if (inputStyle === 'separated') {
      return 'text-center font-medium h-12 input-number-hide-controls !text-3xl h-full'
    }

    return 'bg-gray rounded-none rounded-t-md text-white text-center text-lg h-12 sm:h-[60px] input-number-hide-controls'
  }

  /**
   * Handles focusing on the specified target.
   * @param {object} e
   */
  const onFocus = (e) => e.target.select(e)

  const renderInputs = () =>
    _.map(refs, (r, i) => (
      <TextInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={i === 0}
        className={getStyles(i)}
        id={`code:${i}`}
        inputStyles={getInputStyles()}
        onChange={(e) => onChange(e, i)}
        onKeyDown={(e) => onKeyDown(e, i)}
        onFocus={onFocus}
        ref={r}
        type={maskInput ? 'password' : 'number'}
        value={values[i] || ''}
      />
    ))

  return (
    <div className="flex flex-col">
      <div
        className={mergeClassNames('flex justify-between', inputStyle === 'separated' && 'gap-2')}
      >
        {renderInputs()}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error:code">
          {error}
        </p>
      )}
    </div>
  )
}

VerificationCodeInput.propTypes = {
  codeLength: PropTypes.number,
  handleSubmitCode: PropTypes.func.isRequired,
  inputStyle: PropTypes.string,
  error: PropTypes.string || PropTypes.element,
  maskInput: PropTypes.bool,
}

VerificationCodeInput.defaultProps = {
  codeLength: 6,
  error: null,
  inputStyle: 'default',
  maskInput: false,
}

export default VerificationCodeInput
