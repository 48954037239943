export const ANCHORS = {
  CORNERS: ['top-right', 'bottom-right', 'bottom-left', 'top-left'],
  FULL: [
    'top-right',
    'top-center',
    'bottom-right',
    'bottom-center',
    'bottom-left',
    'top-left',
    'middle-left',
    'middle-right',
  ],
}
