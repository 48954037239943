import React from 'react'
import PropTypes from 'prop-types'

const TicketIcon = ({ fill }) => (
  <svg
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-[55px] sm:w-[73px]"
  >
    <g filter="url(#filter0_d_22_3718)">
      <rect x="8.5" y="7" width="50" height="50" rx="6" fill={fill} shapeRendering="crispEdges" />
      <path
        d="M44.75 29.5V30.5C45.3023 30.5 45.75 30.0523 45.75 29.5H44.75ZM44.75 34.5H45.75C45.75 33.9477 45.3023 33.5 44.75 33.5V34.5ZM22.25 34.5V33.5C21.6977 33.5 21.25 33.9477 21.25 34.5H22.25ZM22.25 29.5H21.25C21.25 30.0523 21.6977 30.5 22.25 30.5V29.5ZM24.25 22.25C22.5931 22.25 21.25 23.5931 21.25 25.25H23.25C23.25 24.6977 23.6977 24.25 24.25 24.25V22.25ZM42.75 22.25H24.25V24.25H42.75V22.25ZM45.75 25.25C45.75 23.5931 44.4069 22.25 42.75 22.25V24.25C43.3023 24.25 43.75 24.6977 43.75 25.25H45.75ZM45.75 29.5V25.25H43.75V29.5H45.75ZM43.25 32C43.25 31.1716 43.9216 30.5 44.75 30.5V28.5C42.817 28.5 41.25 30.067 41.25 32H43.25ZM44.75 33.5C43.9216 33.5 43.25 32.8284 43.25 32H41.25C41.25 33.933 42.817 35.5 44.75 35.5V33.5ZM45.75 38.75V34.5H43.75V38.75H45.75ZM42.75 41.75C44.4069 41.75 45.75 40.4069 45.75 38.75H43.75C43.75 39.3023 43.3023 39.75 42.75 39.75V41.75ZM24.25 41.75H42.75V39.75H24.25V41.75ZM21.25 38.75C21.25 40.4069 22.5931 41.75 24.25 41.75V39.75C23.6977 39.75 23.25 39.3023 23.25 38.75H21.25ZM21.25 34.5V38.75H23.25V34.5H21.25ZM23.75 32C23.75 32.8284 23.0784 33.5 22.25 33.5V35.5C24.183 35.5 25.75 33.933 25.75 32H23.75ZM22.25 30.5C23.0784 30.5 23.75 31.1716 23.75 32H25.75C25.75 30.067 24.183 28.5 22.25 28.5V30.5ZM21.25 25.25V29.5H23.25V25.25H21.25Z"
        fill="white"
      />
      <path d="M37.25 23.25V25.75" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M37.25 30.75V33.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.25 38.25V40.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_22_3718"
        x="0.5"
        y="0"
        width="72"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="4" />
        <feGaussianBlur stdDeviation="5.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22_3718" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_22_3718"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

TicketIcon.defaultProps = {
  fill: '#8C56F6',
}

TicketIcon.propTypes = {
  fill: PropTypes.string,
}

export default TicketIcon
