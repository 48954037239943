import React from 'react'
import PropTypes from 'prop-types'

const TicketSimpleIcon = ({ className }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21 10.5V11.5C21.5523 11.5 22 11.0523 22 10.5H21ZM21 14.5H22C22 13.9477 21.5523 13.5 21 13.5V14.5ZM3 14.5V13.5C2.44772 13.5 2 13.9477 2 14.5H3ZM3 10.5H2C2 11.0523 2.44772 11.5 3 11.5V10.5ZM5 4.5C3.34315 4.5 2 5.84315 2 7.5H4C4 6.94772 4.44772 6.5 5 6.5V4.5ZM19 4.5H5V6.5H19V4.5ZM22 7.5C22 5.84315 20.6569 4.5 19 4.5V6.5C19.5523 6.5 20 6.94772 20 7.5H22ZM22 10.5V7.5H20V10.5H22ZM20 12.5C20 11.9477 20.4477 11.5 21 11.5V9.5C19.3431 9.5 18 10.8431 18 12.5H20ZM21 13.5C20.4477 13.5 20 13.0523 20 12.5H18C18 14.1569 19.3431 15.5 21 15.5V13.5ZM22 17.5V14.5H20V17.5H22ZM19 20.5C20.6569 20.5 22 19.1569 22 17.5H20C20 18.0523 19.5523 18.5 19 18.5V20.5ZM5 20.5H19V18.5H5V20.5ZM2 17.5C2 19.1569 3.34315 20.5 5 20.5V18.5C4.44772 18.5 4 18.0523 4 17.5H2ZM2 14.5V17.5H4V14.5H2ZM4 12.5C4 13.0523 3.55228 13.5 3 13.5V15.5C4.65685 15.5 6 14.1569 6 12.5H4ZM3 11.5C3.55228 11.5 4 11.9477 4 12.5H6C6 10.8431 4.65685 9.5 3 9.5V11.5ZM2 7.5V10.5H4V7.5H2Z"
      fill="inherit"
    />
    <path d="M15 5.5V7.5" stroke="inherit" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M15 11.5V13.5"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 17.5V19.5"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

TicketSimpleIcon.defaultProps = {
  className: null,
}

TicketSimpleIcon.propTypes = {
  className: PropTypes.string,
}

export default TicketSimpleIcon
