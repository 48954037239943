import React from 'react'
import PropTypes from 'prop-types'
import { renderToStaticMarkup } from 'react-dom/server'
import { ResponsivePie } from '@nivo/pie'

// Components
import { DoodleSticks } from '../DoodleSticks'

// Style
import baseColors from '../../utils/colors'

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  const total = dataWithArc[0].value + dataWithArc[1].value
  const percent = (dataWithArc[0].value / total || 0) * 100
  const dataLabel = dataWithArc[0].data.label

  let fontSize = 'text-xl'
  let labelSize = 'text-xs'
  if (window.innerWidth < 768) {
    fontSize = 'text-xl'
    labelSize = 'text-[10px]'
  } else if (window.innerWidth < 990) {
    fontSize = 'text-sm'
    labelSize = 'text-xs'
  }

  let centerYOffset = centerY - 15
  if (dataLabel && window.innerWidth > 990) centerYOffset -= 15
  else if (dataLabel && window.innerWidth < 768) centerYOffset -= 15
  else if (dataLabel && window.innerWidth < 990) centerYOffset -= 5

  let labelCenterYOffset = centerYOffset + 20
  if (dataLabel && window.innerWidth < 768) labelCenterYOffset += 5
  else if (dataLabel && window.innerWidth < 990) labelCenterYOffset += 8

  return (
    <g>
      <text
        x={centerX}
        y={centerYOffset}
        textAnchor="middle"
        dominantBaseline="central"
        className={`fill-white font-semibold ${fontSize}`}
      >
        {percent.toPrecision(2)}%
      </text>

      {dataLabel && (
        <text
          x={centerX}
          y={labelCenterYOffset}
          textAnchor="middle"
          dominantBaseline="central"
          className={`fill-white font-semibold ${labelSize}`}
        >
          {dataLabel}
        </text>
      )}
    </g>
  )
}

CenteredMetric.propTypes = {
  dataWithArc: PropTypes.array.isRequired,
  centerX: PropTypes.number.isRequired,
  centerY: PropTypes.number.isRequired,
}

/**
 * MetricTile
 *
 * - Displays KPI data in a tile
 * - Displays odometer graph when specified
 */
const MetricTile = ({ chartLabel, data, type, width }) => {
  const doodle = encodeURIComponent(
    renderToStaticMarkup(<DoodleSticks fill="#000000" opacity={0.02} />),
  )

  if (type === 'chart') {
    let chartData = [
      {
        color: baseColors.purple.dark,
        id: data.label,
        value: data.value,
        label: chartLabel,
      },
      { color: 'white', id: 'Remaining', value: data.total - data.value },
    ]

    // If both fields have 0 value, set the chart to 0 and 1 to show the empty state
    if (chartData[0].value === 0 && chartData[1].value === 0) {
      chartData = [
        {
          color: baseColors.purple.dark,
          id: data.label,
          value: 0,
          label: chartLabel,
        },
        { color: 'white', id: 'Remaining', value: 1 },
      ]
    }

    return (
      <div
        className={`flex h-24 w-full flex-row rounded-lg bg-white py-4 pl-3 backdrop-blur-[1px] sm:h-36 ${width}`}
        key={`${data.label}:chart`}
        style={{
          boxShadow: '5px 10px 30px rgba(140, 86, 246, 0.3)',
          background: 'linear-gradient(99.84deg, #D9C7FC 0.96%, #8C56F6 98.92%)',
          border: '1px solid linear-gradient(99.84deg, #6123DA 0.96%, #F0E8FF 98.92%)',
        }}
      >
        <div className="w-2/5">
          <ResponsivePie
            colors={{ datum: 'data.color' }}
            cornerRadius={50}
            data={chartData}
            innerRadius={0.9}
            isInteractive={false}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            endAngle={90}
            layers={['arcs', CenteredMetric]}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            startAngle="-90"
          />
        </div>

        <div className="ml-3 flex flex-col pt-2 sm:pt-8">
          <span className="text-xs font-bold text-dark sm:text-sm">{data.label}</span>
          <span className="text-lg font-semibold text-gray-dark sm:text-2xl">
            {data.value.toLocaleString()}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`block h-24 w-full rounded-lg bg-white sm:h-36 ${width}`}
      key={`${data.label}:metric`}
      style={{ boxShadow: '5px 10px 30px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' }}
    >
      <div className="flex h-2/3 flex-row pl-2 pt-0 sm:pl-6 sm:pt-6">
        {data.icon}

        <div className="flex flex-col pt-3 sm:pt-2">
          <span className="text-xs font-bold text-gray-600 sm:text-sm">{data.label}</span>
          <span className="text-md font-semibold text-gray-dark sm:text-2xl">
            {data.value.toLocaleString()}
          </span>
        </div>
      </div>

      <div
        className="h-1/3 w-full rounded-b-lg bg-gray-100"
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8, ${doodle}')`,
          backgroundSize: 'cover',
        }}
      />
    </div>
  )
}

MetricTile.defaultProps = {
  chartLabel: null,
  type: 'default',
  width: 'md:w-[30%]',
}

MetricTile.propTypes = {
  chartLabel: PropTypes.string,
  data: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['default', 'chart']),
  width: PropTypes.string,
}

export default MetricTile
