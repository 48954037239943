import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

/**
 * PublicRoute
 *
 * If a user is authenticated and attempts to access the component, they will be
 * redirected to their portfolio
 */
const PublicRoute = observer(({ component: Component }) => {
  // Context
  const { organizationId, type } = useContext(NavigationStoreContext)
  const { isAuthenticated, isEEUser } = useContext(UserStoreContext)
  const location = useLocation()

  // State
  const [from, setFrom] = useState('')

  useEffect(() => {
    // We only want to navigate the user if we have gathered all of the necessary data.
    // We will need to confirm they have an `accessToken` and have the information to determine
    // if they are an EE user or not.
    if (isAuthenticated && (isEEUser !== null || (type && organizationId))) {
      // if `from` is set, use that value, otherwise navigate to the relevant dashboard
      if (location.state) {
        setFrom(location.state.from)
      }
      // Otherwise, if they are an EE user, navigate to the EE dashboard
      else if (isEEUser) {
        setFrom({ pathname: '/dashboard' })
      }
      // Otherwise, navigate to the first organization's dashboard
      else if (type && organizationId) {
        setFrom({ pathname: `/${type}/${organizationId}/dashboard` })
      }
    }
  }, [isAuthenticated, isEEUser, type, organizationId])

  return isAuthenticated && from ? (
    <Navigate
      to={{
        pathname: from.pathname,
        replace: true,
      }}
    />
  ) : (
    <Component />
  )
})

export default PublicRoute
