import dayjs from 'dayjs'
import _ from 'lodash'

/**
 * Handles calculating the total price to display in the modal.
 * @param {object} data current selections for licenses and rental devices
 * @param {object} pricingInfo pricing from the event
 * @param {number} currentLicenseCount total number of paid, non-refunded licenses
 * @returns total price
 */
export const calculatePriceTotal = (data, pricingInfo, currentLicenseCount) => {
  const firstLicenseCost = parseFloat(pricingInfo.firstPrice)
  const additionalLicenseCost = parseFloat(pricingInfo.additionalPrice)
  const leadRetrievalDeviceRentalCost = parseFloat(pricingInfo.rentalPrice)

  // `id` is also the `value` for licenses and rental devices
  let totalLicenseCost = firstLicenseCost + (data.numberOfLicenses.id - 1) * additionalLicenseCost
  if (currentLicenseCount > 0) {
    totalLicenseCost = data.numberOfLicenses.id * additionalLicenseCost
  }
  const totalLeadRetrievalDeviceRentalCost = !data.numberOfLeadRetrievalDevices?.id
    ? 0
    : leadRetrievalDeviceRentalCost * data.numberOfLeadRetrievalDevices.id

  return totalLicenseCost + totalLeadRetrievalDeviceRentalCost || 0
}

/**
 * Gets pricing data based on the date and event pricing categories.
 * @param {object} event the event to get pricing data for
 * @returns
 */
export const getPricingData = (event) => {
  // Set pricing information based on set pricing categories
  const today = dayjs()
  const earlyBirdEndsAt = dayjs(event.earlyBirdEndsAt)
  const standardEndsAt = dayjs(event.standardEndsAt)

  const pricing = {}

  // If there is an early bird category set, check if today is before the early bird ends date
  if (event.earlyBirdEndsAt && today.isBefore(earlyBirdEndsAt)) {
    pricing.firstPrice = event.earlyBirdFirstPrice
    pricing.additionalPrice = event.earlyBirdAdditionalPrice
    pricing.rentalPrice = event.earlyBirdRentalPrice
  }
  // If there is a standard category set, check if today is before the standard ends date
  else if (event.standardEndsAt && today.isBefore(standardEndsAt)) {
    pricing.firstPrice = event.standardFirstPrice
    pricing.additionalPrice = event.standardAdditionalPrice
    pricing.rentalPrice = event.standardRentalPrice
  }
  // Otherwise, use the onsite category
  else {
    pricing.firstPrice = event.onsiteFirstPrice
    pricing.additionalPrice = event.onsiteAdditionalPrice
    pricing.rentalPrice = event.onsiteRentalPrice
  }

  return pricing
}

// Pulled from https://medium.com/@almestaadmicadiab/how-to-parse-google-maps-address-components-geocoder-response-774d1f3375d
const ADDRESS_MAPPING = {
  streetNumber: ['street_number'],
  zip: ['postal_code'],
  streetName: ['street_address', 'route'],
  state: [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5',
  ],
  city: [
    'locality',
    'sublocality',
    'sublocality_level_1',
    'sublocality_level_2',
    'sublocality_level_3',
    'sublocality_level_4',
  ],
  country: ['country'],
}

/**
 *
 * @param {object} billingInfo data from the payment information form
 * @param {object} pricingInfo pricing from the event
 * @param {number} currentLicenseCount total number of paid, non-refunded licenses
 * @returns
 */
export const getChargeData = async (billingInfo, pricingInfo, currentLicenseCount) => {
  const addressComponents = billingInfo.address.address_components
  const address = {
    streetNumber: '',
    streetName: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  }

  addressComponents.forEach((component) => {
    const { types } = component
    _.forEach(_.keys(ADDRESS_MAPPING), (key) => {
      if (ADDRESS_MAPPING[key].some((type) => types.includes(type))) {
        address[key] = component.long_name
      }
    })
  })

  const billTo = {
    firstName: billingInfo.firstName,
    lastName: billingInfo.lastName,
    address: `${address.streetNumber} ${address.streetName}`,
    city: address.city,
    state: address.state,
    zip: address.zip,
    country: address.country,
  }

  const creditCard = {
    cardNumber: billingInfo.cardNumber.replace(/\s/g, ''),
    expirationDate: dayjs(billingInfo.cardExpiry, 'MM / YY').format('YYYY-MM'),
    cardCode: billingInfo.cardCvc,
  }

  const chargeData = {
    expiration_date: creditCard.expirationDate,
    billing_first_name: billTo.firstName,
    billing_last_name: billTo.lastName,
    billing_address: billTo.address,
    billing_city: billTo.city,
    billing_state: billTo.state,
    billing_zip: billTo.zip,
    billing_country: billTo.country,
    requested_amount: calculatePriceTotal(billingInfo, pricingInfo, currentLicenseCount),
    credit_card_number: creditCard.cardNumber,
    credit_card_code: creditCard.cardCode,
    num_licenses: billingInfo.numberOfLicenses.id, // `id` is `value`
    num_rental_devices: billingInfo.numberOfLeadRetrievalDevices.id, // `id` is `value`
  }

  return chargeData
}
