import React from 'react'
import PropTypes from 'prop-types'

const ScanIcon = ({ className }) => (
  <svg
    width="122"
    height="104"
    viewBox="0 0 122 104"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.4"
      d="M107.839 37.4346C105.648 37.4346 103.863 35.641 103.834 33.4089V27.5336C103.905 22.5465 102.024 17.7355 98.6073 14.1631C95.1901 10.5906 90.5175 8.55049 85.6211 8.49319H81.3483C79.136 8.49319 77.3426 6.66647 77.3426 4.4131C77.3426 2.15973 79.136 0.333008 81.3483 0.333008H85.6211C100.244 0.511759 112.006 12.6377 112.005 27.5336V33.3545C112.034 34.4265 111.636 35.4648 110.902 36.2333C110.167 37.0017 109.159 37.435 108.106 37.4346H107.839ZM14.3729 37.435C13.3155 37.4492 12.2964 37.0315 11.5436 36.2749C10.7908 35.5184 10.3671 34.4861 10.3672 33.4089V27.5336C10.2815 20.3497 13.0234 13.43 17.9807 8.31932C22.9381 3.20862 29.698 0.332478 36.7514 0.333008H41.131C43.3433 0.333008 45.1367 2.15973 45.1367 4.4131C45.1367 6.66647 43.3433 8.49319 41.131 8.49319H36.8048C31.8668 8.4778 27.1305 10.4878 23.664 14.0698C20.1974 17.6519 18.2924 22.5046 18.3786 27.5336V33.4089C18.3494 35.641 16.5645 37.435 14.3729 37.435Z"
      fill="inherit"
    />
    <path
      d="M111.845 61.5345V76.8213C111.674 91.5275 100.058 103.432 85.6211 103.695H81.2949C79.7633 103.877 78.2656 103.146 77.4438 101.817C76.622 100.488 76.622 98.7969 77.4438 97.468C78.2656 96.1391 79.7633 95.4086 81.2949 95.5897H85.6211C95.8063 95.5597 104.047 87.1412 104.047 76.7669V61.5345H111.845ZM45.1367 99.6698C45.108 101.911 43.3313 103.721 41.131 103.75H36.7515C22.1282 103.571 10.3662 91.4452 10.3672 76.5493V61.5345H18.3786V76.8213C18.3785 87.1745 26.5872 95.5842 36.7515 95.6441H41.131C42.1885 95.6295 43.2075 96.0472 43.9603 96.8038C44.7131 97.5603 45.1367 98.5926 45.1367 99.6698ZM121.833 57.4545C121.833 59.7078 120.039 61.5345 117.827 61.5345H4.17172C1.95943 61.5345 0.166016 59.7078 0.166016 57.4545C0.166016 55.2011 1.95943 53.3744 4.17172 53.3744H117.667C118.757 53.3299 119.817 53.7401 120.603 54.5101C121.39 55.2802 121.833 56.3435 121.833 57.4545Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M88.8264 43.5276V36.5099C88.8264 33.1193 87.5041 29.8675 85.1503 27.47C82.7965 25.0725 79.604 23.7256 76.2752 23.7256H45.725C38.7932 23.7256 33.1738 29.4493 33.1738 36.5099V43.5276C33.2015 44.3569 33.8551 45.0227 34.6693 45.0509H87.331C88.1452 45.0227 88.7988 44.3569 88.8264 43.5276Z"
      fill="inherit"
    />
    <path
      d="M88.8264 61.5342V67.6271C88.8264 74.6877 83.2071 80.4114 76.2752 80.4114H45.725C38.7932 80.4114 33.1738 74.6877 33.1738 67.6271V61.5342H88.8264Z"
      fill="inherit"
    />
  </svg>
)

ScanIcon.defaultProps = {
  className: null,
}

ScanIcon.propTypes = {
  className: PropTypes.string,
}

export default ScanIcon
