import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Charge a card for a purchase.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {object} transactionData
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createTransaction = async (
  eventId,
  eventExhibitorId,
  transactionData,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/transactions/`,
      transactionData,
    )
    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
